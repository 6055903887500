import { useCarrierSettings } from 'shared/settings/CarrierSettingsAPI';

export type AuthState = 'undetermined' | 'authorized' | 'unauthorized';

export function useAuthState(): AuthState {
  const { data, error } = useCarrierSettings();
  if (data) return 'authorized';
  if (error) return 'unauthorized';
  return 'undetermined';
}
