import {
  Dialog,
  DialogTitle as MuiDialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { ColorDynamic, Column, Columns } from '@superdispatch/ui';
import { startIntercomTour } from 'shared/helpers/Intercom';
import { useFlag } from 'shared/settings/FeatureToggles';
import styled from 'styled-components';
import { useOnboardingDetails } from '../data/OnboardingAPI';
import { ONBOARDING_MENU_ITEM_TOUR_ID } from '../data/OnboardingConstants';
import { useSaveOnboardingWithErrorHandler } from '../data/useSaveOnboardingWithErrorHandler';
import { OnboardingContent } from './OnboardingContent';

interface OnboardingDialogProps {
  isOpen: boolean;
  onClose?: () => void;
}

export function OnboardingDialog({ isOpen, onClose }: OnboardingDialogProps) {
  const { mutateAsync: saveStep } = useSaveOnboardingWithErrorHandler();
  const isOnboardingEnabled = useFlag('ctms_onboarding');
  const { data: onboarding } = useOnboardingDetails({
    enabled: isOnboardingEnabled,
  });

  const handleClose = (isBackdrop: boolean) => {
    if (
      onboarding?.steps?.getting_started_guide?.status === 'pending' &&
      !isBackdrop
    ) {
      void saveStep({
        stepName: 'getting_started_guide',
        status: 'completed',
      });

      startIntercomTour(ONBOARDING_MENU_ITEM_TOUR_ID);
      void saveStep({
        status: 'started',
        stepName: 'tour_getting_started',
      });
    }
    onClose?.();
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth="lg"
      open={isOpen}
      onClose={() => {
        handleClose(false);
      }}
    >
      <OnboardingDialogContent onClose={handleClose} />
    </Dialog>
  );
}

interface OnboardingDialogContentProps {
  onClose: (isBackdrop?: boolean) => void;
}

const DialogTitle = styled(MuiDialogTitle)`
  border-bottom: 1px solid ${ColorDynamic.Silver400};
`;

function OnboardingDialogContent({ onClose }: OnboardingDialogContentProps) {
  return (
    <>
      <DialogTitle>
        <Columns align="top">
          <Column width="fluid">
            <Typography variant="h3">Getting Started</Typography>
          </Column>

          <Column width="content">
            <IconButton
              size="small"
              aria-label="close"
              onClick={() => {
                onClose(false);
              }}
            >
              <Close />
            </IconButton>
          </Column>
        </Columns>
      </DialogTitle>
      <OnboardingContent
        onClose={() => {
          onClose(true);
        }}
      />
    </>
  );
}
