import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { FormikTextField, useFormikEnhanced } from '@superdispatch/forms';
import { useSnackbarStack } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { FormikProvider } from 'formik';
import { useExperimentState } from 'shared/modules/experiments/ExperimentsAPI';
import { useCarrierSettings } from 'shared/settings/CarrierSettingsAPI';
import { getFirestore } from '../core/Firebase';

const FIRESTORE_COLLECTION_ID = 'central-dispatch-skip-reasons';

function addSkipReasonToFirestore(userId: string, reason: string) {
  return getFirestore().then(({ db, firestore: { addDoc, collection } }) => {
    return addDoc(collection(db, FIRESTORE_COLLECTION_ID), {
      userId,
      reason,
    });
  });
}

export function VerifyCentralDispatchAccountSkipDialog({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) {
  const { data: settings } = useCarrierSettings();
  const { addSnackbar } = useSnackbarStack();
  const { markAsViewed } = useExperimentState('Central Dispatch Integration');
  const formik = useFormikEnhanced({
    initialValues: { reason: '' },
    onSubmit: (values) => {
      if (settings?.user.guid) {
        return addSkipReasonToFirestore(settings.user.guid, values.reason);
      }

      return Promise.reject('No user guid found');
    },
    onSubmitSuccess: () => {
      void markAsViewed();
      window.location.reload();
    },
    onSubmitFailure: () => {
      addSnackbar(
        'Something went wrong while storing your feedback to firebase',
        {
          variant: 'error',
        },
      );
    },
  });

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true}>
      <DialogTitle>Skip Integration with Central Dispatch</DialogTitle>
      <DialogContent>
        <FormikProvider value={formik}>
          <FormikTextField
            name="reason"
            placeholder="Please tell in few words why you don't want to import your data"
            multiline={true}
            fullWidth={true}
            maxRows={2}
          />
        </FormikProvider>
      </DialogContent>
      <DialogActions>
        <Button
          variant="neutral"
          onClick={() => {
            onClose();
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={!formik.values.reason}
          onClick={() => {
            void formik.submitForm();
          }}
          pending={formik.isSubmitting}
        >
          Skip Integration
        </Button>
      </DialogActions>
    </Dialog>
  );
}
