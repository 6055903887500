import { Color } from '@superdispatch/ui';
import { createSvgIcon } from 'shared/icons/IconUtils';

export const PickupAltIcon = createSvgIcon(
  'PostingFilterPickup',
  <>
    <circle cx="10" cy="10" r="8.33333" fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.1666 9.23899L13.1701 10.2537L10.6982 7.72363L10.6982 15L9.30171 15L9.30171 7.72363L6.8299 10.2537L5.83331 9.23899L10.0033 5L14.1666 9.23899Z"
      fill={Color.White}
    />
  </>,
  { viewBox: '0 0 20 20' },
);
