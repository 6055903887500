import { useEventHandler } from '@superdispatch/hooks';
import { Color } from '@superdispatch/ui';
import { useEffect, useMemo, useRef } from 'react';
import { LngLatLike, Marker, MarkerEvent, Popup, useMap } from 'react-map-gl';
import { TrackingDriverDTO } from './data/TrackingDTO';
import { TrackingInfoWindow } from './TrackingInfoWindow';

export interface TrackingDriverWithLocation extends TrackingDriverDTO {
  last_location: NonNullable<TrackingDriverDTO['last_location']>;
}

interface TrackingMapboxMarkerProps {
  driver: TrackingDriverWithLocation;
  isFocused: boolean;
  onFocusChange: (id: number | undefined) => void;
}

export function TrackingMapboxMarker({
  driver,
  isFocused,
  onFocusChange,
}: TrackingMapboxMarkerProps) {
  const map = useMap();
  const handleFocusChange = useEventHandler(onFocusChange);
  const markerRef = useRef<MarkerEvent['target'] | null>(null);
  const lngLat: LngLatLike = useMemo(
    () => [driver.last_location.longitude, driver.last_location.latitude],
    [driver.last_location],
  );

  useEffect(() => {
    if (isFocused) {
      map.current?.panTo(lngLat);
    }
  }, [isFocused, map, lngLat]);

  useEffect(() => {
    const marker = markerRef.current;
    const onMouseOver = () => {
      handleFocusChange(driver.id);
    };

    marker?.getElement().addEventListener('mouseover', onMouseOver);

    return () => {
      marker?.getElement().removeEventListener('mouseover', onMouseOver);
    };
  }, [driver.id, handleFocusChange]);

  return (
    <>
      <Marker
        color={Color.Red300}
        longitude={driver.last_location.longitude}
        latitude={driver.last_location.latitude}
        ref={markerRef}
      />
      {isFocused && (
        <Popup
          longitude={driver.last_location.longitude}
          latitude={driver.last_location.latitude}
          anchor="bottom"
          onClose={() => {
            handleFocusChange(undefined);
          }}
          focusAfterOpen={false}
          offset={40}
          maxWidth="300px"
        >
          <TrackingInfoWindow driver={driver} isOpen={isFocused} />
        </Popup>
      )}
    </>
  );
}
