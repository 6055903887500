import { Card, CardContent, Typography } from '@material-ui/core';
import { FormikCheckboxField } from '@superdispatch/forms';
import { Inline, Stack, useUID } from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { useFormikContext } from 'formik';
import { useMemo } from 'react';
import { DeliveryAltIcon } from 'shared/icons/DeliveryAltIcon';
import { LoadDeliveryForm } from '../core/LoadDeliveryForm';
import { AddLoadDTO, isEmptyLoadLegVenue } from '../data/LoadDTO';

interface AddLoadDeliveryProps {
  onCopyToCustomer: () => void;
}

export function AddLoadDelivery({ onCopyToCustomer }: AddLoadDeliveryProps) {
  const uid = useUID();
  const { values } = useFormikContext<AddLoadDTO>();

  const isDeliveryVenueEmpty = useMemo(
    () => isEmptyLoadLegVenue(values.delivery.venue),
    [values.delivery.venue],
  );

  return (
    <Card aria-label="Delivery Information Card">
      <CardContent>
        <Stack space="small">
          <Inline space="xsmall" verticalAlign="center">
            <DeliveryAltIcon />
            <Typography variant="h3" id={uid}>
              Delivery Information
            </Typography>
          </Inline>

          <LoadDeliveryForm name="delivery" />
        </Stack>
      </CardContent>

      <Box padding="small" backgroundColor="Silver200">
        <Inline space="small">
          <FormikCheckboxField
            name="save_delivery_contact"
            label="Save as New Contact"
            disabled={isDeliveryVenueEmpty}
          />

          <Button
            variant="neutral"
            onClick={onCopyToCustomer}
            disabled={isDeliveryVenueEmpty}
          >
            Copy to Customer Information
          </Button>
        </Inline>
      </Box>
    </Card>
  );
}
