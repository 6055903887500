import { Drawer, IconButton, Typography } from '@material-ui/core';
import { Close, Info } from '@material-ui/icons';
import { useFormikEnhanced } from '@superdispatch/forms';
import {
  ColorDynamic,
  Column,
  Columns,
  DrawerContent,
  DrawerTitle,
  Stack,
} from '@superdispatch/ui';
import {
  Alert,
  Box,
  Button,
  DescriptionLineItem,
  TextBox,
} from '@superdispatch/ui-lab';
import { Form, FormikProvider } from 'formik';
import { useEffect } from 'react';
import { isAPIError } from 'shared/api/APIError';
import { useShowSubscriptionCancelledDialog } from 'shared/errors/data/useShowSubscriptionCancelledDialog';
import { useShowTrialExpiredDialog } from 'shared/errors/data/useShowTrialExpiredDialog';
import {
  CountryFieldOption,
  countryFieldOptions,
} from 'shared/form/CountryFieldOptions';
import { formatCurrency } from 'shared/helpers/IntlHelpers';
import { joinStrings } from 'shared/utils/StringUtils';
import { trackSubscriptionEvent } from './data/SubscriptionAnalytics';
import {
  useBillingAddress,
  useEstimateTaxPrice,
  useSubscriptionAPI,
  useSubscriptionSettings,
} from './SubscriptionAPI';

interface SubscriptionReactivationDrawerProps {
  open: boolean;
  onClose: () => void;
  onSubmitSuccess?: () => void;
}

export function SubscriptionReactivationDrawer({
  open,
  onClose,
  onSubmitSuccess,
}: SubscriptionReactivationDrawerProps) {
  return (
    <Drawer open={open} onClose={onClose}>
      <DrawerTitle
        title="Reactivating Plan"
        endAction={
          <IconButton edge="end" aria-label="close" onClick={onClose}>
            <Close />
          </IconButton>
        }
      />
      <ReactivationDrawerContent
        onSubmitSuccess={onSubmitSuccess}
        onClose={onClose}
      />
    </Drawer>
  );
}

function getCountryName(countryCode: CountryFieldOption) {
  return countryFieldOptions.get(countryCode);
}

interface ReactivationDrawerContentProps {
  onClose: () => void;
  onSubmitSuccess?: () => void;
}

function ReactivationDrawerContent({
  onClose,
  onSubmitSuccess,
}: ReactivationDrawerContentProps) {
  const { data: taxPrice } = useEstimateTaxPrice();
  const { data: subscriptionDetails } = useSubscriptionSettings();
  const { data: billingAddress } = useBillingAddress();
  const { reactivateSubscription } = useSubscriptionAPI();
  const { closeTrialExpiredDialog } = useShowTrialExpiredDialog();
  const { closeSubscriptionCancelledDialog } =
    useShowSubscriptionCancelledDialog();

  const formik = useFormikEnhanced({
    initialValues: {},
    onSubmit: () => {
      return reactivateSubscription();
    },
    onSubmitSuccess: () => {
      closeTrialExpiredDialog();
      closeSubscriptionCancelledDialog();
      trackSubscriptionEvent({
        name: 'Carrier Reactivated Subscription',
      });
      onClose();
      onSubmitSuccess?.();
    },
  });

  const errorMessage = isAPIError(formik.status.payload)
    ? formik.status.payload.message
    : null;

  const country = getCountryName(
    billingAddress?.billing_country as CountryFieldOption,
  );
  const state = billingAddress?.billing_state || '';
  const zipCode = billingAddress?.billing_zip || '';
  const cardDetails = subscriptionDetails?.card_information;

  useEffect(() => {
    trackSubscriptionEvent({
      name: 'Carrier Opened Subscription Checkout',
    });
  }, []);

  return (
    <DrawerContent>
      <FormikProvider value={formik}>
        <Form>
          <Stack space="medium">
            <Box paddingBottom="small">
              <Stack space="large">
                <Stack space="xsmall">
                  <Typography color="textSecondary" variant="h6">
                    CARD DETAILS
                  </Typography>
                  <Typography color="textSecondary">
                    Card Number: {cardDetails?.card_number}
                  </Typography>
                  <Typography color="textSecondary">
                    Expiration Date: {cardDetails?.expiry}
                  </Typography>
                </Stack>

                <Stack space="xsmall">
                  <Typography color="textSecondary" variant="h6">
                    Billing Address
                  </Typography>
                  <Typography color="textSecondary">
                    {joinStrings(', ', state + ' ' + zipCode, country)}
                  </Typography>
                </Stack>
              </Stack>
            </Box>

            <Stack space="small">
              {subscriptionDetails?.plan && (
                <Stack space="small">
                  <Stack space="xxsmall">
                    <DescriptionLineItem
                      title={
                        <TextBox variant="body" color="primary">
                          {subscriptionDetails.plan.type}
                        </TextBox>
                      }
                    >
                      <TextBox align="right" color="primary">
                        {formatCurrency(subscriptionDetails.plan.price)}
                      </TextBox>
                    </DescriptionLineItem>

                    <TextBox color="secondary">
                      {formatCurrency(
                        Number(subscriptionDetails.plan.price) /
                          Number(subscriptionDetails.plan.drivers_count),
                      )}{' '}
                      / one driver and TMS
                    </TextBox>
                    <TextBox color="secondary">Billed Monthly</TextBox>
                  </Stack>
                </Stack>
              )}

              <Stack space="xsmall">
                <DescriptionLineItem
                  title={
                    <TextBox variant="body" color="primary">
                      Subtotal
                    </TextBox>
                  }
                >
                  <TextBox align="right" color="primary">
                    {formatCurrency(
                      taxPrice?.sub_total || subscriptionDetails?.plan?.price,
                    )}
                  </TextBox>
                </DescriptionLineItem>
                {taxPrice?.taxes && taxPrice.taxes.length > 0 && (
                  <Columns>
                    <Column>
                      <Box />
                    </Column>
                    <Column width="2/3">
                      <Stack align="right" space="xxsmall">
                        {taxPrice.taxes.map((tax) => {
                          return (
                            <DescriptionLineItem
                              key={tax.name}
                              title={
                                <TextBox color="secondary">
                                  {tax.description}
                                </TextBox>
                              }
                            >
                              <TextBox align="right" color="secondary">
                                {formatCurrency(tax.amount)}
                              </TextBox>
                            </DescriptionLineItem>
                          );
                        })}
                      </Stack>
                    </Column>
                  </Columns>
                )}
              </Stack>

              <DescriptionLineItem
                title={<TextBox variant="heading-4">Total</TextBox>}
              >
                <TextBox variant="heading-4" align="right">
                  {formatCurrency(
                    taxPrice?.total || subscriptionDetails?.plan?.price,
                  )}
                </TextBox>
              </DescriptionLineItem>
            </Stack>
            <Box paddingTop="xsmall">
              <Button
                pending={formik.isSubmitting}
                type="submit"
                size="large"
                fullWidth={true}
              >
                Reactivate Now
              </Button>
            </Box>

            {errorMessage && (
              <Alert
                icon={<Info fontSize="small" htmlColor={ColorDynamic.Red500} />}
                severity="critical"
              >
                {errorMessage}
              </Alert>
            )}
          </Stack>
        </Form>
      </FormikProvider>
    </DrawerContent>
  );
}
