import { Card, Divider, Typography } from '@material-ui/core';
import { Stack, useResponsiveValue } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { Image } from 'shared/ui/Image';
import AccountDeletedIconDark from '../assets/account-deleted-icon-dark.png';
import AccountDeletedIcon from '../assets/account-deleted-icon.svg';
import AskForInviteIconDark from '../assets/ask-for-invite-icon-dark.png';
import AskForInviteIcon from '../assets/ask-for-invite-icon.svg';
import DownloadAppIcon from '../assets/download-app-icon.svg';
import QRCodeIconDark from '../assets/qr-code-icon-dark.png';
import QRCodeIcon from '../assets/qr-code-icon.svg';
import { DriverActivationStepItem } from '../core/DriverActivationStepItem';
import { DriverActivationDownloadAppSection } from './core/DriverActivationDownloadAppSection';

export function DriverActivationAccountDeletedPage() {
  const isMobile = useResponsiveValue(true, true, false);

  return (
    <Box
      maxWidth="600px"
      marginLeft="auto"
      marginRight="auto"
      marginTop={['none', 'large']}
      marginBottom={['none', 'large']}
    >
      <Card>
        <Box padding={['small', 'medium']}>
          <Stack space="large">
            <Stack space="xsmall" align="center">
              <Image
                src={AccountDeletedIcon}
                srcDark={AccountDeletedIconDark}
              />

              <Typography variant="h2">
                Your account has been deleted
              </Typography>
            </Stack>
            <Divider />
            <Typography variant="h6" align="center">
              WHAT ARE THE NEXT STEPS?
            </Typography>
            <Stack space={['xlarge', 'small']}>
              <DriverActivationStepItem
                iconSrc={AskForInviteIcon}
                iconSrcDark={AskForInviteIconDark}
                title="1. Ask for Invite"
                description="Ask your dispatcher to add you as a driver from their Carrier TMS account. Follow the link you get to set your password and access loads."
              />
              {isMobile ? (
                <DriverActivationStepItem
                  iconSrc={DownloadAppIcon}
                  title="2. Download the App"
                  description="If you are Owner-Operator, download the Super Dispatch App and create account for yourself."
                />
              ) : (
                <DriverActivationStepItem
                  iconSrc={QRCodeIcon}
                  iconSrcDark={QRCodeIconDark}
                  title="2. Download the App"
                  description="If you are Owner-Operator, scan the QR code to download the Super Dispatch App and create account for yourself."
                />
              )}
            </Stack>
          </Stack>
        </Box>
      </Card>
      {isMobile && <DriverActivationDownloadAppSection />}
    </Box>
  );
}
