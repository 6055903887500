import { Tooltip } from '@material-ui/core';
import { CustomerType } from '@superdispatch/sdk';
import { Inline, Tag } from '@superdispatch/ui';
import { AuctionIcon } from 'shared/icons/business-type/AuctionIcon';
import { BusinessIcon } from 'shared/icons/business-type/BusinessIcon';
import { CarRentalIcon } from 'shared/icons/business-type/CarRentalIcon';
import { DealerIcon } from 'shared/icons/business-type/DealerIcon';
import { PortIcon } from 'shared/icons/business-type/PortIcon';
import { PrivateIcon } from 'shared/icons/business-type/PrivateIcon';
import { RepoYardIcon } from 'shared/icons/business-type/RepoYardIcon';

interface Props {
  businessType: CustomerType;
}

export function BusinessTypeTag({ businessType }: Props) {
  return (
    <Tooltip title="Business type" enterDelay={400}>
      <Tag variant="subtle" color="dark">
        <Inline noWrap={true} space="xxsmall" verticalAlign="center">
          {businessType === 'BUSINESS' && (
            <>
              <BusinessIcon fontSize="small" />
              Business
            </>
          )}

          {businessType === 'DEALER' && (
            <>
              <DealerIcon fontSize="small" />
              Dealer
            </>
          )}

          {businessType === 'PRIVATE' && (
            <>
              <PrivateIcon fontSize="small" />
              Private
            </>
          )}

          {businessType === 'AUCTION' && (
            <>
              <AuctionIcon fontSize="small" />
              Auction
            </>
          )}

          {businessType === 'REPO_YARD' && (
            <>
              <RepoYardIcon fontSize="small" />
              Repo Yard
            </>
          )}

          {businessType === 'PORT' && (
            <>
              <PortIcon fontSize="small" />
              Port
            </>
          )}

          {businessType === 'CAR_RENTAL' && (
            <>
              <CarRentalIcon fontSize="small" />
              Car Rental
            </>
          )}
        </Inline>
      </Tag>
    </Tooltip>
  );
}
