import { Dialog, Typography } from '@material-ui/core';
import { Inline, Stack, useSnackbarStack } from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { trackEvent } from 'shared/helpers/Analytics';
import { useExpeditedPayReminder } from 'shared/modules/superpay/SuperPayAPI';
import { useExpeditedPayBannerAccessTime } from './useExpeditedPayBannerAccessTime';

interface ExpeditedPayReminderContentProps {
  onDismissNow: () => void;
  onAlwaysClosed: () => void;
}

function ExpeditedPayReminderContent({
  onAlwaysClosed,
  onDismissNow,
}: ExpeditedPayReminderContentProps) {
  return (
    <Box
      paddingLeft="medium"
      paddingRight="medium"
      paddingTop="small"
      paddingBottom="small"
    >
      <Stack space="medium">
        <Stack space="small">
          <Typography variant="h3">
            Turn Off Expedited Payment Reminders?
          </Typography>
          <Typography>
            Would you like to snooze this reminder for now, or stop receiving it
            completely?
          </Typography>
        </Stack>
        <Inline space="small" horizontalAlign="center">
          <Button variant="neutral" onClick={onAlwaysClosed}>
            Don’t Show Me Again
          </Button>
          <Button
            onClick={() => {
              onDismissNow();
              trackEvent(
                'Carrier Clicked Dismiss Now on Expedited Payment Reminder',
              );
            }}
          >
            Dismiss Now
          </Button>
        </Inline>
      </Stack>
    </Box>
  );
}

interface ExpeditedPayReminderDialogProps {
  onClose: () => void;
  onAlwaysClosed: () => void;
  isOpen: boolean;
}

export function ExpeditedPayReminderDialog({
  isOpen,
  onClose,
  onAlwaysClosed,
}: ExpeditedPayReminderDialogProps) {
  const { addSnackbar } = useSnackbarStack();
  const { setNextDay } = useExpeditedPayBannerAccessTime();

  const { mutateAsync: setAlwaysClosed } = useExpeditedPayReminder({
    onSuccess() {
      onAlwaysClosed();
    },
    onError(error) {
      addSnackbar(error.message || 'An unexpected error happened. Try again.', {
        variant: 'error',
      });
    },
  });

  const onDismissNow = () => {
    setNextDay();
    onClose();
    addSnackbar('Expedited Payment Reminders Snoozed.');
  };

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="xs">
      <ExpeditedPayReminderContent
        onDismissNow={onDismissNow}
        onAlwaysClosed={() => {
          void setAlwaysClosed();
        }}
      />
    </Dialog>
  );
}
