import { Card, CardContent, Typography } from '@material-ui/core';
import { Business } from '@material-ui/icons';
import { FormikCheckboxField } from '@superdispatch/forms';
import { Inline, Stack, useUID } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { useFormikContext } from 'formik';
import { useMemo } from 'react';
import { LoadCustomerForm } from '../core/LoadCustomerForm';
import { AddLoadDTO, isEmptyLoadLegVenue } from '../data/LoadDTO';

export function AddLoadCustomer() {
  const idx = useUID();
  const { values } = useFormikContext<AddLoadDTO>();
  const isEmpty = useMemo(
    () => isEmptyLoadLegVenue(values.customer.venue),
    [values.customer.venue],
  );

  return (
    <Card aria-label="Customer Card">
      <CardContent>
        <Stack space="small">
          <Inline verticalAlign="center" space="xsmall">
            <Business />
            <Typography variant="h3" id={idx}>
              Customer
            </Typography>
          </Inline>

          <LoadCustomerForm name="customer" />
        </Stack>
      </CardContent>

      <Box padding="small" backgroundColor="Silver200">
        <FormikCheckboxField
          disabled={isEmpty}
          name="save_customer_contact"
          label="Save as New Contact"
        />
      </Box>
    </Card>
  );
}
