import { CardComponent } from '@chargebee/chargebee-js-react-wrapper';
import ChargebeeComponents from '@chargebee/chargebee-js-react-wrapper/dist/components/ComponentGroup';
import { Fade, Typography } from '@material-ui/core';
import { FormikCheckboxField } from '@superdispatch/forms';
import { Column, Columns, Stack } from '@superdispatch/ui';
import { useField } from 'formik';
import { forwardRef, useState } from 'react';
import {
  ChargebeeErrorField,
  ChargebeeEvent,
  fonts,
} from 'shared/helpers/ChargebeeWeb';
import { SubscriptionBillingForm } from 'shared/modules/subscription/core/SubscriptionBillingForm';
import { useBillingAddress } from 'shared/modules/subscription/SubscriptionAPI';
import { CVVTitleProps } from 'shared/ui/ChargebeeCvvTitle';
import {
  FormCardCVV,
  FormCardExpiry,
  FormCardNumber,
} from 'shared/ui/ChargebeeFormFields';
import { joinStrings } from 'shared/utils/StringUtils';

export const PaywallSubscriptionForm = forwardRef<ChargebeeComponents | null>(
  (_, cardRef) => {
    const [field] = useField<boolean>({
      name: 'can_use_company_billing_address',
    });

    const canUseCompanyBillingAddress = field.value;

    const [focus, setFocus] = useState<'number' | 'expiry' | 'cvv'>();
    const [errors, setErrors] = useState<ChargebeeErrorField>({
      expiry: null,
      cvv: null,
      number: null,
    });
    const [card, setCard] = useState<CVVTitleProps>({
      isEmpty: true,
      cardType: undefined,
    });

    const { data: billingAddress } = useBillingAddress();

    const handleError = (event: ChargebeeEvent) => {
      const { field: chargebeeField, error } = event;
      setErrors((prevErr) => ({ ...prevErr, [chargebeeField]: error ?? null }));
      if (chargebeeField === 'number') {
        setCard({ cardType: event.cardType, isEmpty: event.empty });
      }
    };

    return (
      <CardComponent
        placeholder={{
          cvv: 'CVV2, CVC2, etc.',
          number: ' ',
          expiry: 'MM / YY',
        }}
        onChange={handleError}
        fonts={fonts}
        ref={cardRef}
      >
        <Stack space="medium">
          <Stack space="small">
            <FormCardNumber
              label="Card Number"
              focused={focus === 'number'}
              onFocus={() => {
                setFocus('number');
              }}
              error={errors.number ? errors.number.message : ''}
            />
            <Columns space="small">
              <Column width="1/2">
                <FormCardExpiry
                  label="Expiration"
                  focused={focus === 'expiry'}
                  onFocus={() => {
                    setFocus('expiry');
                  }}
                  error={errors.expiry ? errors.expiry.message : ''}
                />
              </Column>
              <Column width="1/2">
                <FormCardCVV
                  {...card}
                  label="Security Code"
                  focused={focus === 'cvv'}
                  onFocus={() => {
                    setFocus('cvv');
                  }}
                  error={errors.cvv ? errors.cvv.message : ''}
                />
              </Column>
            </Columns>
          </Stack>
          {canUseCompanyBillingAddress ? (
            <FormikCheckboxField
              name="can_use_company_billing_address"
              label={
                <Typography>Use company address as billing address</Typography>
              }
              helperText={joinStrings(
                ', ',
                billingAddress?.billing_state,
                billingAddress?.billing_city,
                billingAddress?.billing_zip,
                billingAddress?.billing_country,
              )}
            />
          ) : (
            <Fade in={!canUseCompanyBillingAddress}>
              <Stack space="small">
                <Typography variant="h4">Billing Address</Typography>
                <SubscriptionBillingForm
                  onFocus={() => {
                    setFocus(undefined);
                  }}
                />
              </Stack>
            </Fade>
          )}
        </Stack>
      </CardComponent>
    );
  },
);

PaywallSubscriptionForm.displayName = 'PaywallSubscriptionForm';
