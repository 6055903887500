import { Tooltip } from '@material-ui/core';
import { Star } from '@material-ui/icons';
import { ColorDynamic, Inline } from '@superdispatch/ui';
import { Box, TextBox } from '@superdispatch/ui-lab';

export function PostingExclusiveTag() {
  return (
    <Tooltip
      placement="top"
      title={
        <span>
          This load can be booked only on <br /> Super Loadboard
        </span>
      }
    >
      <Box
        borderRadius="small"
        paddingLeft="xxsmall"
        paddingRight="xxsmall"
        backgroundColor="Dark500"
      >
        <Inline space="xxsmall" verticalAlign="center">
          <Star fontSize="small" htmlColor={ColorDynamic.White} />
          <TextBox variant="body-semibold" color="white">
            Exclusive
          </TextBox>
        </Inline>
      </Box>
    </Tooltip>
  );
}
