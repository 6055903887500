import { HTTPError, parseHTTPEndpoint } from '@superdispatch/http';
import { createAPIError } from 'shared/api/APIError';

export function requestBuildInfo() {
  return fetch('/tms/build-info.json')
    .then((response) => {
      if (!response.ok) {
        throw new HTTPError(
          parseHTTPEndpoint('GET /build-info.json'),
          response,
        );
      }

      return response.json();
    })
    .catch((error: Error) => Promise.reject(createAPIError(error)));
}
