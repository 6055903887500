import {
  CardCVV as CardCVVChargebee,
  CardExpiry as CardExpiryChargebee,
  CardNumber as CardNumberChargebee,
} from '@chargebee/chargebee-js-react-wrapper';
import {
  FormGroup,
  FormHelperText,
  InputLabel,
  Tooltip,
} from '@material-ui/core';
import { Info } from '@material-ui/icons';
import { Color, ColorDynamic, Inline } from '@superdispatch/ui';
import { useMemo } from 'react';
import { useAppThemeMode } from 'shared/theme/AppThemeProvider';
import styled, { css } from 'styled-components';
import { CVVTitle, CVVTitleProps } from './ChargebeeCvvTitle';

interface InputStyleProps {
  hasError: boolean;
  focused: boolean;
}

const baseInputStyles = css<InputStyleProps>`
  // Chargebee acts weird its background when color-scheme is set to dark. So we need to set it to normal.
  color-scheme: normal;
  border: 1px solid;
  border-radius: 4px;
  padding: 6px 8px;
  border-color: ${({ hasError, focused }) =>
    hasError ? Color.Red500 : focused ? Color.Blue500 : Color.Silver500};
  outline: ${({ hasError, focused }) =>
    hasError && focused
      ? `${ColorDynamic.Red500} solid 1px`
      : focused
      ? `${ColorDynamic.Blue500} solid 1px`
      : null};

  &:hover {
    border-color: ${({ hasError, focused }) =>
      hasError
        ? ColorDynamic.Red500
        : focused
        ? ColorDynamic.Blue500
        : ColorDynamic.Dark100};
  }
`;

const CardNumber = styled(CardNumberChargebee)<InputStyleProps>`
  ${baseInputStyles}
`;

const CardCVV = styled(CardCVVChargebee)<InputStyleProps>`
  ${baseInputStyles}
`;

const CardExpiry = styled(CardExpiryChargebee)<InputStyleProps>`
  ${baseInputStyles}
`;

function useStyles() {
  const theme = useAppThemeMode();

  return useMemo(
    () => ({
      base: {
        color: theme === 'dark' ? Color.White : Color.Dark500,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        '::placeholder': {
          color: Color.Dark100,
        },
      },
      invalid: {
        color: Color.Red300,
      },
      empty: {},
    }),
    [theme],
  );
}

interface FormFieldProps {
  children: React.ReactNode;
  label: string;
  error: string;
  icon?: React.ReactNode;
}

interface FormInputProps extends CVVTitleProps {
  error: string;
  focused: boolean;
  label: string;
  onFocus: () => void;
  icon?: React.ReactNode;
}

const FormField = ({ children, error, label, icon }: FormFieldProps) => {
  return (
    <FormGroup>
      <InputLabel>
        <Inline verticalAlign="center">
          {label}
          {icon}
        </Inline>
      </InputLabel>

      {children}

      {!!error && <FormHelperText error={true}>{error}</FormHelperText>}
    </FormGroup>
  );
};

export const FormCardNumber = ({
  error,
  focused,
  onFocus,
  label,
}: FormInputProps) => {
  const style = useStyles();

  return (
    <FormField label={label} error={error}>
      <CardNumber
        styles={style}
        focused={focused}
        onFocus={onFocus}
        hasError={!!error}
      />
    </FormField>
  );
};

export const FormCardExpiry = ({
  error,
  focused,
  onFocus,
  label,
}: FormInputProps) => {
  const style = useStyles();

  return (
    <FormField label={label} error={error}>
      <CardExpiry
        styles={style}
        focused={focused}
        onFocus={onFocus}
        hasError={!!error}
      />
    </FormField>
  );
};

export const FormCardCVV = ({
  error,
  focused,
  onFocus,
  label,
  isEmpty,
  cardType,
}: FormInputProps) => {
  const style = useStyles();
  return (
    <FormField
      icon={
        <Tooltip
          interactive={true}
          placement="bottom-end"
          title={<CVVTitle isEmpty={isEmpty} cardType={cardType} />}
        >
          <Info fontSize="small" htmlColor={Color.Dark100} />
        </Tooltip>
      }
      label={label}
      error={error}
    >
      <CardCVV
        styles={style}
        focused={focused}
        onFocus={onFocus}
        hasError={!!error}
      />
    </FormField>
  );
};
