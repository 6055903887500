import { useAPISocket } from 'shared/api/APISocket';
import { ONE_MINUTE } from 'shared/constants/NumberConstants';
import { useTrackingCache, useTrackingDriverPage } from '../data/TrackingAPI';
import {
  getTrackingAppStatus,
  trackingWebSocketMessageSchema,
} from '../data/TrackingDTO';

export function useTrackingDriverAutoUpdatePage() {
  const { updateDriver } = useTrackingCache();

  const { status: webSocketStatus } = useAPISocket(
    '/gps-tracking/',
    (action) => {
      if (action.type !== 'message') return;

      const message = trackingWebSocketMessageSchema.cast(
        JSON.parse(action.event.data as string),
      );

      updateDriver(message.driver, (driver) => ({
        ...driver,
        last_location: message.location || driver.last_location,
        app_status: message.app_status || driver.app_status,
        disabled: message.app_status
          ? getTrackingAppStatus(message.app_status) !== 'tracking_active'
          : driver.disabled,
      }));
    },
  );

  return useTrackingDriverPage({
    refetchInterval: webSocketStatus === 'open' ? false : ONE_MINUTE,
  });
}
