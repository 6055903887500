import { Card, CardContent, Typography } from '@material-ui/core';
import { FormikCheckboxField } from '@superdispatch/forms';
import { Inline, Stack, useUID } from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { useFormikContext } from 'formik';
import { useMemo } from 'react';
import { PickupAltIcon } from 'shared/icons/PickupAltIcon';
import { LoadPickupForm } from '../core/LoadPickupForm';
import { AddLoadDTO, isEmptyLoadLegVenue } from '../data/LoadDTO';

interface AddLoadPickupProps {
  onCopyToCustomer: () => void;
}

export function AddLoadPickup({ onCopyToCustomer }: AddLoadPickupProps) {
  const uid = useUID();
  const { values } = useFormikContext<AddLoadDTO>();
  const isPickupVenueEmpty = useMemo(
    () => isEmptyLoadLegVenue(values.pickup.venue),
    [values.pickup.venue],
  );

  return (
    <Card aria-label="Pickup Information Card">
      <CardContent>
        <Stack space="small">
          <Inline verticalAlign="center" space="xsmall">
            <PickupAltIcon />
            <Typography variant="h3" id={uid}>
              Pickup Information
            </Typography>
          </Inline>

          <LoadPickupForm name="pickup" />
        </Stack>
      </CardContent>

      <Box padding="small" backgroundColor="Silver200">
        <Inline space="small">
          <FormikCheckboxField
            name="save_pickup_contact"
            label="Save as New Contact"
            disabled={isPickupVenueEmpty}
          />
          <Button
            variant="neutral"
            onClick={onCopyToCustomer}
            disabled={isPickupVenueEmpty}
          >
            Copy to Customer Information
          </Button>
        </Inline>
      </Box>
    </Card>
  );
}
