import { CardContent, Typography } from '@material-ui/core';
import { Add, DirectionsCar } from '@material-ui/icons';
import { CardButton, Column, Columns, Inline, Stack } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { useMemo, useState } from 'react';
import { formatPlural } from 'shared/helpers/IntlHelpers';
import { HiddenForPrint } from 'shared/ui/Print';
import { LoadVehicleDeleteDialog } from '../../core/LoadVehicleDeleteDialog';
import { LoadVehicleDisabledTooltip } from '../../core/LoadVehicleDisabledTooltip';
import { LoadVehicleDrawer } from '../../core/LoadVehicleDrawer';
import {
  formatLoadStatus,
  LoadDTO,
  sortVehiclesByStatus,
} from '../../data/LoadDTO';
import { trackLoadsEvent } from '../../data/LoadsAnalytics';
import { useLoadStageFromBackURL } from '../ViewLoadHeaderActions';
import { ViewLoadVehicleMobile } from './ViewLoadVehicleMobile';

interface ViewLoadVehiclesProps {
  load: LoadDTO;
  layout?: 'print';
  isEnabledEditLoad?: boolean;
}

type ModalState =
  | { type: 'add'; payload?: never }
  | { type: 'edit'; payload: string }
  | { type: 'delete'; payload: string };

const getModalStatePayload = <T extends ModalState>(
  type: T['type'],
  modalState: ModalState | undefined,
): undefined | T['payload'] =>
  modalState?.type === type ? modalState.payload : undefined;

export function ViewLoadVehiclesMobile({
  load,
  layout,
  isEnabledEditLoad = true,
}: ViewLoadVehiclesProps) {
  const [modalState, setModalState] = useState<ModalState>();
  const loadStage = useLoadStageFromBackURL();
  const sortedVehicles = useMemo(
    () => sortVehiclesByStatus(load.vehicles),
    [load.vehicles],
  );

  const closeModal = () => {
    setModalState(undefined);
  };

  return (
    <>
      <LoadVehicleDrawer
        type="add"
        loadGUID={load.guid}
        onClose={closeModal}
        open={modalState?.type === 'add'}
      />

      <LoadVehicleDrawer
        type="edit"
        loadGUID={load.guid}
        onClose={closeModal}
        vehicleGUID={getModalStatePayload('edit', modalState)}
      />

      <LoadVehicleDeleteDialog
        loadGUID={load.guid}
        onDismiss={closeModal}
        vehicleGUID={getModalStatePayload('delete', modalState)}
        onSubmitSuccess={() => {
          closeModal();
          trackLoadsEvent({
            name: 'Carrier Edited Load',
            utm_medium: loadStage,
            utm_content: 'View Page',
            load_status: formatLoadStatus(load.status),
            is_created_by_broker: !!load.is_created_by_broker,
            vehicles: 'Edited',
          });
        }}
      />

      {!load.vehicles.length ? (
        layout !== 'print' && (
          <HiddenForPrint>
            <CardContent>
              <CardButton
                startIcon={<Add />}
                disabled={!load.can_be_edited || !isEnabledEditLoad}
                onClick={() => {
                  setModalState({ type: 'add' });
                }}
              >
                Add Vehicles
              </CardButton>
            </CardContent>
          </HiddenForPrint>
        )
      ) : (
        <CardContent aria-label="Vehicles Title">
          <Stack>
            <Columns space="small" align="center">
              <Column width="fluid">
                <Inline verticalAlign="center" space="xsmall">
                  <DirectionsCar />
                  <Typography variant="h3">
                    {load.vehicles.length}{' '}
                    {formatPlural(load.vehicles.length, 'Vehicle', 'Vehicles')}
                  </Typography>
                </Inline>
              </Column>
              <Column width="content">
                {layout !== 'print' && (
                  <HiddenForPrint>
                    <LoadVehicleDisabledTooltip
                      isEnabledEditLoad={isEnabledEditLoad}
                    >
                      <Button
                        aria-label="Add Vehicle"
                        variant="text"
                        disabled={!isEnabledEditLoad}
                        startIcon={<Add />}
                        onClick={() => {
                          setModalState({ type: 'add' });
                        }}
                      >
                        Add
                      </Button>
                    </LoadVehicleDisabledTooltip>
                  </HiddenForPrint>
                )}
              </Column>
            </Columns>
            <Stack>
              {sortedVehicles.map((vehicle) => (
                <ViewLoadVehicleMobile
                  load={load}
                  layout={layout}
                  vehicle={vehicle}
                  key={vehicle.guid}
                  isEnabledEditLoad={isEnabledEditLoad}
                  onEditClick={() => {
                    setModalState({ type: 'edit', payload: vehicle.guid });
                  }}
                  onDeleteClick={() => {
                    setModalState({ type: 'delete', payload: vehicle.guid });

                    if (load.status !== 'new') {
                      trackLoadsEvent({
                        page: 'view_load',
                        name: 'CTMS: Clicked Delete Vehicle',
                      });
                    }
                  }}
                />
              ))}
            </Stack>
          </Stack>
        </CardContent>
      )}
    </>
  );
}
