import { DateConfigProvider } from '@superdispatch/dates';
import { useFormikEnhanced } from '@superdispatch/forms';
import { PaymentMethod, PaymentTerm } from '@superdispatch/sdk';
import {
  Column,
  Columns,
  Inline,
  Stack,
  useSnackbarStack,
} from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { Form, FormikProvider } from 'formik';
import { merge } from 'lodash-es';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DocumentTitle } from 'shared/layout/DocumentTitle';
import { PageHeader, PageLayout } from 'shared/layout/PageLayout';
import { Prompt } from 'shared/routing/NavigationBlock';
import { useCarrierSettings } from 'shared/settings/CarrierSettingsAPI';
import { useFlag } from 'shared/settings/FeatureToggles';
import { BackButton } from 'shared/ui/BackButton';
import { LoadAttachmentsCard } from '../core/LoadAttachmentsCard';
import { LoadCustomerReplaceConfirmDialog } from '../core/LoadCustomerReplaceConfirmDialog';
import { LoadVehiclesCard } from '../core/LoadVehiclesCard';
import { UpgradePlanDialog } from '../core/UpgradePlanDialog';
import {
  isEmptyLoadPayment,
  isEmptyLoadVehicleDTO,
  loadVehicleCreateSchema,
} from '../data/LoadActionsDTO';
import {
  AddLoadDTO,
  addLoadSchema,
  isEmptyLoadLeg,
  isEmptyLoadLegVenue,
} from '../data/LoadDTO';
import { emitLoadEvent } from '../data/LoadEvents';
import { trackLoadsEvent } from '../data/LoadsAnalytics';
import { useLoadsCache } from '../data/LoadsAPI';
import { useLoadsContext } from '../data/LoadsContext';
import { AddLoadCustomer } from './AddLoadCustomer';
import { AddLoadDelivery } from './AddLoadDelivery';
import { AddLoadPageDetails } from './AddLoadPageDetails';
import { AddLoadPayment } from './AddLoadPayment';
import { AddLoadPickup } from './AddLoadPickup';

export function AddLoadPage() {
  const { data: settings } = useCarrierSettings();
  const { loadsAPI, loadPriceCalculationStatus } = useLoadsContext();
  const { addSnackbar } = useSnackbarStack();
  const navigate = useNavigate();
  const [isSaveAndEdit, setIsSaveAndEdit] = useState(false);
  const { invalidateLoads } = useLoadsCache();
  const shouldShowPaymentTerms = useFlag('load_payment_terms');

  const initialValues = useMemo(
    () =>
      addLoadSchema.cast({
        inspection_type: 'standard',
        pickup: { venue: { contacts: [{}] } },
        delivery: { venue: { contacts: [{}] } },
        vehicles: [{}],
        customer: { venue: { contacts: [{}] } },
        payments: [
          {
            method: 'other',
            invoice: {},
            payment_details: {
              payment_method: 'other',
              payment_terms: null,
            },
          },
        ],
        attachments: [],
      }),
    [],
  );

  const formik = useFormikEnhanced({
    initialValues,
    validateOnBlur: true,
    validateOnChange: false,
    validationSchema: addLoadSchema,
    onSubmit(values) {
      const loadValues = {
        ...values,
        payments: [
          {
            ...values.payments[0],
            method: values.payments[0]?.payment_details
              ?.payment_method as PaymentMethod,
            payment_details: {
              payment_method: shouldShowPaymentTerms
                ? (values.payments[0]?.payment_details
                    ?.payment_method as PaymentMethod)
                : null,
              payment_terms: shouldShowPaymentTerms
                ? (values.payments[0]?.payment_details
                    ?.payment_terms as PaymentTerm)
                : null,
            },
          },
        ] as AddLoadDTO['payments'],
        vehicles: values.vehicles
          .map((vehicle) => loadVehicleCreateSchema.cast(vehicle))
          .filter((vehicle) => !isEmptyLoadVehicleDTO(vehicle)),
      };

      return loadsAPI.addLoad(loadValues);
    },
    onSubmitSuccess(response: { guid: string }, values) {
      invalidateLoads();
      addSnackbar('New load created', { variant: 'success' });
      if (isSaveAndEdit) {
        navigate(`/loads/${response.guid}/edit`, { replace: true });
      } else {
        navigate('/loads', { replace: true });
        emitLoadEvent('created');
      }

      trackLoadsEvent({
        name: 'Carrier Created Load',
        saved_by: isSaveAndEdit
          ? 'Save and Continue Editing Button'
          : 'Save Button',
        is_attachments_added: !!values.attachments.length,
        is_customer_information_added: !isEmptyLoadLegVenue(
          values.customer.venue,
        ),
        is_delivery_information_added: !isEmptyLoadLeg(values.delivery),
        is_pickup_information_added: !isEmptyLoadLeg(values.pickup),
        is_instructions_added: !!values.instructions,
        is_load_details_added:
          !!values.number ||
          !!values.internal_load_id ||
          !!values.instructions ||
          values.inspection_type !== 'standard',
        is_vehicles_added: values.vehicles.length
          ? !isEmptyLoadVehicleDTO(
              values.vehicles[values.vehicles.length - 1] || {},
            )
          : false,
        is_payment_information_added: !isEmptyLoadPayment(values.payments[0]),
      });
    },
    onSubmitFailure(error) {
      setIsSaveAndEdit(false);
      addSnackbar(error.message || 'Error occurred while creating load', {
        variant: 'error',
      });
    },
  });

  const [confirmCopyToLeg, setConfirmCopyToLeg] = useState<
    'pickup' | 'delivery'
  >();
  const attachmentsErrorRef = useRef<HTMLElement>(null);

  const shouldRestrictImport =
    !settings?.can_import_load || !settings.can_create_load;

  useEffect(() => {
    if (formik.isSubmitting) {
      attachmentsErrorRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [formik.isSubmitting]);

  const { submitForm, values, setFieldValue } = formik;

  useEffect(() => {
    if (isSaveAndEdit) {
      void submitForm();
    }
  }, [submitForm, isSaveAndEdit]);

  function replaceCustomerWithLeg(leg: 'pickup' | 'delivery'): void {
    void setFieldValue(
      'customer.venue',
      merge(
        {},
        values.customer.venue,
        leg === 'pickup' ? values.pickup.venue : values.delivery.venue,
      ),
    );

    addSnackbar('Copied to Customer Information');
  }

  function handleCopyToCustomer(leg: 'pickup' | 'delivery') {
    if (isEmptyLoadLegVenue(values.customer.venue)) {
      replaceCustomerWithLeg(leg);
    } else {
      setConfirmCopyToLeg(leg);
    }
  }

  const closeModal = () => {
    navigate('/loads', { replace: true });
  };

  return (
    <PageLayout
      loading={formik.isSubmitting}
      stickyHeader={true}
      header={
        <PageHeader
          title="Create Load"
          startAction={
            <BackButton
              onClick={() => {
                navigate('/loads', { replace: true });
              }}
            />
          }
          endActions={
            <Inline space="small">
              <Button
                disabled={
                  formik.isSubmitting ||
                  loadPriceCalculationStatus.isCalculating
                }
                pending={formik.isSubmitting && !isSaveAndEdit}
                onClick={() => {
                  void formik.submitForm();
                }}
              >
                Save
              </Button>
              <Button
                variant="neutral"
                disabled={formik.isSubmitting || formik.isValidating}
                pending={formik.isSubmitting && isSaveAndEdit}
                onClick={() => {
                  setIsSaveAndEdit(true);
                  trackLoadsEvent({
                    name: 'Carrier Clicked Save & Continue Editing',
                  });
                }}
              >
                Save &amp; Continue Editing
              </Button>
            </Inline>
          }
        />
      }
    >
      <Prompt
        when={formik.dirty && formik.status.type !== 'submitted'}
        message="Changes have not been saved. Leaving can result in unsaved changes being lost."
      />
      <DocumentTitle title="Create Load" />

      <LoadCustomerReplaceConfirmDialog
        open={!!confirmCopyToLeg}
        onDismiss={() => {
          setConfirmCopyToLeg(undefined);
        }}
        onConfirm={() => {
          if (confirmCopyToLeg) replaceCustomerWithLeg(confirmCopyToLeg);
          setConfirmCopyToLeg(undefined);
        }}
      />

      <UpgradePlanDialog onClose={closeModal} open={shouldRestrictImport} />

      <Box maxWidth="1296px" margin="auto">
        <FormikProvider value={formik}>
          <Form>
            <Stack space="small">
              <AddLoadPageDetails />

              {/* Pickup/Delivery scheduled date should be relative to the user timezone, so we use UTC Date. */}
              <DateConfigProvider format="DateISO">
                <Columns space="small" collapseBelow="tablet">
                  <Column width="1/2">
                    <AddLoadPickup
                      onCopyToCustomer={() => {
                        handleCopyToCustomer('pickup');
                      }}
                    />
                  </Column>
                  <Column width="1/2">
                    <AddLoadDelivery
                      onCopyToCustomer={() => {
                        handleCopyToCustomer('delivery');
                      }}
                    />
                  </Column>
                </Columns>
              </DateConfigProvider>

              <LoadVehiclesCard />

              <Columns space="small" collapseBelow="tablet">
                <Column width="1/2">
                  <Stack space="small">
                    <AddLoadCustomer />
                    <LoadAttachmentsCard errorsRef={attachmentsErrorRef} />
                  </Stack>
                </Column>

                <Column width="1/2">
                  <AddLoadPayment />
                </Column>
              </Columns>
            </Stack>
          </Form>
        </FormikProvider>
      </Box>
    </PageLayout>
  );
}
