import {
  CircularProgress,
  ListItem,
  ListItemText,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Phone } from '@material-ui/icons';
import { SuspendedPhoneText } from '@superdispatch/phones';
import { ColorDynamic, Stack } from '@superdispatch/ui';
import { Box, DescriptionItem } from '@superdispatch/ui-lab';
import { formatPlural } from 'shared/helpers/IntlHelpers';
import { TrailerIcon } from 'shared/icons/TrailerIcon';
import {
  splitTextByLine,
  StyledNotesIcon,
} from 'shared/modules/driver/DriverUtils';
import { FloatingButtonArea } from 'shared/ui/FloatingButtonArea';
import styled from 'styled-components';
import { DriverDTO } from '../../drivers/data/DriverDTO';
import { getDriverLabel } from '../../drivers/data/DriverPageUtils';

const StyledListItemText = styled(ListItemText)`
  word-break: break-all;

  & .MuiTypography-root {
    max-width: 200px;
  }
`;

const StyledBox = styled(Box)`
  vertical-align: middle;
`;

interface DriverItemProps {
  driver: DriverDTO;
  onClick: () => void;
  isSubmitting: boolean;
  isAssigned?: boolean;
  selectedGUID: string | undefined;
}

export function DriverItem({
  driver,
  onClick,
  isSubmitting,
  isAssigned,
  selectedGUID,
}: DriverItemProps) {
  const isSelected = driver.guid === selectedGUID;
  const isLoading = isSelected && isSubmitting;
  const driverLabel = getDriverLabel(driver);

  return (
    <ListItem
      button={true}
      aria-label="Driver"
      disabled={isSubmitting || isAssigned || isLoading}
      onClick={onClick}
    >
      <FloatingButtonArea
        buttonPlacement="right"
        buttonVisible={isLoading}
        button={
          <Typography variant="body1" color="primary" component="div">
            {isLoading ? <CircularProgress size="1em" /> : 'Assign'}
          </Typography>
        }
      >
        <StyledListItemText
          disableTypography={true}
          primary={
            <Stack>
              <Typography variant="h4">
                {driverLabel}
                {!!driver.notes && (
                  <Tooltip
                    arrow={true}
                    placement="top"
                    title={splitTextByLine(driver.notes)}
                    interactive={true}
                  >
                    <StyledBox paddingLeft="xxsmall" display="inline">
                      <StyledNotesIcon htmlColor={ColorDynamic.Dark100} />
                    </StyledBox>
                  </Tooltip>
                )}
              </Typography>

              <Stack space={['small', 'xsmall']}>
                <DescriptionItem icon={<Phone />} fallback="N/A">
                  {!!driver.phone && (
                    <SuspendedPhoneText
                      phone={driver.phone}
                      fallback={driver.phone}
                    />
                  )}
                </DescriptionItem>

                {driver.trailer?.capacity != null && (
                  <DescriptionItem icon={<TrailerIcon />}>
                    {driver.trailer.capacity}
                    {formatPlural(
                      driver.trailer.capacity,
                      ' vehicle trailer',
                      ' vehicles trailer',
                    )}
                  </DescriptionItem>
                )}
              </Stack>
            </Stack>
          }
        />
      </FloatingButtonArea>
    </ListItem>
  );
}
