import { formatPaymentMethod, formatPaymentTerm } from '@superdispatch/sdk';
import { TextBox } from '@superdispatch/ui-lab';
import {
  formatLoadPayment,
  SuperPayLabel,
} from 'shared/helpers/PaymentHelpers';
import { useFlag } from 'shared/settings/FeatureToggles';
import { InlineBulletItems } from 'shared/ui/InlineBulletItems';
import { LoadDTO, LoadPaymentDTO } from '../data/LoadDTO';

interface LoadPaymentInfoProps {
  paymentDetails: LoadDTO['payment_details'];
  payment: LoadPaymentDTO;
}

export function LoadPaymentInfo({
  paymentDetails,
  payment,
}: LoadPaymentInfoProps) {
  const shouldShowPaymentTerms = useFlag('load_payment_terms');

  if (paymentDetails?.payment_method === 'superpay') {
    return (
      <TextBox color="secondary">
        <InlineBulletItems>
          <SuperPayLabel source="Load List" />
          {shouldShowPaymentTerms
            ? formatPaymentTerm(paymentDetails?.payment_terms)
            : payment.terms}
        </InlineBulletItems>
      </TextBox>
    );
  }

  if (!shouldShowPaymentTerms) {
    return <TextBox>({formatLoadPayment(payment.method)})</TextBox>;
  }

  return (
    <TextBox color="secondary">
      <InlineBulletItems>
        {paymentDetails?.payment_method &&
          formatPaymentMethod(paymentDetails.payment_method)}
        {paymentDetails?.payment_terms &&
          `${formatPaymentTerm(paymentDetails.payment_terms)}`}
      </InlineBulletItems>
    </TextBox>
  );
}
