import { Typography } from '@material-ui/core';
import { ColorDynamic, Stack } from '@superdispatch/ui';
import { Alert } from '@superdispatch/ui-lab';
import { ReactNode } from 'react';
import styled from 'styled-components';

interface MessagesListProps {
  listStyle: 'plain' | 'standard';
}

const MessagesList = styled.ul<MessagesListProps>`
  margin: 0;
  color: ${ColorDynamic.Dark500};
  padding-left: ${({ listStyle }) => (listStyle === 'standard' ? '20px' : '0')};
  list-style: ${({ listStyle }) =>
    listStyle === 'standard' ? 'inherit' : 'none'};
`;

interface FieldAdditionalMessagesProps {
  messages: string[];
  title?: string;
  additionalMessage?: string;
  externalNotes?: string;
  actions?: ReactNode;
  variant: 'error' | 'warning';
  listStyle?: MessagesListProps['listStyle'];
}

export function FieldAdditionalMessages({
  messages,
  title,
  additionalMessage,
  externalNotes,
  actions,
  variant,
  listStyle = 'standard',
}: FieldAdditionalMessagesProps) {
  return (
    <Alert severity={variant === 'error' ? 'critical' : 'caution'}>
      <Stack aria-label="Verification application error message" space="small">
        <Stack space="small">
          {title && <Typography>{title}</Typography>}
          <MessagesList listStyle={listStyle}>
            {messages.map((error) => (
              <li key={error}>
                <Typography color="textPrimary">{error}</Typography>
              </li>
            ))}
          </MessagesList>
          {additionalMessage && (
            <Typography color="textPrimary">{additionalMessage}</Typography>
          )}
          {!!externalNotes && (
            <Stack space="small">
              <Typography color="inherit">Notes:</Typography>
              <Typography color="textPrimary">
                <i>{externalNotes}</i>
              </Typography>
            </Stack>
          )}
        </Stack>
        {actions}
      </Stack>
    </Alert>
  );
}
