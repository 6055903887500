import { Typography } from '@material-ui/core';
import { Stack } from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { useQuery } from 'shared/helpers/RoutingHelpers';
import { useMoovAvailable } from '../../data/MoovAPI';
import { useSuperPaySettings } from '../../data/SuperPayAPI';
import { ProfilePageLayout } from '../../ProfilePageLayout';
import {
  BusinessDetailsForm,
  useBusinessDetailsForm,
} from './BusinessDetailsForm';
import { BusinessDetailsInfo } from './BusinessDetailsInfo';

export function BusinessDetailsPage() {
  const [_, setQuery] = useQuery();
  const { refetch: refetchSuperPaySetting, isFetching } = useSuperPaySettings();

  const isMoovAvailable = useMoovAvailable();

  const formik = useBusinessDetailsForm({
    onComplete: () => {
      setQuery({ utm_medium: 'Business Details' });
    },
    onSettled: () => {
      void refetchSuperPaySetting();
    },
  });

  return (
    <ProfilePageLayout
      title="Setup SuperPay"
      formik={formik}
      isLoading={formik.isSubmitting || isFetching}
      headerEndActions={false}
    >
      <Box
        aria-label="business details container"
        maxWidth={['initial', 'initial', '528px']}
        margin="auto"
        padding="small"
      >
        <Stack space="small">
          <BusinessDetailsInfo isMoovAvailable={isMoovAvailable} />
          <Box
            borderColor="Silver400"
            borderWidth="small"
            borderRadius="small"
            padding="small"
          >
            <Stack space="large">
              <Stack space="small">
                <Typography variant="h3">Business Details</Typography>
                <BusinessDetailsForm disabled={!isMoovAvailable} />
              </Stack>
              <Button
                onClick={() => {
                  void formik.submitForm();
                }}
                pending={formik.isSubmitting}
                disabled={!isMoovAvailable || !formik.dirty}
              >
                Continue
              </Button>
            </Stack>
          </Box>
        </Stack>
      </Box>
    </ProfilePageLayout>
  );
}
