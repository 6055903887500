import { IconButton } from '@material-ui/core';
import { AccessTime, Close } from '@material-ui/icons';
import {
  ColorDynamic,
  Column,
  Columns,
  Inline,
  useUID,
} from '@superdispatch/ui';
import { Box, TextBox } from '@superdispatch/ui-lab';
import { useState } from 'react';
import { PageHeaderContent, PageLayout } from 'shared/layout/PageLayout';
import {
  PlanUpdateDrawer,
  useNextPlanEstimate,
} from 'shared/modules/subscription/core/PlanUpdateDrawer';
import { DriverDetailsBanner } from './core/DriverDetailsBanner';
import { DriverEmptyContainer } from './core/DriverEmptyContainer';
import { DriverInfoCard } from './core/DriverInfoCard';
import { DriverPageLoader } from './core/DriverPageLoader';
import { DriverPageNotes } from './core/DriverPageNotes';
import { DriversPageDetailsHeader } from './core/DriversPageDetailsHeader';
import { InvitedDriver } from './core/InvitedDriver';
import { ReinviteDialog } from './core/ReinviteDialog';
import { DriverFormDrawer } from './DriverFormDrawer';
import { useDriversPageContext } from './DriversPageContext';
import {
  DriversPageMutationDialog,
  DriversPageMutationDialogType,
} from './DriversPageMutationDialog';

function Instructions({ onClose }: { onClose: () => void }) {
  return (
    <Box padding="small" borderTopWidth="small" borderTopColor="Silver400">
      <Columns align="center" space="xsmall">
        <Column width="content">
          <AccessTime htmlColor={ColorDynamic.Yellow500} fontSize="medium" />
        </Column>

        <Column width="fluid">
          <TextBox>
            Driver got text message and email with instructions. Ask them to
            setup their account and download the Super Dispatch app to become
            active.
          </TextBox>
        </Column>
        <Column width="content">
          <IconButton size="small" onClick={onClose}>
            <Close />
          </IconButton>
        </Column>
      </Columns>
    </Box>
  );
}

interface Props {
  isFirstDriverAdded: boolean;
  onInstrcutionsClose: () => void;
}

export function DriversPageDetails({
  isFirstDriverAdded,
  onInstrcutionsClose,
}: Props) {
  const { currentDriverGUID, openDriver, driver, driverList } =
    useDriversPageContext();
  const { data: currentDriver, isFetching, isError: isErrorDriver } = driver;
  const { isFetching: isFetchingDriverList, data: driversData } = driverList;
  const listDriversData = driversData?.pages.flatMap((item) => item.data);
  const nextPlanEstimate = useNextPlanEstimate();
  const [currentModal, setCurrentModal] = useState<
    'edit' | DriversPageMutationDialogType
  >();

  const uid = useUID();
  const labelID = `${uid}-label`;
  const editIconID = `${uid}-edit-icon`;
  const descriptionID = `${uid}-description`;
  const notes = currentDriver?.notes || '';

  const closeModal = () => {
    setCurrentModal(undefined);
  };

  if (
    !listDriversData?.length &&
    (!currentDriver || isErrorDriver) &&
    !isFetchingDriverList
  ) {
    return <DriverEmptyContainer />;
  }

  return (
    <>
      <ReinviteDialog
        currentDriver={currentDriver}
        open={currentModal === 'reinvite'}
        onClose={() => {
          setCurrentModal(undefined);
        }}
      />

      <DriverFormDrawer
        type="edit"
        open={currentModal === 'edit'}
        guid={currentDriverGUID}
        onClose={closeModal}
      />

      <DriversPageMutationDialog
        type="deactivate"
        onDismiss={closeModal}
        guid={currentDriverGUID}
        open={currentModal === 'deactivate'}
      />

      <DriversPageMutationDialog
        type="reactivate"
        onDismiss={closeModal}
        guid={currentDriverGUID}
        open={currentModal === 'reactivate'}
      />

      <DriversPageMutationDialog
        type="delete"
        onDismiss={closeModal}
        guid={currentDriverGUID}
        open={currentModal === 'delete'}
        onSuccess={(nextDriverGUID) => {
          closeModal();
          if (nextDriverGUID) openDriver(nextDriverGUID, true);
        }}
      />

      <PlanUpdateDrawer
        open={currentModal === 'plan_upgrade' && !nextPlanEstimate.isFetching}
        onClose={() => {
          setCurrentModal(undefined);
        }}
        onSubmitSuccess={() => {
          setCurrentModal('reinvite');
        }}
      />

      <PageLayout
        loading={isFetching}
        header={
          <>
            <PageHeaderContent>
              <DriversPageDetailsHeader
                setCurrentModal={setCurrentModal}
                descriptionID={descriptionID}
                labelID={labelID}
              />
            </PageHeaderContent>
            {isFirstDriverAdded && (
              <Instructions
                onClose={() => {
                  onInstrcutionsClose();
                }}
              />
            )}

            {currentDriver && (
              <DriverDetailsBanner currentDriver={currentDriver} />
            )}
          </>
        }
      >
        {currentDriver != null &&
        currentDriver.connection_status === 'pending' &&
        currentDriver.connection_type === 'invite' ? (
          <InvitedDriver driver={currentDriver} />
        ) : currentDriver ? (
          <Inline space="small">
            <DriverInfoCard
              labelID={labelID}
              currentDriver={currentDriver}
              descriptionID={descriptionID}
              editIconID={editIconID}
              setCurrentModal={setCurrentModal}
            />

            {currentDriverGUID &&
              currentDriver.connection_status !== 'pending' && (
                <DriverPageNotes
                  currentDriverGUID={currentDriverGUID}
                  notes={notes}
                />
              )}
          </Inline>
        ) : (
          <DriverPageLoader labelID={labelID} descriptionID={descriptionID} />
        )}
      </PageLayout>
    </>
  );
}
