import { SwipeableDrawer, Typography } from '@material-ui/core';
import { useDeepEqualValue } from '@superdispatch/hooks';
import {
  Color,
  Column,
  Columns,
  DrawerActions as MuiDrawerActions,
  DrawerContent,
  Stack,
} from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { noop } from 'lodash-es';
import { MobilebarIcon } from 'shared/icons/MobilebarIcon';
import styled from 'styled-components';
import { SidebarMenuContainer } from '../../../loadboard/desktop/LoadboardDesktopSidebar';
import { AppCarrierMenuLegacy } from './AppCarrierMenuLegacy';
import { SidebarLinkLegacy, SidebarMenuItem } from './AppSidebarMenuLegacy';
import { useNavbarContext } from './NavbarContext';
import { useCarrierMenuLegacy } from './useCarrierMenuLgacy';
import { useSidebarItems } from './useSidebarItems';

interface MobileMenuDrawerProps {
  localPaths?: {
    carrierProfile?: string;
    dispatcherProfile?: string;
    settings?: string;
    subscriptionDetails?: string;
    ratings?: string;
    logout?: string;
    dispatcherManagement?: string;
  };
  hasMenuBadge: boolean;
}

const SidebarMenuDrawerContent = styled(DrawerContent)`
  background-color: ${Color.Dark500};
`;

const Drawer = styled(SwipeableDrawer)`
  & .MuiDrawer-paper {
    min-width: 320px;
    background-color: ${Color.Dark500};
  }
`;

const DrawerActions = styled(MuiDrawerActions)`
  background-color: ${Color.Dark500};
`;

export function MobileMenuDrawer({
  localPaths: localPathsProp = {},
  hasMenuBadge,
}: MobileMenuDrawerProps) {
  const { isDrawerOpen, setDrawerOpen } = useNavbarContext();
  const localPaths = useDeepEqualValue(localPathsProp);
  const { ratingsLink, accountItems, carrierItems, dispatcherItems } =
    useCarrierMenuLegacy({ localPaths });

  const { offersLinks, primaryLinks, secondaryLinks } = useSidebarItems({
    localPaths,
  });

  const onClose = () => {
    setDrawerOpen(null);
  };

  return (
    <Drawer
      onClose={onClose}
      open={isDrawerOpen === 'menu'}
      onOpen={noop}
      anchor="right"
      disableSwipeToOpen={true}
    >
      <SidebarMenuDrawerContent>
        <Box backgroundColor="Dark500" height="100%">
          <Stack space="large">
            <Box paddingLeft="xsmall" paddingTop="small">
              <a href="/tms/loads" aria-label="Dashboard">
                <MobilebarIcon fontSize="large" htmlColor={Color.Silver500} />
              </a>
            </Box>

            <MobileSidebar
              offersLinks={offersLinks}
              primaryLinks={primaryLinks}
              secondaryLinks={secondaryLinks}
              onClick={onClose}
            />
          </Stack>
        </Box>
      </SidebarMenuDrawerContent>
      <DrawerActions>
        <Box width="100%">
          <AppCarrierMenuLegacy
            ratingsLink={ratingsLink}
            carrierItems={carrierItems}
            dispatcherItems={dispatcherItems}
            accountItems={accountItems}
            hasMenuBadge={hasMenuBadge}
          />
        </Box>
      </DrawerActions>
    </Drawer>
  );
}

export interface MobileSidebarMenuProps {
  offersLinks: SidebarLinkLegacy[];
  primaryLinks: SidebarLinkLegacy[];
  secondaryLinks: SidebarLinkLegacy[];
  onClick?: () => void;
}

const MenuContainer = styled.div`
  flex: 1;
  padding: 0;
  margin-top: 16px;
  display: flex;
  flex-direction: column;

  overflow-y: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;
`;

function MobileSidebar({
  offersLinks,
  primaryLinks,
  secondaryLinks,
  onClick,
}: MobileSidebarMenuProps) {
  return (
    <MenuContainer>
      <MobileSidebarMenu links={offersLinks} />
      <MobileSidebarMenu onClick={onClick} links={primaryLinks} />
      <MobileSidebarMenu onClick={onClick} links={secondaryLinks} />
    </MenuContainer>
  );
}

const StyledBox = styled(Box)`
  border-radius: 50%;
`;
const Label = styled(Typography)`
  font-size: 16px;
  line-height: 24px;
`;
export const LinkIcon = styled.a`
  width: 24px;
  height: 24px;
`;

export function MobileSidebarMenu({
  links,
  disableGutter,
  onClick,
}: {
  links: SidebarLinkLegacy[];
  disableGutter?: boolean;
  onClick?: () => void;
}) {
  return (
    <SidebarMenuContainer
      data-intercom-target="siebar-menu-section"
      data-disablegutter={!!disableGutter}
    >
      {links.map(
        (link) =>
          !link.hidden && (
            <SidebarMenuItem onClick={onClick} key={link.key} link={link}>
              <Columns align="center">
                <Column width="fluid">
                  <Columns space="small" align="center">
                    <Column width="content">
                      <LinkIcon as={link.Icon} />
                    </Column>

                    <Column width="fluid">
                      <Label>{link.label}</Label>
                    </Column>
                  </Columns>
                </Column>

                <Column width="content">
                  {link.badgeContent && (
                    <StyledBox padding="xsmall" backgroundColor="Red300" />
                  )}
                </Column>
              </Columns>
            </SidebarMenuItem>
          ),
      )}
    </SidebarMenuContainer>
  );
}
