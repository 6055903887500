import { ThumbDown, ThumbUp } from '@material-ui/icons';
import { ColorDynamic } from '@superdispatch/ui';
import { AriaAttributes, forwardRef } from 'react';
import styled from 'styled-components';

const RatingIconWrapper = styled.div`
  padding: 12px;
  border-radius: 50%;
  font-size: 16px;

  &[data-size='small'] {
    padding: 8px;
  }

  &[data-variant='success'] {
    color: ${ColorDynamic.Green300};
    background-color: ${ColorDynamic.Green50};
  }

  &[data-variant='error'] {
    color: ${ColorDynamic.Red300};
    background-color: ${ColorDynamic.Red50};
  }
`;

interface RatingProps extends AriaAttributes {
  type: 'positive' | 'negative';
  size?: 'default' | 'small';
}

export const Rating = forwardRef<HTMLDivElement, RatingProps>(
  ({ type, size = 'default', ...props }, ref) => {
    const isPositiveRating = type === 'positive';

    return (
      <RatingIconWrapper
        ref={ref}
        data-variant={isPositiveRating ? 'success' : 'error'}
        data-size={size}
        {...props}
      >
        {isPositiveRating ? (
          <ThumbUp fontSize="inherit" />
        ) : (
          <ThumbDown fontSize="inherit" />
        )}
      </RatingIconWrapper>
    );
  },
);

Rating.displayName = 'Rating';
