import { noop } from 'lodash-es';
import { openExternalURL } from 'shared/helpers/URLHelpers';
import { basePath } from 'shared/utils/RouterUtils';
import { logWarning } from '../ErrorTracker';

if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register(basePath + '/service-worker-messaging.js')
    .catch((error) => {
      logWarning('BrowserNotification', { error });
    });
}

function showServiceWorkerNotification(
  title: string,
  options?: NotificationOptions,
): Promise<void> {
  if ('serviceWorker' in navigator) {
    return navigator.serviceWorker.ready.then((registration) =>
      registration.showNotification(title, options),
    );
  }

  return Promise.reject(
    new Error('Failed to show service worker notification'),
  );
}

export async function requestNotificationPermission() {
  if ('Notification' in window && Notification.permission === 'default') {
    // Support deprecated callback syntax
    // https://developer.mozilla.org/en-US/docs/Web/API/Notification/requestPermission#syntax
    return Promise.resolve(Notification.requestPermission());
  }

  throw new Error('Notification is not supported');
}

export function showNotification(
  title: string,
  clickAction: string,
  options?: NotificationOptions,
): Promise<void> {
  return new Promise((resolve, reject) => {
    const notification = new Notification(title, options);

    notification.addEventListener('click', () => {
      window.focus();
      notification.close();
      openExternalURL(clickAction);
    });

    notification.addEventListener('error', () => {
      reject(new Error('Failed to show notification'));
    });

    notification.addEventListener('show', () => {
      resolve();
    });
  });
}

export async function showBrowserNotification(
  tag: string,
  title: string,
  body: string,
  clickAction: string,
) {
  if (!('Notification' in window)) {
    throw new Error('Not supported');
  }

  if (Notification.permission !== 'granted') {
    void requestNotificationPermission().catch(noop);
    throw new Error('No required permission');
  }

  const { href: icon } = new URL(
    `${basePath}/logo.png`,
    window.location.origin,
  );
  const notificationOptions: NotificationOptions = { body, tag, icon };

  try {
    await showServiceWorkerNotification(title, {
      ...notificationOptions,
      data: { click_action: clickAction },
    });
  } catch (error: unknown) {
    return showNotification(title, clickAction, notificationOptions);
  }
}
