import { ColorDynamic } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import styled from 'styled-components';

export const ExpeditedPayBannerGradientBox = styled(Box)(({ theme }) => {
  const mode = theme.palette.type;
  const color =
    mode === 'dark'
      ? `#3D1E00 0%, #34003D 10%, rgba(0, 61, 61, 0.00) 20%`
      : `#ffe6cea6 0%, #fbe5ffa6 50%, #c8ffff00 100%`;
  return `
    border: 1.5px solid ${ColorDynamic.Silver400};
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 52px;
    padding: 15px;
    position: relative;
    z-index: 1;
    &:before {
      content: '';
      background: ${`linear-gradient(90deg, ${color})`};
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: -1;
    }
    &:after {
      content: '';
      background: ${`linear-gradient(270deg, ${color})`};
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: -1;
    }
  `;
});
