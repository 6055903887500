import { formatPaymentMethod } from '@superdispatch/sdk';
import { TextBox } from '@superdispatch/ui-lab';
import {
  formatLoadPayment,
  SuperPayLabel,
} from 'shared/helpers/PaymentHelpers';
import { useFlag } from 'shared/settings/FeatureToggles';
import { InlineBulletItems } from 'shared/ui/InlineBulletItems';
import { LoadDTO, LoadPaymentDTO } from '../data/LoadDTO';
import { ViewLoadPaymentCardRow } from './ViewLoadPaymentRenderCardRow';

interface PaymentMethodProps {
  payment: LoadPaymentDTO;
  load: LoadDTO;
}

const defaultValuePlaceholder = 'No details';

export function ViewLoadPaymentMethod({ payment, load }: PaymentMethodProps) {
  const shouldShowPaymentTerms = useFlag('load_payment_terms');

  if (shouldShowPaymentTerms) {
    return (
      <ViewLoadPaymentCardRow
        title="Method"
        e2eValue="payment.payment_method"
        value={
          load.payment_details?.payment_method === 'superpay' ? (
            <SuperPayLabel source="Load Details" />
          ) : (
            formatPaymentMethod(load.payment_details?.payment_method) ||
            defaultValuePlaceholder
          )
        }
      />
    );
  }

  return (
    <ViewLoadPaymentCardRow
      title="Method"
      e2eValue="payment.payment_method"
      value={
        payment.method === 'superpay' ? (
          <TextBox color="secondary">
            <InlineBulletItems>
              <SuperPayLabel source="Load Details" />
              {payment.terms}
            </InlineBulletItems>
          </TextBox>
        ) : (
          formatLoadPayment(payment.method) || defaultValuePlaceholder
        )
      }
    />
  );
}
