import { AppBar, Collapse, LinearProgress, Toolbar } from '@material-ui/core';
import {
  ColorDynamic,
  ColorDynamicProp,
  ColorProp,
  Column,
  Columns,
} from '@superdispatch/ui';
import { TextBox } from '@superdispatch/ui-lab';
import { ReactNode } from 'react';
import styled from 'styled-components';

//
// PageLayoutProgress
//

const LinearProgressCollapse = styled(Collapse)`
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: ${({ theme }) => theme.zIndex.appBar + 10};
`;

export interface PageLayoutProgressProps {
  loading?: boolean;
}

export function PageLayoutProgress({ loading }: PageLayoutProgressProps) {
  return (
    <LinearProgressCollapse in={loading}>
      <LinearProgress />
    </LinearProgressCollapse>
  );
}

//
// PageLayout
//

const PageLayoutRoot = styled.div<{ backgroundColor?: ColorProp }>`
  min-width: 0;
  flex-grow: 1;
  min-height: inherit;

  background-color: ${({ backgroundColor = 'Silver200' }) =>
    ColorDynamic[backgroundColor]};
`;

const PageLayoutHeader = styled(AppBar)`
  min-width: 0;
  max-width: 100%;
  border-top: none;
  border-left: none;
  border-right: none;
`;

const PageLayoutContent = styled.div<{ disablePaddings?: boolean }>`
  padding: ${({ disablePaddings }) => (disablePaddings ? 0 : '24px 32px')};
`;

export interface PageLayoutProps {
  loading?: boolean;
  background?: ColorDynamicProp;
  disablePaddings?: boolean;

  header?: ReactNode;
  stickyHeader?: boolean;

  children?: ReactNode;
}

export function PageLayout({
  loading,
  header,
  stickyHeader,
  disablePaddings,
  children,
  background,
}: PageLayoutProps) {
  return (
    <PageLayoutRoot backgroundColor={background}>
      <PageLayoutProgress loading={loading} />

      {!!header && (
        <PageLayoutHeader position={stickyHeader ? 'sticky' : 'static'}>
          {header}
        </PageLayoutHeader>
      )}

      <PageLayoutContent disablePaddings={disablePaddings}>
        {children}
      </PageLayoutContent>
    </PageLayoutRoot>
  );
}

//
// PageHeader
//

export const PageHeaderContent = styled(Toolbar)`
  padding: 16px 32px;

  @media (max-width: 768px) {
    padding: 16px;
  }
`;

export interface PageHeaderProps {
  titleID?: string;
  title?: ReactNode;
  startAction?: ReactNode;
  endActions?: ReactNode;
}

export function PageHeader({
  title,
  titleID,
  endActions,
  startAction,
}: PageHeaderProps) {
  return (
    <PageHeaderContent>
      <Columns space="xsmall" align="center">
        {!!startAction && <Column width="content">{startAction}</Column>}

        {!!title && (
          <Column width="fluid">
            <TextBox id={titleID} noWrap={true} variant="heading-2">
              {title}
            </TextBox>
          </Column>
        )}

        {!!endActions && <Column width="content">{endActions}</Column>}
      </Columns>
    </PageHeaderContent>
  );
}
