import { CUSTOMER_TYPES, VEHICLE_TYPES } from '@superdispatch/sdk';
import { PricingInsightsDTO } from 'shared/modules/pricing-insights/PricingInsightsDTO';
import { verificationSteps } from 'shared/modules/superpay/SuperPayDTO';
import {
  yupArray,
  yupBoolean,
  yupDateString,
  yupEnum,
  yupFloat,
  yupNumber,
  yupObject,
  yupString,
} from 'shared/utils/YupUtils';
import { InferType } from 'yup';
import { OFFER_STATUSES } from '../../core/OffersStatusEnum';

export const publicOfferOrderDriverSchema = yupObject({
  guid: yupString(),
  name: yupString(),
  email: yupString(),
});

export type PublicOfferOrderVehicleDTO = InferType<
  typeof publicOfferOrderVehicleSchema
>;
export const publicOfferOrderVehicleSchema = yupObject({
  year: yupNumber(),
  make: yupString(),
  model: yupString(),
  type: yupEnum(VEHICLE_TYPES, null),

  is_inoperable: yupBoolean(),
  requires_enclosed_trailer: yupBoolean(),

  curb_weight: yupFloat(),
  curb_weight_unit: yupString(),

  height: yupNumber(),
  length: yupNumber(),
  width: yupNumber(),

  sample_photo: yupObject({
    guid: yupString(),
    photo_url: yupString(),
    thumbnail_url: yupString().nullable(),
  }).nullable(),
});

export type PublicOfferUserDTO = InferType<typeof publicOfferUserSchema>;
const publicOfferUserSchema = yupObject({
  guid: yupString(),
  hash: yupString(),
  token: yupString(),
  is_paying_carrier: yupBoolean(),
  company_type: yupEnum(
    ['owner_operator', 'owner_operator_pro', 'fleet'],
    null,
  ),
});

export type PublicOfferDTO = InferType<typeof publicOfferSchema>;
export const publicOfferSchema = yupObject({
  uuid: yupString(),
  guid: yupString(),
  terms_and_conditions: yupString(),
  user: publicOfferUserSchema.nullable().default(null),
  created_at: yupDateString('DateTimeISO'),
  status_changed_at: yupDateString('DateTimeISO'),
  carrier_guid: yupString(),
  carrier_name: yupString(),

  is_first_offer: yupBoolean(),
  is_counter_offer: yupBoolean(),
  is_price_negotiation: yupBoolean(),

  bid_price: yupNumber(),
  original_price: yupNumber(),
  status: yupEnum(OFFER_STATUSES),
  order: yupObject({
    number: yupString(),
    price: yupString(),
    payment_method_display: yupString(),
    payment_terms_display: yupString(),
    pickup_city: yupString(),
    pickup_state: yupString(),
    pickup_zip: yupString(),
    pickup_date_type: yupString(),
    pickup_business_type: yupEnum(CUSTOMER_TYPES, null),
    pickup_date: yupDateString('DateTimeISO'),
    delivery_city: yupString(),
    delivery_state: yupString(),
    delivery_zip: yupString(),
    delivery_date_type: yupString(),
    delivery_business_type: yupEnum(CUSTOMER_TYPES, null),
    delivery_date: yupDateString('DateTimeISO'),
    instructions: yupString(),
    broker_name: yupString(),
    broker_phone: yupString(),
    driver: publicOfferOrderDriverSchema.nullable(),
    vehicles: yupArray(publicOfferOrderVehicleSchema),
    price_per_mile: yupNumber(),
    distance_miles: yupNumber(),
    shipper_profile_url: yupString(),
  }),
  superpay: yupObject({
    is_verification_available: yupBoolean().required(),
    verification: yupEnum(verificationSteps).default('restricted'),
  }),
});

export type AcceptPublicOfferResponseDTO = InferType<
  typeof acceptPublicOfferResponseSchema
>;
export const acceptPublicOfferResponseSchema = yupObject({
  is_paying_carrier: yupBoolean(),
  broker_order_guid: yupString(),
  carrier_guid: yupString(),
  order_guid: yupString(),
  order_url: yupString(),
  token: yupString(),
  user_guid: yupString(),
});

export type PublicOfferDriverDTO = InferType<typeof publicOfferDriverSchema>;
export const publicOfferDriverSchema = yupObject({
  guid: yupString(),
  name: yupString(),
  email: yupString(),
  inbox: yupString(),
  phone: yupString(),
  notes: yupString(),

  is_myself: yupBoolean(),
  is_activated: yupBoolean(),
  is_suspended: yupBoolean(),

  trailer: yupObject({
    capacity: yupNumber().integer().positive(),
  }).nullable(),

  drivers_licence: yupObject({
    state: yupString(),
    number: yupString(),
  }),
});

export type PublicOfferAddDriverDTO = InferType<
  typeof publicOfferAddDriverSchema
>;
export const publicOfferAddDriverSchema = yupObject({
  name: yupString().required(),
  email: yupString().email().required(),
  phone: yupString().required(),
  is_myself: yupBoolean(),
});

export type PublicOfferAssignResponseDTO = InferType<
  typeof publicOfferAssignResponseSchema
>;
export const publicOfferAssignResponseSchema = yupObject({
  autologin_link: yupString().nullable(),
});

export type PublicOfferDeclinePayloadDTO = InferType<
  typeof publicOfferDeclinePayloadSchema
>;
export const publicOfferDeclineReasons = [
  'low_price',
  'lack_of_capacity',
  'inconvenient_dates',
] as const;
export const publicOfferDeclinePayloadSchema = yupObject({
  decline_comment: yupString().max(512),
  decline_reasons: yupArray(yupString().oneOf(publicOfferDeclineReasons)),
});

export function mapPublicOfferLoadToPricingInsights(
  order: PublicOfferDTO['order'],
): PricingInsightsDTO {
  return {
    origin: {
      zip: order.pickup_zip,
      city: order.pickup_city,
      state: order.pickup_state,
    },

    destination: {
      zip: order.delivery_zip,
      city: order.delivery_city,
      state: order.delivery_state,
    },

    vehicles: order.vehicles?.map((vehicle) => ({
      year: vehicle.year,
      type: vehicle.type,
      make: vehicle.make,
      model: vehicle.model,
      is_inoperable: vehicle.is_inoperable,
    })),
  };
}

export type PublicOfferInviteDriverDTO = InferType<
  typeof publicDriverInviteDriverDTO
>;
export const publicDriverInviteDriverDTO = yupObject({
  email: yupString().email().required(),
  phone_number: yupString().required(),
  name: yupString(),
});

export type PublicOfferInviteResponseDTO = InferType<
  typeof publicDriverInviteResponseSchema
>;
export const publicDriverInviteResponseSchema = yupObject({
  guid: yupString().required(),
  email: yupString().email().required(),
  phone: yupString().required(),
  name: yupString().nullable(),
  inbox: yupString().required(),
  trailer: yupObject({
    capacity: yupNumber().nullable(),
  }),
  drivers_licence: yupObject({
    number: yupString().nullable(),
    state: yupString().nullable(),
  }),
  is_activated: yupBoolean().required(),
  is_suspended: yupBoolean().required(),
  is_myself: yupBoolean().required(),
});

export type PublicDriverAcceptDriverResponseDTO = InferType<
  typeof publicDriverAcceptResponseSchema
>;
export const publicDriverAcceptResponseSchema = yupObject({
  guid: yupString().required(),
  email: yupString().email().required(),
  phone: yupString().required(),
  name: yupString().nullable(),
  inbox: yupString().required(),
  trailer: yupObject({
    capacity: yupNumber().nullable(),
  }),
  drivers_licence: yupObject({
    number: yupString().nullable(),
    state: yupString().nullable(),
  }),
  is_activated: yupBoolean().required(),
  is_suspended: yupBoolean().required(),
  is_myself: yupBoolean().required(),
});
