import { Tooltip, Typography } from '@material-ui/core';
import { ColorDynamic } from '@superdispatch/ui';
import { ReactChild } from 'react';
import styled from 'styled-components';

const Marker = styled.div<{ top: string; left: string }>`
  cursor: pointer;
  user-select: none;
  position: absolute;
  top: ${({ top }) => top};
  left: ${({ left }) => left};
  margin-top: -12px;
  margin-left: -12px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  background-color: ${ColorDynamic.White};
  border-radius: 12px;
  border: 2px solid;
  border-color: ${ColorDynamic.Blue300};
  color: ${ColorDynamic.Blue300};
`;

export const MarkerContainer = styled.div`
  /*
    Grey out Markers when MarkerContainer is hovered.

    MarkerContainer's hover event happens only when Marker is hovered.
    Because MarkerContainer is invisible (as it has no height) and
    Markers have 'position: absolute' which makes them pop out of its parent.
    It creates illusion as if Marker's grandparent is its parent.
  */
  &:hover ${Marker} {
    border-color: ${ColorDynamic.Dark100};
    color: ${ColorDynamic.Dark100};
  }

  /* Highlight currently hovered Marker */
  ${Marker}:hover {
    border-color: ${ColorDynamic.Blue300};
    color: ${ColorDynamic.Blue300};
  }
`;

interface ImageMarkerProps {
  top: string;
  left: string;
  label: string;
  title: ReactChild;
}

export function ImageMarker({ top, left, label, title }: ImageMarkerProps) {
  return (
    <Tooltip title={title} placement="top">
      <Marker top={top} left={left}>
        <Typography variant="h6" color="inherit" align="center">
          {label}
        </Typography>
      </Marker>
    </Tooltip>
  );
}
