import {
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  TextField,
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { Column, Columns, Stack, VisibilityObserver } from '@superdispatch/ui';
import { Box, TextBox } from '@superdispatch/ui-lab';
import { useMemo, useState } from 'react';
import { useDebouncedValue } from 'shared/helpers/ReactHelpers';
import { LoadWaypoints } from 'shared/ui/LoadWaypoints';
import styled from 'styled-components';
import { useTripLoadsList } from '../data/TripsAPI';
import { TripFormLoadListItem } from './TripFormLoadListItem';
import { TripFormLoadListItemFormEdit } from './TripFormLoadListItemForEdit';

const StyledList = styled(List)`
  & .MuiListItem-root {
    align-items: flex-start;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-right: 24px;
  }
`;

function LoadingSkeleton() {
  return (
    <ListItem divider={true} component="div" ContainerComponent="div">
      <ListItemText
        primary={
          <Box
            paddingTop="medium"
            paddingBottom="medium"
            paddingLeft="large"
            paddingRight="large"
          >
            <Stack space="xsmall">
              <Columns align="center" space="large">
                <Column width="1/2">
                  <TextBox variant="heading-3">
                    <Skeleton />
                  </TextBox>
                </Column>
                <Column width="1/2">
                  <TextBox variant="heading-3">
                    <Skeleton />
                  </TextBox>
                </Column>
              </Columns>

              <Stack>
                <LoadWaypoints
                  dense={true}
                  space="small"
                  inline={true}
                  pickupAddress={<Skeleton width={208} />}
                  deliveryAddress={<Skeleton width={208} />}
                />

                <TextBox>
                  <Skeleton width={136} />
                </TextBox>
              </Stack>
            </Stack>
          </Box>
        }
      />
    </ListItem>
  );
}

interface TripFormLoadsProps {
  tripGuid?: string;
  driverGuid?: string;
}

export function TripFormLoads({ tripGuid, driverGuid }: TripFormLoadsProps) {
  const [search, setSearch] = useState<string>('');
  const query = useDebouncedValue(search, 500);

  const { data, isFetching, hasNextPage, fetchNextPage } = useTripLoadsList({
    q: query,
    trip_guid: tripGuid,
    driver_guid: driverGuid,
  });

  const options = useMemo(() => {
    return data?.pages ? data.pages.flatMap((page) => page.data) : [];
  }, [data?.pages]);

  const shouldShowLoader = isFetching || hasNextPage;

  return (
    <>
      <Box paddingLeft="medium" paddingRight="medium">
        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search color="action" />
              </InputAdornment>
            ),
          }}
          placeholder="Search loads..."
          fullWidth={true}
          value={search}
          onChange={(event) => {
            setSearch(event.target.value);
          }}
        />
      </Box>
      <StyledList>
        {isFetching && (
          <Stack>
            <LoadingSkeleton />
            <LoadingSkeleton />
          </Stack>
        )}
        {options.map((load) =>
          tripGuid ? (
            <TripFormLoadListItemFormEdit
              key={load.guid}
              tripGuid={tripGuid}
              load={load}
            />
          ) : (
            <TripFormLoadListItem key={load.guid} load={load} />
          ),
        )}

        <VisibilityObserver
          onChange={(visibility) => {
            if (visibility === 'visible') {
              void fetchNextPage();
            }
          }}
          render={({ ref }) => (
            <div>
              {shouldShowLoader && (
                <div ref={ref}>
                  <LoadingSkeleton />
                </div>
              )}
            </div>
          )}
        />
      </StyledList>
    </>
  );
}
