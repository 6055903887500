import { Stack } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { atom, useAtomValue, useSetAtom } from 'jotai';
import { useEffect } from 'react';
import {
  Navigate,
  Outlet,
  RouteObject,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { isCarrierTMSWebView } from 'shared/constants/AppTypeConstants';
import { MobileAppBridge } from 'shared/data/MobileAppBridge';
import { trackEvent } from 'shared/helpers/Analytics';
import { useQuery } from 'shared/helpers/RoutingHelpers';
import { openExternalURL } from 'shared/helpers/URLHelpers';
import { DocumentTitle } from 'shared/layout/DocumentTitle';
import { ProfileVerifiedCarrierApplicationTabs } from 'shared/modules/carrier-profile/core/ProfileVerifiedCarrierApplicationTabs';
import { useVerifiedCarrierApplicationStatus } from 'shared/modules/carrier-profile/VerifiedCarrierApplicationStatus';
import { useSignupExperiment } from 'shared/modules/experiments/signup-experiment/useSignupExperiment';
import { PendingVerificationDialog } from 'shared/modules/verified-carrier-application/core/PendingVerificationDialog';
import { StepCargoInsurancePage } from 'shared/modules/verified-carrier-application/StepCargoInsurancePage';
import { StepCargoInsurancePageLegacy } from 'shared/modules/verified-carrier-application/StepCargoInsurancePageLegacy';
import { StepCarrierInfoPage } from 'shared/modules/verified-carrier-application/StepCarrierInfoPage';
import { StepIdentificationPage } from 'shared/modules/verified-carrier-application/StepIdentificationPage';
import { StepPhoneVerificationPage } from 'shared/modules/verified-carrier-application/StepPhoneVerificationPage';
import { useFlag } from 'shared/settings/FeatureToggles';
import { ProfileVerifiedCarrierApplicationLayout } from './ProfileVerifiedCarrierApplicationLayout';

export const pendingDialogOpenAtom = atom(false);

export function ProfileVerifiedCarrierApplicationOutlet() {
  const navigate = useNavigate();
  const [searchParams] = useQuery();
  const setIsPendingDialogOpen = useSetAtom(pendingDialogOpenAtom);
  const isPendingDialogOpen = useAtomValue(pendingDialogOpenAtom);
  const { shouldRunExperiment } = useSignupExperiment();
  const isRedirectionEnabled = useFlag('verification_application_redirect');
  const { data } = useVerifiedCarrierApplicationStatus();

  useEffect(() => {
    if (data?.verified_carrier_application.status === 'pending') {
      setIsPendingDialogOpen(true);
    }
  }, [data, setIsPendingDialogOpen]);

  useEffect(() => {
    const status = data?.verified_carrier_application.status;
    if (
      isRedirectionEnabled &&
      status &&
      status !== 'pending' &&
      status !== 'not_submitted'
    ) {
      navigate('/profile');
    }
  }, [isRedirectionEnabled, data, navigate]);

  useEffect(() => {
    trackEvent('CTMS: Started Identity Verification Process', {
      utm_medium: searchParams.utm_medium || 'carrier_profile',
    });
  }, [searchParams.utm_medium]);

  return (
    <>
      <DocumentTitle title="Verify Carrier Application" />

      <ProfileVerifiedCarrierApplicationLayout>
        <Stack space={['none', 'large']}>
          <ProfileVerifiedCarrierApplicationTabs />
          <Box maxWidth="992px" marginLeft="auto" marginRight="auto">
            <Outlet />
          </Box>
        </Stack>
      </ProfileVerifiedCarrierApplicationLayout>
      <PendingVerificationDialog
        open={isPendingDialogOpen && !shouldRunExperiment}
      />
    </>
  );
}

export const verifiedCarrierApplicationRoutes: RouteObject[] = [
  {
    path: 'verified-carrier-application/*',
    element: <ProfileVerifiedCarrierApplicationOutlet />,
    children: [
      {
        path: 'step-1',
        element: <FirstStepPageRoute />,
      },
      {
        path: 'step-2',
        element: <SecondStepPageRoute />,
      },
      {
        path: 'step-3',
        element: <ThirdStepPageRoute />,
      },
      {
        path: 'step-4',
        element: <FourthStepPageRoute />,
      },
      {
        path: '*',
        element: <Navigate to="/profile/verified-carrier-application/step-1" />,
      },
    ],
  },
];

function FirstStepPageRoute() {
  const navigate = useNavigate();
  const location = useLocation();
  const { data } = useVerifiedCarrierApplicationStatus();
  const progress = data?.verified_carrier_application.progress;

  return (
    <Box maxWidth="496px" marginLeft="auto" marginRight="auto">
      <StepPhoneVerificationPage
        onBack={() => {
          if (isCarrierTMSWebView) {
            MobileAppBridge.closeScreen();
          } else {
            navigate('/profile');
          }
        }}
        isPhoneVerificationComplete={
          !!progress?.fmcsa_phone_verification_is_complete
        }
        onComplete={() => {
          navigate({
            pathname: '/profile/verified-carrier-application/step-2',
            search: location.search,
          });
        }}
      />
    </Box>
  );
}

function SecondStepPageRoute() {
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <Box maxWidth="496px" marginLeft="auto" marginRight="auto">
      <StepCarrierInfoPage
        onComplete={() => {
          navigate({
            pathname: '/profile/verified-carrier-application/step-3',
            search: location.search,
          });
        }}
      />
    </Box>
  );
}

function ThirdStepPageRoute() {
  const location = useLocation();
  const navigate = useNavigate();
  const isCargoRenewalEnabled = useFlag('cargo_insurance_renewal');

  if (isCargoRenewalEnabled) {
    return (
      <StepCargoInsurancePage
        onComplete={() => {
          navigate({
            pathname: '/profile/verified-carrier-application/step-4',
            search: location.search,
          });
        }}
      />
    );
  }
  return (
    <StepCargoInsurancePageLegacy
      onComplete={() => {
        navigate({
          pathname: '/profile/verified-carrier-application/step-4',
          search: location.search,
        });
      }}
    />
  );
}

function FourthStepPageRoute() {
  const { shouldRunExperiment } = useSignupExperiment();
  const setIsPendingDialogOpen = useSetAtom(pendingDialogOpenAtom);

  return (
    <Box maxWidth="496px" marginLeft="auto" marginRight="auto">
      <StepIdentificationPage
        onComplete={() => {
          if (shouldRunExperiment && !isCarrierTMSWebView) {
            // in experiment we want user to hit paywall and see verification processing screen
            openExternalURL('/tms/loads/', { target: '_self' });
          } else {
            setIsPendingDialogOpen(true);
          }
        }}
      />
    </Box>
  );
}
