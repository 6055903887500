import { ButtonProps } from '@superdispatch/ui-lab';
import { isEmpty, omitBy } from 'lodash-es';
import { ReactNode } from 'react';
import { openExternalURL } from 'shared/helpers/URLHelpers';
import {
  canHaveSimilarSearch,
  createSearchCriteria,
  createSimilarSearch,
  searchCriteriaToJSON,
} from 'shared/modules/loadboard/PostingSearchCriteriaDTO';
import { CarrierSettings } from 'shared/settings/CarrierSettingsAPI';
import { isLoadCanceled, LoadDTO } from '../data/LoadDTO';
import { trackLoadsEvent } from '../data/LoadsAnalytics';
import { LoadsPageLoadAction } from '../loads-page/LoadsPageLoadActions';

export type ViewLoadAction = LoadsPageLoadAction | 'view_invoice';

export interface ViewLoadActionProps {
  load: LoadDTO;
  settings?: CarrierSettings;
  loadStage: string;
  isCancelLoadEnabled: boolean;
}

interface ViewLoadActionOption {
  label: ReactNode;
  action?: ViewLoadAction;
  onClick?: (props: ViewLoadActionProps) => void;
  isPrimary?: (props: ViewLoadActionProps) => boolean;
  isVisible?: (props: ViewLoadActionProps) => boolean;
  isDisabled?: (props: ViewLoadActionProps) => boolean;
  buttonProps?: (props: ViewLoadActionProps) => Partial<ButtonProps>;
  dataIntercomTarget?: string;
}

interface ViewLoadActionGroup {
  options: ViewLoadActionOption[];
  isVisible?: (props: ViewLoadActionProps) => boolean;
}

export const viewLoadActions: ViewLoadActionGroup[] = [
  {
    isVisible: ({ load }) => !!load.is_dispatched_to_carrier,
    options: [
      {
        label: 'Open Dispatched Load',
        onClick: ({ load }) => {
          openExternalURL(load.url_in_stms, { target: '_blank' });
        },
      },
      {
        label: 'Remove "Dispatched to Carrier"',
        action: 'remove_dispatched_to_carrier_label',
      },
    ],
  },

  {
    isVisible: ({ load, settings }) =>
      !!settings?.has_terminal && !load.archived && !!load.is_active,

    options: [
      {
        label: 'Mark as in Terminal',
        action: 'mark_as_in_terminal',
        isVisible: ({ load }) =>
          !!load.terminals.length && !!load.can_mark_as_in_terminal,
      },
      {
        label: 'Add Terminal',
        action: 'add_terminal',
        isVisible: ({ load }) => !!load.can_add_terminal,
      },
      {
        label: 'Remove Terminal',
        action: 'remove_terminal',
        isVisible: ({ load }) =>
          load.terminals.some((terminal) => !terminal.is_delivered),
      },
    ],
  },
  {
    isVisible: ({ load }) => !!load.is_active,

    options: [
      {
        label: 'Assign Driver',
        dataIntercomTarget: 'view-load-assign-driver-button',
        action: 'assign',
        isVisible: ({ load }) =>
          !load.archived && !!load.can_be_assigned_to_driver,
        isPrimary: ({ load }) => !load.driver && load.status === 'new',
      },

      {
        label: 'Reassign',
        action: 'assign',
        isVisible: ({ load }) => !!load.can_be_unassigned_from_driver,
      },

      {
        label: 'Unassign',
        action: 'unassign',
        isVisible: ({ load }) =>
          !load.archived && !!load.can_be_unassigned_from_driver,
      },

      {
        label: 'Duplicate',
        action: 'duplicate',
        isVisible: ({ load }) => !load.archived && !!load.can_be_duplicated,
      },

      {
        label: 'Split Order',
        isVisible: ({ load }) => !load.archived && !!load.can_be_split,
        onClick: ({ load, loadStage }) => {
          trackLoadsEvent({
            name: 'Carrier Clicked Split Order',
            page: 'view_load',
            utm_medium: loadStage,
            is_created_by_broker: !!load.is_created_by_broker,
          });
          openExternalURL(
            '/tms/loads-split/{guid}{?back_url,is_created_by_broker,utm_medium}',
            {
              guid: load.guid,
              back_url: window.location.pathname + window.location.search,
              utm_medium: loadStage,
              is_created_by_broker: load.is_created_by_broker,
            },
          );
        },
      },

      {
        label: 'Mark as New',
        action: 'mark_as_new',
        buttonProps: () => ({ variant: 'neutral' }),
        isVisible: ({ load }) => load.status !== 'new',
      },

      {
        label: 'Mark as Picked Up',
        action: 'mark_as_picked_up',
        buttonProps: () => ({ variant: 'neutral' }),
        isVisible: ({ load }) => !load.archived && !!load.can_be_picked_up,
      },

      {
        label: 'Mark as Delivered',
        action: 'mark_as_delivered',
        buttonProps: () => ({ variant: 'neutral' }),
        isVisible: ({ load }) =>
          !load.archived &&
          !!load.can_be_delivered &&
          !load.can_mark_as_in_terminal,
      },
      {
        label: 'Dispatch to Carrier',
        action: 'dispatch_to_carrier',
        isVisible: ({ load }) =>
          !load.archived &&
          !!load.can_be_dispatched_to_carrier &&
          !load.should_request_dispatch_to_carrier,
      },

      { label: 'Send BOL', action: 'send_bol' },

      {
        label: 'Send Invoice',
        action: 'send_invoice_drawer',
        isPrimary: ({ load }) =>
          load.status === 'delivered' &&
          !load.payments[0]?.invoice.sent_at &&
          !load.payments[0]?.paid_at,
        isVisible: ({ load }) => !!load.can_send_invoice,
        onClick: ({ load }) => {
          trackLoadsEvent({
            name: 'CTMS: Send Invoice Clicked',
            page: 'view_load',
            load_stage: load.archived ? 'archived' : undefined,
          });
        },
      },

      {
        label: 'Mark as Paid',
        action: 'mark_as_paid',
        isPrimary: ({ load }) => !!load.payments[0]?.invoice.sent_at,
        isVisible: ({ load }) => !load.archived && !!load.can_be_marked_as_paid,
      },

      {
        label: 'Mark as Unpaid',
        action: 'mark_as_unpaid',
        isVisible: ({ load }) => !!load.can_be_marked_as_unpaid,
      },
    ],
  },
  {
    options: [
      {
        label: 'View BOL',
        action: 'view_bol',
        onClick: () => {
          trackLoadsEvent({
            name: 'CTMS: Clicked View BOL',
            page: 'view_load',
          });
        },
      },
      {
        label: 'View Invoice',
        action: 'view_invoice',
        onClick: () => {
          trackLoadsEvent({ name: 'CTMS: View Invoice Clicked' });
        },
      },
    ],
  },
  {
    isVisible: ({ load }) => !load.archived && !!load.is_active,
    options: [
      {
        label: 'Search for Similar Loads',
        isDisabled: ({ load }) =>
          !canHaveSimilarSearch(
            createSearchCriteria({
              pickup_venues: [load.pickup.venue],
              delivery_venues: [load.delivery.venue],
            }),
          ),
        onClick: ({ load }) => {
          trackLoadsEvent({
            name: 'CTMS: Search for Similar Loads Clicked',
            page: 'view_load',
          });
          openExternalURL(
            '/loadboard/loads{?utm_medium,utm_source,search_criteria}',
            {
              utm_source: 'Web CTMS',
              utm_medium: 'Similar Loads',
              search_criteria: searchCriteriaToJSON(
                createSimilarSearch(
                  createSearchCriteria({
                    pickup_venues: [omitBy(load.pickup.venue, isEmpty)],
                    delivery_venues: [omitBy(load.delivery.venue, isEmpty)],
                  }),
                ),
              ),
            },
          );
        },
      },
    ],
  },

  {
    options: [
      {
        label: 'Archive',
        action: 'archive',
        buttonProps: () => ({ variant: 'neutral' }),
        isPrimary: ({ load }) =>
          !!load.payments[0]?.paid_at && load.status === 'delivered',
        isVisible: ({ load, isCancelLoadEnabled }) => {
          if (isCancelLoadEnabled) {
            return (
              !load.archived &&
              !!load.is_active &&
              (!load.is_created_by_broker ||
                isLoadCanceled(load) ||
                load.status !== 'new')
            );
          }
          return !load.archived && !!load.is_active;
        },
      },
      {
        label: 'Unarchive',
        action: 'unarchive',
        isVisible: ({ load }) => !!load.archived && !!load.is_active,
      },
      {
        label: 'Delete',
        action: 'delete',
        isVisible: ({ load, isCancelLoadEnabled }) => {
          if (isCancelLoadEnabled) {
            return (
              load.is_active &&
              (!load.is_created_by_broker ||
                isLoadCanceled(load) ||
                load.status !== 'new')
            );
          }

          return load.is_active;
        },
      },
      {
        label: 'Cancel Load',
        action: 'cancel',
        isVisible: ({ load, isCancelLoadEnabled }) =>
          load.is_created_by_broker &&
          !isLoadCanceled(load) &&
          load.is_active &&
          load.status === 'new' &&
          isCancelLoadEnabled,
      },
      {
        label: 'Restore',
        action: 'restore',
        isPrimary: ({ load }) => !load.is_active,
        isVisible: ({ load }) => !load.is_active,
        buttonProps: () => ({ variant: 'neutral' }),
      },
    ],
  },
];
