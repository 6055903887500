import { ColorDynamic } from '@superdispatch/ui';
import { PasswordType } from 'shared/utils/PasswordUtils';
import styled from 'styled-components';

export const Stepper = styled.div`
  height: 5px;
  display: flex;
  width: 100%;
  margin-bottom: 8px;
  margin-top: 4px;
`;

export const StepperItem = styled.div<{
  passwordType?: PasswordType;
}>`
  height: 2px;
  background-color: ${({ passwordType }) =>
    passwordType === 'weak'
      ? ColorDynamic.Red500
      : passwordType === 'average'
      ? ColorDynamic.Yellow500
      : passwordType === 'good' || passwordType === 'strong'
      ? ColorDynamic.Green500
      : ColorDynamic.Silver400};
  flex: 1;
  border-radius: 100px;
  &:not(:last-child) {
    margin-right: 10px;
    flex: 1;
  }
`;
