import { CUSTOMER_TYPES } from '@superdispatch/sdk';
import { validate as validateVIN } from '@voxasoftworks/vin';
import {
  yupArray,
  yupBoolean,
  yupEnum,
  yupNumber,
  yupObject,
  yupString,
} from 'shared/utils/YupUtils';
import { InferType, mixed, Schema } from 'yup';

const PREFERENCES_VEHICLE_TYPES = [
  'sedan',
  'pickup',
  'trailer',
  'truck',
  'suv',
  'van',
  'rv',
  'motorcycle',
] as const;

const PREFERENCES_TRANSPORT_TYPE = ['truck', 'towing', 'driveaway'] as const;

const PREFERENCES_CUSTOMER_TYPE = CUSTOMER_TYPES.map((element) => {
  return element.toLowerCase();
});

export type DriverPreferencesBusinessTypeDTO = InferType<
  typeof driverPreferencesBusinessTypeSchema
>;

export const driverPreferencesBusinessTypeSchema = yupObject({
  preferred_business_locations: yupArray(
    yupEnum(PREFERENCES_CUSTOMER_TYPE),
  ).min(1, 'Select preferred business types'),
});

export type DriverPreferencesTransportTypeDTO = InferType<
  typeof driverPreferencesTransportTypeSchema
>;

export const driverPreferencesTransportTypeSchema = yupObject({
  transport_type: yupEnum(PREFERENCES_TRANSPORT_TYPE, null).required(
    'Select the type of transportation',
  ),
  transport_fuel_expenditure_mpg: whenTransportType(['truck', 'towing'], () => {
    return yupNumber()
      .required('Enter fuel expenditure')
      .max(999.99, 'Enter valid fuel expenditure');
  }),
  transport_trailer_type_is_enclosed: whenTransportType(['truck'], () => {
    return yupBoolean().required('Select the trailer type');
  }),
  transport_vin: yupString()
    .nullable()
    .test('validate-vin', 'Invalid VIN Number', (value) => {
      return !value ? true : validateVIN(value);
    }),
  transport_load_capacity_lbs: whenTransportType(['truck'], () => {
    return yupNumber().required('Enter payload capacity');
  }),
});

export type DriverPreferencesVehicleDetailsDTO = InferType<
  typeof driverPreferencesVehicleDetailsSchema
>;

export const driverPreferencesVehicleDetailsSchema = yupObject({
  transport_type: yupEnum(PREFERENCES_TRANSPORT_TYPE, null).required(
    'Select the type of transportation',
  ),
  can_move_inoperable_vehicles: whenTransportType(
    ['truck', 'towing'],
    () => yupBoolean().default(null).required('Select a specification'),
    () => yupBoolean().transform(Boolean), // prevent null value for driveway
  ),
  vehicles_max_height_inch: yupString().nullable(),
  vehicles_max_length_inch: yupString().nullable(),
  vehicles_max_width_inch: yupString().nullable(),
  vehicles_max_weight_lbs: yupString().nullable(),
  preferred_vehicle_types: yupArray(yupEnum(PREFERENCES_VEHICLE_TYPES)).min(
    1,
    'Select preferred vehicle types',
  ),
});

export type DriverPreferencesVehicleDetailsHistoricalDataDTO = InferType<
  typeof driverPreferencesVehicleDetailsHistoricalDataSchema
>;

export const driverPreferencesVehicleDetailsHistoricalDataSchema = yupObject({
  can_move_inoperable_vehicles: yupBoolean(),
  vehicles_max_height_inch: yupString().nullable(),
  vehicles_max_length_inch: yupString().nullable(),
  vehicles_max_width_inch: yupString().nullable(),
  vehicles_max_weight_lbs: yupString().nullable(),
});

function whenTransportType(
  values: Array<(typeof PREFERENCES_TRANSPORT_TYPE)[number]>,
  fn: () => Schema<unknown>,
  elseFn?: () => Schema<unknown>,
) {
  return mixed().when('transport_type', {
    is: (value: 'truck' | 'towing' | 'driveaway') => values.includes(value),
    then: fn,
    otherwise: elseFn,
  });
}

export function prepareTransportTypePreferences(
  values: DriverPreferencesTransportTypeDTO,
) {
  switch (values.transport_type) {
    case 'truck':
      return values;
    case 'towing':
      return {
        ...values,
        transport_trailer_type_is_enclosed: null,
        transport_load_capacity_lbs: null,
        transport_vin: null,
      };
    case 'driveaway':
      return {
        ...values,
        transport_fuel_expenditure_mpg: null,
        transport_trailer_type_is_enclosed: null,
        transport_load_capacity_lbs: null,
        transport_vin: null,
      };
  }
}

export type DriverPreferencesDTO = InferType<typeof driverPreferencesSchema>;

export const driverPreferencesSchema = yupObject({
  driver_id: yupNumber().required(),
  onboarding_step: yupString()
    .oneOf([
      'completed',
      'business_type',
      'transport_details',
      'vehicle_details',
    ])
    .required(),
}).shape({
  ...driverPreferencesBusinessTypeSchema.fields,
  ...driverPreferencesTransportTypeSchema.fields,
  ...driverPreferencesVehicleDetailsSchema.fields,
});
