import { Stack } from '@superdispatch/ui';
import { TextBox } from '@superdispatch/ui-lab';
import { useFormikContext } from 'formik';
import { MobileAppBridge } from 'shared/data/MobileAppBridge';
import {
  EMPTY_ERROR_MESSAGE,
  FormikMediaField,
} from 'shared/form/FormikMediaField';
import { FieldAdditionalMessages } from 'shared/modules/carrier-profile/core/AdditionalMessages';
import { useVerifiedCarrierApplicationStatus } from 'shared/modules/carrier-profile/VerifiedCarrierApplicationStatus';
import { useCarrierSettings } from 'shared/settings/CarrierSettingsAPI';
import { useFlag } from 'shared/settings/FeatureToggles';
import styled from 'styled-components';
import { W9DTO } from '../data/CarrierProfileDTO';

const HelperList = styled.ul`
  padding-inline-start: 20px;
  margin: 0;
`;

export function ProfileW9Form() {
  const { data } = useVerifiedCarrierApplicationStatus();
  const { data: settings } = useCarrierSettings();
  const isRestrictedMediaEnabled = useFlag('restricted_w9_files');
  const formik = useFormikContext<W9DTO>();

  const decliningReasons = data?.verified_carrier_application.declining_reasons;
  const W9DecliningReasons = decliningReasons?.w9_form_url;
  const externalNotes = data?.verified_carrier_application.external_notes?.w9;

  const isInitialW9File =
    formik.initialValues.w9_form_url === formik.values.w9_form_url;
  const isEditingDisabled =
    !settings?.drivers_can_edit_carrier_info && MobileAppBridge.isInjected();

  const shouldShowW9Error = isInitialW9File && !!W9DecliningReasons?.length;

  return (
    <Stack space="xsmall">
      <FormikMediaField
        label="W-9 Form"
        name="w9_form_url"
        isRestricted={isRestrictedMediaEnabled}
        fullWidth={true}
        disabled={isEditingDisabled}
        error={shouldShowW9Error}
        validate={() => (shouldShowW9Error ? EMPTY_ERROR_MESSAGE : undefined)}
        helperText={
          shouldShowW9Error ? undefined : (
            <HelperList>
              <li>
                <TextBox variant="caption" color="secondary">
                  A signed and dated W-9, issued within the last two years, is
                  required.
                </TextBox>
              </li>
              <li>
                <TextBox variant="caption" color="secondary">
                  Including your Social Security Number (SSN) is optional. If
                  you choose to include it, we will take every precaution to
                  ensure it remains confidential.
                </TextBox>
              </li>
            </HelperList>
          )
        }
      />

      {shouldShowW9Error && (
        <FieldAdditionalMessages
          title="W-9 Form declined due to:"
          variant="error"
          messages={W9DecliningReasons}
          externalNotes={externalNotes}
        />
      )}
    </Stack>
  );
}
