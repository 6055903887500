import { useDeepEqualValue } from '@superdispatch/hooks';
import {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { APIListQueryResult } from 'shared/api/APIListQuery';
import { APIQueryResult } from 'shared/api/APIQuery';
import {
  SearchQueryTextType,
  useSearchQueryText,
} from 'shared/helpers/ReactHelpers';
import { useSearchParam } from 'shared/helpers/SearchParamsHelpers';
import { useNullableContext } from 'shared/hooks/useNullableContext';
import { DispatcherDTO } from 'shared/modules/dispatcher/DispatcherDTO';
import { useDispatcher, useDispatcherList } from './DispatchersAPI';

export interface DispatcherPageContext {
  rootURL: string;
  currentDispatcherGUID: string | undefined;
  openDispatcher: (guid: string, replace?: boolean) => void;
  searchQueryDrivers: SearchQueryTextType;
  dispatcherList: APIListQueryResult<DispatcherDTO>;
  dispatcher: APIQueryResult<DispatcherDTO>;
}

const context = createContext<null | DispatcherPageContext>(null);

export function useDispatcherPageContext(): DispatcherPageContext {
  return useNullableContext('DispatcherPageContext', context);
}

export interface DispatcherPageContextProviderProps {
  children?: ReactNode;
}

const rootURL = '/dispatchers';

export function DispatcherPageContextProvider({
  children,
}: DispatcherPageContextProviderProps) {
  const [utmMediumParams] = useSearchParam('utm_medium');
  const { dispatcherGUID: currentDispatcherGUID } = useParams();
  const searchQueryDrivers = useSearchQueryText();
  const [_, searchQuery] = searchQueryDrivers;
  const dispatcherList = useDispatcherList({ q: searchQuery, page_size: 20 });
  const dispatcher = useDispatcher(currentDispatcherGUID);

  const navigate = useNavigate();
  const openDispatcher = useCallback(
    (guid: string, replace?: boolean) => {
      let dispatcherURL = `${rootURL}/${guid}`;
      if (utmMediumParams) dispatcherURL += `?utm_medium=${utmMediumParams}`;
      navigate(dispatcherURL, { replace });
    },
    [utmMediumParams, navigate],
  );

  const { isFetching, data } = dispatcherList;

  const list = useMemo(() => data?.pages.flatMap((item) => item.data), [data]);

  useEffect(() => {
    const fistDriverGUID = list?.[0]?.guid;
    if (!isFetching && fistDriverGUID && !currentDispatcherGUID) {
      openDispatcher(fistDriverGUID, true);
    }
  }, [openDispatcher, isFetching, list, currentDispatcherGUID]);

  const ctx = useDeepEqualValue({
    rootURL,
    openDispatcher,
    currentDispatcherGUID,
    searchQueryDrivers,
    dispatcherList,
    dispatcher,
  });

  return <context.Provider value={ctx}>{children}</context.Provider>;
}
