import { Tooltip } from '@material-ui/core';
import { Star } from '@material-ui/icons';
import { Color, ColorDynamic, Inline } from '@superdispatch/ui';
import { Box, TextBox } from '@superdispatch/ui-lab';
import styled from 'styled-components';

const StyledTextBox = styled(TextBox)`
  color: ${Color.White};
`;

export function ExclusiveLoadTag() {
  return (
    <Tooltip
      title={
        <span>
          This load can be booked only on <br /> Super Loadboard
        </span>
      }
    >
      <Box
        borderRadius="small"
        paddingLeft="xxsmall"
        paddingRight="xxsmall"
        backgroundColor="Dark500"
      >
        <Inline space="xxsmall" verticalAlign="center">
          <Star fontSize="small" htmlColor={ColorDynamic.Yellow300} />

          <TextBox variant="body-semibold" color="white">
            Exclusive
          </TextBox>
        </Inline>
      </Box>
    </Tooltip>
  );
}

export function SuggestedLoadTag() {
  return (
    <Box
      borderRadius="small"
      paddingLeft="xxsmall"
      paddingRight="xxsmall"
      backgroundColor="Blue300"
    >
      <StyledTextBox variant="body-semibold" color="white">
        Suggested For You
      </StyledTextBox>
    </Box>
  );
}
