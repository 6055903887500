import { ColorDynamic } from '@superdispatch/ui';
import { createSvgIcon } from 'shared/icons/IconUtils';

export const RemoveIcon = createSvgIcon(
  'RemoveIcon',
  <path
    d="M10.0001 1.66602C14.6084 1.66602 18.3334 5.39102 18.3334 9.99935C18.3334 14.6077 14.6084 18.3327 10.0001 18.3327C5.39175 18.3327 1.66675 14.6077 1.66675 9.99935C1.66675 5.39102 5.39175 1.66602 10.0001 1.66602ZM12.9917 5.83268L10.0001 8.82435L7.00841 5.83268L5.83342 7.00768L8.82508 9.99935L5.83342 12.991L7.00841 14.166L10.0001 11.1743L12.9917 14.166L14.1667 12.991L11.1751 9.99935L14.1667 7.00768L12.9917 5.83268Z"
    fill={ColorDynamic.Red500}
  />,
  {
    viewBox: '0 0 20 20',
    style: { height: '20px', width: '20px', fill: 'none' },
  },
);
