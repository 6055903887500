import { ButtonBase, ButtonBaseProps, Checkbox } from '@material-ui/core';
import { Color, ColorDynamic, Column, Columns } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { useField } from 'formik';
import { ReactNode } from 'react';
import styled from 'styled-components';

const StyledButtonBase = styled(ButtonBase)`
  padding: 16px;
  border-radius: 4px;
  border: 1px solid ${ColorDynamic.Silver500};
  width: 100%;
  display: block;
  text-align: left;
  &[data-checked='true'] {
    border-color: transparent;
    box-shadow: 0 0 0px 2px ${Color.Blue300};
  }
  &[disabled] {
    border: 1px solid ${ColorDynamic.Silver400};
  }
  & .MuiCheckbox-root {
    margin: 0;
  }
`;

interface OnboardingCheckboxProps extends ButtonBaseProps {
  label: ReactNode;
  name: string;
  disabled?: boolean;
  shouldShowCheckbox?: boolean;
  selected?: boolean;
}

export function OnboardingCheckboxField({
  label,
  name,
  disabled,
  onClick,
  shouldShowCheckbox,
  selected,
}: OnboardingCheckboxProps) {
  const [fieldProps, _, { setValue }] = useField<boolean>(name);

  return (
    <StyledButtonBase
      disableRipple={true}
      data-checked={selected}
      disabled={disabled}
      onClick={(event) => {
        event.stopPropagation();
        onClick?.(event);
        if (!disabled && shouldShowCheckbox) {
          void setValue(!fieldProps.value);
        }
      }}
      name={name}
    >
      <Columns space="small" align="top">
        <Column width="content">
          {shouldShowCheckbox && (
            <Checkbox checked={fieldProps.value} disabled={disabled} />
          )}
        </Column>
        <Column width="fluid">
          <Box color={disabled ? 'Dark200' : 'Dark500'}>{label}</Box>
        </Column>
      </Columns>
    </StyledButtonBase>
  );
}
