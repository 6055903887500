import { ColorDynamic } from '@superdispatch/ui';
import { createSvgIcon } from 'shared/icons/IconUtils';

export const TimeIcon = createSvgIcon(
  'TimeIcon',
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M1.6665 9.99999C1.6665 5.39999 5.3915 1.66666 9.9915 1.66666C14.5998 1.66666 18.3332 5.39999 18.3332 9.99999C18.3332 14.6 14.5998 18.3333 9.9915 18.3333C5.3915 18.3333 1.6665 14.6 1.6665 9.99999ZM3.33317 9.99999C3.33317 13.6833 6.3165 16.6667 9.99984 16.6667C13.6832 16.6667 16.6665 13.6833 16.6665 9.99999C16.6665 6.31666 13.6832 3.33332 9.99984 3.33332C6.3165 3.33332 3.33317 6.31666 3.33317 9.99999ZM9.1665 5.83334H10.4165V10.2083L14.1665 12.4333L13.5415 13.4583L9.1665 10.8333V5.83334Z"
    fill={ColorDynamic.Yellow500}
  />,
  {
    viewBox: '0 0 20 20',
    style: { height: '20px', width: '20px', fill: 'none' },
  },
);
