import { IconButton, Tooltip, Typography } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import { formatVehicleType } from '@superdispatch/sdk';
import {
  ColorDynamic,
  Column,
  Columns,
  Inline,
  OverflowText,
} from '@superdispatch/ui';
import { formatCurrency } from 'shared/helpers/IntlHelpers';
import {
  formatCurbWeight,
  formatVehicleDimensions,
  hasVehicleDimension,
} from 'shared/helpers/VehicleHelpers';
import { EnclosedTrailerIcon } from 'shared/icons/EnclosedTrailerIcon';
import { InoperableIcon } from 'shared/icons/InoperableIcon';
import { InlineBulletItems } from 'shared/ui/InlineBulletItems';
import { joinStrings } from 'shared/utils/StringUtils';
import { LoadVehicleStatusTag } from '../../../loads/core/LoadVehicleStatusTag';
import { LoadDTO, LoadVehicleDTO } from '../../data/LoadDTO';

interface Props {
  load: LoadDTO;
  layout?: 'print';
  vehicle: LoadVehicleDTO;
  onEditClick: () => void;
  onDeleteClick: () => void;
  isEnabledEditLoad: boolean;
}

export function ViewLoadVehicleMobile({
  load,
  layout,
  vehicle,
  onEditClick,
  onDeleteClick,
  isEnabledEditLoad,
}: Props) {
  return (
    <Columns
      aria-label={vehicle.make + ' ' + vehicle.model + ' ' + vehicle.type}
    >
      <Column width="fluid">
        <Columns space="xsmall">
          <Column width="content">
            <LoadVehicleStatusTag status={vehicle.status} />
            <OverflowText variant="body1">
              {joinStrings(' ', vehicle.year, vehicle.make, vehicle.model)}
            </OverflowText>
          </Column>
          <Column width="fluid">
            <OverflowText color="textSecondary">
              {vehicle.color}{' '}
              {formatVehicleType(vehicle.type, { fallback: vehicle.type })}
            </OverflowText>
          </Column>
        </Columns>
        <Inline verticalAlign="center">
          <Typography color="textSecondary">{vehicle.vin}</Typography>
          <Inline space="xsmall">
            {vehicle.is_inoperable && (
              <Tooltip title="Inoperable vehicle" enterTouchDelay={0}>
                <InoperableIcon htmlColor={ColorDynamic.Red500} />
              </Tooltip>
            )}
            {vehicle.requires_enclosed_trailer && (
              <Tooltip title="Requires enclosed trailer" enterTouchDelay={0}>
                <EnclosedTrailerIcon htmlColor={ColorDynamic.Yellow500} />
              </Tooltip>
            )}
          </Inline>
        </Inline>

        <InlineBulletItems>
          {vehicle.lot_number && (
            <Typography color="textSecondary">{vehicle.lot_number}</Typography>
          )}
          {vehicle.curb_weight && (
            <Typography color="textSecondary">
              {formatCurbWeight(vehicle.curb_weight)}
            </Typography>
          )}
          {hasVehicleDimension(vehicle) && (
            <Typography noWrap={true} color="textSecondary">
              {formatVehicleDimensions(vehicle)}
            </Typography>
          )}
          {!!vehicle.price && (
            <Typography color="textSecondary">
              {formatCurrency(vehicle.price)}
            </Typography>
          )}
        </InlineBulletItems>
      </Column>
      <Column width="content">
        {layout !== 'print' && isEnabledEditLoad && load.can_be_edited && (
          <Inline space="xxsmall">
            <IconButton
              onClick={onEditClick}
              size="small"
              aria-label="edit vehicle"
            >
              <Edit />
            </IconButton>
            <IconButton
              onClick={onDeleteClick}
              size="small"
              aria-label="delete vehicle"
            >
              <Delete />
            </IconButton>
          </Inline>
        )}
      </Column>
    </Columns>
  );
}
