import { Typography } from '@material-ui/core';
import { ColorDynamic, Inline } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { TickDoneIcon } from 'shared/icons/TickDoneIcon';
import styled from 'styled-components';

interface CheckPasswordItemProps {
  isDone: boolean;
  text: string;
}

const ListItem = styled.div`
  display: flex;
  align-items: center;
`;

const Dot = styled.div`
  height: 4px;
  width: 4px;
  background-color: ${ColorDynamic.Blue300};
  border-radius: 100px;
`;

const TickBox = styled(Box)`
  width: 13.33px;
  height: 13.33px;
  border-radius: 15px;
  background-color: ${ColorDynamic.Blue50};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CheckPasswordItemText = styled(Typography)<{ isDone?: boolean }>`
  text-decoration: ${({ isDone }) => (isDone ? 'line-through' : 'inherit')};
  color: ${({ isDone }) =>
    isDone ? ColorDynamic.Dark100 : ColorDynamic.Dark500};
  margin-left: 6px;
`;

export function CheckPasswordItem({ isDone, text }: CheckPasswordItemProps) {
  return (
    <ListItem>
      <Box minWidth="16px">
        <Inline verticalAlign="center" horizontalAlign="center">
          {isDone ? (
            <TickBox>
              <TickDoneIcon />
            </TickBox>
          ) : (
            <Dot />
          )}
        </Inline>
      </Box>
      <CheckPasswordItemText isDone={isDone} variant="body2">
        {text}
      </CheckPasswordItemText>
    </ListItem>
  );
}
