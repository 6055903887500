import { ColorDynamic } from '@superdispatch/ui';
import { createSvgIcon } from 'shared/icons/IconUtils';

export const WarningRoundedIcon = createSvgIcon(
  'WarningRoundedIcon',
  <>
    <rect width="32" height="32" rx="16" fill={ColorDynamic.Silver200} />
    <g clipPath="url(#clip0_1899_51101)">
      <path
        d="M5 25H27L16 6L5 25ZM17 22H15V20H17V22ZM17 18H15V14H17V18Z"
        fill={ColorDynamic.Dark100}
      />
    </g>
    <defs>
      <clipPath id="clip0_1899_51101">
        <rect width="24" height="24" fill="white" transform="translate(4 4)" />
      </clipPath>
    </defs>
  </>,
  { viewBox: '0 0 32 32', style: { height: '32px', width: '32px' } },
);
