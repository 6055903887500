import { useFormikEnhanced } from '@superdispatch/forms';
import { useValueObserver } from '@superdispatch/hooks';
import { useSnackbarStack } from '@superdispatch/ui';
import { parseURITemplate } from '@superdispatch/uri';
import { noop } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';
import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom';
import { APIResponse } from 'shared/api/CarrierAPIClient';
import { parseSearchQuery } from 'shared/utils/URLUtils';
import {
  formatLoadStage,
  formatLoadStatus,
  LoadDTO,
  LoadStage,
} from '../data/LoadDTO';
import { trackLoadsEvent } from '../data/LoadsAnalytics';
import { LoadsAPI } from '../data/LoadsAPI';
import { useLoadsContext } from '../data/LoadsContext';

export type LoadInstantActionType =
  | 'unarchive'
  | 'duplicate'
  | 'mark_as_unpaid'
  | 'view_bol'
  | 'view_invoice';

export function isLoadInstantActionType(
  type: string,
): type is LoadInstantActionType {
  return [
    'unarchive',
    'duplicate',
    'mark_as_unpaid',
    'view_bol',
    'view_invoice',
  ].includes(type);
}

interface InstantActionState {
  type: LoadInstantActionType;
  load: LoadDTO;
}

function getConfig(
  navigate: NavigateFunction,
  type: LoadInstantActionType,
  search: string,
  pathname: string,
): {
  message: ((load: LoadDTO) => string) | string;
  submit: (
    api: LoadsAPI,
    load: LoadDTO,
  ) => Promise<APIResponse> | Promise<unknown>;
} {
  switch (type) {
    case 'duplicate':
      return {
        message: 'Load has been duplicated.',
        submit: (api, load) =>
          api.duplicateLoad(load.guid).then(({ guid }) => {
            const query = parseSearchQuery(search);
            const stage = query.stage as LoadStage | undefined;
            const isGuid = pathname.includes(load.guid);

            trackLoadsEvent({
              name: 'Carrier Load Duplicated',
              page: isGuid ? 'view_load' : 'loads_list',
              load_status: formatLoadStatus(load.status),
              is_created_by_broker: Boolean(load.is_created_by_broker),
              utm_medium: formatLoadStage(stage || load.stage) || 'New',
            });

            navigate(parseURITemplate('/loads/{guid}', { guid }));

            // Return never ending promise so it will not stop submitting.
            return new Promise(noop);
          }),
      };
    case 'unarchive':
      return {
        message: 'Load has been unarchived.',
        submit: (api, load) => api.unarchiveLoad(load.guid),
      };

    case 'mark_as_unpaid':
      return {
        message: (load) => `Load ${load.number} marked as unpaid`,
        submit: (api, load) => api.markAsUnpaid(load.guid),
      };
    case 'view_bol':
      return {
        message: () => 'Successfully generated BOL',
        submit: (api, load) =>
          api.getBolPDF(load.guid).then(async (response) => {
            const blob = await response.blob();
            const fileURL = URL.createObjectURL(blob);
            window.open(fileURL, '_blank');
            return response;
          }),
      };
    case 'view_invoice':
      return {
        message: () => 'Successfully generated Invoice',
        submit: (api, load) =>
          api.getInvoicePDF(load.guid).then(async (response) => {
            const blob = await response.blob();
            const fileURL = URL.createObjectURL(blob);
            window.open(fileURL, '_blank');
            return response;
          }),
      };
  }
}

interface Props {
  key?: string;
  onActionComplete?: (type: LoadInstantActionType, load: LoadDTO) => void;
}

export function useLoadInstantAction({ key, onActionComplete }: Props) {
  const navigate = useNavigate();
  const location = useLocation();
  const { loadsAPI } = useLoadsContext();
  const { addSnackbar } = useSnackbarStack();
  const [actionState, setActionState] = useState<InstantActionState>();

  const config = useMemo(() => {
    if (actionState) {
      const { type } = actionState;
      return getConfig(navigate, type, location.search, location.pathname);
    }

    return undefined;
  }, [navigate, location.search, location.pathname, actionState]);

  const { isSubmitting, handleSubmit, resetForm } = useFormikEnhanced({
    initialValues: { load: actionState?.load },
    onSubmit: (values) => {
      if (config && values.load) {
        return config.submit(loadsAPI, values.load);
      }

      return Promise.reject('Load not found');
    },
    onSubmitSuccess: ({ user_message }, { load }) => {
      const configMessage =
        typeof config?.message === 'function'
          ? load && config.message(load)
          : config?.message;

      addSnackbar((user_message as string) || configMessage, {
        variant: 'success',
      });
      setActionState(undefined);

      if (actionState) {
        onActionComplete?.(actionState.type, actionState.load);
      }
    },
    onSubmitFailure: ({ message }) => {
      addSnackbar(message, { variant: 'error' });
    },
  });

  /* Here the form will not be dirty but it will have isSubmitting state true */
  useValueObserver(key, () => {
    resetForm();
  });

  useEffect(() => {
    if (actionState) {
      handleSubmit();
    }
  }, [handleSubmit, actionState]);

  return {
    isSubmitting,
    submitInstantAction: (
      type: LoadInstantActionType,
      currentLoad: LoadDTO,
    ) => {
      setActionState({ type, load: currentLoad });
    },
  };
}
