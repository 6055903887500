import { ButtonBase as MuiButtonBase, Typography } from '@material-ui/core';
import { ArrowForwardIos } from '@material-ui/icons';
import { ColorDynamic, Column, Columns, Stack } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { VerifiedIcon } from 'shared/icons/VerifiedIcon';
import styled from 'styled-components';

interface OnboardingTaskItemProps {
  name: string;
  done?: boolean;
  icon: JSX.Element;
  description: string;
  onClick?: () => void;
}

const IconBox = styled(Box)`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
`;

const ArrowIconBox = styled(Box)`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid ${ColorDynamic.Silver500};
`;

const ButtonBase = styled(MuiButtonBase)`
  width: 100%;
  height: 100%;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid ${ColorDynamic.Silver400};
`;

const Description = styled(Typography)<{ done: boolean }>`
  font-size: 12px;
  text-decoration-color: ${ColorDynamic.Dark100};
  color: ${({ done }) => (done ? ColorDynamic.Dark100 : ColorDynamic.Dark300)};
  text-decoration: ${({ done }) => (done ? 'line-through' : 'none')};
`;

const Title = styled(Typography)<{ done: boolean }>`
  font-weight: 500;
  text-decoration-color: ${ColorDynamic.Dark100};
  color: ${({ done }) => (done ? ColorDynamic.Dark100 : ColorDynamic.Dark500)};
  text-decoration: ${({ done }) => (done ? 'line-through' : 'none')};
`;

export function OnboardingTaskItem({
  name,
  icon,
  done,
  description,
  onClick,
}: OnboardingTaskItemProps) {
  return (
    <ButtonBase onClick={onClick}>
      <Columns space="small" align="center">
        <Column width="content">
          <IconBox backgroundColor="Silver200">{icon}</IconBox>
        </Column>
        <Column width="fluid">
          <Stack align="left" space="xxsmall">
            <Title done={!!done} align="left">
              {name}
            </Title>
            <Description done={!!done} align="left" color="textSecondary">
              {description}
            </Description>
          </Stack>
        </Column>
        <Column width="content">
          {done ? (
            <VerifiedIcon />
          ) : (
            <ArrowIconBox>
              <ArrowForwardIos
                fontSize="small"
                htmlColor={ColorDynamic.Dark100}
              />
            </ArrowIconBox>
          )}
        </Column>
      </Columns>
    </ButtonBase>
  );
}
