import { CardComponent } from '@chargebee/chargebee-js-react-wrapper';
import ChargebeeComponents from '@chargebee/chargebee-js-react-wrapper/dist/components/ComponentGroup';
import { FormikTextField } from '@superdispatch/forms';
import { Column, Columns, Stack } from '@superdispatch/ui';
import { forwardRef, useState } from 'react';
import {
  ChargebeeErrorField,
  ChargebeeEvent,
  fonts,
} from 'shared/helpers/ChargebeeWeb';
import { validateString } from 'shared/helpers/ValidationHelpers';
import { CVVTitleProps } from 'shared/ui/ChargebeeCvvTitle';
import {
  FormCardCVV,
  FormCardExpiry,
  FormCardNumber,
} from 'shared/ui/ChargebeeFormFields';

interface SubscriptionChargebeeCardProps {
  hasCardHolderName: boolean;
}

export const SubscriptionChargebeeCard = forwardRef<
  ChargebeeComponents | null,
  SubscriptionChargebeeCardProps
>(({ hasCardHolderName }, cardRef) => {
  const [focus, setFocus] = useState<'number' | 'expiry' | 'cvv'>();
  const [errors, setErrors] = useState<ChargebeeErrorField>({
    expiry: null,
    cvv: null,
    number: null,
  });
  const [card, setCard] = useState<CVVTitleProps>({
    isEmpty: true,
    cardType: undefined,
  });

  const handleError = (event: ChargebeeEvent) => {
    const { field, error } = event;
    setErrors((prevErr) => ({ ...prevErr, [field]: error ?? null }));
    if (field === 'number') {
      setCard({ cardType: event.cardType, isEmpty: event.empty });
    }
  };

  return (
    <CardComponent
      placeholder={{
        cvv: !hasCardHolderName ? 'CVV2, CVC2, etc.' : ' ',
        number: ' ',
        expiry: 'MM / YY',
      }}
      onChange={handleError}
      fonts={fonts}
      ref={cardRef}
    >
      <Stack space="small">
        <FormCardNumber
          label="Card Number"
          focused={focus === 'number'}
          onFocus={() => {
            setFocus('number');
          }}
          error={errors.number ? errors.number.message : ''}
        />

        {hasCardHolderName && (
          <FormikTextField
            name="cardholder_name"
            label="Name on Card"
            onFocus={() => {
              setFocus(undefined);
            }}
            fullWidth={true}
            required={false}
            validate={(value) => validateString(value, { required: true })}
          />
        )}
        <Columns space="small">
          <Column width="1/2">
            <FormCardExpiry
              label="Expiration"
              focused={focus === 'expiry'}
              onFocus={() => {
                setFocus('expiry');
              }}
              error={errors.expiry ? errors.expiry.message : ''}
            />
          </Column>
          <Column width="1/2">
            <FormCardCVV
              {...card}
              label="Security Code"
              focused={focus === 'cvv'}
              onFocus={() => {
                setFocus('cvv');
              }}
              error={errors.cvv ? errors.cvv.message : ''}
            />
          </Column>
        </Columns>
      </Stack>
    </CardComponent>
  );
});

SubscriptionChargebeeCard.displayName = 'SubscriptionChargebeeCard';
