import { Link, Typography } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import { FormattedDate } from '@superdispatch/dates';
import { Column, Columns, Inline, Stack, Tag } from '@superdispatch/ui';
import { DateTime } from 'luxon';
import { getFilenameFromURL } from 'shared/helpers/CommonHelpers';
import { formatCurrency } from 'shared/helpers/IntlHelpers';
import { LinkIconButton } from 'shared/routing/Links';
import {
  formatLoadExpenseType,
  LoadDTO,
  LoadExpenseDTO,
} from '../../../loads/data/LoadDTO';

interface ExpensesItemProps {
  load: LoadDTO;
  expense: LoadExpenseDTO;
}

export function ViewLoadExpenseMobile({ load, expense }: ExpensesItemProps) {
  const expenseType = formatLoadExpenseType(expense.type);
  return (
    <Stack space="xxsmall">
      <Columns align="center">
        <Column width="fluid">
          <Inline>
            <Typography variant="body1">
              {expense.type === 'other'
                ? expense.name || expenseType
                : expenseType}
            </Typography>
            {expense.price && (
              <Typography>· {formatCurrency(expense.price)}</Typography>
            )}
          </Inline>
        </Column>
        <Column width="content">
          <Inline horizontalAlign="right">
            <LinkIconButton
              size="small"
              aria-label="edit expense"
              to={`/loads/${load.guid}/expense/${expense.guid}`}
            >
              <Edit />
            </LinkIconButton>

            <LinkIconButton
              size="small"
              aria-label="delete expense"
              to={`/loads/${load.guid}/delete-expense/${expense.guid}`}
            >
              <Delete />
            </LinkIconButton>
          </Inline>
        </Column>
      </Columns>

      <Columns space="xsmall">
        {expense.receipt_url && (
          <Column width="fluid">
            <Typography noWrap={true}>
              <Link
                target="_blank"
                rel="noopener noreferrer"
                href={expense.receipt_url}
              >
                {getFilenameFromURL(expense.receipt_url)}
              </Link>
            </Typography>
          </Column>
        )}
        {expense.receipt_url && <Column width="content">·</Column>}
        <Column width="content">
          <Typography color="textSecondary">
            <FormattedDate
              date={expense.date}
              format="DateTimeISO"
              variant="ShortDate"
            />
            , {DateTime.local().get('year')}
          </Typography>
        </Column>
      </Columns>

      <Inline>
        <Typography color="textSecondary">Show on invoice</Typography>
        {expense.show_on_invoice ? (
          <Tag variant="subtle" color="green">
            Yes
          </Tag>
        ) : (
          <Tag variant="subtle" color="grey">
            No
          </Tag>
        )}
      </Inline>

      <Inline>
        <Typography color="textSecondary">Deduct from driver pay</Typography>
        {expense.deduct_from_driver_pay ? (
          <Tag variant="subtle" color="green">
            Yes
          </Tag>
        ) : (
          <Tag variant="subtle" color="grey">
            No
          </Tag>
        )}
      </Inline>
    </Stack>
  );
}
