import { Link, ListItem, ListItemText, Typography } from '@material-ui/core';
import { Check, Info, Lock } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { useFormikEnhanced } from '@superdispatch/forms';
import {
  ColorDynamic,
  Column,
  Columns,
  DrawerActions,
  Inline,
  Stack,
  useSnackbarStack,
} from '@superdispatch/ui';
import { Alert, Box, Button, TextBox } from '@superdispatch/ui-lab';
import { APIError, isAPIError } from 'shared/api/APIError';
import { useCreditCard } from 'shared/errors/PaymentService';
import { SlowFormikDrawer } from 'shared/form/SlowFormikDrawer';
import { defaultFormErrorsGetter } from 'shared/helpers/FormHelpers';
import { ChargebeeEmptyPlaceholder } from 'shared/ui/ChargebeeEmptyPlaceholder';
import { SubscriptionChargebeeCard } from './core/SubscriptionChargebeeCard';
import { trackSubscriptionEvent } from './data/SubscriptionAnalytics';
import { useSubscriptionAPI } from './SubscriptionAPI';
import { SubscriptionSettingsDTO } from './SubscriptionDTO';

export interface SubscriptionUpdateCardDetailsDrawerProps {
  title?: string;
  hasCardHolderName: boolean;
  open: boolean;
  onClose: () => void;
  onSubmitSuccess?: () => void;
  subscriptionDetails?: SubscriptionSettingsDTO | null;
}

export function SubscriptionUpdateCardDetailsDrawer({
  open,
  onClose,
  onSubmitSuccess,
  subscriptionDetails,
  title,
  hasCardHolderName,
}: SubscriptionUpdateCardDetailsDrawerProps) {
  const { addCreditCard, cardRef, isChargebeeAvailable } = useCreditCard(
    subscriptionDetails?.client_token,
  );

  const { addSnackbar } = useSnackbarStack();

  const { updateSubscriptionSettings } = useSubscriptionAPI();
  const formik = useFormikEnhanced({
    key: open,
    validateOnBlur: false,
    initialValues: {
      cardholder_name: '',
    },
    getFormErrors: (error) => {
      return defaultFormErrorsGetter(error);
    },
    onSubmit: async (values) => {
      const [firstName, lastName] = values.cardholder_name.split(' ');

      try {
        const { token, vaultToken } = await addCreditCard({
          firstName,
          lastName,
        });
        return await updateSubscriptionSettings({
          payment_method_nonce: token,
          id: token,
          id_at_vault: vaultToken,
        });
      } catch (err: unknown) {
        if (isAPIError(err)) {
          const errorDetails = (err as APIError<{ details: string[] }>).context
            ?.details;

          if (Array.isArray(errorDetails)) {
            err.message = errorDetails.join('\n');
          }
        }

        return Promise.reject(err);
      }
    },
    onSubmitSuccess: () => {
      onClose();
      onSubmitSuccess?.();
      trackSubscriptionEvent({
        name: 'Carrier Edited Subscription Payment Information',
      });
      addSnackbar('Payment information was successfully updated', {
        variant: 'success',
      });
    },
  });

  return (
    <SlowFormikDrawer
      formik={formik}
      open={open}
      onClose={onClose}
      title={
        !subscriptionDetails ? (
          <>Loading&hellip;</>
        ) : title ? (
          title
        ) : subscriptionDetails.card_information ? (
          'Update Payment Information'
        ) : (
          'Add Payment Information'
        )
      }
      actions={
        !!subscriptionDetails && (
          <Stack space="small">
            <DrawerActions>
              <Columns align="center">
                <Column width="1/2">
                  <Button
                    startIcon={<Lock />}
                    type="submit"
                    pending={formik.isSubmitting}
                  >
                    Save
                  </Button>
                </Column>

                <Column width="1/2">
                  <Inline horizontalAlign="right">
                    <Link
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://support.superdispatch.com/en/articles/6975505-carrier-tms-how-do-i-cancel-my-subscription"
                    >
                      Refund Policy
                    </Link>
                  </Inline>
                </Column>
              </Columns>
            </DrawerActions>

            {formik.status.type === 'rejected' && (
              <Box maxWidth="500px" paddingRight="large" paddingLeft="large">
                <Alert
                  icon={
                    <Info fontSize="small" htmlColor={ColorDynamic.Red500} />
                  }
                  severity="critical"
                >
                  {formik.status.payload.message}
                </Alert>
              </Box>
            )}
          </Stack>
        )
      }
    >
      <Stack space={hasCardHolderName ? 'small' : 'large'}>
        <Box maxWidth="420px">
          <Stack space="xsmall">
            <Columns space="xxsmall" align="top">
              <Column width="content">
                <Lock fontSize="small" htmlColor={ColorDynamic.Dark100} />
              </Column>
              <Column width="fluid">
                <TextBox color="secondary" variant="body">
                  We use industry-standard encryption and security protocols to
                  safeguard your information. Your card details are not stored
                  on our internal servers for your protection.
                </TextBox>
              </Column>
            </Columns>
            <Columns space="xxsmall" align="top">
              <Column width="content">
                <Check fontSize="small" htmlColor={ColorDynamic.Dark100} />
              </Column>
              <Column width="fluid">
                <TextBox color="secondary" variant="body">
                  We accept all major credit cards
                </TextBox>
              </Column>
            </Columns>
          </Stack>
        </Box>

        {subscriptionDetails === undefined ? (
          <Stack space="small">
            <Skeleton />
            <Skeleton />
          </Stack>
        ) : subscriptionDetails === null ? (
          <ListItem>
            <ListItemText
              disableTypography={true}
              primary={
                <Typography color="textSecondary" align="center" variant="h4">
                  There are no subscription details
                </Typography>
              }
            />
          </ListItem>
        ) : isChargebeeAvailable ? (
          <SubscriptionChargebeeCard
            hasCardHolderName={hasCardHolderName}
            ref={cardRef}
          />
        ) : (
          <ChargebeeEmptyPlaceholder />
        )}
      </Stack>
    </SlowFormikDrawer>
  );
}
