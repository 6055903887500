import { createSvgIcon } from 'shared/icons/IconUtils';

export const UploadCloudIcon = createSvgIcon(
  'UploadCloudIcon',

  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M22.1973 5.43482C23.5345 6.63323 24.4088 8.21244 24.706 9.94285C27.7003 10.7157 29.889 13.5101 29.8947 16.6517C29.8947 18.3877 29.2375 20.0565 28.0318 21.3557C26.8203 22.6661 25.1917 23.4501 23.3916 23.5733L17.2453 23.5756V15.9962L19.1522 17.7C19.3078 17.8473 19.5024 17.9211 19.7361 17.9211C19.9651 17.9211 20.162 17.8473 20.3262 17.7L20.8125 17.264C20.9725 17.1127 21.0526 16.9383 21.0526 16.7407C21.0526 16.5391 20.9726 16.3626 20.8126 16.2114L16.5901 12.4257C16.4301 12.2822 16.2336 12.2105 16 12.2105C15.7622 12.2105 15.5675 12.2822 15.4162 12.4257L11.1938 16.2114C11.0295 16.3587 10.9473 16.5352 10.9473 16.7406C10.9473 16.9422 11.0295 17.1166 11.1938 17.264L11.6802 17.7C11.8359 17.8473 12.0305 17.9211 12.264 17.9211C12.4975 17.9211 12.692 17.8473 12.8477 17.7L14.7546 15.9962V23.5765L7.88256 23.5789C6.32822 23.4949 4.87103 22.7725 3.76814 21.5517C2.69382 20.3589 2.10522 18.8525 2.10522 17.3013C2.10522 15.3805 3.05954 13.5773 4.63102 12.4573C4.57388 12.1661 4.53959 11.8693 4.53959 11.5613C4.53959 9.18124 6.5168 7.24363 8.94545 7.24363C9.23117 7.24363 9.51689 7.27163 9.79119 7.32203C10.4255 6.23563 11.3227 5.31162 12.4027 4.62842C13.6999 3.80521 15.2142 3.36841 16.7686 3.36841C18.7801 3.36841 20.7115 4.10201 22.1973 5.43482ZM14.7546 23.5765V27.9513C14.7546 28.1527 14.8357 28.3166 14.9979 28.4427C15.16 28.5686 15.3557 28.6316 15.5848 28.6316H16.415C16.6442 28.6316 16.8398 28.5686 17.002 28.4427C17.1642 28.3167 17.2453 28.1527 17.2453 27.9513V23.5756L14.7546 23.5765Z"
    fill="currentColor"
  />,
  {
    viewBox: '0 0 32 32',
    style: {
      width: '32px',
      height: '32px',
    },
  },
);
