import { Typography } from '@material-ui/core';
import { ThumbUp } from '@material-ui/icons';
import { formatRelativeTime, FormattedDate } from '@superdispatch/dates';
import { Inline, Stack, Tag } from '@superdispatch/ui';
import { Box, Button, TextBox } from '@superdispatch/ui-lab';
import { startCase, truncate } from 'lodash-es';
import { useMemo, useState } from 'react';
import { formatAddressLine } from 'shared/helpers/AddressHelpers';
import { formatCurrency, formatNumber } from 'shared/helpers/IntlHelpers';
import { formatVehicleLength } from 'shared/helpers/VehicleHelpers';
import { PricingInsightsLink } from 'shared/modules/pricing-insights/PricingInsightsLink';
import { useShipperRatingDetails } from 'shared/modules/ratings/data/ShipperRatingsAPI';
import { ShipperProfileLink } from 'shared/modules/shipper/ShipperProfileLink';
import { useFlag } from 'shared/settings/FeatureToggles';
import { Image } from 'shared/ui/Image';
import { LoadWaypoints } from 'shared/ui/LoadWaypoints';
import { PaymentInfo } from 'shared/ui/PaymentInfo';
import styled from 'styled-components';
import { getDriverLabel } from '../../drivers/data/DriverPageUtils';
import OfferAcceptedLogo from '../assets/offer-accepted-logo.svg';
import OfferAssignedLogoDark from '../assets/offer-assigned-logo-dark.svg';
import OfferAssignedLogo from '../assets/offer-assigned-logo.svg';
import OfferCancelledLogo from '../assets/offer-cancelled-logo.svg';
import OfferDeclinedLogo from '../assets/offer-declined-logo.svg';
import { OfferDescription } from '../core/OfferDescription';
import { OffersVehicleGallery } from '../core/OffersVehicleGallery';
import { OffersVehicleItem } from '../core/OffersVehicleItem';
import { OffersVehicleWeight } from '../core/OffersVehicleWeight';
import {
  mapPublicOfferLoadToPricingInsights,
  PublicOfferDTO,
} from './data/PublicOfferDTO';
import { PublicOfferSuperPayNudgingBanner } from './PublicOfferSuperPayNudgingBanner';

function getShipperGuidFromURL(shipperProfileURL: string) {
  const { pathname } = new URL(shipperProfileURL);
  return pathname.replace('/loadboard/shipper/', '').replace('/', '');
}

const PriceBox = styled(TextBox)`
  line-height: normal;
`;

export interface OfferViewDetailsProps {
  offer: PublicOfferDTO;
}

export function PublicOfferDetails({
  offer: {
    order,
    terms_and_conditions: terms,
    is_counter_offer,
    bid_price,
    original_price,
    status,
    status_changed_at,
  },
}: OfferViewDetailsProps) {
  const [isTermsExpanded, setIsTermsExpanded] = useState(false);
  const [currentIndex, setCurrentIndex] = useState<number>(-1);

  const termsTruncated = useMemo(
    () => truncate(terms, { length: 175, omission: '…' }),
    [terms],
  );
  const shipperGuid = useMemo(
    () => getShipperGuidFromURL(order.shipper_profile_url),
    [order.shipper_profile_url],
  );
  const { data: ratingDetails } = useShipperRatingDetails(shipperGuid);
  const shouldShowPricingInsightsLink = useFlag(
    'pricing_insights_adoption_load_offer_link',
  );

  const driverLabel = getDriverLabel({
    name: order.driver?.name,
    email: order.driver?.email,
  });

  return (
    <Stack space="large">
      {status !== 'pending' && (
        <Box
          paddingBottom="xlarge"
          borderColor="Silver400"
          borderBottomWidth="small"
        >
          {status === 'accepted' &&
            (order.driver ? (
              <Stack align="center">
                <Image
                  width={100}
                  height={100}
                  alt="Offer Assigned"
                  src={OfferAssignedLogo}
                  srcDark={OfferAssignedLogoDark}
                />

                <Inline horizontalAlign="center" space="xxsmall">
                  <TextBox align="center" color="secondary" variant="heading-2">
                    Assigned to
                  </TextBox>
                  <TextBox align="center" variant="heading-2">
                    {driverLabel}
                  </TextBox>
                </Inline>

                <TextBox color="secondary">
                  {formatRelativeTime(status_changed_at)}
                </TextBox>
              </Stack>
            ) : (
              <Stack align="center">
                <img
                  width={152}
                  height={56}
                  alt="Offer Accepted"
                  src={OfferAcceptedLogo}
                />

                {!status_changed_at ? (
                  <TextBox variant="heading-2">Offer Accepted</TextBox>
                ) : (
                  <span>
                    <TextBox color="secondary" variant="heading-2">
                      Offer Accepted:
                    </TextBox>{' '}
                    <TextBox variant="heading-2">
                      {formatRelativeTime(status_changed_at)}
                    </TextBox>
                  </span>
                )}
              </Stack>
            ))}

          {status === 'declined' && (
            <Stack align="center">
              <img
                width={152}
                height={56}
                alt="Offer Declined"
                src={OfferDeclinedLogo}
              />

              <TextBox variant="heading-2">Offer Declined</TextBox>
            </Stack>
          )}

          {status === 'canceled' && (
            <Stack align="center">
              <img
                width={85}
                height={56}
                alt="Offer Cancelled"
                src={OfferCancelledLogo}
              />

              <TextBox variant="heading-2">Offer Cancelled</TextBox>
            </Stack>
          )}
        </Box>
      )}

      <OfferDescription label="Load ID" value={order.number} inline={true} />

      <Stack>
        {is_counter_offer && (
          <Stack>
            <Inline verticalAlign="bottom" space="small">
              <Stack>
                <Tag variant="subtle" color="purple">
                  Counter Offer
                </Tag>

                <Box height="25px">
                  <Inline space="xsmall" verticalAlign="bottom">
                    <PriceBox variant="heading-3">
                      {formatCurrency(order.price)}
                    </PriceBox>

                    {!!order.price_per_mile && (
                      <TextBox variant="caption" color="secondary">
                        {' '}
                        {formatCurrency(order.price_per_mile, {
                          maximumFractionDigits: 2,
                        })}
                        /mi
                      </TextBox>
                    )}
                  </Inline>
                </Box>
              </Stack>

              <Stack space="xxsmall">
                <TextBox variant="caption" color="secondary">
                  Bid
                </TextBox>
                <TextBox>
                  {formatCurrency(bid_price, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 0,
                  })}
                </TextBox>
              </Stack>

              <Stack space="xxsmall">
                <TextBox variant="caption" color="secondary">
                  Original
                </TextBox>
                <TextBox>
                  {formatCurrency(original_price, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 0,
                  })}
                </TextBox>
              </Stack>
            </Inline>

            <PaymentInfo
              source="Public Load Offer Details"
              method={order.payment_method_display}
              terms={order.payment_terms_display}
            />
          </Stack>
        )}

        {!is_counter_offer && (
          <OfferDescription
            label="Payment"
            fallback="No payment info"
            value={
              <div>
                {!order.price || !order.payment_method_display ? null : (
                  <Stack>
                    <Inline space="xsmall" verticalAlign="bottom">
                      <PriceBox variant="heading-3">
                        {formatCurrency(order.price)}
                      </PriceBox>

                      {!!order.price_per_mile && (
                        <TextBox variant="caption" color="secondary">
                          {formatCurrency(order.price_per_mile, {
                            maximumFractionDigits: 2,
                          })}
                          /mi
                        </TextBox>
                      )}
                    </Inline>

                    <PaymentInfo
                      method={order.payment_method_display}
                      terms={order.payment_terms_display}
                    />

                    {order.payment_method_display === 'SuperPay' && (
                      <PublicOfferSuperPayNudgingBanner source="Public Load Offer Details" />
                    )}
                  </Stack>
                )}
              </div>
            }
          />
        )}

        {shouldShowPricingInsightsLink && (
          <PricingInsightsLink
            source="Public Offer Details"
            data={mapPublicOfferLoadToPricingInsights(order)}
          />
        )}
      </Stack>

      <Stack>
        <LoadWaypoints
          pickupAddress={formatAddressLine(
            order.pickup_zip,
            order.pickup_city,
            order.pickup_state,
          )}
          pickupBusinessType={order.pickup_business_type}
          pickupDate={
            order.pickup_date && (
              <FormattedDate variant="Date" date={order.pickup_date} />
            )
          }
          deliveryAddress={formatAddressLine(
            order.delivery_zip,
            order.delivery_city,
            order.delivery_state,
          )}
          deliveryBusinessType={order.delivery_business_type}
          deliveryDate={
            order.delivery_date && (
              <FormattedDate variant="Date" date={order.delivery_date} />
            )
          }
        />

        {!!order.distance_miles && (
          <TextBox color="secondary">
            {formatNumber(order.distance_miles, {
              maximumFractionDigits: 2,
            })}{' '}
            mi
          </TextBox>
        )}
      </Stack>

      <OfferDescription
        label="Special Instructions"
        fallback="No special instructions"
        value={order.instructions}
      />

      {!!order.vehicles?.length && (
        <OfferDescription
          label={startCase(
            `${order.vehicles.length} ${formatVehicleLength(
              order.vehicles.length,
            )}`,
          )}
          value={
            <Stack space="small">
              <OffersVehicleWeight vehicles={order.vehicles} />

              <Stack space="medium">
                {order.vehicles.map((vehicle, idx) => (
                  <OffersVehicleItem
                    key={idx}
                    vehicle={vehicle}
                    onVehicleImageClick={() => {
                      setCurrentIndex(idx);
                    }}
                  />
                ))}
              </Stack>

              <OffersVehicleGallery
                vehicles={order.vehicles}
                currentSlideIndex={currentIndex}
                setCurrentSlideIndex={setCurrentIndex}
              />
            </Stack>
          }
        />
      )}

      <Stack space="none">
        <OfferDescription
          label="Shipper"
          fallback="No shipper info"
          value={
            <>
              {order.broker_name && (
                <Inline space="xsmall" verticalAlign="center">
                  <ShipperProfileLink
                    aria-label="shipper name"
                    guid={shipperGuid}
                    medium="Load Details"
                    variant="body1"
                  >
                    {order.broker_name}
                  </ShipperProfileLink>

                  {ratingDetails && (
                    <Inline space="xxsmall" verticalAlign="center">
                      <ThumbUp fontSize="small" color="action" />

                      <ShipperProfileLink
                        aria-label="shipper rating percentage"
                        guid={shipperGuid}
                        medium="Load Details"
                        tab="ratings"
                      >
                        {ratingDetails.overall_rating}%
                      </ShipperProfileLink>

                      {ratingDetails.total_rating_count > 0 && (
                        <Typography>
                          ({ratingDetails.total_rating_count})
                        </Typography>
                      )}
                    </Inline>
                  )}
                </Inline>
              )}
              {order.broker_phone && (
                <Typography color="textSecondary">
                  Phone: {order.broker_phone}
                </Typography>
              )}
            </>
          }
        />
      </Stack>

      <OfferDescription
        label="Terms and conditions"
        fallback="No terms and conditions"
        value={
          termsTruncated && (
            <Stack>
              <TextBox>{isTermsExpanded ? terms : termsTruncated} </TextBox>
              {terms !== termsTruncated && (
                <Button
                  variant="neutral"
                  onClick={(event) => {
                    event.preventDefault();
                    setIsTermsExpanded(!isTermsExpanded);
                  }}
                >
                  {isTermsExpanded ? 'Read Less' : 'Read More'}
                </Button>
              )}
            </Stack>
          )
        }
      />
    </Stack>
  );
}
