import { IconButton, Tooltip } from '@material-ui/core';
import { useSnackbarStack } from '@superdispatch/ui';
import { downloadBlob } from 'shared/helpers/FileHelpers';
import { FileDownloadIcon } from 'shared/icons/FileDownloadIcon';
import { ContentProgressIndicator } from 'shared/layout/ContentProgressIndicator';
import { useTripReport } from '../data/TripsAPI';
import { TripActionsDTO } from '../data/TripsDTO';

export function TripDownloadReportAction({ trip }: { trip: TripActionsDTO }) {
  const { addSnackbar } = useSnackbarStack();
  const { mutate: downloadReport, isLoading } = useTripReport({
    onSuccess: (blob) => {
      downloadBlob(blob, `${trip.name}-report.xlsx`);
      addSnackbar('Report downloaded');
    },
    onError: (error: Error) => {
      addSnackbar(error.message || 'Failed to download report', {
        variant: 'error',
      });
    },
  });

  if (isLoading) {
    return (
      <IconButton>
        <ContentProgressIndicator progressSize={20} />
      </IconButton>
    );
  }

  return (
    <Tooltip title="Download report">
      <IconButton
        onClick={() => {
          downloadReport(trip.guid);
        }}
      >
        <FileDownloadIcon />
      </IconButton>
    </Tooltip>
  );
}
