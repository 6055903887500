import {
  ALL_PAYMENT_METHODS,
  ALL_PAYMENT_TERMS,
  LoadPaymentMethod,
  LOAD_PAYMENT_METHODS,
  LOAD_PAYMENT_TERMS,
} from '@superdispatch/sdk';

export const LOAD_PAYMENT_TYPES = [
  ...LOAD_PAYMENT_METHODS.filter(
    (method): method is Exclude<LoadPaymentMethod, 'other'> =>
      method !== 'other',
  ),
  ...LOAD_PAYMENT_TERMS,
] as const;

// Carrier TMS mixed payment methods and terms. This constant holds all possible payment types.
export const PAYMENT_TERMS_AND_METHODS = [
  ...LOAD_PAYMENT_METHODS,
  ...LOAD_PAYMENT_TERMS,
  ...ALL_PAYMENT_METHODS,
  ...ALL_PAYMENT_TERMS,
] as const;
