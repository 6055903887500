import { Card, CardContent, Link } from '@material-ui/core';
import { WarningRounded } from '@material-ui/icons';
import { ColorDynamic, Stack } from '@superdispatch/ui';
import { Box, Button, TextBox } from '@superdispatch/ui-lab';
import { Component, ErrorInfo, ReactNode } from 'react';
import { logError } from 'shared/helpers/ErrorTracker';
import { showNewIntercomMessage } from 'shared/helpers/Intercom';
import { AppThemeProvider } from 'shared/theme/AppThemeProvider';

export function ErrorScreen() {
  return (
    <AppThemeProvider>
      <Box
        maxWidth="800px"
        marginLeft="auto"
        marginRight="auto"
        marginTop={['small', 'xxlarge']}
      >
        <Card>
          <CardContent>
            <Box paddingTop="xxlarge" paddingBottom="xxlarge">
              <Stack align="center" space="medium">
                <Stack align="center" space="small">
                  <WarningRounded
                    htmlColor={ColorDynamic.Yellow500}
                    style={{ fontSize: '54px' }}
                  />

                  <Stack align="center">
                    <TextBox variant="heading-2">Something Went Wrong</TextBox>
                    <TextBox>
                      Reload the page. If this happens again contact{' '}
                      <Link
                        component="button"
                        onClick={() => {
                          showNewIntercomMessage();
                        }}
                      >
                        Support
                      </Link>
                    </TextBox>
                  </Stack>
                </Stack>

                <Button
                  size="large"
                  variant="primary"
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  Reload Page
                </Button>
              </Stack>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </AppThemeProvider>
  );
}

interface State {
  error?: Error;
  errorInfo?: ErrorInfo;
}

export interface ErrorBoundaryProps {
  children: ReactNode;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, State> {
  static getDerivedStateFromError(error: Error, errorInfo: ErrorInfo) {
    return { error, errorInfo };
  }

  state: State = {};

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    logError(error, 'ErrorBoundary', {
      extraInfo: { componentStack: errorInfo.componentStack },
    });
  }

  render() {
    return this.state.error ? <ErrorScreen /> : this.props.children;
  }
}

class FakeErrorBoundary extends Component<
  ErrorBoundaryProps,
  { error: boolean }
> {
  state = { error: false };

  // componentDidMount() {
  //   setTimeout(() => {
  //     this.setState({ error: true });
  //   }, 1000);
  // }

  render() {
    if (this.state.error) {
      return <ErrorScreen />;
    }

    return this.props.children;
  }
}

export const AppErrorBoundary =
  import.meta.env.MODE === 'production' ? ErrorBoundary : FakeErrorBoundary;
