import {
  ButtonBase,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { ArrowDownward, ArrowUpward } from '@material-ui/icons';
import { Color, ColorDynamic, Column, Columns, Stack } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { useLocationParams } from 'shared/routing/LocationParams';
import { Image } from 'shared/ui/Image';
import styled from 'styled-components';
import emptyListIllustrationDark from '../assets/empty-list-dark.png';
import emptyListIllustration from '../assets/empty-list.png';
import {
  TripShortDTO,
  TripsPageOrderingParam,
  tripsPageParamsSchema,
} from '../data/TripsDTO';
import { TripsTableRow } from './TripsTableRow';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const StickyTableCell = styled(MuiTableCell)`
  left: 0;
  position: sticky;
  z-index: 15;
  border-bottom: 1px solid ${ColorDynamic.Silver400};
  background-color: ${ColorDynamic.Silver200};
`;

const TableCell = styled(MuiTableCell)<{ $width?: string }>`
  border-bottom: 1px solid ${ColorDynamic.Silver400};
  width: ${({ $width }) => $width || 'auto'};
  background-color: ${ColorDynamic.Silver200};
`;

const StyledButtonBase = styled(ButtonBase)`
  & [data-show-on-hover] {
    opacity: 0;
    transition: opacity 0.1s ease-in-out;
  }
  &:hover [data-show-on-hover] {
    opacity: 1;
  }
`;

interface TripsTableProps {
  trips?: TripShortDTO[];
  isLoading?: boolean;
  error: Error | null;
  onOrderingChange: (param: TripsPageOrderingParam) => void;
  ordering: TripsPageOrderingParam;
}

const Styled1 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export function TripsTable({
  trips,
  isLoading,
  ordering,
  onOrderingChange,
}: TripsTableProps) {
  const [_, updateParams] = useLocationParams({
    yupSchema: tripsPageParamsSchema,
  });
  if (isLoading) {
    return (
      <Styled1>
        <CircularProgress />
      </Styled1>
    );
  }

  if (!trips || trips.length === 0) {
    return (
      <Wrapper>
        <Stack space="medium" align="center">
          <Image
            src={emptyListIllustration}
            srcDark={emptyListIllustrationDark}
            width="68px"
            height="80px"
          />
          <Stack space="small">
            <Typography variant="h3">Couldn&apos;t Find Anything</Typography>
            <Stack space="xxsmall">
              <Typography align="center" color="textSecondary">
                Check your filters.
              </Typography>
              <Typography align="center" color="textSecondary">
                Try modifying them to find the trips you need.
              </Typography>
            </Stack>
          </Stack>
          <Button
            onClick={() => {
              updateParams({
                driver_guid: null,
                dispatcher_guid: null,
                query: null,
              });
            }}
            variant="neutral"
          >
            Clear Filters
          </Button>
        </Stack>
      </Wrapper>
    );
  }

  return (
    <Table component={Paper} stickyHeader={true}>
      <TableHead>
        <TableRow>
          <StickyTableCell>
            <Typography variant="h5" color="textSecondary">
              Trip Name
            </Typography>
          </StickyTableCell>
          <TableCell>
            <Typography variant="h5" color="textSecondary">
              Trip Status
            </Typography>
          </TableCell>

          <TableCell>
            <StyledButtonBase
              onClick={() => {
                onOrderingChange(ordering === 'loads' ? '-loads' : 'loads');
              }}
              disableRipple={true}
            >
              <Columns align="center" space="xxsmall">
                <Column width="fluid">
                  <Typography variant="h5" color="textSecondary">
                    Loads
                  </Typography>
                </Column>
                <Column width="content">
                  {ordering === '-loads' && (
                    <ArrowDownward htmlColor={Color.Dark300} fontSize="small" />
                  )}
                  {ordering === 'loads' && (
                    <ArrowUpward htmlColor={Color.Dark300} fontSize="small" />
                  )}
                  {ordering !== 'loads' && ordering !== '-loads' && (
                    <ArrowUpward
                      data-show-on-hover={true}
                      htmlColor={Color.Dark100}
                      fontSize="small"
                    />
                  )}
                </Column>
              </Columns>
            </StyledButtonBase>
          </TableCell>
          <TableCell>
            <Typography variant="h5" color="textSecondary">
              Driver
            </Typography>
          </TableCell>

          <TableCell>
            <Typography variant="h5" color="textSecondary">
              Dispatcher
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="h5" color="textSecondary">
              Start
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="h5" color="textSecondary">
              End
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="h5" color="textSecondary">
              Price
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="h5" color="textSecondary">
              Payment Status
            </Typography>
          </TableCell>
          <TableCell $width="150px">
            <StyledButtonBase
              onClick={() => {
                onOrderingChange(
                  ordering === 'changed_at' ? '-changed_at' : 'changed_at',
                );
              }}
              disableRipple={true}
            >
              <Columns align="center" space="xxsmall">
                <Column width="fluid">
                  <Typography variant="h5" color="textSecondary">
                    Modified Date
                  </Typography>
                </Column>
                <Column width="content">
                  {ordering === '-changed_at' && (
                    <ArrowDownward
                      htmlColor={ColorDynamic.Dark300}
                      fontSize="small"
                    />
                  )}
                  {ordering === 'changed_at' && (
                    <ArrowUpward
                      htmlColor={ColorDynamic.Dark300}
                      fontSize="small"
                    />
                  )}
                  {ordering !== 'changed_at' && ordering !== '-changed_at' && (
                    <ArrowUpward
                      data-show-on-hover={true}
                      htmlColor={Color.Dark100}
                      fontSize="small"
                    />
                  )}
                </Column>
              </Columns>
            </StyledButtonBase>
          </TableCell>
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {trips.map((trip) => (
          <TripsTableRow key={trip.guid} {...trip} />
        ))}
      </TableBody>
    </Table>
  );
}
