import mitt from 'mitt';
import { PaymentPastDueContextDTO } from 'shared/errors/data/PaywallDTO';

export type ServerError =
  | { type: 'Unauthorized'; context: null } // Carrier auth error
  | { type: 'AuthenticationError'; context: null } // Gateway auth error
  | { type: 'AUTH_ERROR'; context: null } // Loadboard auth error
  | { type: 'CARRIER_CONNECTION_ERROR'; context: null }
  | { type: 'trial_expired'; context: PaymentPastDueContextDTO }
  | { type: 'payment_past_due'; context: PaymentPastDueContextDTO }
  | { type: 'subscription_cancelled'; context: { message: string } };

// eslint-disable-next-line @typescript-eslint/naming-convention
const emitter = mitt<{ 'server-error': ServerError }>();
const ERROR_TYPES: ReadonlyArray<ServerError['type']> = [
  'Unauthorized',
  'AuthenticationError',
  'AUTH_ERROR',
  'CARRIER_CONNECTION_ERROR',
  'trial_expired',
  'payment_past_due',
  'subscription_cancelled',
];

export function listenToServerError(
  handler: (error: ServerError) => void,
): () => void {
  emitter.on('server-error', handler);
  return () => {
    emitter.off('server-error', handler);
  };
}

function isServerError(error: unknown): error is ServerError {
  return (
    typeof error == 'object' &&
    error != null &&
    'type' in error &&
    ERROR_TYPES.includes((error as ServerError).type)
  );
}

export function emitServerError(error: unknown): void {
  if (isServerError(error)) emitter.emit('server-error', error);
}
