import { Color } from '@superdispatch/ui';
import { createSvgIcon } from 'shared/icons/IconUtils';

export const AccountDeletionIcon = createSvgIcon(
  'AccountDeletionIcon',
  <svg
    width="270"
    height="100"
    viewBox="0 0 270 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_945_8700)">
      <path
        d="M210.037 60.5899C209.687 74.8599 194.767 89.3299 170.927 82.0899C109.477 63.4999 122.597 88.0599 87.1375 83.3499C59.1875 79.6199 54.6275 54.8099 65.2975 41.6099C80.8275 22.3399 112.027 32.3899 123.697 14.2299C138.567 -8.8301 178.037 -4.1201 182.097 30.1999C183.237 39.8599 210.537 39.9099 210.037 60.5899Z"
        fill={Color.Red50}
      />
      <path
        d="M112.357 88.84H104.647C102.637 88.84 101.037 87.14 101.037 85.01V66.51H112.357V88.84Z"
        fill={Color.Red75}
      />
      <path
        d="M113.357 89.84H104.647C102.067 89.84 100.037 87.72 100.037 85.01V65.51H113.357V89.84ZM102.037 67.51V85.01C102.037 86.6 103.177 87.84 104.647 87.84H111.357V67.51H102.037Z"
        fill={Color.Red400}
      />
      <path
        d="M123.667 66.51H112.357V99.03H123.677V66.51H123.667Z"
        fill="white"
      />
      <path
        d="M124.667 100.04H111.357V65.51H124.677V100.04H124.667ZM113.357 98.04H122.677V67.51H113.357V98.04Z"
        fill={Color.Red400}
      />
      <path
        d="M135.037 66.51H123.717V81.07H135.037V66.51Z"
        fill={Color.Red50}
      />
      <path
        d="M136.037 82.08H122.717V65.51H136.037V82.08ZM124.717 80.08H134.037V67.51H124.717V80.08Z"
        fill={Color.Red400}
      />
      <path d="M146.357 66.51H135.037V94.18H146.357V66.51Z" fill="white" />
      <path
        d="M147.357 95.18H134.037V65.51H147.357V95.18ZM136.037 93.18H145.357V67.51H136.037V93.18Z"
        fill={Color.Red400}
      />
      <path
        d="M157.667 66.51H146.357V86.9H157.677V66.51H157.667Z"
        fill={Color.Red50}
      />
      <path
        d="M158.667 87.9H145.357V65.51H158.677V87.9H158.667ZM147.357 85.9H156.677V67.51H147.357V85.9Z"
        fill={Color.Red400}
      />
      <path
        d="M165.297 99.04H157.677V66.51H169.037V95.05C169.037 97.24 167.387 99.04 165.297 99.04Z"
        fill={Color.White}
      />
      <path
        d="M165.297 100.04H156.677V65.51H170.037V95.05C170.037 97.8 167.907 100.04 165.297 100.04ZM158.667 98.04H165.287C166.797 98.04 168.027 96.7 168.027 95.06V67.51H158.667V98.04Z"
        fill={Color.Red400}
      />
      <path
        d="M176.727 71.3699H94.0871C90.7771 71.3699 88.0771 68.7499 88.0771 65.5399V47.5799C88.0771 46.5099 88.9771 45.6399 90.0771 45.6399H180.737C181.837 45.6399 182.737 46.5099 182.737 47.5799V65.5399C182.737 68.7499 180.037 71.3699 176.727 71.3699Z"
        fill={Color.Red75}
      />
      <path
        d="M176.357 35.45H93.717C89.857 35.45 86.707 38.51 86.707 42.25V47.59V49.5299V65.55C86.707 69.29 89.867 72.3499 93.717 72.3499H176.357C180.217 72.3499 183.367 69.29 183.367 65.55V49.52V47.58V42.24C183.367 38.5 180.217 35.45 176.357 35.45ZM181.367 65.54C181.367 68.21 179.117 70.3899 176.357 70.3899H93.717C90.967 70.3899 88.707 68.21 88.707 65.54V54.2799C89.957 55.5399 91.767 56.32 93.717 56.32H176.357C178.307 56.32 180.117 55.5399 181.367 54.2799V65.54Z"
        fill={Color.Red400}
      />
      <path
        d="M169.698 45.6399H100.378V5.00988C100.378 2.86988 102.178 1.12988 104.388 1.12988H165.698C167.898 1.12988 169.708 2.87988 169.708 5.00988V45.6399H169.698Z"
        fill="white"
      />
      <path
        d="M170.197 46.6099H99.8672V5.00991C99.8672 2.33991 102.117 0.159912 104.877 0.159912H165.187C167.937 0.159912 170.197 2.33991 170.197 5.00991V46.6099ZM101.877 44.6699H168.197V5.00991C168.197 3.40991 166.847 2.09991 165.187 2.09991H104.877C103.227 2.09991 101.877 3.40991 101.877 5.00991V44.6699Z"
        fill={Color.Red400}
      />
      <path
        d="M172.717 64.0899H164.207C163.657 64.0899 163.207 63.6499 163.207 63.1199C163.207 62.5899 163.657 62.1499 164.207 62.1499H172.717C173.267 62.1499 173.717 62.5899 173.717 63.1199C173.717 63.6499 173.267 64.0899 172.717 64.0899Z"
        fill={Color.Red400}
      />
      <path
        d="M159.188 64.0899H153.678C153.128 64.0899 152.678 63.6499 152.678 63.1199C152.678 62.5899 153.128 62.1499 153.678 62.1499H159.188C159.738 62.1499 160.188 62.5899 160.188 63.1199C160.198 63.6499 159.748 64.0899 159.188 64.0899Z"
        fill={Color.Red400}
      />
      <path
        d="M119.408 17.9098L121.708 15.6798C122.108 15.2898 122.108 14.7098 121.708 14.3198C121.308 13.9298 120.708 13.9298 120.308 14.3198L118.008 16.5498L115.708 14.3198C115.308 13.9298 114.708 13.9298 114.308 14.3198C113.908 14.7098 113.908 15.2898 114.308 15.6798L116.608 17.9098L114.308 20.1398C113.908 20.5298 113.908 21.1098 114.308 21.4998C114.508 21.6898 114.758 21.7898 115.008 21.7898C115.258 21.7898 115.508 21.6898 115.708 21.4998L118.008 19.2698L120.308 21.4998C120.508 21.6898 120.758 21.7898 121.008 21.7898C121.258 21.7898 121.508 21.6898 121.708 21.4998C122.108 21.1098 122.108 20.5298 121.708 20.1398L119.408 17.9098Z"
        fill={Color.Red400}
      />
      <path
        d="M119.408 34.8898L121.708 32.6598C122.108 32.2698 122.108 31.6898 121.708 31.2998C121.308 30.9098 120.708 30.9098 120.308 31.2998L118.008 33.5298L115.708 31.2998C115.308 30.9098 114.708 30.9098 114.308 31.2998C113.908 31.6898 113.908 32.2698 114.308 32.6598L116.608 34.8898L114.308 37.1198C113.908 37.5098 113.908 38.0898 114.308 38.4798C114.508 38.6698 114.758 38.7698 115.008 38.7698C115.258 38.7698 115.508 38.6698 115.708 38.4798L118.008 36.2498L120.308 38.4798C120.508 38.6698 120.758 38.7698 121.008 38.7698C121.258 38.7698 121.508 38.6698 121.708 38.4798C122.108 38.0898 122.108 37.5098 121.708 37.1198L119.408 34.8898Z"
        fill={Color.Red400}
      />
      <path
        d="M128.048 16.6499H155.118C155.648 16.6499 156.078 17.0799 156.078 17.6099V19.5399C156.078 20.0699 155.648 20.4999 155.118 20.4999H128.048C127.518 20.4999 127.088 20.0699 127.088 19.5399V17.6099C127.078 17.0799 127.518 16.6499 128.048 16.6499Z"
        fill={Color.Red200}
      />
      <path
        d="M128.048 32.6499H155.118C155.648 32.6499 156.078 33.0799 156.078 33.6099V35.5399C156.078 36.0699 155.648 36.4999 155.118 36.4999H128.048C127.518 36.4999 127.088 36.0699 127.088 35.5399V33.6099C127.078 33.0799 127.518 32.6499 128.048 32.6499Z"
        fill={Color.Red200}
      />
    </g>
    <defs>
      <clipPath id="clip0_945_8700">
        <rect width="270" height="100" fill={Color.White} />
      </clipPath>
    </defs>
  </svg>,
  {
    viewBox: '0 0 270 100',
    style: {
      width: '270px',
      height: '100px',
    },
  },
);

export const AccountDeletionIconDark = createSvgIcon(
  'AccountDeletionIconDark',
  <>
    <g clipPath="url(#clip0_5407_1541)">
      <path
        d="M210.037 60.59C209.687 74.86 194.767 89.33 170.927 82.09C109.477 63.5 122.597 88.06 87.1375 83.35C59.1875 79.62 54.6275 54.81 65.2975 41.61C80.8275 22.34 112.027 32.39 123.697 14.23C138.567 -8.83 178.037 -4.12 182.097 30.2C183.237 39.86 210.537 39.91 210.037 60.59Z"
        fill="#3D0A06"
      />
      <path
        d="M112.357 88.84H104.647C102.637 88.84 101.037 87.14 101.037 85.01V66.51H112.357V88.84Z"
        fill="#3D0A06"
      />
      <path
        d="M113.357 89.84H104.647C102.067 89.84 100.037 87.72 100.037 85.01V65.51H113.357V89.84ZM102.037 67.51V85.01C102.037 86.6 103.177 87.84 104.647 87.84H111.357V67.51H102.037Z"
        fill={Color.Red400}
      />
      <path
        d="M123.667 66.51H112.357V99.03H123.677V66.51H123.667Z"
        fill="#151B22"
      />
      <path
        d="M124.667 100.04H111.357V65.51H124.677V100.04H124.667ZM113.357 98.04H122.677V67.51H113.357V98.04Z"
        fill={Color.Red400}
      />
      <path d="M135.038 66.51H123.718V81.07H135.038V66.51Z" fill="#3D0A06" />
      <path
        d="M136.038 82.08H122.718V65.51H136.038V82.08ZM124.718 80.08H134.038V67.51H124.718V80.08Z"
        fill={Color.Red400}
      />
      <path d="M146.357 66.51H135.037V94.18H146.357V66.51Z" fill="#151B22" />
      <path
        d="M147.357 95.18H134.037V65.51H147.357V95.18ZM136.037 93.18H145.357V67.51H136.037V93.18Z"
        fill={Color.Red400}
      />
      <path
        d="M157.667 66.51H146.357V86.9H157.677V66.51H157.667Z"
        fill="#3D0A06"
      />
      <path
        d="M158.667 87.9H145.357V65.51H158.677V87.9H158.667ZM147.357 85.9H156.677V67.51H147.357V85.9Z"
        fill={Color.Red400}
      />
      <path
        d="M165.297 99.04H157.677V66.51H169.037V95.05C169.037 97.24 167.387 99.04 165.297 99.04Z"
        fill="#151B22"
      />
      <path
        d="M165.297 100.04H156.677V65.51H170.037V95.05C170.037 97.8 167.907 100.04 165.297 100.04ZM158.667 98.04H165.287C166.797 98.04 168.027 96.7 168.027 95.06V67.51H158.667V98.04Z"
        fill={Color.Red400}
      />
      <path
        d="M176.728 71.37H94.0875C90.7775 71.37 88.0775 68.75 88.0775 65.54V47.58C88.0775 46.51 88.9775 45.64 90.0775 45.64H180.738C181.838 45.64 182.738 46.51 182.738 47.58V65.54C182.738 68.75 180.038 71.37 176.728 71.37Z"
        fill="#3D0A06"
      />
      <path
        d="M176.358 35.45H93.7175C89.8575 35.45 86.7075 38.51 86.7075 42.25V47.59V49.53V65.55C86.7075 69.29 89.8675 72.35 93.7175 72.35H176.358C180.218 72.35 183.368 69.29 183.368 65.55V49.52V47.58V42.24C183.368 38.5 180.218 35.45 176.358 35.45ZM181.368 65.54C181.368 68.21 179.118 70.39 176.358 70.39H93.7175C90.9675 70.39 88.7075 68.21 88.7075 65.54V54.28C89.9575 55.54 91.7675 56.32 93.7175 56.32H176.358C178.308 56.32 180.118 55.54 181.368 54.28V65.54Z"
        fill="#E91B0C"
      />
      <path
        d="M169.697 45.64H100.377V5.01001C100.377 2.87001 102.177 1.13001 104.387 1.13001H165.697C167.897 1.13001 169.707 2.88001 169.707 5.01001V45.64H169.697Z"
        fill="#151B22"
      />
      <path
        d="M170.197 46.61H99.8674V5.01001C99.8674 2.34001 102.117 0.160011 104.877 0.160011H165.187C167.937 0.160011 170.197 2.34001 170.197 5.01001V46.61ZM101.877 44.67H168.197V5.01001C168.197 3.41001 166.847 2.10001 165.187 2.10001H104.877C103.227 2.10001 101.877 3.41001 101.877 5.01001V44.67Z"
        fill={Color.Red400}
      />
      <path
        d="M172.718 64.09H164.208C163.658 64.09 163.208 63.65 163.208 63.12C163.208 62.59 163.658 62.15 164.208 62.15H172.718C173.268 62.15 173.718 62.59 173.718 63.12C173.718 63.65 173.268 64.09 172.718 64.09Z"
        fill={Color.Red400}
      />
      <path
        d="M159.187 64.09H153.677C153.127 64.09 152.677 63.65 152.677 63.12C152.677 62.59 153.127 62.15 153.677 62.15H159.187C159.737 62.15 160.187 62.59 160.187 63.12C160.197 63.65 159.747 64.09 159.187 64.09Z"
        fill={Color.Red400}
      />
      <path
        d="M119.407 17.91L121.707 15.68C122.107 15.29 122.107 14.71 121.707 14.32C121.307 13.93 120.707 13.93 120.307 14.32L118.007 16.55L115.707 14.32C115.307 13.93 114.707 13.93 114.307 14.32C113.907 14.71 113.907 15.29 114.307 15.68L116.607 17.91L114.307 20.14C113.907 20.53 113.907 21.11 114.307 21.5C114.507 21.69 114.757 21.79 115.007 21.79C115.257 21.79 115.507 21.69 115.707 21.5L118.007 19.27L120.307 21.5C120.507 21.69 120.757 21.79 121.007 21.79C121.257 21.79 121.507 21.69 121.707 21.5C122.107 21.11 122.107 20.53 121.707 20.14L119.407 17.91Z"
        fill="#E91B0C"
      />
      <path
        d="M119.407 34.89L121.707 32.66C122.107 32.27 122.107 31.69 121.707 31.3C121.307 30.91 120.707 30.91 120.307 31.3L118.007 33.53L115.707 31.3C115.307 30.91 114.707 30.91 114.307 31.3C113.907 31.69 113.907 32.27 114.307 32.66L116.607 34.89L114.307 37.12C113.907 37.51 113.907 38.09 114.307 38.48C114.507 38.67 114.757 38.77 115.007 38.77C115.257 38.77 115.507 38.67 115.707 38.48L118.007 36.25L120.307 38.48C120.507 38.67 120.757 38.77 121.007 38.77C121.257 38.77 121.507 38.67 121.707 38.48C122.107 38.09 122.107 37.51 121.707 37.12L119.407 34.89Z"
        fill="#E91B0C"
      />
      <path
        d="M128.047 16.65H155.117C155.647 16.65 156.077 17.08 156.077 17.61V19.54C156.077 20.07 155.647 20.5 155.117 20.5H128.047C127.517 20.5 127.087 20.07 127.087 19.54V17.61C127.077 17.08 127.517 16.65 128.047 16.65Z"
        fill="#E91B0C"
      />
      <path
        d="M128.047 32.65H155.117C155.647 32.65 156.077 33.08 156.077 33.61V35.54C156.077 36.07 155.647 36.5 155.117 36.5H128.047C127.517 36.5 127.087 36.07 127.087 35.54V33.61C127.077 33.08 127.517 32.65 128.047 32.65Z"
        fill="#E91B0C"
      />
    </g>
    <defs>
      <clipPath id="clip0_5407_1541">
        <rect width="270" height="100" fill="white" />
      </clipPath>
    </defs>
  </>,
  {
    viewBox: '0 0 270 100',
    style: {
      width: '270px',
      height: '100px',
    },
  },
);
