import { createSvgIcon } from 'shared/icons/IconUtils';

export const OrganizationIcon = createSvgIcon(
  'OrganizationIcon',
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M16.0009 2.66669C20.7388 2.66669 24.611 6.37354 24.8754 11.0453C23.5314 10.4106 22.0293 10.0556 20.4443 10.0556C18.8541 10.0556 17.3474 10.4129 16 11.0515C14.6526 10.4129 13.1459 10.0556 11.5557 10.0556C9.97142 10.0556 8.46995 10.4102 7.12637 11.0445C7.39123 6.37309 11.2632 2.66669 16.0009 2.66669ZM20.4443 11.5556C22.0336 11.5556 23.5254 11.9727 24.8164 12.7034C24.5255 14.9601 23.3883 16.9516 21.7333 18.3495C21.2015 15.7524 19.7007 13.5085 17.6251 12.012C18.5109 11.716 19.4589 11.5556 20.4443 11.5556ZM26.1782 13.652C25.635 16.3032 24.0819 18.5863 21.9384 20.0818C21.9426 20.2022 21.9447 20.3231 21.9447 20.4446C21.9447 23.9165 20.2415 26.9907 17.6251 28.8772C18.5109 29.1732 19.4589 29.3335 20.4443 29.3335C25.3535 29.3335 29.3332 25.3538 29.3332 20.4446C29.3332 17.7205 28.1078 15.2826 26.1782 13.652ZM16 28.1445C18.5185 26.6877 20.2559 24.0308 20.4302 20.9558C19.0866 21.59 17.5852 21.9446 16.0009 21.9446C14.4159 21.9446 12.9138 21.5897 11.5697 20.9549C11.7438 24.0303 13.4813 26.6875 16 28.1445ZM16 12.7447C18.3432 14.1001 20.0103 16.4944 20.3714 19.2977C19.0808 20.0279 17.5895 20.4446 16.0009 20.4446C14.4116 20.4446 12.9197 20.0275 11.6287 19.2968C11.9901 16.4938 13.657 14.0999 16 12.7447ZM14.3749 12.012C12.2996 13.5083 10.7989 15.7517 10.2669 18.3482C8.61257 16.9503 7.47586 14.9589 7.18521 12.7025C8.4758 11.9724 9.96709 11.5556 11.5557 11.5556C12.5411 11.5556 13.489 11.716 14.3749 12.012ZM5.82326 13.6507C6.3661 16.3017 7.91868 18.5848 10.0616 20.0806C10.0574 20.2014 10.0553 20.3227 10.0553 20.4446C10.0553 23.9165 11.7585 26.9907 14.3749 28.8772C13.489 29.1732 12.5411 29.3335 11.5557 29.3335C6.64647 29.3335 2.66675 25.3538 2.66675 20.4446C2.66675 17.7198 3.89277 15.2813 5.82326 13.6507Z"
    fill="currentColor"
  />,
  {
    viewBox: '0 0 32 32',
    style: {
      width: '32px',
      height: '32px',
    },
  },
);
