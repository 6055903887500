import {
  ButtonBase,
  CircularProgress,
  Fade,
  IconButton,
  Typography,
} from '@material-ui/core';
import {
  ChevronLeft,
  ChevronRight,
  Fullscreen,
  FullscreenExit,
} from '@material-ui/icons';
import { Color, ColorDynamic, Inline, Stack } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { lazy, Suspense, useMemo, useRef, useState } from 'react';
import defaultImage from 'shared/icons/assets/black_default_image.png';
import { ErrorIcon } from 'shared/icons/ErrorIcon';
import { PlayIcon } from 'shared/icons/PlayIcon';
import { useFlag } from 'shared/settings/FeatureToggles';
import {
  formatDuration,
  useInlineMedia,
  useSlides,
} from 'shared/utils/MediaUtils';
import styled from 'styled-components';
import { PhotoDTO, VideoDTO } from '../../carrier/loads/data/LoadDTO';
const PhotoGallery = lazy(() => import('./PhotoGallery'));

const PhotoGalleryWrapper = styled.div<{ isVideo: boolean }>`
  display: flex;
  align-items: stretch;
  & button {
    width: 50px;
    text-align: center;
    transition: opacity 300ms, background 300ms;
    border-radius: 3px;
    flex-shrink: 0;
  }
  & button:hover {
    background: ${ColorDynamic.Silver400};
  }
  & > *:nth-child(2) {
    flex-grow: 1;
    margin: auto 16px;
    display: flex;
    justify-content: center;
    height: 480px;
  }
  & img {
    object-fit: ${({ isVideo }) => (isVideo ? 'fill' : 'contain')};
    user-select: none;
  }
`;

const ErrorText = styled.div`
  color: ${Color.White};
`;

const ThunmbnailsContainer = styled.div`
  white-space: nowrap;
  overflow-y: hidden;
  overflow-x: auto;
  position: relative;
  display: flex;
  justify-content: start;

  & div {
    margin: auto;
    display: flex;
    justify-content: center;
  }
`;

const ThumbnailItem = styled(ButtonBase)`
  margin-left: 8px;
  opacity: 0.5;
  &[data-selected='true'] {
    border: 2px solid ${Color.Blue300};
    opacity: 1;
  }

  &[data-non-vehicle='true'] {
    opacity: 1;
  }

  & img {
    object-fit: cover;
  }
`;

const PhotoViewerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;
`;

const NonVehiclePhotoBagde = styled.div`
  position: absolute;
  z-index: 2;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.7);
`;

const VideoContainer = styled.div`
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const VideoDuration = styled.div`
  z-index: 2;
  position: absolute;
  bottom: 4px;
  right: 4px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 2px;
  padding: 0 2px;
  color: ${Color.White};
  font-size: 11px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NonVehiclePhotoAlert = styled.div`
  position: absolute;
  z-index: 2;
  top: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${ColorDynamic.Dark500};
  flex-direction: row;
  padding: 4px 8px;
  gap: 4px;
  border-radius: 8px;
  color: ${Color.White};
  height: 36px;
  left: 50%;
  transform: translateX(-50%);
`;

interface PhotoViewerProps {
  url: string;
  selectedIndex: number;
  total: number;
  photos: PhotoDTO[];
  inlinePhotos: Array<{
    id: string;
    url: string;
    thumbnail_url: string;
    is_vehicle_detected: boolean | null;
    is_video: boolean;
  }>;
  videos?: VideoDTO[];
  isVideo?: boolean;
  onClose: (index: number) => void;
  onView?: (type: 'image' | 'video' | undefined) => void;
}

const PlayButton = styled(ButtonBase)`
  &.MuiButtonBase-root {
    width: 80px;
    height: 80px;
    border-radius: 50%;
  }
`;

const VideoButton = styled.div`
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const ButtonContainer = styled(Box)<{ width?: string; height?: string }>`
  background: rgba(0, 0, 0, 0.7);
  border-radius: 50%;
  width: ${({ width = '80px' }) => width};
  height: ${({ height = '80px' }) => height};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const VideoCounter = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Img = styled.img`
  object-fit: cover;
`;

function PhotoViewer({
  url,
  photos,
  selectedIndex,
  total,
  videos,
  isVideo,
  onClose,
  inlinePhotos,
  onView,
}: PhotoViewerProps) {
  const [currentSlideIndex, setCurrentSlideIndex] =
    useState<number>(selectedIndex);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const isInspectionPhotoIssuesEnabled = useFlag(
    'ctms_photo_inspection_issues',
  );

  const isVehicleNotDetected =
    inlinePhotos[currentSlideIndex]?.is_vehicle_detected === false &&
    isInspectionPhotoIssuesEnabled;

  const title = isVehicleNotDetected && (
    <NonVehiclePhotoAlert>
      <ErrorIcon />
      Possibly Not a Vehicle
    </NonVehiclePhotoAlert>
  );

  const slides = useSlides({ photos, videos, title });

  function openFullscreen() {
    setIsFullscreen(true);
    setCurrentSlideIndex(selectedIndex);
  }

  return (
    <>
      {isFullscreen && (
        <Suspense fallback={<CircularProgress />}>
          <PhotoGallery
            slides={slides}
            currentSlideIndex={currentSlideIndex}
            setCurrentSlideIndex={setCurrentSlideIndex}
            onClose={(index) => {
              setIsFullscreen(false);
              onClose(index);
            }}
            onView={onView}
          />
        </Suspense>
      )}
      <PhotoViewerWrapper>
        <Box position="absolute">
          <Fade in={!isLoaded}>
            <CircularProgress color="inherit" />
          </Fade>
        </Box>
        <Fade in={isLoaded}>
          <PhotoViewerWrapper>
            {isVideo && (
              <VideoButton
                onClick={() => {
                  openFullscreen();
                }}
              >
                <ButtonContainer>
                  <PlayButton onClick={openFullscreen}>
                    <PlayIcon style={{ width: '34px', height: '32px' }} />
                  </PlayButton>
                </ButtonContainer>
              </VideoButton>
            )}
            {isVideo ? (
              <VideoCounter
                borderRadius="small"
                backgroundColor="Dark300"
                color="White"
                paddingLeft="xsmall"
                paddingRight="xsmall"
                position="absolute"
                top="16px"
              >
                {selectedIndex + 1} of {total}
              </VideoCounter>
            ) : (
              <Box
                position="absolute"
                borderRadius="small"
                backgroundColor="Dark300"
                color="White"
                paddingLeft="xsmall"
                paddingRight="xsmall"
                top="16px"
                left="16px"
              >
                {selectedIndex + 1} of {total}
              </Box>
            )}

            {isVehicleNotDetected && (
              <Box
                position="absolute"
                top="16px"
                backgroundColor="Dark400"
                borderRadius="medium"
                paddingTop="xxsmall"
                paddingBottom="xxsmall"
                paddingRight="xsmall"
                paddingLeft="xsmall"
                color="White"
              >
                <Inline verticalAlign="center" space="xxsmall">
                  <ErrorIcon fontSize="small" />
                  <ErrorText>Possibly Not a Vehicle</ErrorText>
                </Inline>
              </Box>
            )}
            {!isVideo && (
              <Box
                position="absolute"
                right="16px"
                top="16px"
                backgroundColor="Dark300"
                borderRadius="small"
              >
                <IconButton
                  aria-label="toggle fullscreen"
                  onClick={openFullscreen}
                >
                  {isFullscreen ? (
                    <FullscreenExit htmlColor={Color.White} />
                  ) : (
                    <Fullscreen htmlColor={Color.White} />
                  )}
                </IconButton>
              </Box>
            )}
            <Img
              src={url}
              width="100%"
              height="100%"
              alt={`gallery main image ${selectedIndex + 1}`}
              onLoad={() => {
                setIsLoaded(true);
              }}
              onError={(e) => {
                e.currentTarget.src = defaultImage;
              }}
            />
          </PhotoViewerWrapper>
        </Fade>
      </PhotoViewerWrapper>
    </>
  );
}

interface PhotoGalleryProps {
  photos: PhotoDTO[];
  videos?: VideoDTO[];
  onView?: (type: 'image' | 'video' | undefined) => void;
}

export function InlinePhotoGallery({
  photos,
  videos,
  onView,
}: PhotoGalleryProps) {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [photoUrl, setPhotoUrl] = useState(photos[0]?.url || '');
  const thumbnailRefs = useRef<Array<HTMLButtonElement | null>>([]);
  const isInspectionPhotoIssuesEnabled = useFlag(
    'ctms_photo_inspection_issues',
  );

  const inlinePhotos = useInlineMedia(photos, videos);

  const damages = useMemo(
    () => photos[selectedIndex]?.damages || [],
    [selectedIndex, photos],
  );

  const handleScrollThumbnailIntoView = () => {
    thumbnailRefs.current[selectedIndex]?.scrollIntoView({
      inline: 'center',
      block: 'nearest',
      behavior: 'smooth',
    });
  };

  const hasPrevious = selectedIndex !== 0;
  const hasNext = selectedIndex !== inlinePhotos.length - 1;
  const isVideo = Boolean(inlinePhotos[selectedIndex]?.is_video);

  return (
    <Stack space="small">
      {damages.length > 0 && (
        <Box paddingBottom="small">
          <Stack space="xxsmall">
            {damages.map((damage) => (
              <Inline space="xxsmall" key={damage.code}>
                <Typography variant="body1">{damage.code}</Typography>
                {damage.note && (
                  <Typography
                    key={damage.code}
                    color="textSecondary"
                    variant="body2"
                  >
                    - {damage.note}
                  </Typography>
                )}
              </Inline>
            ))}
          </Stack>
        </Box>
      )}
      <PhotoGalleryWrapper isVideo={isVideo}>
        <ButtonBase
          aria-label="go to previous"
          disabled={!hasPrevious}
          onClick={() => {
            handleScrollThumbnailIntoView();
            setSelectedIndex(selectedIndex - 1);
            setPhotoUrl(inlinePhotos[selectedIndex - 1]?.url || '');
          }}
        >
          <ChevronLeft color={!hasPrevious ? 'disabled' : 'inherit'} />
        </ButtonBase>
        <Box position="relative">
          <PhotoViewer
            videos={videos}
            photos={photos}
            inlinePhotos={inlinePhotos}
            total={inlinePhotos.length}
            selectedIndex={selectedIndex}
            key={selectedIndex}
            url={photoUrl}
            isVideo={inlinePhotos[selectedIndex]?.is_video}
            onClose={(index) => {
              setSelectedIndex(index);
              setPhotoUrl(inlinePhotos[index]?.url || '');
            }}
            onView={onView}
          />
        </Box>
        <ButtonBase
          aria-label="go to next"
          disabled={!hasNext}
          onClick={() => {
            handleScrollThumbnailIntoView();
            setSelectedIndex(selectedIndex + 1);
            setPhotoUrl(inlinePhotos[selectedIndex + 1]?.url || '');
          }}
        >
          <ChevronRight color={!hasNext ? 'disabled' : 'inherit'} />
        </ButtonBase>
      </PhotoGalleryWrapper>
      <ThunmbnailsContainer>
        <div>
          {inlinePhotos.map(
            (
              {
                id,
                thumbnail_url,
                is_vehicle_detected,
                is_video,
                duration_in_seconds,
              },
              idx,
            ) => (
              <ThumbnailItem
                key={id}
                data-selected={idx === selectedIndex}
                data-non-vehicle={
                  is_vehicle_detected === false &&
                  isInspectionPhotoIssuesEnabled
                }
                ref={(node) => {
                  thumbnailRefs.current[idx] = node;
                }}
                onClick={(event) => {
                  event.currentTarget.scrollIntoView({
                    inline: 'center',
                    block: 'nearest',
                    behavior: 'smooth',
                  });
                  setSelectedIndex(idx);
                  setPhotoUrl(thumbnail_url);
                }}
              >
                <img
                  alt={`gallery thumbnail image ${idx + 1}`}
                  src={thumbnail_url}
                  height="100px"
                  width="100px"
                  onError={(e) => {
                    e.currentTarget.src = defaultImage;
                  }}
                />
                {is_video && (
                  <>
                    <VideoContainer>
                      <ButtonContainer width="38px" height="38px">
                        <PlayIcon style={{ width: '20px', height: '18px' }} />
                      </ButtonContainer>
                    </VideoContainer>
                    {duration_in_seconds && (
                      <VideoDuration>
                        {formatDuration(duration_in_seconds)}
                      </VideoDuration>
                    )}
                  </>
                )}
                {is_vehicle_detected === false &&
                  isInspectionPhotoIssuesEnabled && (
                    <NonVehiclePhotoBagde aria-label="Non-vehicle photo badge">
                      <ErrorIcon />
                    </NonVehiclePhotoBagde>
                  )}
              </ThumbnailItem>
            ),
          )}
        </div>
      </ThunmbnailsContainer>
    </Stack>
  );
}
