import { Typography } from '@material-ui/core';
import { ColorDynamic, Stack } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import styled from 'styled-components';
import {
  passwordType,
  validateToNumber,
  validateToPasswordLength,
  validateToSpecailCharacters,
  validateToUpperAndLowerCase,
} from '../utils/PasswordUtils';
import { CheckPasswordItem } from './CheckPasswordItem';
import { Stepper, StepperItem } from './Stepper';

const PasswordText = styled(Typography)<{ passwordstrength: number }>`
  color: ${({ passwordstrength }) =>
    passwordstrength === 3
      ? ColorDynamic.Red500
      : passwordstrength === 4 || passwordstrength === 5
      ? ColorDynamic.Yellow500
      : passwordstrength === 6
      ? ColorDynamic.Dark500
      : passwordstrength === 7
      ? ColorDynamic.Green500
      : ColorDynamic.Dark100};
`;

interface PasswordValidationStepperProps {
  passwordstrength: number;
  value: string;
}

export function PasswordValidationStepper({
  passwordstrength,
  value,
}: PasswordValidationStepperProps) {
  return (
    <Box>
      <Box>
        <PasswordText variant="body2" passwordstrength={passwordstrength}>
          {passwordstrength === 2 || passwordstrength === 1
            ? 'Password Strength'
            : passwordstrength === 3
            ? 'Weak Password'
            : passwordstrength === 4 || passwordstrength === 5
            ? 'Average Password'
            : passwordstrength === 6
            ? 'Good Password'
            : 'Strong Password'}
        </PasswordText>
        <Stepper>
          <StepperItem passwordType={passwordType(passwordstrength, 0)} />
          <StepperItem passwordType={passwordType(passwordstrength, 1)} />
          <StepperItem passwordType={passwordType(passwordstrength, 2)} />
          <StepperItem passwordType={passwordType(passwordstrength, 3)} />
        </Stepper>
      </Box>
      <Box>
        <Typography variant="body2">It must have:</Typography>
        <Stack space="xxsmall">
          <CheckPasswordItem
            isDone={validateToPasswordLength(value)}
            text="At least 8 characters"
          />
          <CheckPasswordItem
            isDone={validateToUpperAndLowerCase(value)}
            text="Upper & lowercase letters"
          />
          <CheckPasswordItem isDone={validateToNumber(value)} text="A number" />
          <CheckPasswordItem
            isDone={validateToSpecailCharacters(value)}
            text="A special character (%, $, #, etc.)"
          />
        </Stack>
      </Box>
    </Box>
  );
}
