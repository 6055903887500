const firebaseConfig = {
  apiKey: 'AIzaSyDpluWkDc25dHQ4vpMZbdOpD6ascL6fxoU',
  authDomain: 'growth-experiment.firebaseapp.com',
  projectId: 'growth-experiment',
  storageBucket: 'growth-experiment.appspot.com',
  messagingSenderId: '512830199417',
  appId: '1:512830199417:web:6ecc4dab4214c7a5b3e6a8',
  measurementId: 'G-N9SEHH17DR',
};

export function getFirestore() {
  return Promise.all([
    import('firebase/app'),
    //eslint-disable-next-line import/no-internal-modules
    import('firebase/auth'),
    import('firebase/firestore'),
  ]).then(async ([{ initializeApp }, auth, firestore]) => {
    const app = initializeApp(firebaseConfig);
    await auth.signInAnonymously(auth.getAuth(app));

    return {
      firestore,
      db: firestore.getFirestore(app),
    };
  });
}
