import { Typography } from '@material-ui/core';
import { Warning } from '@material-ui/icons';
import { ColorDynamic, Inline } from '@superdispatch/ui';
import { Box, TextBox } from '@superdispatch/ui-lab';
import {
  formatCurbWeight,
  formatVehicleLength,
} from 'shared/helpers/VehicleHelpers';
import { WeightIcon } from 'shared/icons/WeightIcon';
import { OfferVehicleDTO } from '../offers/data/OffersDTO';

interface Props {
  vehicles: OfferVehicleDTO[];
}

export function OffersVehicleWeight({ vehicles }: Props) {
  const vehiclesWithWeight = vehicles.filter(
    ({ curb_weight }) => !!curb_weight,
  );
  const totalCurbWeight = vehicles.reduce(
    (total, { curb_weight }) => total + (curb_weight || 0),
    0,
  );

  if (totalCurbWeight === 0) {
    return null;
  }

  return vehiclesWithWeight.length !== vehicles.length ? (
    <Box color="Yellow500">
      <Inline space="xxsmall" verticalAlign="center">
        <Warning fontSize="small" />

        <Typography color="inherit" variant="caption">
          Total curb weight ({vehiclesWithWeight.length} out of{' '}
          {vehicles.length} {formatVehicleLength(vehicles.length)})
        </Typography>

        <TextBox variant="caption">
          {formatCurbWeight(totalCurbWeight, vehicles[0]?.curb_weight_unit)}
        </TextBox>
      </Inline>
    </Box>
  ) : (
    <Inline space="xxsmall" verticalAlign="center">
      <WeightIcon fontSize="small" htmlColor={ColorDynamic.Dark100} />

      <Typography variant="caption">
        Total curb weight:{' '}
        {formatCurbWeight(totalCurbWeight, vehicles[0]?.curb_weight_unit)}
      </Typography>
    </Inline>
  );
}
