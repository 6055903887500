import { useMemo } from 'react';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { APIPageQueryResult, useAPIPageQuery } from 'shared/api/APIPageQuery';
import { APIQueryResult, useAPIQuery } from 'shared/api/APIQuery';
import { APIResponse } from 'shared/api/CarrierAPIClient';
import {
  LoadboardAPIPageResponse,
  mapLoadboardAPIPageToCarrierAPIPage,
  mapLoadboardAPIToCarrierAPI,
  requestJSONLoadboardAPI,
  requestLoadboardAPI,
} from 'shared/api/LoadboardAPIClient';
import {
  ShipperNewRatingDTO,
  ShipperRatingDetailsDTO,
  shipperRatingDetailsSchema,
  ShipperRatingDTO,
  shipperRatingSchema,
  ShipperRatingStatisticsDTO,
  shipperRatingStatisticsSchema,
} from './ShipperRatingDTO';

export function useShipperRatingDetails(
  guid: string | undefined,
): APIQueryResult<ShipperRatingDetailsDTO> {
  return useAPIQuery(
    ['shippers', 'ratings', { guid }],
    () =>
      requestJSONLoadboardAPI([
        'GET /internal/v3/shippers/{guid}/ratings/details',
        { guid },
      ]),
    {
      schema: shipperRatingDetailsSchema,
      enabled: !!guid,
    },
  );
}

export function useShipperRatingsAPI() {
  const queryClient = useQueryClient();

  return useMemo(
    () => ({
      rateShipper: (guid: string, data: ShipperNewRatingDTO) =>
        requestJSONLoadboardAPI<APIResponse<ShipperRatingDTO>>(
          ['POST /internal/v3/shippers/{guid}/ratings', { guid }],
          { json: data },
        ).then((response) => {
          void queryClient.invalidateQueries(['ratings']);
          void queryClient.invalidateQueries(['shippers']);
          return response;
        }),
    }),
    [queryClient],
  );
}

export function useLatestShipperRating(
  guid: string | undefined | null,
): APIQueryResult<ShipperRatingDTO> {
  return useAPIQuery(
    ['shippers', 'latest-rating', { guid }],
    () =>
      requestJSONLoadboardAPI<APIResponse<ShipperRatingDTO>>([
        'GET /internal/v3/shippers/{guid}/ratings/latest',
        { guid },
      ]).then(mapLoadboardAPIToCarrierAPI),
    {
      enabled: !!guid,
      schema: shipperRatingSchema,
      refetchOnWindowFocus: false,
    },
  );
}

export function useShipperRatingStatistics(
  guid: string,
): APIQueryResult<ShipperRatingStatisticsDTO> {
  return useAPIQuery(
    ['shippers', 'ratings', { guid }],
    () =>
      requestJSONLoadboardAPI([
        'GET /internal/v3/shippers/{guid}/ratings/statistics',
        { guid },
      ]),
    {
      schema: shipperRatingStatisticsSchema,
    },
  );
}

export function useShipperRatings(
  guid: string,
  params: { size: number; page: number },
): APIPageQueryResult<ShipperRatingDTO> {
  return useAPIPageQuery(
    ['shippers', 'ratings-list', { guid, params }],
    () =>
      requestJSONLoadboardAPI<LoadboardAPIPageResponse<ShipperRatingDTO>>([
        'GET /internal/v3/shippers/{guid}/ratings{?params*}',
        {
          guid,
          params: {
            size: params.size,
            page: params.page - 1, // normalize page number as count start from 0 on backend
          },
        },
      ]).then(mapLoadboardAPIPageToCarrierAPIPage),
    { schema: shipperRatingSchema },
  );
}

export function useDeleteNegativeRating(
  options?: UseMutationOptions<Response, Error, string>,
) {
  return useMutation(
    (guid) =>
      requestLoadboardAPI([
        'DELETE /internal/v3/shippers/{guid}/ratings',
        { guid },
      ]),
    options,
  );
}

export function useShipperRatingsCache() {
  const queryClient = useQueryClient();

  return useMemo(() => {
    function invalidateShipperRatings() {
      void queryClient.invalidateQueries(['shippers']);
    }

    return { invalidateShipperRatings };
  }, [queryClient]);
}
