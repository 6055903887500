import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { Stack, useResponsiveValue } from '@superdispatch/ui';
import { Button, ButtonProps } from '@superdispatch/ui-lab';
import { ReactNode } from 'react';

export interface ConfirmDialogContentProps {
  title?: ReactNode;
  onClose?: () => void;
  children?: ReactNode;
  cancelButtonProps?: Partial<ButtonProps>;
  confirmButtonProps?: Partial<ButtonProps>;
}

export function ConfirmDialogContent({
  title,
  onClose,
  children,
  cancelButtonProps,
  confirmButtonProps,
}: ConfirmDialogContentProps) {
  const screen = useResponsiveValue('mobile', 'tablet', 'desktop');
  const isMobile = screen === 'mobile' || screen === 'tablet';
  return (
    <>
      {title && (
        <DialogTitle disableTypography={true}>
          <Typography variant="h3">{title}</Typography>
        </DialogTitle>
      )}

      {children && <DialogContent>{children}</DialogContent>}

      <DialogActions>
        {isMobile ? (
          <Stack space="small">
            <Button
              fullWidth={true}
              variant="neutral"
              onClick={onClose}
              {...cancelButtonProps}
            >
              {cancelButtonProps?.children || 'Cancel'}
            </Button>
            <Button fullWidth={true} autoFocus={true} {...confirmButtonProps}>
              {confirmButtonProps?.children || 'Confirm'}
            </Button>
          </Stack>
        ) : (
          <>
            <Button variant="neutral" onClick={onClose} {...cancelButtonProps}>
              {cancelButtonProps?.children || 'Cancel'}
            </Button>
            <Button autoFocus={true} {...confirmButtonProps}>
              {confirmButtonProps?.children || 'Confirm'}
            </Button>
          </>
        )}
      </DialogActions>
    </>
  );
}

export interface ConfirmDialogProps
  extends ConfirmDialogContentProps,
    Omit<DialogProps, 'title' | 'onClose'> {}

export function ConfirmDialog({
  onClose,
  title,
  children,
  cancelButtonProps,
  confirmButtonProps,

  maxWidth = 'xs',
  fullWidth = true,

  ...props
}: ConfirmDialogProps) {
  return (
    <Dialog
      {...props}
      onClose={onClose}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
    >
      <ConfirmDialogContent
        title={title}
        onClose={onClose}
        cancelButtonProps={cancelButtonProps}
        confirmButtonProps={confirmButtonProps}
      >
        {children}
      </ConfirmDialogContent>
    </Dialog>
  );
}
