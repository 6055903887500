import { Color } from '@superdispatch/ui';
import { createSvgIcon } from 'shared/icons/IconUtils';

export const DeliveryAltIcon = createSvgIcon(
  'PostingFilterDeliver',
  <>
    <circle cx="10" cy="10" r="8.33333" fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.83335 10.761L6.82994 9.74633L9.30175 12.2764V5H10.6983V12.2764L13.1701 9.74633L14.1667 10.761L9.99674 15L5.83335 10.761Z"
      fill={Color.White}
    />
  </>,
  { viewBox: '0 0 20 20' },
);
