import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { Button } from '@superdispatch/ui-lab';
import { MobileAppBridge } from 'shared/data/MobileAppBridge';

export function LoadboardMobileCarrierConnectionDialog() {
  return (
    <Dialog open={true} fullWidth={true}>
      <DialogTitle>
        <Typography variant="h3">Access Unavailable</Typography>
      </DialogTitle>

      <DialogContent>
        <Typography>
          Access is unavailable because you’re no longer connected to this
          carrier.
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={() => {
            MobileAppBridge.openAvailableLoads();
          }}
        >
          Got it
        </Button>
      </DialogActions>
    </Dialog>
  );
}
