import { getAuthRole } from 'shared/auth/AuthToken';
import { onAnalyticsReady, trackEvent } from 'shared/helpers/Analytics';
import { logError, logInfo } from 'shared/helpers/ErrorTracker';
import { LocalStore } from './Store';

declare global {
  interface Window {
    Intercom?: (...args: any[]) => void;
    intercomSettings?: {
      hide_default_launcher?: boolean;
      alignment?: 'left' | 'right';
      horizontal_padding?: number;
      vertical_padding?: number;
      custom_launcher_selector?: string;
    };
  }
}

const INTERCOM_TOURS = {
  load_suggestions_menu_item: {
    staging: '487722',
    production: '487721',
  },
  load_suggestions_drawer: {
    staging: '487723',
    production: '488056',
  },
};

window.intercomSettings = {
  hide_default_launcher: getAuthRole() === 'admin',
};

export function launchIntercomTour(tourKey: keyof typeof INTERCOM_TOURS) {
  const hasSeenTour = LocalStore.get(tourKey);
  if (hasSeenTour !== 'true') {
    startIntercomTour(
      import.meta.env.VITE_APP_TARGET === 'production'
        ? INTERCOM_TOURS[tourKey].production
        : INTERCOM_TOURS[tourKey].staging,
    );
    LocalStore.set(tourKey, 'true');
  }
}

function invokeIntercom(command: string, ...args: any[]): void {
  onAnalyticsReady(() => {
    try {
      if (command === 'update' && typeof args[0] === 'object') {
        window.intercomSettings = {
          ...window.intercomSettings,
          ...(args[0] as typeof window.intercomSettings),
        };
      }

      //eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      window.Intercom?.(command, ...args);
    } catch (error: unknown) {
      logError(error, 'Intercom');
    }
  });
}

export function hideIntercomMessenger(): void {
  invokeIntercom('hide');
}

export function hideIntercomLauncher(): void {
  hideIntercomMessenger();
  invokeIntercom('update', { hide_default_launcher: true });
}

export function showIntercomLauncher(): void {
  const authRole = getAuthRole();
  if (authRole !== 'admin') {
    invokeIntercom('update', { hide_default_launcher: false });
  }
}

export function showIntercomMessenger(): void {
  invokeIntercom('show');
  showIntercomLauncher();
}

export function showNewIntercomMessage(newMessage?: string): void {
  invokeIntercom('showNewMessage', newMessage);
  showIntercomLauncher();
}

export function startIntercomTour(tourId: string): void {
  invokeIntercom('startTour', tourId);
}

export function startIntercomSurvey(surveyId: string): void {
  invokeIntercom('startSurvey', surveyId);
}

export function onShow(fn: () => void) {
  invokeIntercom('onShow', fn);
}

onShow(() => {
  logInfo('Opened Intercom');
  trackEvent('Opened Intercom');
});
