import { Card, CardContent } from '@material-ui/core';
import { ColorDynamic } from '@superdispatch/ui';
import { ReactNode } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import styled, { css } from 'styled-components';

export const OrderCard = styled(Card)<{
  $hovered?: boolean;
  $isEmpty?: boolean;
}>`
  min-width: 320px;
  width: 320px;
  height: 100%;
  min-height: 300px;
  padding: ${({ $isEmpty }) => ($isEmpty ? '100px 16px' : '16px')};
  border-style: ${({ $isEmpty }) => ($isEmpty ? 'dashed' : 'solid')};

  ${({ $hovered }) =>
    !!$hovered &&
    css`
      border-color: ${ColorDynamic.Blue300};
      background-color: ${ColorDynamic.Blue50};
    `}
`;

export const OrderCardContent = styled(CardContent)`
  height: 100%;
  padding: 0;
`;

export interface SplitLoadsCardDropableProps {
  uniqueId: string;
  isEmpty?: boolean;
  label?: string;
  isDropDisabled?: boolean;
  children: ReactNode;
}

export function SplitLoadsCardDropable({
  uniqueId,
  isEmpty,
  label,
  isDropDisabled = false,
  children,
}: SplitLoadsCardDropableProps) {
  return (
    <Droppable
      isDropDisabled={isDropDisabled}
      key={uniqueId}
      droppableId={uniqueId}
    >
      {({ innerRef, droppableProps, placeholder }, { isDraggingOver }) => (
        <OrderCard
          aria-label={label}
          $hovered={isDraggingOver}
          $isEmpty={isEmpty}
          {...droppableProps}
          ref={innerRef}
        >
          <OrderCardContent>
            {children}
            {placeholder}
          </OrderCardContent>
        </OrderCard>
      )}
    </Droppable>
  );
}

export interface SplitLoadsCardContainerProps {
  label?: string;
  children: ReactNode;
}

export function SplitLoadsCardContainer({
  label,
  children,
}: SplitLoadsCardContainerProps) {
  return (
    <OrderCard aria-label={label}>
      <OrderCardContent>{children}</OrderCardContent>
    </OrderCard>
  );
}
