import { FormControlLabel, Radio, Typography } from '@material-ui/core';
import { InfoRounded } from '@material-ui/icons';
import { parseDate } from '@superdispatch/dates';
import {
  FormikCheckboxField,
  FormikDateField,
  FormikRadioGroupField,
  FormikTextField,
  useFormikEnhanced,
} from '@superdispatch/forms';
import {
  Column,
  Columns,
  ExitTransitionPlaceholder,
  Stack,
  useResponsiveValue,
} from '@superdispatch/ui';
import { Alert, AnchorButton, Box } from '@superdispatch/ui-lab';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { getAuthToken } from 'shared/auth/AuthToken';
import { FormikDrawer, FormikDrawerContent } from 'shared/form/FormikDrawer';
import { startIntercomSurvey } from 'shared/helpers/Intercom';
import { formatLoadPayment } from 'shared/helpers/PaymentHelpers';
import { LocalStore, useLocalStore } from 'shared/helpers/Store';
import { useDispatcherProfile } from 'shared/modules/dispatcher/DispatcherAPI';
import { ContactDTO } from '../../contacts/data/ContactDTO';
import { sendInvoiceSchema } from '../data/LoadActionsDTO';
import { CustomerDTO, LoadDTO, loadVenueSchema } from '../data/LoadDTO';
import { trackLoadsEvent } from '../data/LoadsAnalytics';
import { useAccountingInfo } from '../data/LoadsAPI';
import { FormikVenueAutofill } from './FormikVenueAutofill';
import { LoadEmailSelectionField } from './LoadEmailSelectionField';
import { LoadSelectionField } from './LoadSelectionField';
import { LoadSendInvoiceAttachments } from './LoadSendInvoiceAttachments';
import { LoadSendInvoiceDrawerActions } from './LoadSendInvoiceDrawerActions';
import { useSuperPayRequested } from './LoadSendInvoiceRequestPayment';
import { LoadSendInvoiceStatus } from './LoadSendInvoiceStatus';

const LOAD_SEND_INVOICE_COUNT_STORAGE_KEY = 'load_send_invoice_count';
const newSendInvoiceIntercomSurveyId =
  import.meta.env.VITE_APP_TARGET === 'production' ? '34538606' : '34557398';
const BANNER_QUICKBOOK_SEEN_KEY = 'quickbook.has_seen_banner';
const SIGNED_UP_AFTER = '2024-02-01T00:00:00Z';

export interface LoadSendInvoiceDrawerProps {
  load?: LoadDTO;
  onClose: () => void;
}

const bolTemplateArray = [
  { name: 'Default', value: 'default' },
  { name: 'Metrogistics', value: 'metrogistics' },
  { name: 'Multiple Units', value: 'multiple-units' },
];

function useHasSeenQuickBooksAlert() {
  const quickBooksBannerSeen = useLocalStore(BANNER_QUICKBOOK_SEEN_KEY, null);
  const { data: dispatcher } = useDispatcherProfile();

  const createdAtDate = parseDate(dispatcher?.created_at, {
    format: 'DateISO',
  });

  const setShowQuickBooksAlert = useCallback(() => {
    LocalStore.set(BANNER_QUICKBOOK_SEEN_KEY, 'true');
  }, []);

  return {
    setShowQuickBooksAlert,
    shouldShowQuickBooksBanner:
      quickBooksBannerSeen !== 'true' &&
      createdAtDate > DateTime.fromISO(SIGNED_UP_AFTER),
  };
}

export function LoadSendInvoiceDrawer({
  load,
  onClose,
}: LoadSendInvoiceDrawerProps) {
  return (
    <FormikDrawer
      open={!!load}
      onClose={onClose}
      drawerProps={{ disableEnforceFocus: true }}
    >
      <ExitTransitionPlaceholder in={!!load}>
        <SendInvoiceDrawerContent load={load} onClose={onClose} />
      </ExitTransitionPlaceholder>
    </FormikDrawer>
  );
}

type SendInvoiceDrawerContentProps = LoadSendInvoiceDrawerProps;

function handleEmails(customer?: CustomerDTO) {
  if (!customer?.email) {
    return [];
  }

  return customer.email.split(',').map((email) => ({
    email: email.trim(),
  }));
}

export function SendInvoiceDrawerContent({
  load,
  onClose,
}: SendInvoiceDrawerContentProps) {
  const screen = useResponsiveValue('mobile', 'tablet', 'desktop');
  const isMobile = screen === 'mobile' || screen === 'tablet';
  const loadSendInvoiceCount = useLocalStore(
    LOAD_SEND_INVOICE_COUNT_STORAGE_KEY,
    '1',
  );
  const [isSubmitedForm, setSubmitForm] = useState(false);

  const authToken = getAuthToken();
  const integrationsURL = `/apps/integrations?token=${authToken}?utm_medium=Send Invoice`;

  const { data: accountInfo } = useAccountingInfo({
    order_guid: load?.guid,
    skip_customer_name: true,
  });

  const paymentMethod = useMemo(() => {
    if (load && load.payments.length > 0) {
      return load.payments[0]?.method;
    }
    return '';
  }, [load]);

  const { isEnableSuperPayRequestCheckBox } = useSuperPayRequested(
    paymentMethod,
    load?.is_created_by_broker,
  );

  const { setShowQuickBooksAlert, shouldShowQuickBooksBanner } =
    useHasSeenQuickBooksAlert();

  const formik = useFormikEnhanced({
    validationSchema: sendInvoiceSchema,
    enableReinitialize: true,
    initialValues: {
      customer: {
        name: accountInfo?.customer.name,
      },
      invoice_id: accountInfo?.invoice_number,
      invoice_date: accountInfo?.invoice_date,
      orders: accountInfo?.order
        ? [{ ...accountInfo.order, attachments: accountInfo.attachments }]
        : [],
      email: !!accountInfo?.customer.email,
      emails: handleEmails(accountInfo?.customer),
      bol_template: 'default',
      factor_this_invoice: false,
      qb_online: accountInfo?.qb_online.connected,
      qb_desktop: accountInfo?.qb_desktop.connected,
      attachments: [],
      is_carrier_requested_superpay: isEnableSuperPayRequestCheckBox,
    },
    onSubmit: () => {
      setSubmitForm(true);
    },
    onSubmitSuccess: (_, value) => {
      const invoiceType =
        (value.email && value.qb_online) ||
        (value.email && value.qb_desktop) ||
        (value.qb_desktop && value.qb_online)
          ? 'combined'
          : 'single';
      let sendingMethod = [];
      if (value.email) sendingMethod.push('email');
      if (value.qb_online) sendingMethod.push('qbo');
      if (value.qb_desktop) sendingMethod.push('qbd');

      const invoiceSentCount = Number(loadSendInvoiceCount);

      if (invoiceSentCount < 3) {
        LocalStore.set(
          LOAD_SEND_INVOICE_COUNT_STORAGE_KEY,
          String(invoiceSentCount + 1),
        );
      } else if (invoiceSentCount === 3) {
        startIntercomSurvey(newSendInvoiceIntercomSurveyId);

        // Setting the count to 4 so that the survey is not shown again
        LocalStore.set(LOAD_SEND_INVOICE_COUNT_STORAGE_KEY, '4');
      }

      trackLoadsEvent({
        name: 'Carrier Sent Invoice',
        bol_template: value.bol_template,
        factor: value.factor_this_invoice,
        invoice_type: invoiceType,
        is_attachment_added: !!value.attachments.length,
        payment_method: formatLoadPayment(paymentMethod),
        sending_method: sendingMethod,
        carrier_requested_payment_via_superpay:
          !!value.is_carrier_requested_superpay,
      });
    },
  });
  const { values, setValues } = formik;

  const isSubmitDisabled =
    !values.qb_online && !values.qb_desktop && !values.email;

  const handleBack = () => {
    setSubmitForm(false);
  };

  const handleSelectCustomer = (contact: ContactDTO) => {
    if (!contact.email) {
      return;
    }

    const emails = contact.email
      .split(',')
      .map((item) => item.replace(' ', ''));

    void setValues((value) => ({
      ...value,
      email: true,
      emails: [
        ...value.emails,
        ...emails.map((email) => ({
          email,
          guid: contact.guid,
          name: contact.name,
          fax: '',
        })),
      ],
    }));
  };

  useEffect(() => {
    trackLoadsEvent({
      name: 'Carrier Opened Send Invoice Drawer',
      carrier_requested_payment_via_superpay: !!isEnableSuperPayRequestCheckBox,
    });
  }, [isEnableSuperPayRequestCheckBox]);

  const isQBConnected =
    accountInfo?.qb_online.connected || accountInfo?.qb_desktop.connected;

  const width = isMobile ? '100%' : '500px';

  if (!isSubmitedForm) {
    return (
      <FormikDrawerContent
        title="Send Invoice"
        formik={formik}
        onClose={onClose}
        minWidth={width}
        maxWidth={width}
        actions={
          <LoadSendInvoiceDrawerActions
            submitDisabled={isSubmitDisabled}
            orders={values.orders}
            paymentMethod={paymentMethod}
            isCreatedByBroker={load?.is_created_by_broker}
          />
        }
      >
        <Stack space="large">
          {!isQBConnected && shouldShowQuickBooksBanner && (
            <Alert
              icon={<InfoRounded />}
              onClose={() => {
                setShowQuickBooksAlert();
              }}
              severity="info"
            >
              <Box>
                <Stack>
                  <Typography variant="h5">Do you use QuickBooks?</Typography>
                  <Typography>
                    Super Dispatch offers a seamless integration with QuickBooks
                    Online to help you streamline invoicing and payments.
                  </Typography>

                  <AnchorButton
                    size="small"
                    variant="neutral"
                    target="_blank"
                    href={integrationsURL}
                    onClick={() => {
                      setShowQuickBooksAlert();
                      onClose();
                    }}
                  >
                    Learn more
                  </AnchorButton>
                </Stack>
              </Box>
            </Alert>
          )}
          <Stack space="small">
            <FormikVenueAutofill
              fullWidth={true}
              label="Customer"
              name="customer"
              schema={loadVenueSchema}
              onSelect={handleSelectCustomer}
              inputProps={{ maxLength: 100 }}
              disabled={
                load?.is_created_by_broker && !!accountInfo?.customer.name
              }
            />
            <Columns space="xsmall">
              <Column width="1/2">
                <FormikTextField
                  fullWidth={true}
                  name="invoice_id"
                  label="Invoice ID"
                />
              </Column>
              <Column width="1/2">
                <FormikDateField
                  fullWidth={true}
                  variant="Date"
                  label="Invoice Date"
                  name="invoice_date"
                  enableClearable={true}
                />
              </Column>
            </Columns>
          </Stack>
          <LoadSelectionField
            guid={load?.guid}
            loadNumber={load?.number}
            name="orders"
          />

          {accountInfo?.is_factoring_switched_on && (
            <Box>
              <FormikCheckboxField
                name="factor_this_invoice"
                label="Factor Invoice"
              />
              <Box paddingLeft="large">
                <Typography color="textSecondary">
                  Notice of Assignment sticker is added to invoice.
                </Typography>
              </Box>
            </Box>
          )}
          <Stack space="small">
            <Typography variant="h4">Sending Method</Typography>
            <Box>
              <FormikCheckboxField name="email" label="Email" />
              <Box paddingLeft="large">
                <LoadEmailSelectionField name="emails" />
              </Box>
            </Box>
            <Box paddingLeft="large">
              <Typography variant="h5" color="textSecondary">
                BOL Template
              </Typography>
              <FormikRadioGroupField name="bol_template">
                {bolTemplateArray.map((option) => (
                  <FormControlLabel
                    key={option.value}
                    value={option.value}
                    control={<Radio />}
                    label={option.name}
                  />
                ))}
              </FormikRadioGroupField>
            </Box>

            {accountInfo?.qb_online.connected && (
              <Box>
                <FormikCheckboxField
                  name="qb_online"
                  label="QuickBooks Online"
                />
                {accountInfo.qb_online.message && (
                  <Box paddingLeft="large">
                    <Typography color="textSecondary">
                      {accountInfo.qb_online.message}
                    </Typography>
                  </Box>
                )}
              </Box>
            )}

            {accountInfo?.qb_desktop.connected && (
              <Box>
                <FormikCheckboxField
                  name="qb_desktop"
                  label="QuickBooks Desktop"
                />

                {accountInfo.qb_desktop.message && (
                  <Box paddingLeft="large">
                    <Typography color="textSecondary">
                      {accountInfo.qb_desktop.message}
                    </Typography>
                  </Box>
                )}
              </Box>
            )}
          </Stack>

          {accountInfo?.attachments && accountInfo.attachments.length > 0 && (
            <LoadSendInvoiceAttachments name="attachments" />
          )}
        </Stack>
      </FormikDrawerContent>
    );
  }

  return (
    <FormikDrawerContent
      title="Send Invoice"
      formik={formik}
      onClose={onClose}
      enableBack={true}
      onBack={handleBack}
      minWidth="500px"
      maxWidth="500px"
    >
      <LoadSendInvoiceStatus onClose={onClose} />
    </FormikDrawerContent>
  );
}
