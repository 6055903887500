import {
  Card,
  CardContent,
  Link,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Business, Payment } from '@material-ui/icons';
import { parseDate } from '@superdispatch/dates';
import { formatPaymentTerm } from '@superdispatch/sdk';
import { Column, Columns, Inline, Stack } from '@superdispatch/ui';
import { Box, DescriptionItem, TextBox } from '@superdispatch/ui-lab';
import { parseURITemplate } from '@superdispatch/uri';
import { useEffect, useMemo } from 'react';
import { generateGoogleMapDirectionLink } from 'shared/geo/GoogleMapUtils';
import { formatAddressLine } from 'shared/helpers/AddressHelpers';
import { formatCurrency } from 'shared/helpers/IntlHelpers';
import { openExternalURL } from 'shared/helpers/URLHelpers';
import { CalendarMonthIcon } from 'shared/icons';
import { AvailableDateIcon } from 'shared/icons/AvailableDateIcon';
import { HashtagIcon } from 'shared/icons/HashtagIcon';
import { InstantIcon } from 'shared/icons/InstantIcon';
import { FormattedRelativeTimeToNow } from 'shared/ui/FormattedRelativeTimeToNow';
import { LoadWaypoints } from 'shared/ui/LoadWaypoints';
import { PaymentInfo } from 'shared/ui/PaymentInfo';
import { VehicleConditionIcon } from 'shared/ui/VehicleConditionIcon';
import { joinStrings } from 'shared/utils/StringUtils';
import styled from 'styled-components';
import { trackLoadsEvent } from '../data/LoadsAnalytics';
import { useLoadsPageContext } from './data/LoadsPageParamsContext';
import { SuggestedLoadDTO } from './suggested-loads-components/data/SuggestedLoadDTO';
import { SuggestedLoadAvailableDate } from './suggested-loads-components/SuggestedLoadAvailableDate';
import {
  ExclusiveLoadTag,
  SuggestedLoadTag,
} from './suggested-loads-components/SuggestedLoadTags';

const StyledCard = styled(Card)`
  cursor: pointer;
`;

export function formatAddress(
  city: string | null | undefined,
  state: string | null | undefined,
  zip: string | null | undefined,
): string {
  const cityAndState = joinStrings(', ', city, state?.toUpperCase());

  return joinStrings(' ', cityAndState, zip);
}
function formatBooleanValue(
  value: boolean | null | undefined,
): 'yes' | 'no' | undefined {
  if (value == null) {
    return undefined;
  }

  return value ? 'yes' : 'no';
}

function formatSuggestedLoadEventProps(
  load: SuggestedLoadDTO,
  tabName: string,
) {
  const postedToLoadboardAt = parseDate(load.posted_to_loadboard_at, {
    format: 'DateTimeISO',
  });

  return {
    payment: formatPaymentTerm(load.payment.terms, {
      fallback: load.payment.terms,
      short: true,
    }),
    guid: load.guid,
    price: load.price,
    utm_source: 'Web CTMS',
    utm_medium: 'Suggested Load',
    'tab name': tabName,
    'delivery state': load.delivery.venue.state,
    'delivery state and city': joinStrings(
      ', ',
      load.delivery.venue.state,
      load.delivery.venue.city,
    ),
    'is exclusive': formatBooleanValue(load.is_exclusive),
    'pickup state': load.pickup.venue.state,
    'pickup state and city': joinStrings(
      ', ',
      load.pickup.venue.state,
      load.pickup.venue.city,
    ),
    'posted date': postedToLoadboardAt.toUTC().toISO(),
    'posting guid': load.posting_guid,
    'price per mile': load.price_per_mile,
    'shipper guid': load.shipper.guid,
  };
}

const viewedLoadsPerTab = new Map<string, Set<string>>();

export function LoadsPageSuggestedLoad({ load }: { load: SuggestedLoadDTO }) {
  const { pickup, delivery } = load;
  const { params } = useLoadsPageContext();
  const googleMapDirectionLink = useMemo(
    () => generateGoogleMapDirectionLink(pickup.venue, delivery.venue),
    [pickup, delivery],
  );

  useEffect(() => {
    const viewedLoads = viewedLoadsPerTab.get(params.stage) || new Set();

    if (!viewedLoads.has(load.guid)) {
      viewedLoads.add(load.guid);
      viewedLoadsPerTab.set(params.stage, viewedLoads);

      trackLoadsEvent({
        name: 'SLB: Viewed Load',
        ...formatSuggestedLoadEventProps(load, params.stage),
      });
    }
  }, [params.stage, load]);

  const [vehiclesToRender, vehiclesToShowOnTooltip] = useMemo(() => {
    if (load.vehicles.length <= 3) {
      return [load.vehicles, []];
    }
    return [load.vehicles.slice(0, 2), load.vehicles.slice(2)];
  }, [load.vehicles]);

  const additionalVehicles = load.vehicles.length - 3;

  return (
    <StyledCard
      onClick={() => {
        trackLoadsEvent({
          name: 'CTMS: Clicked Suggested SLB Load',
          ...formatSuggestedLoadEventProps(load, params.stage),
        });

        openExternalURL(
          '/loadboard/loads/{?back_url,current_load_guid,current_page,current_page_size,current_tab,page_sort,search_criteria,utm_medium,utm_source}',
          {
            back_url: window.location.pathname + window.location.search,
            current_load_guid: load.guid,
            current_page: 0,
            current_page_size: 50,
            current_tab: 'suggested',
            page_sort: 'posted_to_loadboard_at,desc',
            search_criteria: '{}',
            utm_source: 'Web CTMS',
            utm_medium: 'Suggested Load',
          },
        );
      }}
    >
      <CardContent aria-label={load.guid}>
        <Stack space="small">
          <Inline space="small">
            <SuggestedLoadTag />
            {load.is_exclusive && <ExclusiveLoadTag />}
          </Inline>

          <Columns collapseBelow="desktop" space="small">
            <Column>
              <Stack space="xxsmall">
                <LoadWaypoints
                  dense={true}
                  space="none"
                  pickupAddress={
                    <TextBox>
                      {formatAddressLine(
                        pickup.venue.zip,
                        pickup.venue.city,
                        pickup.venue.state,
                      )}
                    </TextBox>
                  }
                  deliveryAddress={
                    <TextBox>
                      {formatAddressLine(
                        delivery.venue.zip,
                        delivery.venue.city,
                        delivery.venue.state,
                      )}
                    </TextBox>
                  }
                />
                <Inline space="xxsmall">
                  <Link
                    href={googleMapDirectionLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    View Route
                  </Link>
                  ·
                  {!!load.distance_miles && (
                    <TextBox color="secondary">
                      {load.distance_miles} mi
                    </TextBox>
                  )}
                </Inline>
              </Stack>
            </Column>

            <Column>
              <Stack space="xxsmall">
                {vehiclesToRender.map((vehicle) => (
                  <Inline
                    key={vehicle.guid}
                    space="xxsmall"
                    verticalAlign="center"
                  >
                    <TextBox>
                      {vehicle.year} {vehicle.make} {vehicle.model}
                    </TextBox>
                    <TextBox color="secondary">{vehicle.type}</TextBox>
                    <VehicleConditionIcon
                      transportType={load.transport_type}
                      isInoperable={vehicle.is_inoperable}
                    />
                  </Inline>
                ))}

                {additionalVehicles > 0 && (
                  <Tooltip
                    title={
                      <Stack>
                        {vehiclesToShowOnTooltip.map((v) => (
                          <div key={v.guid}>
                            {v.year} {v.make} {v.model} {v.type}
                          </div>
                        ))}
                      </Stack>
                    }
                  >
                    <div>
                      <TextBox>+{additionalVehicles} more</TextBox>
                    </div>
                  </Tooltip>
                )}
              </Stack>
            </Column>

            <Column>
              <Stack space="xxsmall">
                <DescriptionItem
                  icon={<Business color="action" fontSize="small" />}
                >
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    href={parseURITemplate(
                      '/loadboard/shipper/{guid}{?utm_source,utm_medium}',
                      {
                        guid: load.shipper.guid,
                        utm_source: 'Web CTMS',
                        utm_medium: 'List',
                      },
                    )}
                  >
                    {load.shipper.name}
                  </Link>
                </DescriptionItem>

                <DescriptionItem
                  icon={<HashtagIcon color="action" fontSize="small" />}
                  label="Load ID"
                >
                  {load.number}
                </DescriptionItem>
              </Stack>
            </Column>

            <Column>
              <Stack space="xxsmall">
                <DescriptionItem
                  icon={
                    <Payment
                      aria-label="Payment terms and Price per mile icon"
                      color="action"
                      fontSize="small"
                    />
                  }
                >
                  <PaymentInfo
                    terms={load.payment.terms}
                    method={load.payment.method}
                  />
                </DescriptionItem>

                <DescriptionItem
                  icon={<CalendarMonthIcon color="action" fontSize="small" />}
                  label="Posted"
                >
                  <FormattedRelativeTimeToNow
                    date={load.posted_to_loadboard_at}
                  />
                </DescriptionItem>

                <DescriptionItem
                  icon={<AvailableDateIcon color="action" fontSize="small" />}
                  label="Available"
                >
                  <SuggestedLoadAvailableDate load={load} />
                </DescriptionItem>
              </Stack>
            </Column>

            <Column>
              <Stack space="xsmall">
                {load.is_bookable ? (
                  <Box padding="xxsmall" display="inline-block" color="Blue400">
                    <Inline verticalAlign="center">
                      <Typography color="inherit" variant="h2">
                        {formatCurrency(load.price, {
                          maximumFractionDigits: 0,
                        })}
                      </Typography>
                      <InstantIcon color="inherit" />
                    </Inline>
                  </Box>
                ) : (
                  <TextBox variant="heading-2">
                    {formatCurrency(load.price, { maximumFractionDigits: 0 })}
                  </TextBox>
                )}
                {load.price_per_mile != null && (
                  <TextBox color="secondary">
                    {formatCurrency(load.price_per_mile, {
                      maximumFractionDigits: 2,
                    })}
                    /mi
                  </TextBox>
                )}
              </Stack>
            </Column>
          </Columns>
        </Stack>
      </CardContent>
    </StyledCard>
  );
}
