import { SidebarContainer } from '@superdispatch/ui-lab';
import { Navigate, Outlet, RouteObject } from 'react-router-dom';
import { useFlag } from 'shared/settings/FeatureToggles';
import { ProfileACHPaymentInfoPage } from './ProfileACHPaymentInfoPage';
import { ProfileBillingInfoPage } from './ProfileBillingInfoPage';
import { ProfileBusinessLicensePage } from './ProfileBusinessLicensePage';
import { ProfileCargoInsurancePage } from './ProfileCargoInsurancePage';
import { ProfileCargoInsurancePageLegacy } from './ProfileCargoInsurancePageLegacy';
import { ProfileCarrierInfoPage } from './ProfileCarrierInfoPage';
import { ProfileOverviewPage } from './ProfileOverviewPage';
import { ProfilePhoneVerificationPage } from './ProfilePhoneVerificationPage';
import { ProfileSidebar } from './ProfileSidebar';
import { ProfileStateMotorCarrierPermitPage } from './ProfileStateMotorCarrierPermit';
import { superPayRoutes } from './ProfileSuperPayPage';
import { ProfileTermsAndConditionsPage } from './ProfileTermsAndConditionsPage';
import { ProfileTransporterPlateRegistrationPage } from './ProfileTransporterPlateRegistrationPage';
import { ProfileUSDOTPage } from './ProfileUSDOTPage';
import { ProfileW9Page } from './ProfileW9Page';
import { verifiedCarrierApplicationRoutes } from './verified-carrier-application/ProfileVerifiedCarrierApplicationOutlet';

function ProfileCargoInsuranceRoute() {
  const isCargoRenewalEnabled = useFlag('cargo_insurance_renewal');
  if (isCargoRenewalEnabled) {
    return <ProfileCargoInsurancePage />;
  }
  return <ProfileCargoInsurancePageLegacy />;
}

export const profileRoutes: RouteObject[] = [
  {
    path: 'profile',
    children: [
      ...verifiedCarrierApplicationRoutes,
      {
        path: 'phone-verification',
        element: <ProfilePhoneVerificationPage />,
      },
      {
        path: '*',
        element: (
          <SidebarContainer sidebar={<ProfileSidebar />}>
            <Outlet />
          </SidebarContainer>
        ),
        children: [
          ...superPayRoutes,
          {
            path: 'overview',
            element: <ProfileOverviewPage />,
          },
          {
            path: 'carrier_info',
            element: <ProfileCarrierInfoPage />,
          },
          {
            path: 'cargo_insurance',
            element: <ProfileCargoInsuranceRoute />,
          },
          {
            path: 'w9',
            element: <ProfileW9Page />,
          },
          {
            path: 'usdot',
            element: <ProfileUSDOTPage />,
          },
          {
            path: 'transporter_plate_registration',
            element: <ProfileTransporterPlateRegistrationPage />,
          },
          {
            path: 'state_motor_carrier_permit',
            element: <ProfileStateMotorCarrierPermitPage />,
          },
          {
            path: 'business_license',
            element: <ProfileBusinessLicensePage />,
          },
          {
            path: 'billing_info',
            element: <ProfileBillingInfoPage />,
          },
          {
            path: 'ach_payment',
            element: <ProfileACHPaymentInfoPage />,
          },
          {
            path: 'terms_and_conditions',
            element: <ProfileTermsAndConditionsPage />,
          },
        ],
      },
      {
        index: true,
        element: <Navigate to="/profile/overview" replace={true} />,
      },
    ],
  },
];
