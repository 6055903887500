import { useAPISocketWorker } from 'shared/api/APISocketWorker';
import { tryParseJSON } from 'shared/utils/DataUtils';

interface APISocketWorkerPayload<TData, TMetaType> {
  data: TData;
  meta: { type: TMetaType; message_guid: string };
}

export function useAPISocketMessages<TData = unknown, TMetaType = string>(
  fn: (message: APISocketWorkerPayload<TData, TMetaType>) => void,
) {
  return useAPISocketWorker('/messages/', (action) => {
    if (action.type === 'message') {
      const payload = tryParseJSON<APISocketWorkerPayload<TData, TMetaType>>(
        action.event.data as string,
      );

      if (payload) {
        fn(payload);
      }
    }
  });
}
