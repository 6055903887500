import { Divider, Tab, TablePagination, Tabs } from '@material-ui/core';
import { ColorDynamic } from '@superdispatch/ui';
import { toInteger } from 'lodash-es';
import { useLocationParams } from 'shared/routing/LocationParams';
import styled from 'styled-components';
import { useTripsPage } from '../data/TripsAPI';
import {
  TripsCountResponse,
  tripsPageParamsSchemaLegacy,
} from '../data/TripsDTO';
import { TripsTableLegacy } from '../trips-list-page/TripsTableLegacy';

const TableWrapper = styled.div`
  overflow: auto;
  background-color: ${ColorDynamic.White};
`;

const Styled1 = styled(TablePagination).attrs({ component: 'div' })`
  & {
    /* Remove space on the right to make left aligned */
    .MuiTablePagination-spacer {
      flex: 0;
    }

    /* Remove extra pixel from top for select icon  */
    .MuiTablePagination-selectIcon {
      top: unset;
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

interface TripsListContentLegacyProps {
  counts: TripsCountResponse | undefined;
}

export function TripsListContentLegacy({
  counts,
}: TripsListContentLegacyProps) {
  const [params, updateParams] = useLocationParams({
    yupSchema: tripsPageParamsSchemaLegacy,
  });
  const { data, error, isFetching } = useTripsPage(params);

  return (
    <Wrapper>
      <Tabs value={params.status}>
        <Tab
          value="unarchived"
          onClick={() => {
            updateParams({ status: 'unarchived', page: 1 });
          }}
          label={`Active (${counts?.active || ''})`}
        />
        <Tab
          value="archived"
          onClick={() => {
            updateParams({ status: 'archived', page: 1 });
          }}
          label={`Archived (${counts?.archived || ''})`}
        />
      </Tabs>

      <Divider />

      <TableWrapper>
        <TripsTableLegacy
          trips={data?.data}
          isLoading={isFetching}
          error={error}
          ordering={params.ordering}
          onOrderingChange={() => {
            if (params.ordering === 'changed_at') {
              updateParams({ ordering: '-changed_at' });
            } else {
              updateParams({ ordering: 'changed_at' });
            }
          }}
        />
        {data && data.pagination.count !== 0 && (
          <Styled1
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={data.pagination.count}
            rowsPerPage={params.page_size}
            onRowsPerPageChange={(event) => {
              const pageSize = toInteger(event.target.value);
              const lastPage = Math.ceil(data.pagination.count / pageSize);

              updateParams({
                page_size: pageSize,
                page: params.page > lastPage ? lastPage : params.page,
              });
            }}
            page={params.page - 1}
            onPageChange={(_, pageNumber) => {
              updateParams({ page: pageNumber + 1 });
            }}
          />
        )}
      </TableWrapper>
    </Wrapper>
  );
}
