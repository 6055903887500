import { User } from '@datadog/browser-core';
import { datadogLogs } from '@datadog/browser-logs';

const urlMatchers = [
  'chrome-extension://npciikilfcggljgdkebakdcccmkdmpik',
  'https://api.segment.io',
  'https://cdn.segment.com',
  'safari-extension://',
];

const errorMatchers = [
  'Load failed',
  'Script error.',
  'ResizeObserver loop limit exceeded',
  'Error sending segment performance metrics', // Segment error
  'Failed to load Analytics.js', // Segment error
  'Fetch error GET chrome-extension://npciikilfcggljgdkebakdcccmkdmpik/icons/logo.png', // Chrome extension error
];

const errorKindMatchers = ['APIError', 'MapboxError'];

initializeLogger();

function getAppInfo() {
  switch (import.meta.env.VITE_APP_NAME) {
    case 'ctms':
      return {
        service: 'carrier-tms-web',
        rumApplicationId: 'c2c27e0f-339f-4c11-889a-2da0684639c6',
      };
    case 'slbd':
      return {
        service: 'loadboard-web',
        rumApplicationId: '902f2dd6-8316-4c1d-8e08-ff6db4c1dcc2',
      };
    case 'slbm':
      return {
        service: 'loadboard-webview',
        rumApplicationId: '57b553f9-dc65-4088-8ce6-2075e17bc19d',
      };

    case 'ctmsw':
      return {
        service: 'carrier-tms-webview',
        rumApplicationId: '7852f5c6-ce07-47ac-901c-5f13779fcae4',
      };
  }
}

function initializeLogger() {
  if ('Cypress' in window || import.meta.env.VITE_APP_TARGET === 'local') {
    return;
  }

  try {
    datadogLogs.init({
      clientToken: 'pubf1b3fd8bf6ce2148e6ce7eb8a7c80b3a',
      site: 'datadoghq.com',
      forwardErrorsToLogs: true,
      sessionSampleRate: 100,
      version: import.meta.env.VITE_APP_RELEASE,
      env: import.meta.env.VITE_APP_TARGET,
      service: getAppInfo().service,
      forwardConsoleLogs: ['error'],
      beforeSend(log) {
        // silence all "Failed to fetch" errors
        if (log.http?.status_code === 0) {
          log.status = 'warn';
          return;
        }

        if (log.origin === 'console') {
          log.status = 'warn';
          return;
        }

        //silence third-party errors
        if (log.error?.stack?.includes('<anonymous>')) {
          log.status = 'warn';
          return;
        }

        //silence logging loading errors of specific URLs
        if (log.http) {
          for (const urlPart of urlMatchers) {
            if (log.http.url.startsWith(urlPart)) {
              log.status = 'warn';
              return;
            }
          }
        }

        //silence logging specific kind of errors
        if (log.error) {
          for (const matcher of errorKindMatchers) {
            if (log.error.kind === matcher) {
              log.status = 'warn';
              return;
            }
          }
        }

        for (const matcher of errorMatchers) {
          if (log.message.includes(matcher)) {
            log.status = 'warn';
            return;
          }
        }

        return undefined;
      },
    });
  } catch (error: unknown) {
    //
  }
}

export async function initializeUserMonitor() {
  if ('Cypress' in window || import.meta.env.VITE_APP_TARGET === 'local') {
    return;
  }

  const { service, rumApplicationId } = getAppInfo();

  return import('@datadog/browser-rum')
    .then(({ datadogRum }) => {
      datadogRum.init({
        service,
        applicationId: rumApplicationId,
        clientToken: 'pubf1b3fd8bf6ce2148e6ce7eb8a7c80b3a',
        site: 'datadoghq.com',
        version: import.meta.env.VITE_APP_RELEASE,
        env: import.meta.env.VITE_APP_TARGET,
        sessionSampleRate: 10,
        sessionReplaySampleRate: 0,
        traceSampleRate: 100,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
        allowedTracingUrls: [
          'http://dashboard.superdispatch.com',
          'https://carrier.superdispatch.com',
          'https://staging.carrier.superdispatch.org',
          'https://internal-web-api.carrier.superdispatch.com',
          'https://staging.internal-web-api.carrier.superdispatch.org',
        ],
      });
    })
    .catch((error) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      logWarning('Failed to load DataDog RUM', error);
    });
}

export function getDatadogLogger() {
  return datadogLogs.logger;
}

export interface LogExceptionOptions {
  extraInfo?: Record<string, unknown>;
}

export function requestForceReload() {
  if (
    // eslint-disable-next-line no-alert
    window.confirm(
      'Failed to load required assets. This can happen either due to bad internet connection or a new version of the website. Reload the page?\n' +
        '(Clear your browser cache and reload the page manually if this error occurs again)',
    )
  ) {
    window.location.reload();
  }
}

export function identifyErrorTrackerUser(user: User) {
  datadogLogs.setUser(user);
}

export function clearErrorTrackerUser() {
  datadogLogs.clearUser();
}

const moduleImportErrorMessages = [
  'failed to fetch dynamically imported module',
  'error loading dynamically imported module',
  'importing a module script failed',
];

const moduleImportErrorMessagesRegExp = new RegExp(
  moduleImportErrorMessages.join('|'),
  'i',
);

export function isModuleImportError(error: unknown) {
  return (
    error instanceof Error &&
    moduleImportErrorMessagesRegExp.test(error.message)
  );
}

export function logError(
  error: unknown,
  namespace: string,
  { extraInfo }: LogExceptionOptions = {},
) {
  const logger = getDatadogLogger();
  const messageContext = {
    namespace,
    extraInfo,
    original_error: error,
  };

  if (typeof error === 'string') {
    logger.error(error, messageContext, new Error(error));
  } else if (error instanceof Error) {
    logger.error(error.message, messageContext, error);
  } else {
    logger.error(
      'Unknown Error',
      messageContext,
      new Error('Unknown Error'), // create inline Error to include stacktrace
    );
  }

  if (isModuleImportError(error)) {
    setTimeout(() => {
      requestForceReload();
    }, 2000);
  }
}

export function logInfo(message: string, extraInfo?: Record<string, unknown>) {
  const logger = getDatadogLogger();
  const messageContext = {
    namespace: extraInfo?.namespace,
    extraInfo,
  };
  logger.info(message, messageContext);
}

export function logWarning(
  message: string,
  extraInfo?: Record<string, unknown>,
) {
  const logger = getDatadogLogger();
  const messageContext = {
    namespace: extraInfo?.namespace,
    extraInfo,
  };
  logger.warn(message, messageContext);
}

export function addLogContext(key: string, value: Record<string, unknown>) {
  getDatadogLogger().addContext(`context.${key}`, value);
}

export function removeLogContext(key: string) {
  getDatadogLogger().removeContext(`context.${key}`);
}
