import { Color, ColorDynamic } from '@superdispatch/ui';
import { createSvgIcon } from 'shared/icons/IconUtils';

export const DeclinedEnvelopIcon = createSvgIcon(
  'DeclinedEnvelopIcon',
  <>
    <path
      d="M66.7073 64.96C67.4773 81.03 68.7773 86.46 96.8873 94.06C118.777 99.96 143.407 100.78 177.547 95.44C204.457 91.25 208.837 63.25 198.567 48.29C183.667 26.61 171.717 37.66 149.637 28.12C133.767 21.27 111.877 12.93 92.7373 37.34C85.2773 46.81 65.7573 44.24 66.7073 64.96Z"
      fill={ColorDynamic.Red50}
    />
    <path
      d="M132.807 16.83L92.8475 44.37C87.7575 47.88 87.7475 55.38 92.8175 58.91L132.337 86.39C134.397 87.82 137.127 87.83 139.187 86.4L179.147 58.86C184.237 55.35 184.247 47.85 179.177 44.32L139.657 16.85C137.597 15.42 134.867 15.41 132.807 16.83Z"
      fill={ColorDynamic.Dark500}
      stroke={Color.Dark450}
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M104.678 98C103.028 98 101.678 96.65 101.678 95V4C101.678 2.35 103.028 1 104.678 1H166.678C168.328 1 169.678 2.35 169.678 4V95C169.678 96.65 168.328 98 166.678 98H104.678Z"
      fill={Color.White}
    />
    <path
      d="M166.678 2C167.778 2 168.678 2.9 168.678 4V95C168.678 96.1 167.778 97 166.678 97H104.678C103.578 97 102.678 96.1 102.678 95V4C102.678 2.9 103.578 2 104.678 2H166.678ZM166.678 0H104.678C102.468 0 100.678 1.79 100.678 4V95C100.678 97.21 102.468 99 104.678 99H166.678C168.888 99 170.678 97.21 170.678 95V4C170.678 1.79 168.878 0 166.678 0Z"
      fill={ColorDynamic.Dark500}
    />
    <path
      d="M179.198 59.3901L139.218 83.2001C137.158 84.7201 134.418 84.7201 132.368 83.1901L92.8176 59.4301C90.2776 57.5301 89.0076 54.5701 89.0176 51.6201V93.3101C89.0176 96.4501 91.5776 99.0001 94.7476 99.0001H177.298C180.458 99.0001 183.028 96.4601 183.028 93.3101V51.6201C183.018 54.5701 181.738 57.5101 179.198 59.3901Z"
      fill={ColorDynamic.White}
      stroke={ColorDynamic.Dark500}
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M182.937 94.0701C183.177 91.8401 181.927 89.5201 179.167 88.0901L139.657 67.5201C137.597 66.4501 134.867 66.4501 132.807 67.5101L92.8473 88.1301C90.2973 89.4401 89.0273 91.5101 89.0273 93.5701"
      fill={ColorDynamic.White}
    />
    <path
      d="M182.937 94.0701C183.177 91.8401 181.927 89.5201 179.167 88.0901L139.657 67.5201C137.597 66.4501 134.867 66.4501 132.807 67.5101L92.8473 88.1301C90.2973 89.4401 89.0273 91.5101 89.0273 93.5701"
      stroke={ColorDynamic.Dark500}
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M153.068 44.81H112.978C112.378 44.81 111.888 44.32 111.888 43.73V41.57C111.888 40.98 112.378 40.49 112.978 40.49H153.068C153.668 40.49 154.158 40.98 154.158 41.57V43.73C154.148 44.33 153.668 44.81 153.068 44.81Z"
      fill={Color.Silver500}
    />
    <path
      d="M138.068 57.81H112.978C112.378 57.81 111.888 57.32 111.888 56.73V54.57C111.888 53.98 112.378 53.49 112.978 53.49H138.068C138.668 53.49 139.158 53.98 139.158 54.57V56.73C139.148 57.33 138.668 57.81 138.068 57.81Z"
      fill={Color.Silver400}
    />
    <path
      d="M136 9.5C142.913 9.5 148.5 15.0875 148.5 22C148.5 28.9125 142.913 34.5 136 34.5C129.087 34.5 123.5 28.9125 123.5 22C123.5 15.0875 129.087 9.5 136 9.5ZM140.487 15.75L136 20.2375L131.512 15.75L129.75 17.5125L134.238 22L129.75 26.4875L131.512 28.25L136 23.7625L140.487 28.25L142.25 26.4875L137.762 22L142.25 17.5125L140.487 15.75Z"
      fill={Color.Red300}
    />
  </>,
  {
    viewBox: '0 0 270 100',
    style: { height: '100px', width: '270px', fill: 'none' },
  },
);
