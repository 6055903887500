import { Typography } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { ColorDynamic, Column, Columns, Stack } from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { startIntercomTour } from 'shared/helpers/Intercom';
import { openExternalURL } from 'shared/helpers/URLHelpers';
import { ArrowRightRoundedIcon } from 'shared/icons/ArrowRightRounded';
import { ChatIcon } from 'shared/icons/ChatIcon';
import { LoadsSidebarIcon } from 'shared/icons/LoadsSidebarIcon';
import styled from 'styled-components';
import { GET_TO_KNOW_CARRIER_TMS_TOUR_ID } from '../data/OnboardingConstants';
import { OnboardingStep } from '../data/OnboardingDTO';
import { useSaveOnboardingWithErrorHandler } from '../data/useSaveOnboardingWithErrorHandler';
import { OnboardingCompletion } from './OnboardingCompletion';
import { OnboardingTaskItem } from './OnboardingTaskItem';

const ArrowBox = styled(Box)`
  width: 70px;
  display: flex;
  justify-content: flex-end;
`;

interface OnboardingTasksProps {
  onClose: () => void;
  completed: boolean;
  onboardingSteps?: OnboardingStep;
}

export function OnboardingTasks({
  onClose,
  completed,
  onboardingSteps,
}: OnboardingTasksProps) {
  if (completed) {
    return <OnboardingCompletion />;
  }

  return (
    <NextExploreSteps onboardingSteps={onboardingSteps} onClose={onClose} />
  );
}

interface NextExploreStepsProps {
  onboardingSteps?: OnboardingStep;
  onClose: () => void;
}

function NextExploreSteps({ onboardingSteps, onClose }: NextExploreStepsProps) {
  const steps = onboardingSteps?.steps;

  const { mutateAsync: saveStep, isLoading } =
    useSaveOnboardingWithErrorHandler();

  const isCreateOrImportLoadCompleted =
    steps?.tour_create_or_import_load?.status === 'started';
  const isGetToKnowCarrierTMSCompleted =
    steps?.get_to_know_ctms?.status === 'started';
  const isSLBSearchTourCompleted = steps?.tour_slb_search?.status === 'started';

  return (
    <Columns align="top" space="small">
      <Column width="content">
        <ArrowBox>
          <ArrowRightRoundedIcon />
        </ArrowBox>
      </Column>
      <Column width="fluid">
        <Stack space="large">
          <Stack space="small">
            <Stack space="xsmall">
              <Typography variant="h3">Next, explore Carrier TMS</Typography>
              <Typography color="textSecondary">
                While waiting for verification, explore the Carrier TMS to
                prepare for requesting loads once you&apos;re approved. You can
                also create or import a load now to start managing your loads
                with Carrier TMS right away.
              </Typography>
            </Stack>

            {!isSLBSearchTourCompleted && (
              <OnboardingTaskItem
                name="Explore Super Loadboard"
                description="Access loads now! More loads unlock once you're verified."
                icon={<Search htmlColor={ColorDynamic.Dark100} />}
                onClick={() => {
                  void saveStep({
                    status: 'started',
                    stepName: 'tour_slb_search',
                  }).then(() => {
                    openExternalURL('/loadboard?launch_slb_search_tour=true');
                  });
                  onClose();
                }}
              />
            )}

            {!isCreateOrImportLoadCompleted && (
              <OnboardingTaskItem
                name="Create or Import Load"
                description="Manage your loads with Carrier TMS and streamline your operations."
                icon={<LoadsSidebarIcon htmlColor={ColorDynamic.Dark100} />}
                onClick={() => {
                  void saveStep({
                    status: 'started',
                    stepName: 'tour_create_or_import_load',
                  }).then(() => {
                    openExternalURL(
                      '/tms/loads?launch_create_import_loads_tour=true',
                    );
                  });
                  onClose();
                }}
              />
            )}
            {!isGetToKnowCarrierTMSCompleted && (
              <OnboardingTaskItem
                name="Get to Know Carrier TMS"
                description="Take a quick tour to master Carrier TMS."
                icon={<ChatIcon />}
                onClick={() => {
                  startIntercomTour(GET_TO_KNOW_CARRIER_TMS_TOUR_ID);
                  void saveStep({
                    status: 'started',
                    stepName: 'get_to_know_ctms',
                  });
                  onClose();
                }}
              />
            )}
          </Stack>
          {steps?.getting_started_guide?.status === 'pending' && (
            <Button
              onClick={() => {
                void saveStep({
                  stepName: 'getting_started_guide',
                  status: 'skipped',
                }).then(onClose);
              }}
              variant="text"
              pending={isLoading}
            >
              Don&apos;t Show Again
            </Button>
          )}
        </Stack>
      </Column>
    </Columns>
  );
}
