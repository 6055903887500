import { Color, ColorDynamic } from '@superdispatch/ui';

export function PendingVerificationIllustration() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="140"
      height="102"
      fill="none"
    >
      <path
        fill={ColorDynamic.Purple50}
        d="M28.945 31C49.472-2.864 85.346-3.812 99.23 22.218c10.889 20.498 21.339 9.154 35.828 30.906 9.956 14.9 5.701 42.905-20.377 47.126-33.085 5.317-20.843-22.406-78.177-1.422C14.26 106.989.339 90.667.013 74.559-.463 51.205 12.673 57.831 28.945 31z"
      />
      <path
        fill={ColorDynamic.Dark500}
        d="M102.853 94.302l-59.217 7.667a3.61 3.61 0 01-4.04-3.12L30.03 24.743a3.613 3.613 0 013.116-4.045l59.212-7.668a3.61 3.61 0 014.04 3.12l9.571 74.107a3.613 3.613 0 01-3.117 4.045z"
      />
      <path
        fill={ColorDynamic.White}
        stroke={ColorDynamic.Dark500}
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M97.442 98H38.558C36.592 98 35 96.382 35 94.385v-74.77C35 17.618 36.592 16 38.558 16h58.884c1.966 0 3.558 1.618 3.558 3.615v74.77C101 96.382 99.403 98 97.442 98z"
      />
      <path
        fill={ColorDynamic.White}
        stroke={ColorDynamic.Dark500}
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M51.387 40.8a3.394 3.394 0 003.386-3.4c0-1.878-1.516-3.4-3.386-3.4A3.393 3.393 0 0048 37.4c0 1.878 1.516 3.4 3.387 3.4z"
      />
      <path
        fill={ColorDynamic.Dark500}
        d="M70.643 39h-9.286c-.196 0-.357-.45-.357-1v-2c0-.55.16-1 .357-1h9.286c.196 0 .357.45.357 1v2c0 .55-.159 1-.357 1z"
      />
      <path
        stroke={Color.Purple300}
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M51.387 57.831a3.394 3.394 0 003.386-3.4c0-1.878-1.516-3.4-3.386-3.4A3.393 3.393 0 0048 54.43c0 1.878 1.516 3.4 3.387 3.4z"
      />
      <path
        fill={Color.Purple300}
        d="M80.286 56H61.714c-.393 0-.714-.45-.714-1v-2c0-.55.321-1 .714-1h18.572c.393 0 .714.45.714 1v2c0 .55-.318 1-.714 1z"
      />
      <path
        fill={ColorDynamic.White}
        stroke={ColorDynamic.Dark500}
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M51.387 74.319a3.394 3.394 0 003.386-3.4c0-1.879-1.516-3.4-3.386-3.4a3.393 3.393 0 00-3.387 3.4c0 1.877 1.516 3.4 3.387 3.4z"
      />
      <path
        fill={ColorDynamic.Dark500}
        d="M79.75 73h-17.5c-.688 0-1.25-.45-1.25-1v-2c0-.55.563-1 1.25-1h17.5c.688 0 1.25.45 1.25 1v2c0 .55-.556 1-1.25 1z"
      />
      <circle
        cx="101"
        cy="30"
        r="28"
        fill={Color.Purple300}
        stroke={ColorDynamic.White}
        strokeWidth="4"
      />
      <path
        stroke={Color.White}
        strokeWidth="1.8"
        d="M91.25 21.667V19a2 2 0 012-2h16a2 2 0 012 2v2.667c0 4-6 4.667-6 8s6 4 6 8V41a2 2 0 01-2 1.999h-16a2 2 0 01-2-2v-3.333c0-4 6-4.667 6-8 0-3.334-6-4-6-8z"
      />
      <path
        fill={Color.White}
        d="M93.917 37.564v1.77a1 1 0 001 1h12.666a1 1 0 001-1v-1.77a1 1 0 00-.764-.971l-5.155-1.25a6.001 6.001 0 00-2.828 0l-5.155 1.25a1 1 0 00-.764.971z"
      />
      <path
        fill={Color.White}
        stroke={Color.White}
        d="M106.036 22.022c-3.489.585-6.056.551-9.482-.002-.34-.055-.543.487-.26.684 2.097 1.452 3.334 3.1 4.696 5.221.12.187.396.185.513-.004 1.299-2.09 2.552-3.696 4.79-5.242.281-.194.081-.714-.257-.657z"
      />
    </svg>
  );
}
