import { useCallback, useEffect, useMemo } from 'react';
import { LocalStore, useLocalStore } from 'shared/helpers/Store';
import { useCarrierSettings } from 'shared/settings/CarrierSettingsAPI';

const ONBOARDING_COMPLETION_STORAGE_KEY = 'onboarding_completion_sent_time';
const ONBOARDING_CLEBRATION_CONFEETTI_STORAGE_KEY =
  'onboarding_celebration_confetti';

export function useOnboardingCompletion() {
  const onboardingCompletion = useLocalStore(ONBOARDING_COMPLETION_STORAGE_KEY);
  const shouldShowConfetti = useLocalStore(
    ONBOARDING_CLEBRATION_CONFEETTI_STORAGE_KEY,
  );

  const { data: settings } = useCarrierSettings();
  const dispatcherGuid = settings?.carrier.guid;

  const parsedOnboardingSentTime = useMemo(() => {
    if (onboardingCompletion) {
      return new Map<string, string>(
        JSON.parse(onboardingCompletion) as Array<[string, string]>,
      );
    }
    return new Map<string, string>();
  }, [onboardingCompletion]);

  const showCelebrationNotification = useCallback(() => {
    if (dispatcherGuid && !parsedOnboardingSentTime.has(dispatcherGuid)) {
      LocalStore.set(ONBOARDING_CLEBRATION_CONFEETTI_STORAGE_KEY, 'true');
      const updatedOnboardingSentTime = new Map(parsedOnboardingSentTime);
      updatedOnboardingSentTime.set(dispatcherGuid, new Date().toISOString());
      LocalStore.set(
        ONBOARDING_COMPLETION_STORAGE_KEY,
        JSON.stringify(Array.from(updatedOnboardingSentTime.entries())),
      );
    }
  }, [dispatcherGuid, parsedOnboardingSentTime]);

  function onCelebrate() {
    LocalStore.set(ONBOARDING_CLEBRATION_CONFEETTI_STORAGE_KEY, 'true');
  }

  function closeCelebration() {
    LocalStore.set(ONBOARDING_CLEBRATION_CONFEETTI_STORAGE_KEY, null);
  }

  useEffect(() => {
    showCelebrationNotification();
  }, [showCelebrationNotification]);

  useEffect(() => {
    if (!shouldShowConfetti) return;

    const celebrationTimeout = setTimeout(() => {
      closeCelebration();
    }, 5000);

    return () => {
      clearTimeout(celebrationTimeout);
    };
  }, [shouldShowConfetti]);

  return {
    onCelebrate,
    closeCelebration,
    shouldShowConfetti,
  };
}
