import { Card, CardContent, Typography } from '@material-ui/core';
import { AttachMoney } from '@material-ui/icons';
import { Inline, Stack, useUID } from '@superdispatch/ui';
import { FormikProvider } from 'formik';
import { omit } from 'lodash-es';
import { useEffect } from 'react';
import { useAppFormik } from 'shared/form/AppFormik';
import { useFormikComposerForm } from 'shared/form/FormikComposer';
import { useFlag } from 'shared/settings/FeatureToggles';
import { LoadPaymentForm } from '../core/LoadPaymentForm';
import { LoadDTO, loadPaymentSchema } from '../data/LoadDTO';
import { useLoadsAPI } from '../data/LoadsAPI';

export interface EditLoadPagePaymentProps {
  load: LoadDTO;
  vehiclesTotalPrice?: number;
  isEnabledEditLoad: boolean;
}

export function EditLoadPagePayment({
  load,
  vehiclesTotalPrice,
  isEnabledEditLoad,
}: EditLoadPagePaymentProps) {
  const uid = useUID();
  const shouldShowPaymentTerms = useFlag('load_payment_terms');
  const { editLoadPayment } = useLoadsAPI();
  const formik = useAppFormik({
    initialValues: {
      ...load.payments[0],
      payment_details: load.payment_details,
    },
    validationSchema: loadPaymentSchema,
    onSubmit(values) {
      const formValues = omit(values, ['paid_at']);
      return editLoadPayment(load.guid, {
        ...formValues,
        receipt_date: values.paid_at,
      });
    },
    getErrorMessage({ message }) {
      return message || 'Something went wrong, contact support.';
    },
  });

  const nodeRef = useFormikComposerForm('payment', formik);

  useEffect(() => {
    if (!shouldShowPaymentTerms) {
      void formik.setFieldValue(
        'payment_details.payment_method',
        load?.payments[0]?.method,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldShowPaymentTerms]);

  return (
    <FormikProvider value={formik}>
      <Card ref={nodeRef} aria-labelledby={uid}>
        <CardContent aria-label="Payment Card">
          <Stack space="small">
            <Inline verticalAlign="center" space="xsmall" noWrap={true}>
              <AttachMoney />
              <Typography variant="h3" id={uid}>
                Payment
              </Typography>
            </Inline>

            <LoadPaymentForm
              vehiclesTotalPrice={vehiclesTotalPrice}
              canPaymentBeEdited={!!load.can_payment_be_edited}
              isEnabledEditLoad={isEnabledEditLoad}
            />
          </Stack>
        </CardContent>
      </Card>
    </FormikProvider>
  );
}
