import { Link, LinkProps } from '@material-ui/core';
import React, { forwardRef, ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import { chooseUTMSource } from 'shared/helpers/Analytics';
import { ShipperProfilePageParams } from './ShipperProfilePageParams';

const isLoadboardMobile = import.meta.env.VITE_APP_NAME === 'slbm';
const isTMSMobile = import.meta.env.VITE_APP_NAME === 'ctmsw';

interface ShipperProfileLinkParams {
  guid: string;
  medium?:
    | 'Load List'
    | 'Load Details'
    | 'Shipper Requirements'
    | 'Shipper Profile'
    | 'Load'
    | 'Load Offer'
    | 'Ratings To Give';
  tab?: ShipperProfilePageParams['tab'];
}

export function getShipperProfileURL({
  guid,
  medium,
  tab,
}: ShipperProfileLinkParams): string {
  const source = chooseUTMSource();
  const params = new URLSearchParams({
    utm_source: source,
  });

  if (medium) {
    params.set('utm_medium', medium);
  }

  if (tab) {
    params.set('tab', tab);
  }

  return `/shipper/${guid}?${params.toString()}`;
}

interface ShipperProfileLinkProps
  extends ShipperProfileLinkParams,
    Omit<LinkProps, 'href' | 'target' | 'rel' | 'to' | 'component'> {
  component?: React.ElementType;
  children: ReactNode;
}

export const ShipperProfileLink = forwardRef<
  HTMLAnchorElement,
  ShipperProfileLinkProps
>(({ guid, medium, tab, children, ...linkProps }, ref) => {
  const url = getShipperProfileURL({ guid, medium, tab });

  if (isLoadboardMobile) {
    return (
      <Link
        {...linkProps}
        color="textPrimary"
        component={NavLink}
        to={url}
        ref={ref}
      >
        {children}
      </Link>
    );
  }

  if (isTMSMobile) {
    return (
      <Link
        {...linkProps}
        href={`/loadboard-mobile${url}`}
        rel="noreferrer"
        ref={ref}
      >
        {children}
      </Link>
    );
  }

  return (
    <Link
      {...linkProps}
      href={`/loadboard${url}`}
      target="_blank"
      rel="noreferrer"
      ref={ref}
    >
      {children}
    </Link>
  );
});

ShipperProfileLink.displayName = 'ShipperProfileLink';
