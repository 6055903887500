import { IconButton, Typography } from '@material-ui/core';
import { Add, Remove } from '@material-ui/icons';
import {
  Color,
  ColorDynamic,
  Column,
  Columns,
  Inline,
  Stack,
  useSnackbarStack,
} from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { useState } from 'react';
import { useClipboard } from 'shared/helpers/DomHelpers';
import { ContentCopyIcon } from 'shared/icons';
import styled from 'styled-components';
import quickbookCertificateImage from '../assets/quickbook_certificate.png';
import quickbookConfirmationImage from '../assets/quickbook_confirmation.png';
import quickbookRunImage from '../assets/quickbook_desktop_run.png';
import quickbookSecurityImage from '../assets/quickbook_security.png';
import quickbookSelectedImage from '../assets/quickbook_selected.png';
import quickbookTimeImage from '../assets/quickbook_time.png';
import quickbookWebConnectorPasswordImage from '../assets/quickbook_web_connector_password.png';
import { useQuickbookDesktopConnectionDetails } from '../data/SettingsAPI';
import { downloadQwcUrl } from '../data/SettingsUtils';

const Image = styled.img`
  object-fit: contain;
`;

const steps = [
  {
    id: 1,
    title:
      'Run your QuickBooks Desktop application. If you have multiple companies, open the QuickBooks Desktop company file that you want to integrate with Super Dispatch.',
    content: <FirstStepContent />,
  },
  {
    id: 2,
    title:
      'Download and open the QuickBooks Web Connector (QWC) file. QWC file allows us to connect your Super Dispatch account with QuickBooks Desktop account.',
    content: <QBDDownload />,
  },
  {
    id: 3,
    title: 'In the security window click OK. ',
    content: <Image width="40%" src={quickbookSecurityImage} />,
  },
  {
    id: 4,
    title:
      'In the next security window, select Yes, whenever this QuickBooks company file is open → Continue. This will give access to transfer your data from Super Dispatch to QuickBooks company file and keep them in sync whenever your company file is open.',
    content: <Image width="40%" src={quickbookCertificateImage} />,
  },
  {
    id: 5,
    title: 'In the access confirmation window, click Done.',
    content: <Image width="40%" src={quickbookConfirmationImage} />,
  },
  {
    id: 6,
    title:
      'In the Web Connector window, enter the 4 digit password and hit enter on your keyboard. Choose to save the password when prompted.',
    content: <QuicbookWebConnector />,
  },

  {
    id: 7,
    title:
      'Then, check the box to the left of the Super Dispatch application and click Update Selected.',
    content: <Image width="60%" src={quickbookSelectedImage} />,
  },
  {
    id: 8,
    title:
      'To send updates to QuickBooks automatically, click the checkbox under Auto-Run. Enter a number of minutes for automatical updates under Every-Min field. For example: if you enter 10, Web Connector will be connecting to Super Dispatch every 10 minutes.',
    content: <Image width="60%" src={quickbookTimeImage} />,
  },
];

export function SettingsQuickbookDestopGuide() {
  return (
    <Stack space="large">
      {steps.map((step) => (
        <Raw
          key={step.id}
          step={step.id}
          title={step.title}
          content={step.content}
        />
      ))}
    </Stack>
  );
}

interface RawProps {
  step: number;
  title: string;
  content: React.ReactNode;
}

const StepNumber = styled(Box)`
  border-radius: 50%;
  padding: 2px 7.5px;
`;

function Raw({ step, title, content }: RawProps) {
  return (
    <Columns space="small">
      <Column width="content">
        <StepNumber backgroundColor="Blue50">
          <Inline horizontalAlign="center">
            <Typography variant="body1" color="primary">
              {step}
            </Typography>
          </Inline>
        </StepNumber>
      </Column>
      <Column width="fluid">
        <Stack space="small">
          <Typography>{title}</Typography>
          {content}
        </Stack>
      </Column>
    </Columns>
  );
}

const BlueDot = styled(Box)`
  border-radius: 50%;
  height: 8px;
  width: 8px;
`;

const PreviewButton = styled(IconButton)`
  padding: 2px;
`;

function FirstStepContent() {
  const [isShow, setShow] = useState(false);

  const handleShow = () => {
    setShow(!isShow);
  };

  return (
    <Box paddingTop="xsmall" paddingBottom="xsmall">
      <Stack>
        <Columns space="small" align="top">
          <Column width="adaptive">
            <PreviewButton onClick={handleShow}>
              {isShow ? (
                <Remove htmlColor={Color.Blue300} />
              ) : (
                <Add htmlColor={Color.Blue300} />
              )}
            </PreviewButton>
          </Column>
          <Column width="fluid">
            <Box paddingTop="xxsmall">
              <Stack space="small">
                <Typography variant="body1">
                  How can I quickly switch between companies?
                </Typography>

                {isShow && (
                  <Stack space="small">
                    <Stack space="xsmall">
                      <Inline verticalAlign="center">
                        <BlueDot backgroundColor="Blue200" />
                        <Typography>
                          Select File → Open Previous Company
                        </Typography>
                      </Inline>
                      <Inline verticalAlign="center">
                        <BlueDot backgroundColor="Blue200" />
                        <Typography>
                          From the list, select the company you want to switch
                          to.
                        </Typography>
                      </Inline>
                      <Typography>
                        The company you selected will now be active.
                      </Typography>
                    </Stack>
                    <Box width="100%">
                      <Image width="100%" src={quickbookRunImage} />
                    </Box>
                  </Stack>
                )}
              </Stack>
            </Box>
          </Column>
        </Columns>
      </Stack>
    </Box>
  );
}

const PreviewPassword = styled(Box)`
  padding-top: 6px;
  padding-bottom: 6px;
`;

function QuicbookWebConnector() {
  const { addSnackbar } = useSnackbarStack();
  const { copy } = useClipboard();
  const { data: desktop } = useQuickbookDesktopConnectionDetails();

  const handleCopy = () => {
    copy(desktop?.password || '');
    addSnackbar('Copied!');
  };
  return (
    <Stack space="small">
      <PreviewPassword
        width="100px"
        borderWidth="small"
        borderRadius="small"
        borderColor="Silver400"
        backgroundColor="Silver100"
      >
        <Inline space="medium" horizontalAlign="center" verticalAlign="center">
          <Typography>{desktop?.password}</Typography>
          <PreviewButton onClick={handleCopy}>
            <ContentCopyIcon
              htmlColor={ColorDynamic.Dark100}
              fontSize="small"
            />
          </PreviewButton>
        </Inline>
      </PreviewPassword>
      <Image width="60%" src={quickbookWebConnectorPasswordImage} />
    </Stack>
  );
}

function QBDDownload() {
  const { data: desktop } = useQuickbookDesktopConnectionDetails();

  const downloadQbd = () => {
    if (desktop?.qwc_url) {
      downloadQwcUrl(desktop.qwc_url);
    }
  };

  return <Button onClick={downloadQbd}>Download QWC File</Button>;
}
