import { useTheme } from '@material-ui/core';
import { renderChildren } from '@superdispatch/ui';
import React from 'react';

interface IconProps {
  light: React.ReactNode;
  dark: React.ReactNode;
}

export function LightDark({ light, dark }: IconProps) {
  const theme = useTheme();
  const isDarkMode = theme.palette.type === 'dark';

  return renderChildren(isDarkMode ? dark : light);
}
