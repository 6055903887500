import { EMPTY_ERROR_MESSAGE } from '@superdispatch/forms';
import { dispatcherSchema } from 'shared/modules/dispatcher/DispatcherDTO';
import { passwordCheckTypes } from 'shared/utils/PasswordUtils';
import {
  yupBoolean,
  yupEnum,
  yupObject,
  yupOmit,
  yupPhone,
  yupString,
} from 'shared/utils/YupUtils';
import { InferType } from 'yup';

export type DispatcherEditDTO = InferType<typeof dispatcherEditSchema>;
export const dispatcherEditSchema = dispatcherSchema.shape({
  guid: yupOmit,
  created_at: yupOmit,
});

export type DispatcherProfilePasswordDTO = InferType<
  typeof dispatcherProfilePasswordSchema
>;
export const dispatcherProfilePasswordSchema = yupObject({
  current: yupString().required(),
  new: yupString()
    .required(EMPTY_ERROR_MESSAGE)
    .min(8, EMPTY_ERROR_MESSAGE)
    .matches(passwordCheckTypes.toLowerCaseAndUpperCase, EMPTY_ERROR_MESSAGE)
    .matches(passwordCheckTypes.toSpecialCharacter, EMPTY_ERROR_MESSAGE)
    .matches(passwordCheckTypes.toNumber, EMPTY_ERROR_MESSAGE),
});

export type DispatcherInvitationDTO = InferType<
  typeof dispatcherInvitationSchema
>;
export const dispatcherInvitationSchema = yupObject({
  carrier_name: yupString(),
  email: yupString().email().required(),
  full_name: yupString(),
  phone_number: yupPhone().required(),
  has_driver_role: yupBoolean(),
  connection_status: yupEnum([
    'pending',
    'activated',
    'declined',
    'deactivated',
  ]),
  is_setup_completed: yupBoolean(),
});

export type DispatcherActivateDTO = InferType<
  typeof dispatcherActivationSchema
>;
export const dispatcherActivationSchema = yupObject({
  email: yupString(),
  full_name: yupString().required(),
  phone_number: yupPhone().required(),
  is_subscribed: yupBoolean(),
  password: yupString()
    .required(EMPTY_ERROR_MESSAGE)
    .min(8, EMPTY_ERROR_MESSAGE)
    .matches(passwordCheckTypes.toLowerCaseAndUpperCase, EMPTY_ERROR_MESSAGE)
    .matches(passwordCheckTypes.toSpecialCharacter, EMPTY_ERROR_MESSAGE)
    .matches(passwordCheckTypes.toNumber, EMPTY_ERROR_MESSAGE),
});

export type InviteDispatcherDTO = InferType<typeof inviteDispatcherDTO>;
export const inviteDispatcherDTO = yupObject({
  email: yupString().email().required(),
  phone_number: yupPhone().required(),
});
