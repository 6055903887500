import { useEventHandler } from '@superdispatch/hooks';
import { renderChildren } from '@superdispatch/ui';
import { DateTime, Duration } from 'luxon';
import { ReactNode, useEffect, useState } from 'react';

export interface CountdownProps {
  children:
    | ReactNode
    | ((timeLeft: Duration | undefined, isElapsed: boolean) => ReactNode);
  onElapsed?: () => void;
  targetDate: DateTime | undefined;
}

export function Countdown({ children, onElapsed, targetDate }: CountdownProps) {
  const [timeLeft, setTimeLeft] = useState<Duration>(
    () => targetDate?.diffNow() ?? Duration.fromObject({}),
  );
  const [isElapsed, setElapsed] = useState<boolean>(false);
  const elapsedHandler = useEventHandler(onElapsed);

  useEffect(() => {
    if (!targetDate) return;

    const interval = setInterval(() => {
      const targetDuration = targetDate.diffNow();
      setTimeLeft(targetDuration);

      const isDurationElapsed = targetDuration.as('seconds') <= 0;
      setElapsed(isDurationElapsed);

      if (isDurationElapsed) {
        elapsedHandler(undefined);
        clearInterval(interval);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [targetDate, elapsedHandler]);

  if (typeof children === 'function') {
    return renderChildren(children(timeLeft, isElapsed) as ReactNode);
  }

  return isElapsed ? renderChildren(children) : null;
}
