import { Card, CardContent } from '@material-ui/core';
import { Column, Columns, Stack, useResponsiveValue } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { QUARTER_OF_A_SECOND } from 'shared/constants/NumberConstants';
import { FormikDrawer } from 'shared/form/FormikDrawer';
import { MutationDialog } from 'shared/form/MutationDialog';
import {
  hideIntercomLauncher,
  showIntercomLauncher,
} from 'shared/helpers/Intercom';
import { useDocumentTitle } from 'shared/layout/DocumentTitle';
import { PageLayout } from 'shared/layout/PageLayout';
import { SuperPayExpeditedPayDialog } from 'shared/modules/superpay/SuperPayExpeditedPayDialog';
import { usePromptNavigate } from 'shared/routing/NavigationBlock';
import { useCarrierSettings } from 'shared/settings/CarrierSettingsAPI';
import { useFlag } from 'shared/settings/FeatureToggles';
import { HiddenForPrint, PrintPage } from 'shared/ui/Print';
import { EditLoadDetailsDrawer } from '../core/EditLoadDetailsDrawer';
import {
  getLoadActionThreeModesVariants,
  LoadActionThreeModes,
} from '../core/LoadActionThreeModes';
import { LoadAddTerminalDrawer } from '../core/LoadAddTerminalDrawer';
import { LoadAssign } from '../core/LoadAssign';
import { LoadExpenseDeleteDialogContent } from '../core/LoadExpenseDeleteDialog';
import {
  AddLoadExpenseDrawerContent,
  EditLoadExpenseDrawerContent,
} from '../core/LoadExpenseDrawer';
import {
  isLoadInstantActionType,
  useLoadInstantAction,
} from '../core/LoadInstantAction';
import { LoadMarkAsPaidDrawer } from '../core/LoadMarkAsPaidDrawer';
import { LoadNoteAddDialog } from '../core/LoadNoteAddDialog';
import { LoadSendBOLDrawer } from '../core/LoadSendBOLDrawer';
import { LoadSendInvoiceDrawer } from '../core/LoadsendInvoiceDrawer';
import { formatLoadStatus, LoadDTO } from '../data/LoadDTO';
import { trackLoadsEvent } from '../data/LoadsAnalytics';
import { useLoadDetails, useLoadsCache } from '../data/LoadsAPI';
import { LoadCancelDialog } from '../loads-page/LoadCancelDialog';
import { LoadsPageLoadAction } from '../loads-page/LoadsPageLoadActions';
import { ViewLoadAction } from './ViewLoadActionGroups';
import { ViewLoadActivity } from './ViewLoadActivity';
import { ViewLoadAttachments } from './ViewLoadAttachments';
import { ViewLoadCustomer } from './ViewLoadCustomer';
import { ViewLoadDelivery } from './ViewLoadDelivery';
import { ViewLoadExpenses } from './ViewLoadExpenses';
import { useLoadStageFromBackURL } from './ViewLoadHeaderActions';
import { ViewLoadInspectionDetails } from './ViewLoadInspectionDetails';
import { ViewLoadInstructions } from './ViewLoadInstructions';
import { ViewLoadInternalNotesLegacy } from './ViewLoadInternalNotesLegacy';
import { ViewLoadMessages } from './ViewLoadMessaging';
import { ViewLoadNotPickedVehicles } from './ViewLoadNotPickedUpVehicles';
import { ViewLoadPageHeader } from './ViewLoadPageHeader';
import { ViewLoadPayment } from './ViewLoadPayment';
import { ViewLoadPickup } from './ViewLoadPickup';
import { ViewLoadRatingBanner } from './ViewLoadRatingBanner';
import { ViewLoadVehicles } from './ViewLoadVehicles';

export function ViewLoadPage() {
  const { loadGUID = '', action, actionGUID } = useParams();
  const { data } = useLoadDetails(loadGUID);
  const navigate = usePromptNavigate();
  const { invalidateLoads } = useLoadsCache();
  const [modalState, setModalState] = useState<'edit'>();
  const [actionType, setActionType] = useState<undefined | ViewLoadAction>();
  const loadStage = useLoadStageFromBackURL();
  const isEditLoad = useFlag('edit_load');
  const isChatEnabled = useFlag('ctms_web_chat');
  const isNotPickedUpVehiclesEnabled = useFlag('not_picked_up_vehicles');
  const isEnabledEditLoad = isEditLoad || !data?.is_created_by_broker;
  const isRestrictRating = useFlag('restrict_rating');
  const { data: carrierSettings } = useCarrierSettings();

  const shouldShowEditExpense = action === 'expense';
  const shouldShowAddExpense = action === 'add-expense';
  const shouldShowDeleteExpense = action === 'delete-expense';
  const isMobile = useResponsiveValue(true, false);

  useDocumentTitle(`${data?.number || 'No Load ID'} - Loads`);

  const changedByBrokerActivities = data?.activities?.filter(
    (item) => item.is_changed_by_broker,
  );

  const { submitInstantAction, isSubmitting } = useLoadInstantAction({
    key: loadGUID,
    onActionComplete: () => {
      invalidateLoads();
    },
  });

  const getModalLoad = (variant: LoadsPageLoadAction): undefined | LoadDTO =>
    actionType !== variant ? undefined : data;

  const getModalLoadGUID = (variant: LoadsPageLoadAction): undefined | string =>
    actionType !== variant ? undefined : loadGUID;

  const closeActionModal = () => {
    setActionType(undefined);
  };

  const closeActionModalAndRefetch = () => {
    closeActionModal();
    invalidateLoads();
  };

  const closeRouteModal = () => {
    navigate(-1);
  };

  useEffect(() => {
    trackLoadsEvent({
      name: 'CTMS: Viewed Load',
      loadGuid: loadGUID,
    });
  }, [loadGUID]);

  useEffect(() => {
    const afterPrint = () => {
      setTimeout(() => {
        trackLoadsEvent({ name: 'CTMS: Pressed Manual Print Load Page' });
        // eslint-disable-next-line no-alert
        window.alert('Please use print button for the better print output.');
      }, QUARTER_OF_A_SECOND);
    };

    window.addEventListener('afterprint', afterPrint);

    return () => {
      window.removeEventListener('afterprint', afterPrint);
    };
  }, []);

  const isSendInvoiceDrawerOpened = getModalLoad('send_invoice_drawer');

  useEffect(() => {
    if (isSendInvoiceDrawerOpened) {
      hideIntercomLauncher();
    } else {
      showIntercomLauncher();
    }
  }, [isSendInvoiceDrawerOpened]);

  return (
    <>
      <LoadNoteAddDialog
        onClose={closeActionModal}
        load={getModalLoad('add_note')}
        onSubmitSuccess={closeActionModalAndRefetch}
      />

      <LoadAssign
        onClose={closeActionModal}
        load={getModalLoad('assign')}
        onSubmitSuccess={({ currentDriver }) => {
          if (currentDriver) {
            trackLoadsEvent({
              name: 'CTMS: Driver Reassigned',
              page: 'view_load',
            });
          } else {
            trackLoadsEvent({
              name: 'CTMS: Driver Assigned',
              page: 'view_load',
            });
          }

          closeActionModalAndRefetch();
        }}
      />

      <LoadSendBOLDrawer
        pageType="view_load"
        onClose={closeActionModal}
        loadGUID={getModalLoadGUID('send_bol')}
        onSubmitSuccess={closeActionModalAndRefetch}
      />

      <LoadMarkAsPaidDrawer
        onClose={closeActionModal}
        load={getModalLoad('mark_as_paid')}
        onSubmitSuccess={() => {
          trackLoadsEvent({ name: 'CTMS: Marked as Paid', page: 'view_load' });
          closeActionModalAndRefetch();
        }}
      />

      <LoadSendInvoiceDrawer
        onClose={closeActionModal}
        load={getModalLoad('send_invoice_drawer')}
      />

      <SuperPayExpeditedPayDialog
        source="Load Details"
        onClose={closeActionModalAndRefetch}
        load={getModalLoad('expedite_payment')}
      />

      {getLoadActionThreeModesVariants().map((variant) => (
        <LoadActionThreeModes
          key={variant}
          variant={variant}
          onClose={closeActionModal}
          load={getModalLoad(variant)}
          onSubmitSuccess={() => {
            switch (variant) {
              case 'delete':
                trackLoadsEvent({
                  name: 'Carrier Deleted Load',
                  page: 'view_load',
                  utm_medium: loadStage,
                  load_status: formatLoadStatus(data?.status),
                  is_created_by_broker: !!data?.is_created_by_broker,
                });
                break;
              case 'archive':
                trackLoadsEvent({
                  name: 'CTMS: Load Archived',
                  page: 'view_load',
                });
                break;
              case 'restore':
                trackLoadsEvent({
                  name: 'CTMS: Restored Load',
                  page: 'view_load',
                });
                break;

              case 'mark_as_picked_up':
                trackLoadsEvent({
                  name: 'CTMS: Marked as Picked Up',
                  page: 'view_load',
                });
                break;

              case 'mark_as_delivered':
                trackLoadsEvent({
                  name: 'CTMS: Marked as Delivered',
                  page: 'view_load',
                });
                break;

              case 'mark_as_new':
                trackLoadsEvent({
                  name: 'CTMS: Marked as New',
                  page: 'view_load',
                });
                break;

              case 'dispatch_to_carrier':
                trackLoadsEvent({
                  name: 'CTMS: Dispatched To Carrier',
                  page: 'view_load',
                });
                break;

              case 'unassign':
                trackLoadsEvent({
                  name: 'CTMS: Driver Unassigned',
                  page: 'view_load',
                });
                break;
            }

            closeActionModalAndRefetch();
          }}
        />
      ))}

      <LoadAddTerminalDrawer
        onClose={closeActionModal}
        load={getModalLoad('add_terminal')}
        onSubmitSuccess={() => {
          trackLoadsEvent({ name: 'CTMS: Terminal Added', page: 'view_load' });
          closeActionModalAndRefetch();
        }}
      />
      <FormikDrawer open={!!shouldShowAddExpense} onClose={closeRouteModal}>
        <AddLoadExpenseDrawerContent
          loadGUID={loadGUID}
          onClose={closeRouteModal}
        />
      </FormikDrawer>

      <FormikDrawer onClose={closeRouteModal} open={!!shouldShowEditExpense}>
        <EditLoadExpenseDrawerContent
          loadGUID={loadGUID}
          onClose={closeRouteModal}
          expenseGUID={actionGUID || ''}
        />
      </FormikDrawer>

      <MutationDialog
        onClose={closeRouteModal}
        open={!!shouldShowDeleteExpense}
      >
        <LoadExpenseDeleteDialogContent
          loadGUID={loadGUID}
          onClose={closeRouteModal}
          expenseGUID={actionGUID || ''}
        />
      </MutationDialog>

      <EditLoadDetailsDrawer
        isEnabledEditLoad={isEnabledEditLoad}
        loadGUID={loadGUID}
        open={modalState === 'edit'}
        onClose={() => {
          setModalState(undefined);
        }}
      />

      <LoadCancelDialog
        onClose={closeActionModalAndRefetch}
        load={getModalLoad('cancel')}
      />

      <PageLayout
        loading={!data || isSubmitting}
        disablePaddings={isMobile}
        header={
          <Stack space="none">
            <ViewLoadPageHeader
              load={data}
              onBackClick={() => {
                invalidateLoads();
                /* History length should be greater than `2` to go back
                because opening an empty tab in the browser already adds 1 to the length */
                if (window.history.length > 2) {
                  navigate(-1);
                } else {
                  navigate('/loads');
                }
              }}
              onEditClick={() => {
                setModalState('edit');
              }}
              onAction={(type) => {
                if (isLoadInstantActionType(type) && data) {
                  submitInstantAction(type, data);
                } else {
                  setActionType(type);
                }
              }}
            />

            {data?.status === 'delivered' &&
              !data.archived &&
              data.is_active &&
              (!isRestrictRating ||
                carrierSettings?.carrier.verification_status === 'approved') &&
              !isMobile && <ViewLoadRatingBanner load={data} />}
          </Stack>
        }
      >
        <PrintPage>
          <Box maxWidth="1296px" margin="auto" padding={['small', 'none']}>
            {data && (
              <Columns space="small" collapseBelow="desktop">
                <Column width={['fluid', '2/3']}>
                  <Stack space="small">
                    <Card>
                      <CardContent>
                        <Columns space="small" collapseBelow="tablet">
                          <Column aria-label="Pickup Details" width="fluid">
                            <ViewLoadPickup load={data} />
                          </Column>
                          <Column aria-label="Delivery Details" width="fluid">
                            <ViewLoadDelivery load={data} />
                          </Column>
                        </Columns>
                      </CardContent>
                      <ViewLoadVehicles
                        load={data}
                        isEnabledEditLoad={isEnabledEditLoad}
                      />

                      {data.not_picked_up_vehicles &&
                        data.not_picked_up_vehicles.length > 0 &&
                        isNotPickedUpVehiclesEnabled && (
                          <ViewLoadNotPickedVehicles
                            notPickedUpVehicles={data.not_picked_up_vehicles}
                          />
                        )}
                    </Card>

                    <ViewLoadInstructions load={data} />

                    <ViewLoadExpenses load={data} />

                    <HiddenForPrint>
                      <ViewLoadInspectionDetails load={data} />
                    </HiddenForPrint>
                  </Stack>
                </Column>
                <Column width="1/3">
                  <Stack space="small">
                    <ViewLoadPayment
                      load={data}
                      isEnabledEditLoad={isEnabledEditLoad}
                    />
                    <ViewLoadCustomer load={data} />

                    <HiddenForPrint>
                      <Stack space="small">
                        <ViewLoadAttachments load={data} />

                        {isChatEnabled && data.is_created_by_broker ? (
                          <ViewLoadMessages load={data} />
                        ) : (
                          <ViewLoadInternalNotesLegacy load={data} />
                        )}
                        <ViewLoadActivity
                          load={data}
                          changedByBrokerActivities={changedByBrokerActivities}
                        />
                      </Stack>
                    </HiddenForPrint>
                  </Stack>
                </Column>
              </Columns>
            )}
          </Box>
        </PrintPage>
      </PageLayout>
    </>
  );
}
