import { Typography } from '@material-ui/core';
import {
  ColorDynamic,
  Column,
  Columns,
  Inline,
  Stack,
  SuperDispatchTheme,
  Tag,
} from '@superdispatch/ui';
import { TextBox } from '@superdispatch/ui-lab';
import {
  formatCurbWeight,
  formatVehicleDimensions,
  hasVehicleDimension,
} from 'shared/helpers/VehicleHelpers';
import { useFlag } from 'shared/settings/FeatureToggles';
import { joinStrings } from 'shared/utils/StringUtils';
import styled, { css } from 'styled-components';
import { OfferVehicleDTO } from '../offers/data/OffersDTO';

const Thumbnail = styled.img(
  ({ theme }: { theme: SuperDispatchTheme }) => css`
    cursor: pointer;
    object-fit: cover;
    border-radius: 3px;
    aspect-ratio: 4 / 3;
    width: 85px;

    transition: ${theme.transitions.create('width')};
  `,
);

const NoVehicle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 25px 7px;
  border-radius: 3px;
  aspect-ratio: 4 / 3;
  width: 88px;
  background: ${ColorDynamic.Silver200};
`;

interface Props {
  vehicle: OfferVehicleDTO;
  onVehicleImageClick: () => void;
}

export function OffersVehicleItem({ vehicle, onVehicleImageClick }: Props) {
  const shouldShowVehiclePhoto = useFlag('load_offer_vehicle_photo');
  return (
    <Columns>
      <Column>
        <Stack space="xsmall">
          <Inline verticalAlign="center">
            <TextBox variant="heading-4">
              {joinStrings(' ', vehicle.year, vehicle.make, vehicle.model)}
            </TextBox>

            {vehicle.is_inoperable && (
              <Tag variant="bold" color="yellow">
                INOP
              </Tag>
            )}

            {vehicle.requires_enclosed_trailer && (
              <Tag variant="bold" color="grey">
                ENCLOSED
              </Tag>
            )}
          </Inline>

          <Stack space="xxsmall">
            {!hasVehicleDimension(vehicle) ? (
              <TextBox color="secondary">Dimensions not available</TextBox>
            ) : (
              <TextBox color="secondary">
                {formatVehicleDimensions(vehicle)}
              </TextBox>
            )}

            {!vehicle.curb_weight ? (
              <TextBox color="secondary">Curb weight not available</TextBox>
            ) : (
              <TextBox color="secondary">
                {formatCurbWeight(
                  vehicle.curb_weight,
                  vehicle.curb_weight_unit,
                )}
              </TextBox>
            )}
          </Stack>
        </Stack>
      </Column>

      {shouldShowVehiclePhoto && (
        <Column width="content">
          {vehicle.sample_photo ? (
            <Thumbnail
              alt=""
              onClick={onVehicleImageClick}
              src={
                vehicle.sample_photo.thumbnail_url ||
                vehicle.sample_photo.photo_url
              }
            />
          ) : (
            <NoVehicle>
              <Typography variant="caption" color="textSecondary">
                NO PHOTO
              </Typography>
            </NoVehicle>
          )}
        </Column>
      )}
    </Columns>
  );
}
