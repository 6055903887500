import { useFormikEnhanced } from '@superdispatch/forms';
import {
  Column,
  Columns,
  useResponsiveValue,
  useSnackbarStack,
} from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { useState } from 'react';
import { updateAuthToken } from 'shared/auth/AuthToken';
import { OffersPageLayout } from '../../core/OffersPageLayout';
import { trackPublicOfferEvent } from '../data/PublicOfferAnalytics';
import {
  AcceptPublicOfferResponseDTO,
  PublicOfferDTO,
} from '../data/PublicOfferDTO';
import { usePublicOfferAPI } from '../data/PublicOffersAPI';
import { PublicOfferDetails } from '../PublicOfferDetails';
import { PublicOfferPageFooter } from '../PublicOfferPageFooter';
import { PublicOfferPageHeader } from '../PublicOfferPageHeader';
import { PublicOfferStepper } from '../PublicOfferStepper';
import { PublicOfferDeclineDialog } from './PublicOfferDeclineDialog';

interface Props {
  offer: PublicOfferDTO;
}

export function PublicOfferPendingPage({ offer }: Props) {
  const { addSnackbar } = useSnackbarStack();
  const [dialog, setDialog] = useState<'decline'>();
  const { acceptPublicOffer } = usePublicOfferAPI();
  const isMobile = useResponsiveValue(true, false);

  const { handleSubmit: acceptOffer, isSubmitting: isAcceptingOffer } =
    useFormikEnhanced({
      initialValues: {},
      onSubmit: () => acceptPublicOffer(offer.guid, !!offer.is_first_offer),
      onSubmitSuccess: (response: AcceptPublicOfferResponseDTO) => {
        if (response.token) {
          updateAuthToken(response.token);
        }
        trackPublicOfferEvent({
          name: 'Accepted Load Offer',
          offer,
          response,
        });
      },
      onSubmitFailure: ({ message }) => {
        addSnackbar(message, { variant: 'error' });
      },
    });

  return (
    <>
      <PublicOfferDeclineDialog
        offer={offer}
        open={dialog === 'decline'}
        onSuccess={() => {
          setDialog(undefined);
        }}
        onClose={() => {
          setDialog(undefined);
        }}
      />

      <PublicOfferPageHeader offer={offer} />

      <OffersPageLayout
        header={<PublicOfferStepper />}
        footer={
          <PublicOfferPageFooter>
            <Columns space="small">
              <Column width="1/2">
                <Button
                  size={isMobile ? 'medium' : 'large'}
                  fullWidth={true}
                  variant="critical"
                  disabled={isAcceptingOffer}
                  onClick={() => {
                    setDialog('decline');
                  }}
                >
                  Decline
                </Button>
              </Column>

              <Column width="1/2">
                <Button
                  size={isMobile ? 'medium' : 'large'}
                  variant="primary"
                  fullWidth={true}
                  pending={isAcceptingOffer}
                  onClick={() => {
                    acceptOffer();
                  }}
                >
                  Accept
                </Button>
              </Column>
            </Columns>
          </PublicOfferPageFooter>
        }
      >
        <PublicOfferDetails offer={offer} />
      </OffersPageLayout>
    </>
  );
}
