import { ColorDark } from '@superdispatch/ui';
import { useMemo } from 'react';
import { FormattedRelativeTimeToNow } from 'shared/ui/FormattedRelativeTimeToNow';
import styled from 'styled-components';
import { getDriverLabel } from '../drivers/data/DriverPageUtils';
import { useAddressPrediction } from './core/AddressPredictions';
import { TrackingDriverDTO } from './data/TrackingDTO';

const Title = styled.h4`
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  color: ${ColorDark.White};
`;

const Caption = styled.p`
  font-size: 13px;
  font-weight: 400;
  color: #8c8c8c;
  margin-top: 5px;
  color: ${ColorDark.White};
`;

const Address = styled.p`
  width: 270px;
  font-size: 16px;
  font-weight: 400;
  min-height: 30px;
  margin-bottom: 0;
  color: ${ColorDark.White};
`;

function useDriverAddress(driver: TrackingDriverDTO, enabled: boolean) {
  const { latitude, longitude } = driver.last_location || {};
  const latLng: mapboxgl.LngLatLike | undefined = useMemo(() => {
    if (latitude != null && longitude != null) {
      return [longitude, latitude];
    }
    return undefined;
  }, [latitude, longitude]);

  return useAddressPrediction(latLng, enabled);
}

interface Props {
  driver: TrackingDriverDTO;
  isOpen: boolean;
}

export const TrackingInfoWindow = ({ driver, isOpen }: Props) => {
  const address = useDriverAddress(driver, isOpen);

  const driverLabel = getDriverLabel({
    name: driver.name || '',
    email: driver.email || '',
  });

  return (
    <div>
      <Title>{driverLabel}</Title>
      <Caption>
        {driver.device_name}
        {driver.last_location?.time && (
          <>
            <br />
            <FormattedRelativeTimeToNow date={driver.last_location.time} />
          </>
        )}
      </Caption>

      <Address>{address || 'Loading...'}</Address>
    </div>
  );
};
