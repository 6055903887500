import { ColorDynamic } from '@superdispatch/ui';
import { createSvgIcon } from 'shared/icons/IconUtils';

export const DeliveryIcon = createSvgIcon(
  'Delivery',
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M0 6.91322L1.19591 5.69559L4.16208 8.73164V0L5.83792 0V8.73164L8.80409 5.69559L10 6.91322L4.99607 12L0 6.91322Z"
    fill={ColorDynamic.Green500}
  />,
  { viewBox: '0 0 10 12', style: { width: 16, height: 16 } },
);
