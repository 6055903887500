import { HTTPEndpointInput, HTTPRequestJSONOptions } from '@superdispatch/http';
import { URITemplateParams } from '@superdispatch/uri';
import {
  APIResponse,
  parseAPIResponse,
  requestJSON,
} from 'shared/api/CarrierAPIClient';
import { CTMS_GATEWAY_HOST } from 'shared/constants/ServerConstants';
import { requestJSONPublic } from './PublicCarrierAPIClient';

export function requestAPIGatewayPublic<
  TData extends APIResponse,
  TParams extends URITemplateParams = URITemplateParams,
>(
  input: HTTPEndpointInput<TParams>,
  options?: Omit<HTTPRequestJSONOptions<TData>, 'baseURL'>,
): Promise<TData> {
  return requestJSONPublic(input, {
    ...options,
    baseURL: CTMS_GATEWAY_HOST,
  }).then((response) => parseAPIResponse(input, response));
}

export function requestAPIGateway<
  TData extends APIResponse,
  TParams extends URITemplateParams = URITemplateParams,
>(
  input: HTTPEndpointInput<TParams>,
  options?: Omit<HTTPRequestJSONOptions<TData>, 'baseURL'>,
): Promise<TData> {
  return requestJSON(input, { ...options, baseURL: CTMS_GATEWAY_HOST }).then(
    (response) => parseAPIResponse(input, response),
  );
}
