import {
  Card,
  CardContent,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { ArrowDropDown, ArrowDropUp, Delete, Info } from '@material-ui/icons';
import { FormattedDate } from '@superdispatch/dates';
import { FormikTextField, useFormikEnhanced } from '@superdispatch/forms';
import {
  Column,
  Columns,
  Inline,
  Stack,
  useSnackbarStack,
} from '@superdispatch/ui';
import { Box, Button, MultilineText, TextBox } from '@superdispatch/ui-lab';
import { Form, FormikProvider } from 'formik';
import { useRef, useState } from 'react';
import { useDispatcherProfile } from 'shared/modules/dispatcher/DispatcherAPI';
import { FormattedRelativeTimeToNow } from 'shared/ui/FormattedRelativeTimeToNow';
import { useAddTripInternalNote } from '../data/TripsAPI';
import {
  internalNoteTextSchema,
  TripDTO,
  TripInternalNoteDTO,
} from '../data/TripsDTO';
import { TripDeleteInternalNoteConfirmationDialog } from './TripDeleteInternalNoteConfirmationDialog';

interface InternalNoteProps extends TripInternalNoteDTO {
  tripGUID: string;
}

function InternalNote({
  author,
  created_at,
  text,
  guid,
  tripGUID,
}: InternalNoteProps) {
  const dispatcher = useDispatcherProfile();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const isCreatedByCurrentDispatcher = dispatcher.data?.name === author.name;

  return (
    <>
      <TripDeleteInternalNoteConfirmationDialog
        open={isDeleteDialogOpen}
        onClose={() => {
          setIsDeleteDialogOpen(false);
        }}
        guid={guid}
        authorName={author.name}
        tripGUID={tripGUID}
      />

      <Columns align="center">
        <Column width="fluid">
          <Inline space="xsmall">
            <Typography variant="body1">{author.name}</Typography>
            {!!created_at && (
              <Tooltip
                enterTouchDelay={0}
                title={<FormattedDate date={created_at} variant="DateTime" />}
                placement="top"
              >
                <TextBox color="secondary">
                  <FormattedRelativeTimeToNow
                    format="DateISO"
                    date={new Date(created_at)}
                  />
                </TextBox>
              </Tooltip>
            )}
          </Inline>
        </Column>

        {isCreatedByCurrentDispatcher && (
          <Column width="content">
            <IconButton
              size="small"
              onClick={() => {
                setIsDeleteDialogOpen(true);
              }}
            >
              <Delete fontSize="small" />
            </IconButton>
          </Column>
        )}
      </Columns>

      <Box backgroundColor="Yellow50" padding="xsmall" borderRadius="small">
        <MultilineText overflowWrap="break-word">{text}</MultilineText>
      </Box>
    </>
  );
}

const MAX_CONTENT_HEIGHT = 150; //in px

interface TripDetailsInternalNotesProps {
  trip: TripDTO | undefined;
}

export function TripDetailsInternalNotes({
  trip,
}: TripDetailsInternalNotesProps) {
  const { addSnackbar } = useSnackbarStack();
  const [isExpanded, setIsExpanded] = useState(false);
  const notesContainer = useRef<HTMLElement>();
  const isExpandable =
    !!notesContainer.current &&
    MAX_CONTENT_HEIGHT < notesContainer.current.scrollHeight;
  const { mutate: addTripInternalNote } = useAddTripInternalNote();

  const formik = useFormikEnhanced({
    initialValues: { text: '' },
    validationSchema: internalNoteTextSchema,
    onSubmit: (values) => {
      addTripInternalNote({
        text: values.text,
        tripGUID: trip?.guid as string,
      });
    },
    onSubmitSuccess: () => {
      formik.resetForm();
    },
    onSubmitFailure: (error) => {
      addSnackbar(
        error.message || 'Failed to add internal note, contact support.',
        { variant: 'error' },
      );
    },
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <Card aria-label="Notes Card">
          <CardContent>
            <Stack space="small">
              <Inline space="xsmall" verticalAlign="center">
                <Typography
                  variant="h3"
                  color="textPrimary"
                  id="internal-note-field"
                >
                  Internal Notes
                </Typography>
                <Tooltip
                  enterTouchDelay={0}
                  title="Visible only to dispatchers "
                >
                  <Info fontSize="small" color="action" />
                </Tooltip>
              </Inline>

              <Stack space="xsmall">
                <FormikTextField
                  name="text"
                  fullWidth={true}
                  multiline={true}
                  minRows={5}
                />

                <Inline horizontalAlign="right">
                  <Button
                    type="submit"
                    disabled={!formik.dirty}
                    pending={formik.isSubmitting}
                  >
                    Add Note
                  </Button>
                </Inline>
              </Stack>

              {!!trip?.internal_notes?.length && (
                <>
                  <Divider />

                  <Box
                    maxHeight={isExpanded ? 'unset' : `${MAX_CONTENT_HEIGHT}px`}
                    overflowY="auto"
                    overflowX="hidden"
                    ref={notesContainer}
                  >
                    <Stack space="small">
                      {trip.internal_notes.map((internalNote, idx) => (
                        <InternalNote
                          key={idx}
                          tripGUID={trip.guid}
                          {...internalNote}
                        />
                      ))}
                    </Stack>
                  </Box>

                  {isExpandable && (
                    <>
                      <Divider />

                      <Button
                        variant="text"
                        fullWidth={true}
                        startIcon={
                          isExpanded ? <ArrowDropUp /> : <ArrowDropDown />
                        }
                        onClick={() => {
                          setIsExpanded(!isExpanded);
                        }}
                      >
                        {isExpanded ? 'Show less' : 'Show more'}
                      </Button>
                    </>
                  )}
                </>
              )}
            </Stack>
          </CardContent>
        </Card>
      </Form>
    </FormikProvider>
  );
}
