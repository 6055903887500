import { Cancel, PowerSettingsNew } from '@material-ui/icons';
import { ColorDynamic, Inline } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { DispatcherPageMutationDialogType } from './DispatchersPageDetailsHeader';

interface DispatcherDetailsMenuButtonProps {
  connectionStatus: 'deactivated' | 'activated';
  onSelect: (modal: DispatcherPageMutationDialogType) => void;
}

export function DispatcherDetailsMenuButton({
  connectionStatus,
  onSelect,
}: DispatcherDetailsMenuButtonProps) {
  if (connectionStatus === 'deactivated') {
    return (
      <Button
        key="delete"
        variant="neutral"
        onClick={() => {
          onSelect('delete');
        }}
      >
        <Inline noWrap={true} space="xxsmall" verticalAlign="center">
          <Cancel fontSize="small" htmlColor={ColorDynamic.Red500} />
          Remove
        </Inline>
      </Button>
    );
  }

  return (
    <Button
      variant="neutral"
      onClick={() => {
        onSelect('deactivate');
      }}
    >
      <Inline noWrap={true} space="xxsmall" verticalAlign="center">
        <PowerSettingsNew fontSize="small" />
        Deactivate
      </Inline>
    </Button>
  );
}
