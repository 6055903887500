import { getBrowser } from './BrowserHelpers';

const CHROME_EXTENSION_PACKAGE_ID = 'npciikilfcggljgdkebakdcccmkdmpik';
const MICROSOFT_EDGE_EXTENSION_ID = 'jkdiocgccpkkjccjdobfmoabbjhjhiac';

export const CHROME_EXTENSION_STORE_URL = `https://chrome.google.com/webstore/detail/super-dispatch-importer/${CHROME_EXTENSION_PACKAGE_ID}`;
export const MICROSOFT_EDGE_EXTENSION_STORE_URL = `https://microsoftedge.microsoft.com/addons/detail/super-dispatch-connectio/${MICROSOFT_EDGE_EXTENSION_ID}`;
export const SAFARI_EXTENSION_STORE_URL =
  'https://apps.apple.com/app/super-dispatch-connection/id6532599506';

export function hasSuperDispatchExtensionInstalled() {
  // Extension adds 'data-extension-loaded' attribute to the document's root element when it initializes
  return document.documentElement.hasAttribute('data-extension-loaded');
}

export function getExtensionStoreURL() {
  const browser = getBrowser();

  return browser === 'safari'
    ? SAFARI_EXTENSION_STORE_URL
    : browser === 'edge'
    ? MICROSOFT_EDGE_EXTENSION_STORE_URL
    : CHROME_EXTENSION_STORE_URL;
}
