import { CUSTOMER_TYPES, VEHICLE_TYPES } from '@superdispatch/sdk';
import { PricingInsightsDTO } from 'shared/modules/pricing-insights/PricingInsightsDTO';
import {
  yupArray,
  yupBoolean,
  yupDateString,
  yupEnum,
  yupFloat,
  yupNumber,
  yupObject,
  yupString,
} from 'shared/utils/YupUtils';
import { InferType } from 'yup';
import { LOAD_INSPECTION_TYPES } from '../../../loads/data/LoadDTO';
import { OFFER_STATUSES } from '../../core/OffersStatusEnum';

export type OfferVehicleDTO = InferType<typeof offerVehiclesSchema>;
const offerVehiclesSchema = yupObject({
  make: yupString(),
  model: yupString(),
  year: yupNumber(),
  type: yupEnum(VEHICLE_TYPES, null),

  is_inoperable: yupBoolean(),
  requires_enclosed_trailer: yupBoolean(),

  curb_weight: yupFloat(),
  curb_weight_unit: yupString(),

  height: yupNumber(),
  length: yupNumber(),
  width: yupNumber(),

  sample_photo: yupObject({
    guid: yupString(),
    photo_url: yupString(),
    thumbnail_url: yupString().nullable(),
  }).nullable(),
});

export type OfferDTO = InferType<typeof offerSchema>;
export const offerSchema = yupObject({
  guid: yupString(),
  created_at: yupDateString('DateTimeISO'),
  is_counter_offer: yupBoolean(),
  bid_price: yupNumber(),
  original_price: yupNumber(),
  load: yupObject({
    load_id: yupString(),
    broker_name: yupString(),
    payment_method_display: yupString(),
    payment_terms_display: yupString(),
    price: yupNumber(),
    price_per_mile: yupNumber(),
    distance_miles: yupNumber(),

    is_inoperable: yupBoolean(),
    requires_enclosed_trailer: yupBoolean(),

    pickup_city: yupString(),
    pickup_state: yupString(),
    pickup_zip: yupString(),
    pickup_date: yupDateString('DateTimeISO'),
    pickup_business_type: yupEnum(CUSTOMER_TYPES, null),

    delivery_city: yupString(),
    delivery_state: yupString(),
    delivery_zip: yupString(),
    delivery_date: yupDateString('DateTimeISO'),
    delivery_business_type: yupEnum(CUSTOMER_TYPES, null),

    vehicles: yupArray(offerVehiclesSchema),
  }).nullable(),
});

export type OfferDetailsDTO = InferType<typeof offerDetailsSchema>;
export const offerDetailsSchema = yupObject({
  guid: yupString(),
  status: yupEnum(OFFER_STATUSES),
  created_at: yupDateString('DateTimeISO'),
  status_changed_at: yupDateString('DateTimeISO'),
  is_counter_offer: yupBoolean(),
  is_price_negotiation: yupBoolean(),
  bid_price: yupNumber(),
  original_price: yupNumber(),

  load: yupObject({
    guid: yupString().nullable(),
    load_id: yupString(),
    inspection_type: yupEnum(LOAD_INSPECTION_TYPES),

    pickup_city: yupString(),
    pickup_state: yupString(),
    pickup_zip: yupString(),
    pickup_date: yupDateString('DateTimeISO'),
    pickup_date_type: yupString(),
    pickup_business_type: yupEnum(CUSTOMER_TYPES, null),

    delivery_city: yupString(),
    delivery_state: yupString(),
    delivery_zip: yupString(),
    delivery_date: yupDateString('DateTimeISO'),
    delivery_date_type: yupString(),
    delivery_business_type: yupEnum(CUSTOMER_TYPES, null),

    shipper_guid: yupString(),
    shipper_name: yupString(),
    shipper_phone: yupString(),
    shipper_profile_url: yupString(),
    shipper_is_verified: yupBoolean().default(null),

    instructions: yupString(),
    terms_and_conditions: yupString(),

    is_inoperable: yupBoolean(),
    requires_enclosed_trailer: yupBoolean(),

    price: yupNumber(),
    price_per_mile: yupNumber(),
    distance_miles: yupNumber(),

    payment_method_display: yupString(),
    payment_terms_display: yupString(),

    vehicles: yupArray(offerVehiclesSchema),
  }),
});

export const offerCountDataSchema = yupObject({
  count: yupNumber(),
  timestamp: yupDateString('DateTimeISO'),
});

export const counterOfferNotificationSchema = yupObject({
  timestamp: yupDateString('DateTimeISO'),
  counter_offer: yupObject({
    guid: yupString(),
    load: yupObject({
      pickup_state: yupString(),
      delivery_state: yupString(),
      price: yupNumber(),
    }),
  }),
});

export function mapOfferLoadToPricingInsights(
  offer: OfferDetailsDTO['load'],
): PricingInsightsDTO {
  return {
    origin: {
      zip: offer.pickup_zip,
      city: offer.pickup_city,
      state: offer.pickup_state,
    },

    destination: {
      zip: offer.delivery_zip,
      city: offer.delivery_city,
      state: offer.delivery_state,
    },

    vehicles: offer.vehicles?.map((vehicle) => ({
      year: vehicle.year,
      type: vehicle.type,
      make: vehicle.make,
      model: vehicle.model,
      is_inoperable: vehicle.is_inoperable,
    })),
  };
}
