import {
  ClickAwayListener,
  Divider,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Email, MoreHoriz, Phone, Print } from '@material-ui/icons';
import { useEventHandler } from '@superdispatch/hooks';
import { SuspendedPhoneLink } from '@superdispatch/phones';
import {
  ColorDynamic,
  Column,
  Columns,
  Inline,
  Stack,
  Tag,
  useResponsiveValue,
} from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AccountOffIcon } from 'shared/icons';
import { NotesIcon } from 'shared/icons/NotesIcon';
import { splitTextByLine } from 'shared/modules/driver/DriverUtils';
import { LinkButton } from 'shared/routing/Links';
import { useCarrierSettings } from 'shared/settings/CarrierSettingsAPI';
import { useFlag } from 'shared/settings/FeatureToggles';
import { HiddenForPrint } from 'shared/ui/Print';
import { parseSearchQuery } from 'shared/utils/URLUtils';
import styled from 'styled-components';
import { getDriverLabel } from '../../drivers/data/DriverPageUtils';
import {
  formatLoadStage,
  formatLoadStatus,
  LoadDTO,
  LoadStage,
} from '../data/LoadDTO';
import { trackLoadsEvent } from '../data/LoadsAnalytics';
import {
  ViewLoadAction,
  ViewLoadActionProps,
  viewLoadActions,
} from './ViewLoadActionGroups';

const ClickableText = styled(Typography)`
  border-bottom: 0.5px dashed ${ColorDynamic.Dark300};
  cursor: pointer;
`;

let LAST_VISITED_LOAD_STAGE: string;
export function useLoadStageFromBackURL() {
  const location = useLocation();

  return useMemo(() => {
    const searchQuery = parseSearchQuery(location.search);
    if (!searchQuery.next) {
      if (LAST_VISITED_LOAD_STAGE) {
        return LAST_VISITED_LOAD_STAGE;
      }
      return '';
    }

    const backURLSearchParams = parseSearchQuery(
      searchQuery.next.substring(searchQuery.next.indexOf('?')),
    );

    const loadStage = backURLSearchParams.stage
      ? backURLSearchParams.stage === 'active'
        ? 'Search Results'
        : formatLoadStage(backURLSearchParams.stage as unknown as LoadStage)
      : 'New';

    LAST_VISITED_LOAD_STAGE = loadStage;

    return loadStage;
  }, [location.search]);
}

function AssignedDriverLabel({ driver }: { driver: LoadDTO['driver'] }) {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const driverLabel = getDriverLabel(driver || {});
  const isDisconnected = driver?.connection_status === 'deactivated';

  return (
    <Inline verticalAlign="center" space="xxsmall">
      Assigned to{' '}
      <ClickAwayListener
        onClickAway={() => {
          setIsTooltipOpen(false);
        }}
      >
        <span>
          <Tooltip
            enterTouchDelay={0}
            title={
              <Stack space="xsmall">
                {driver?.phone_number && (
                  <Inline verticalAlign="center">
                    <Phone fontSize="small" color="inherit" />
                    <SuspendedPhoneLink
                      color="inherit"
                      phone={driver.phone_number}
                      fallback={driver.phone_number}
                      suspenseFallback={<span>{driver.phone_number}</span>}
                    />
                  </Inline>
                )}
                {driver?.email && (
                  <Columns align="center" space="xsmall">
                    <Column width="content">
                      <Email fontSize="small" color="inherit" />
                    </Column>
                    <Column width="fluid">
                      <Link href={`mailto:${driver.email}`} color="inherit">
                        {driver.email}
                      </Link>
                    </Column>
                  </Columns>
                )}
                {driver?.notes && (
                  <Columns space="xxsmall">
                    <Column width="content">
                      <NotesIcon htmlColor={ColorDynamic.White} />
                    </Column>
                    <Column width="fluid">
                      {splitTextByLine(driver.notes)}
                    </Column>
                  </Columns>
                )}
              </Stack>
            }
            onClose={() => {
              setIsTooltipOpen(false);
            }}
            open={isTooltipOpen}
            interactive={true}
            disableFocusListener={true}
            disableHoverListener={true}
            disableTouchListener={true}
          >
            <ClickableText
              display="inline"
              color="textPrimary"
              variant="body1"
              onClick={() => {
                setIsTooltipOpen(true);
              }}
            >
              {driverLabel}
            </ClickableText>
          </Tooltip>
        </span>
      </ClickAwayListener>
      {isDisconnected && (
        <Tag variant="subtle" color="red">
          <Inline space="xxsmall" verticalAlign="center">
            <AccountOffIcon fontSize="small" />
            Deactivated
          </Inline>
        </Tag>
      )}
    </Inline>
  );
}

interface Props {
  load: LoadDTO;
  layout?: 'print';
  onAction?: (action: ViewLoadAction) => void;
}

export function ViewLoadHeaderActions({ load, layout, onAction }: Props) {
  const handleAction = useEventHandler(onAction);
  const { data: settings } = useCarrierSettings();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const loadStage = useLoadStageFromBackURL();
  const isCancelLoadEnabled = useFlag('enable_cancel_load');
  const isExpeditedPayEnabled = useFlag('payments_expedited_pay');
  const isMobile = useResponsiveValue(true, false);

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    function listener(e: KeyboardEvent) {
      if ((e.ctrlKey || e.metaKey) && e.key === 'p') {
        e.cancelBubble = true;
        e.stopPropagation();
        e.preventDefault();

        window.open(
          `/tms/loads/${load.guid}/print`,
          'Print',
          'width=950, height=500, toolbar=0, resizable=0',
        );
      }
    }

    document.addEventListener('keydown', listener);

    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [load]);

  const [primaryAction, menuOptions] = useMemo(() => {
    let nextPrimaryAction: ReactNode = null;
    let nextMenuOptions: ReactNode[] = [];
    let idx = 0;

    for (const { options, isVisible: isVisibleGroup } of viewLoadActions) {
      const menuProps: ViewLoadActionProps = {
        load,
        settings,
        loadStage,
        isCancelLoadEnabled,
      };

      if (isVisibleGroup && !isVisibleGroup(menuProps)) continue;

      const groupOptions: ReactNode[] = [];

      for (const {
        label,
        action,
        isPrimary,
        isVisible,
        isDisabled,
        onClick,
        buttonProps,
        dataIntercomTarget,
      } of options) {
        if (isVisible && !isVisible(menuProps)) continue;

        const disabled = isDisabled?.(menuProps);
        const handleClick = () => {
          onClick?.(menuProps);
          handleCloseMenu();
          if (action) handleAction(action);
        };

        if (isPrimary?.(menuProps) && !isMobile) {
          nextPrimaryAction = (
            <Button
              {...buttonProps?.(menuProps)}
              disabled={disabled}
              onClick={handleClick}
              data-intercom-target={dataIntercomTarget}
            >
              {label}
            </Button>
          );
        } else {
          groupOptions.push(
            <MenuItem
              data-intercom-target={dataIntercomTarget}
              key={idx++}
              disabled={disabled}
              onClick={handleClick}
            >
              {label}
            </MenuItem>,
          );
        }
      }

      if (groupOptions.length) {
        if (nextMenuOptions.length > 0) {
          nextMenuOptions.push(<Divider key={idx++} />);
        }

        nextMenuOptions.push(groupOptions);
      }
    }

    return [nextPrimaryAction, nextMenuOptions];
  }, [load, settings, loadStage, handleAction, isCancelLoadEnabled, isMobile]);

  return (
    <Inline space="large" verticalAlign="center">
      {load.driver && !isMobile && <AssignedDriverLabel driver={load.driver} />}

      {layout !== 'print' && (
        <HiddenForPrint>
          <Inline space="xsmall" verticalAlign="center">
            {primaryAction}

            {isExpeditedPayEnabled &&
              load.payments[0]?.is_expedited_pay_available && (
                <Button
                  variant="neutral"
                  onClick={() => {
                    handleAction('expedite_payment');
                  }}
                >
                  Expedite Payment
                </Button>
              )}

            {load.can_be_edited && !isMobile && (
              <LinkButton
                variant="neutral"
                to={`/loads/${load.guid}/edit`}
                onClick={() => {
                  trackLoadsEvent({
                    name: 'Carrier Clicked Edit Load',
                    page: 'view_load',
                    is_created_by_broker: !!load.is_created_by_broker,
                    load_status: formatLoadStatus(load.status),
                    utm_medium: loadStage,
                  });
                }}
              >
                Edit Load
              </LinkButton>
            )}

            {!!menuOptions.length && (
              <>
                <Button
                  variant="neutral"
                  aria-label="options menu"
                  aria-controls="options-menu"
                  aria-haspopup="true"
                  onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                  }}
                >
                  <MoreHoriz htmlColor={ColorDynamic.Dark300} />
                </Button>
                <Menu
                  id="edit load options"
                  anchorEl={anchorEl}
                  keepMounted={true}
                  open={Boolean(anchorEl)}
                  onClose={handleCloseMenu}
                >
                  {menuOptions}
                </Menu>
              </>
            )}
          </Inline>
        </HiddenForPrint>
      )}

      {layout !== 'print' && !isMobile && (
        <HiddenForPrint>
          <IconButton
            edge="end"
            aria-label="Load Print Button"
            onClick={() => {
              trackLoadsEvent({ name: 'CTMS: Load Print Clicked' });

              window.open(
                `/tms/loads/${load.guid}/print`,
                'Print',
                'width=950, height=500, toolbar=0, resizable=0',
              );
            }}
          >
            <Print color="action" />
          </IconButton>
        </HiddenForPrint>
      )}
    </Inline>
  );
}
