import React, { useEffect, useMemo } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { identifyAnalytics } from 'shared/helpers/Analytics';
import { hideIntercomLauncher } from 'shared/helpers/Intercom';
import { useNullableContext } from 'shared/hooks/useNullableContext';
import { SplashScreen } from 'shared/layout/SplashScreen';
import { useValidateDriverAccountDeletionToken } from './data/DriverAccountDeletionAPI';
import { DriverAccountDeletionDTO } from './data/DriverAccountDeletionDTO';
import { DriverAccountDeletionErrorPage } from './DriverAccountDeletionErrorPage';

interface ContextProps {
  token: string;
  authToken: string;
  driverAccount: DriverAccountDeletionDTO['driver'] | undefined;
}

const Context = React.createContext<ContextProps | null>(null);

export function useDriverAccountDeletionContext() {
  return useNullableContext('DriverAccountDeletion', Context);
}

export function DriverAccountDeletionLayout() {
  const { token = '' } = useParams();

  const { data, isLoading } = useValidateDriverAccountDeletionToken(token, {
    onSuccess: ({ driver }) => {
      identifyAnalytics({
        guid: driver.driver_guid,
      });
    },
    staleTime: Infinity,
    cacheTime: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  const driverAccount = data?.driver;

  useEffect(() => {
    hideIntercomLauncher();
  }, [token]);

  const contextValue = useMemo(
    () => ({
      token,
      authToken: data?.token || '',
      driverAccount,
    }),
    [data?.token, driverAccount, token],
  );

  if (isLoading) {
    return <SplashScreen />;
  }

  if (!driverAccount) {
    return <DriverAccountDeletionErrorPage />;
  }

  return (
    <Context.Provider value={contextValue}>
      <Outlet />
    </Context.Provider>
  );
}
