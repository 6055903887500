import { Card, CardContent, Typography } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { CardButton, Column, Columns, Stack } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { useNavigate } from 'react-router-dom';
import { HiddenForPrint } from 'shared/ui/Print';
import { LoadDTO } from '../../data/LoadDTO';
import { ViewLoadExpenseMobile } from './ViewLoadExpenseMobile';

interface ViewLoadExpensesProps {
  load: LoadDTO;
}

export function ViewLoadExpensesMobile({ load }: ViewLoadExpensesProps) {
  const navigate = useNavigate();

  return !load.expenses?.length ? (
    <HiddenForPrint>
      <CardButton
        startIcon={<Add />}
        onClick={() => {
          navigate(`/loads/${load.guid}/add-expense/`);
        }}
      >
        Add Expenses
      </CardButton>
    </HiddenForPrint>
  ) : (
    <Card aria-label="Expenses Card">
      <CardContent>
        <Stack space="small">
          <Columns align="center">
            <Column width="fluid">
              <Typography variant="h3">Expenses</Typography>
            </Column>
            <Column width="content">
              <Button
                variant="text"
                startIcon={<Add />}
                onClick={() => {
                  navigate(`/loads/${load.guid}/add-expense/`);
                }}
              >
                Add
              </Button>
            </Column>
          </Columns>
          {load.expenses.map((expense) => (
            <ViewLoadExpenseMobile
              load={load}
              expense={expense}
              key={expense.guid}
            />
          ))}
        </Stack>
      </CardContent>
    </Card>
  );
}
