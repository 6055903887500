import { Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { FormikCheckboxField, useFormikEnhanced } from '@superdispatch/forms';
import { Inline, Stack, useSnackbarStack } from '@superdispatch/ui';
import { Box, BoxProps, Button } from '@superdispatch/ui-lab';
import { Form, FormikProvider } from 'formik';
import { useEffect, useMemo } from 'react';
import { APIError } from 'shared/api/APIError';
import { trackEvent } from 'shared/helpers/Analytics';
import { SuperPayIcon } from 'shared/icons/SuperPayLabel';
import {
  useSendSuperPayInvitesShippers,
  useSuperPayInvitesShippers,
} from 'shared/modules/superpay/SuperPayAPI';
import { useCarrierSettingsCache } from 'shared/settings/CarrierSettingsAPI';
import { Image } from 'shared/ui/Image';
import styled from 'styled-components';
import enjoySuperPayImg from './assets/enjoy_superpay.png';
import enjoySuperPayDarkImg from './assets/enjoy_superpay_dark.svg';
export type Source =
  | 'Load List'
  | 'Reports Page Banner'
  | 'SuperPay Settings'
  | 'Load List Banner'
  | string;

const BoxList = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Wrapper = styled(Box)<{ $isWebView?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: ${({ $isWebView }) => ($isWebView ? '100vh' : '100%')};
`;

function getSelectedItems(value: Record<string, boolean>) {
  return Object.keys(value).filter((key) => value[key]);
}

interface SuperPayRequestShipperContentProps {
  onClose: () => void;
  onSubmitSuccess?: (message: string) => void;
  source: Source;
  isSuperPay?: boolean;
  isWebView?: boolean;
}

export function SuperPayRequestShipperContent({
  onClose,
  onSubmitSuccess,
  source,
  isSuperPay,
  isWebView,
}: SuperPayRequestShipperContentProps) {
  const { data, isLoading } = useSuperPayInvitesShippers();
  const { mutateAsync: sendSuperPayInvites } = useSendSuperPayInvitesShippers();
  const { invalidateCarrierSettings } = useCarrierSettingsCache();
  const { addSnackbar } = useSnackbarStack();
  const widthSize: BoxProps['width'] = isWebView ? '100%' : ['auto', '400px'];
  const isOnlyOneShipper = data?.shippers_to_invite.length === 1;
  const topCarrier = data?.shippers_to_invite[0];

  const initialValues = useMemo(() => {
    const values: Record<string, boolean> = {};
    data?.shippers_to_invite.forEach(({ guid }) => {
      values[guid] = true;
    });
    return values;
  }, [data?.shippers_to_invite]);

  const formik = useFormikEnhanced({
    initialValues,
    onSubmit: (values) => {
      const items = getSelectedItems(values);
      return sendSuperPayInvites(items);
    },
    onSubmitSuccess: (_, values) => {
      const items = getSelectedItems(values);
      const isSelectedOne = items.length === 1;
      const message = isSelectedOne
        ? 'Request sent to the shipper.'
        : 'Requests sent to the shippers.';
      invalidateCarrierSettings();
      trackEvent('Carrier Requested SuperPay from Shippers', {
        utm_medium: source,
        shipper_count: items.length,
        payment_is_superpay: isSuperPay,
      });
      onSubmitSuccess?.(message);
      onClose();
    },
    onSubmitFailure: (e) => {
      const { context, message: errorMessage } = e as APIError<{
        shipper_guids: Record<string, []>;
      }>;

      const message =
        (context?.shipper_guids &&
          Object.values(context.shipper_guids).flat()[0]) ||
        errorMessage ||
        '';

      addSnackbar(message, {
        variant: 'error',
      });
    },
  });

  const isSelected = useMemo(() => {
    const items = getSelectedItems(formik.values);
    return !!items.length;
  }, [formik.values]);

  useEffect(() => {
    if (data) {
      trackEvent('Carrier Opened Request SuperPay', {
        utm_medium: source,
        payment_is_superpay: isSuperPay,
        shipper_count: data.shippers_to_invite.length,
      });
    }
  }, [data, isSuperPay, source]);

  if (isLoading) {
    return (
      <Box width={widthSize} padding={['small', 'large']}>
        <Stack space="small">
          <Skeleton width="70%" height="22px" />
          <Stack space="xxsmall">
            <Skeleton width="100%" height="16px" />
            <Skeleton width="50%" height="16px" />
            <Skeleton width="70%" height="16px" />
          </Stack>
        </Stack>
      </Box>
    );
  }

  return (
    <FormikProvider value={formik}>
      <Form>
        <Wrapper $isWebView={isWebView}>
          <Box
            width={widthSize}
            padding={['small', 'large']}
            paddingBottom="none"
          >
            <Stack space="medium" align="center">
              <Box width={['142px', '172px']} height={['106px', '128px']}>
                <Image
                  src={enjoySuperPayImg}
                  alt="Enjoying SuperPay"
                  width="100%"
                  height="100%"
                  srcDark={enjoySuperPayDarkImg}
                />
              </Box>

              <Stack align="center">
                {data?.shippers_to_invite.length ? (
                  <>
                    <Inline verticalAlign="bottom" space="xxsmall">
                      <Typography variant="h3">Enjoying</Typography>
                      <SuperPayIcon height={20} verticalAlign="sub" />
                      <Typography variant="h3">?</Typography>
                    </Inline>
                    {isOnlyOneShipper ? (
                      <Typography align="center">
                        Send SuperPay request to <b>{topCarrier?.name}.</b> You
                        have delivered <b>{topCarrier?.loads_count} loads</b>{' '}
                        for them in the last 30 days.
                      </Typography>
                    ) : (
                      <Typography align="center">
                        Request SuperPay from Shippers you work with the most.
                      </Typography>
                    )}
                  </>
                ) : (
                  <>
                    <Typography variant="h3">Already Requested</Typography>
                    <Typography align="center">
                      It appears there are no more shippers left to send
                      SuperPay requests to.
                    </Typography>
                  </>
                )}
              </Stack>

              {!isOnlyOneShipper && !!data?.shippers_to_invite.length && (
                <Stack space="xxsmall">
                  <BoxList>
                    <Typography variant="body1">Company Name</Typography>
                    <Typography variant="body1">
                      Loads Delivered / 30d
                    </Typography>
                  </BoxList>
                  <Box>
                    {data.shippers_to_invite.map(
                      ({ name, guid, loads_count }) => (
                        <BoxList key={guid}>
                          <FormikCheckboxField label={name} name={guid} />
                          <Typography variant="body2" color="textSecondary">
                            {loads_count}
                          </Typography>
                        </BoxList>
                      ),
                    )}
                  </Box>
                </Stack>
              )}
            </Stack>
          </Box>
          <Box
            position="sticky"
            bottom={0}
            padding={['small', 'large']}
            paddingTop="small"
            backgroundColor="White"
            width={widthSize}
          >
            <Stack align="center">
              {data?.shippers_to_invite.length ? (
                <>
                  <Button
                    size="large"
                    pending={formik.isSubmitting}
                    disabled={!isSelected}
                    fullWidth={true}
                    onClick={() => {
                      formik.handleSubmit();
                    }}
                  >
                    Send Requests
                  </Button>
                  <Button
                    size="large"
                    fullWidth={true}
                    variant="text"
                    onClick={() => {
                      trackEvent(
                        'Carrier Clicked Ill Do it Later in SuperPay Request',
                        {
                          utm_medium: source,
                          shipper_count: data.shippers_to_invite.length,
                          payment_is_superpay: isSuperPay,
                        },
                      );
                      onClose();
                    }}
                  >
                    I’ll Do it Later
                  </Button>
                </>
              ) : (
                <Button
                  size="large"
                  fullWidth={true}
                  onClick={() => {
                    onClose();
                  }}
                >
                  Got It
                </Button>
              )}
            </Stack>
          </Box>
        </Wrapper>
      </Form>
    </FormikProvider>
  );
}
