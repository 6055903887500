import { formatPaymentMethod, formatPaymentTerm } from '@superdispatch/sdk';
import { SuperPayLabel } from 'shared/helpers/PaymentHelpers';
import { InlineBulletItems } from './InlineBulletItems';

interface PaymentInfoProps {
  method?: string;
  terms?: string;
  superPayLabelTarget?: '_blank' | '_self';
  source?:
    | 'Load Details'
    | 'Load List'
    | 'Suggested Load'
    | 'Load Offer Details'
    | 'Public Load Offer Details'
    | 'Load Offer';
}

export function PaymentInfo({
  method,
  terms,
  source,
  superPayLabelTarget,
}: PaymentInfoProps) {
  // Some APIs return method in formatted form
  if (method === 'superpay' || method === 'SuperPay') {
    return (
      <InlineBulletItems verticalAlign="center">
        <SuperPayLabel source={source} target={superPayLabelTarget} />
        {formatPaymentTerm(terms, { fallback: terms })}
      </InlineBulletItems>
    );
  }

  return (
    <InlineBulletItems>
      {method && (
        <span>{formatPaymentMethod(method, { fallback: method })}</span>
      )}
      <span>{formatPaymentTerm(terms, { fallback: terms, short: true })}</span>
    </InlineBulletItems>
  );
}
