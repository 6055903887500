import {
  CircularProgress,
  Drawer,
  IconButton,
  InputAdornment,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import { Clear, Close, Edit, Remove, Search } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import {
  ColorDynamic,
  DrawerContent,
  DrawerList,
  DrawerTitle,
  Inline,
  OverflowText,
  Stack,
  Tag,
  useSnackbarStack,
  VisibilityObserver,
} from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { useEffect, useState } from 'react';
import { useAppFormik } from 'shared/form/AppFormik';
import { useDebouncedValue } from 'shared/helpers/ReactHelpers';
import { useBoolean } from 'shared/hooks/useBoolean';
import { DriveawayIcon } from 'shared/icons/DriveawayIcon';
import { toSearchQueryText } from 'shared/utils/TextUtils';
import styled from 'styled-components';
import { useDriverList } from '../../drivers/data/DriversAPI';
import { trackTripsEvent } from '../data/TripsAnalytics';
import { useTripsAPI, useTripsCache } from '../data/TripsAPI';
import { assignDriverSchema, TripDriverDTO, TripDTO } from '../data/TripsDTO';
import { TripDriverItem } from './TripDriverItem';
import { TripUnassignDriverDialog } from './TripUnassignDriverDialog';

interface TripDetailsAssignDriverProps {
  trip: TripDTO;
}

const EditIconButton = styled(IconButton)`
  padding: 2px;
`;

const DriverNameWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const TriverTag = styled(Tag)`
  flex-shrink: 0;
`;

export function TripDetailsAssignDriver({
  trip,
}: TripDetailsAssignDriverProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { addSnackbar } = useSnackbarStack();
  const [searchText, setSearchText] = useState('');
  const assignDriverDrawer = useBoolean();
  const unassignDriverDrawer = useBoolean();
  const query = useDebouncedValue(searchText, 300);
  const { invalidateTrip, invalidateTrips } = useTripsCache();

  const drivers = useDriverList(
    { exclude_suspended: true, q: toSearchQueryText(query) },
    { enabled: !!assignDriverDrawer.isEnabled },
  );

  const { assignDriver } = useTripsAPI();

  const currentDriver = trip.driver;

  const { values, handleSubmit, isSubmitting, setFieldValue } = useAppFormik({
    initialValues: {
      newDriverGUID: '',
      newDriverName: '',
    },
    validationSchema: assignDriverSchema,
    onSubmit: ({ newDriverGUID }) => {
      return assignDriver(trip.guid, newDriverGUID);
    },
    onSubmitSuccess: () => {
      if (trip.driver?.guid) {
        trackTripsEvent({
          name: 'Carrier Reassigned to Driver',
          trip_guid: trip.guid,
        });
      } else {
        trackTripsEvent({
          name: 'Carrier Assigned to Driver',
          trip_guid: trip.guid,
        });
      }
      addSnackbar(`Trip assigned to ${values.newDriverName}`, {
        variant: 'success',
      });
      assignDriverDrawer.setFalse();
      invalidateTrip(trip.guid);
      invalidateTrips();
    },
    onSubmitFailure: ({ message }) => {
      addSnackbar(message, { variant: 'error' });
    },
  });

  useEffect(() => {
    if (values.newDriverGUID) {
      handleSubmit();
    }
  }, [handleSubmit, values.newDriverGUID]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box width="100%">
      {currentDriver?.guid ? (
        <Stack>
          <DriverNameWrapper>
            {!!currentDriver.name && (
              <OverflowText variant="h3">{currentDriver.name}</OverflowText>
            )}
            <TriverTag variant="subtle" color="grey">
              <Inline space="xxsmall" verticalAlign="center" noWrap={true}>
                <DriveawayIcon />
                <Typography variant="body1" color="textSecondary">
                  Driver
                </Typography>
              </Inline>
            </TriverTag>
            <EditIconButton
              aria-controls="driver-assignment-menu"
              aria-haspopup="true"
              onClick={handleClick}
            >
              <Edit />
            </EditIconButton>
            <Menu
              id="driver-assignment-menu"
              anchorEl={anchorEl}
              keepMounted={true}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem
                onClick={() => {
                  assignDriverDrawer.setTrue();
                  handleClose();
                }}
              >
                <Inline space="xsmall" verticalAlign="center">
                  <Edit htmlColor={ColorDynamic.Dark100} />
                  <Typography>Reassign</Typography>
                </Inline>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  unassignDriverDrawer.setTrue();
                  handleClose();
                }}
              >
                <Inline space="xsmall" verticalAlign="center">
                  <Remove htmlColor={ColorDynamic.Dark100} />
                  <Typography>Unassign</Typography>
                </Inline>
              </MenuItem>
            </Menu>
          </DriverNameWrapper>

          <Typography color="textSecondary">
            {currentDriver.email}{' '}
            {currentDriver.phone ? `· ${currentDriver.phone}` : ''}
          </Typography>
        </Stack>
      ) : (
        <Button fullWidth={true} onClick={assignDriverDrawer.setTrue}>
          Assign Driver
        </Button>
      )}

      <Drawer
        open={assignDriverDrawer.isEnabled}
        onClose={assignDriverDrawer.setFalse}
        SlideProps={{
          onExited: () => {
            setSearchText('');
          },
        }}
      >
        <DrawerTitle
          title={getTitle(trip.driver)}
          endAction={
            <IconButton
              edge="end"
              aria-label="close"
              onClick={assignDriverDrawer.setFalse}
              disabled={isSubmitting}
            >
              <Close />
            </IconButton>
          }
        />

        <DrawerContent>
          <Stack space="medium">
            <TextField
              autoFocus={true}
              fullWidth={true}
              placeholder="Enter name to search a driver"
              value={searchText}
              disabled={isSubmitting}
              onChange={(event) => {
                setSearchText(event.target.value);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search color="action" />
                  </InputAdornment>
                ),
                endAdornment: !!searchText && (
                  <InputAdornment position="end">
                    {drivers.isFetching ? (
                      <CircularProgress size="1em" />
                    ) : (
                      <IconButton
                        aria-label="clear"
                        onClick={() => {
                          setSearchText('');
                        }}
                      >
                        <Clear />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
        </DrawerContent>
        <DrawerList>
          {!drivers.data ? (
            <ListItem>
              <ListItemText
                disableTypography={true}
                primary={<Skeleton />}
                secondary={<Skeleton />}
              />
            </ListItem>
          ) : !drivers.data.pages[0]?.pagination.count ? (
            <ListItem>
              <ListItemText
                disableTypography={true}
                secondary={
                  <Typography align="center" color="textSecondary">
                    No results
                  </Typography>
                }
              />
            </ListItem>
          ) : (
            <>
              {drivers.data.pages.flatMap((page) =>
                page.data.map((driver) => (
                  <TripDriverItem
                    driver={driver}
                    key={driver.guid}
                    selectedGUID={values.newDriverGUID}
                    isSubmitting={isSubmitting}
                    isAssigned={driver.guid === trip.driver?.guid}
                    onClick={() => {
                      void setFieldValue('newDriverGUID', driver.guid);
                      void setFieldValue(
                        'newDriverName',
                        driver.name || driver.email,
                      );
                    }}
                  />
                )),
              )}

              {drivers.hasNextPage && (
                <VisibilityObserver
                  threshold={0.01}
                  onChange={(visibility) => {
                    if (
                      !drivers.isFetchingNextPage &&
                      visibility === 'visible'
                    ) {
                      void drivers.fetchNextPage();
                    }
                  }}
                  render={({ ref }) => (
                    <ListItem ref={ref}>
                      <ListItemText
                        disableTypography={true}
                        primary={<Skeleton />}
                        secondary={<Skeleton />}
                      />
                    </ListItem>
                  )}
                />
              )}
            </>
          )}
        </DrawerList>
      </Drawer>

      <TripUnassignDriverDialog
        open={unassignDriverDrawer.isEnabled}
        onClose={unassignDriverDrawer.setFalse}
        trip={trip}
        currentDriver={currentDriver}
      />
    </Box>
  );
}

function getTitle(driver: TripDriverDTO | null | undefined) {
  if (driver?.guid) {
    return 'Reassign to Driver';
  }
  return 'Assign to Driver';
}
