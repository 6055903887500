import { alpha, Typography } from '@material-ui/core';
import { Color, ColorDynamic } from '@superdispatch/ui';
import React from 'react';
import styled from 'styled-components';

export const SidebarMenuContainer = styled.ul`
  list-style: none;
  padding: 0;
`;

export const SidebarMenuItem = styled.li(({ theme }) => {
  const mode = theme.palette.type;
  const color =
    mode === 'dark' ? alpha(Color.White, 0.08) : ColorDynamic.Silver200;
  return `
  display: flex;
  cursor: pointer;
  padding: 8px 16px;
  color: ${ColorDynamic.Dark500};
  min-height: 36px;
  transition: background-color 255ms, color 225ms;

  &:hover,
  &:focus {
    color: ${ColorDynamic.Blue500};
    background-color: ${color};
  }
  
  &[aria-selected='true'] {
    &,
    &:hover,
    &:focus {
      padding-left: 12px;
      border-left: 4px solid ${ColorDynamic.Blue300};
      color: ${ColorDynamic.Blue500};
      background-color: ${color};
    }
  }
`;
});

export interface LoadboardDesktopSidebarProps {
  sidebarMenu: React.ReactNode;
}

const Styled1 = styled(Typography)`
  margin: 20px 24px;
`;

export function LoadboardDesktopSidebar({
  sidebarMenu,
}: LoadboardDesktopSidebarProps) {
  return (
    <div>
      <Styled1 variant="h2">Super Loadboard</Styled1>
      {sidebarMenu}
    </div>
  );
}
