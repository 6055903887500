import { useDeepEqualValue } from '@superdispatch/hooks';
import { renderChildren } from '@superdispatch/ui';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { clearAuthToken, saveAuthRole } from 'shared/auth/AuthToken';
import { useVerification } from 'shared/auth/core/VerificationStorage';
import { useDriverAccount } from 'shared/auth/data/useDriverAccount';
import {
  listenToServerError,
  ServerError,
} from 'shared/errors/ServerErrorEvents';
import { clearErrorTrackerUser } from 'shared/helpers/ErrorTracker';
import { openExternalURL } from 'shared/helpers/URLHelpers';
import { CarrierVerificationExperiment } from 'shared/modules/experiments/signup-experiment/CarrierVerificationExperimentPage';
import { useSignupExperiment } from 'shared/modules/experiments/signup-experiment/useSignupExperiment';
import { useSubscriptionCelebrationState } from 'shared/modules/subscription/core/useSubscriptionCelebrationState';
import { RedirectToLogin } from 'shared/routing/RedirectToLogin';
import { LoadboardMobileCarrierConnectionDialog } from '../../loadboard/webview/LoadboardMobileCarrierConnectionErrorDialog';
import { useShowSubscriptionCancelledDialog } from './data/useShowSubscriptionCancelledDialog';
import { useShowTrialExpiredDialog } from './data/useShowTrialExpiredDialog';
interface Props {
  children?: ReactNode;
  subscribe?: Array<ServerError['type']>;
}

export function ServerErrorBoundary({
  children,
  subscribe: subscribeProp = [
    'Unauthorized',
    'AUTH_ERROR',
    'CARRIER_CONNECTION_ERROR',
    'AuthenticationError',
    'trial_expired',
    'payment_past_due',
    'subscription_cancelled',
  ],
}: Props) {
  const [serverError, setServerError] = useState<ServerError>();
  const subscribe = useDeepEqualValue(subscribeProp);
  const location = useLocation();
  const { shouldRunExperiment } = useSignupExperiment();
  const { setOpenTrialExpiredDialog } = useShowTrialExpiredDialog();
  const { removeVerification } = useVerification();
  const { removeDriverToken } = useDriverAccount();
  const { removeTrialExpiredDialog } = useShowTrialExpiredDialog();
  const { removeSubscriptionCancelledDialog } =
    useShowSubscriptionCancelledDialog();
  const { removeStatuses } = useSubscriptionCelebrationState();
  const {
    setOpenSubscriptionCancelledDialog,
    closeSubscriptionCancelledDialog,
  } = useShowSubscriptionCancelledDialog();

  const clearStorage = useCallback(() => {
    clearErrorTrackerUser();
    clearAuthToken();
    saveAuthRole(null);
    removeVerification();
    removeTrialExpiredDialog();
    removeSubscriptionCancelledDialog();
    removeDriverToken();
    removeStatuses();
  }, [
    removeVerification,
    removeTrialExpiredDialog,
    removeSubscriptionCancelledDialog,
    removeDriverToken,
    removeStatuses,
  ]);

  useEffect(
    () =>
      listenToServerError((nextServerError) => {
        if (!subscribe.length || subscribe.includes(nextServerError.type)) {
          setServerError(nextServerError);
        }

        if (
          nextServerError.type === 'Unauthorized' ||
          nextServerError.type === 'AuthenticationError' ||
          nextServerError.type === 'AUTH_ERROR'
        ) {
          clearStorage();
        }
      }),
    [subscribe, clearStorage],
  );

  // pathname starts with `/loads` in both slb and ctms so, this condition covers both
  if (location.pathname.startsWith('/loads') && shouldRunExperiment) {
    return <CarrierVerificationExperiment />;
  }

  switch (serverError?.type) {
    case 'trial_expired': {
      setOpenTrialExpiredDialog();
      closeSubscriptionCancelledDialog();

      if (shouldRunExperiment) {
        return <CarrierVerificationExperiment />;
      }

      return renderChildren(children);
    }

    case 'payment_past_due': {
      openExternalURL('/loadboard');
      return renderChildren(children);
    }

    case 'subscription_cancelled': {
      setOpenSubscriptionCancelledDialog('cancelled');

      return renderChildren(children);
    }

    case 'Unauthorized':
    case 'AuthenticationError':
    case 'AUTH_ERROR': {
      return <RedirectToLogin />;
    }

    case 'CARRIER_CONNECTION_ERROR': {
      return <LoadboardMobileCarrierConnectionDialog />;
    }

    default:
      return renderChildren(children);
  }
}
