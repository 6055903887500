import { ColorDynamic } from '@superdispatch/ui';
import { createSvgIcon } from 'shared/icons/IconUtils';

export const FileDownloadIcon = createSvgIcon(
  'FileDownloadIcon',
  <path
    d="M18 21L22 17L20.6 15.6L19 17.2V13.025H17V17.2L15.4 15.6L14 17L18 21ZM14 24V22H22V24H14ZM6 20C5.45 20 4.97917 19.8042 4.5875 19.4125C4.19583 19.0208 4 18.55 4 18V4C4 3.45 4.19583 2.97917 4.5875 2.5875C4.97917 2.19583 5.45 2 6 2H13L19 8V11.025H17V9H12V4H6V18H12V20H6Z"
    fill="currentColor"
  />,
  { style: { color: ColorDynamic.Dark100 } },
);
