import {
  ButtonBase,
  IconButton,
  Menu,
  MenuItem,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import { MoreHoriz } from '@material-ui/icons';
import { FormattedDate } from '@superdispatch/dates';
import { Color, ColorDynamic, Stack, Tag } from '@superdispatch/ui';
import { Box, TextBox } from '@superdispatch/ui-lab';
import React, { forwardRef, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { formatCurrency, formatPlural } from 'shared/helpers/IntlHelpers';
import { DragIcon } from 'shared/icons/DragIcon';
import { joinStrings } from 'shared/utils/StringUtils';
import styled from 'styled-components';
import { formatLoadStage } from '../../loads/data/LoadDTO';
import { TripLoadDTO } from '../data/TripsDTO';

const StyledRow = styled(TableRow)`
  display: flex;
  background-color: ${ColorDynamic.White};
  min-height: 93px; /* height of a row when there is no remove&edit buttons */
`;

const LoadIdWrapper = styled(Box)`
  word-break: break-all;
`;

export const StyledCell = styled(TableCell)`
  min-width: 10.6%;
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &:first-child {
    min-width: 2%;
    max-width: 2%;
  }

  &:last-child {
    flex: 1;
    min-width: 4%;
    align-items: center;
  }
`;

const LinkButton = styled(ButtonBase)`
  border-bottom: 1px solid ${ColorDynamic.Silver500};
  text-align: left;
`;

const ActionButton = styled(IconButton)`
  width: 50%;
  padding: 4px 8px;
`;

interface TripLoadsTableRowProps {
  load: TripLoadDTO;
  isTripArchived: boolean;
  style?: React.CSSProperties;
  path: string;
}

export const TripLoadsTableRow = forwardRef<
  HTMLTableRowElement,
  TripLoadsTableRowProps
>(({ load, isTripArchived, path: pathname, ...props }, ref) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const {
    guid,
    number: loadId,
    status,
    vehicles,
    pickup,
    delivery,
    customer_name: customerName,
    payment,
    broker_fee,
    terminals,
  } = load;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const currentTerminal = useMemo(
    () => terminals.find((terminal) => terminal.is_current),
    [terminals],
  );

  return (
    <StyledRow {...props} ref={ref}>
      <StyledCell>
        <DragIcon />
      </StyledCell>
      <StyledCell>
        <LoadIdWrapper>
          <LinkButton
            onClick={() => {
              navigate(`/loads/${guid}`);
            }}
          >
            {loadId ? loadId : "Load doesn't have ID"}
          </LinkButton>
        </LoadIdWrapper>
      </StyledCell>
      <StyledCell>
        <Box>
          {currentTerminal ? (
            <Tag color="purple" variant="bold" aria-label="Terminal">
              In {currentTerminal.name}
            </Tag>
          ) : (
            <Tag
              aria-label="status tag"
              variant="subtle"
              color={
                status === 'new'
                  ? 'teal'
                  : status === 'picked_up'
                  ? 'yellow'
                  : 'green'
              }
            >
              {formatLoadStage(status)}
            </Tag>
          )}
        </Box>
      </StyledCell>
      <StyledCell>
        {vehicles?.map(({ year, maker, model }, i) =>
          formatPlural(
            vehicles.length - i,
            <Typography key={i}>
              {joinStrings(' ', year, maker, model)}
            </Typography>,
            <Typography key={i}>
              {joinStrings(' ', year, maker, model)},
            </Typography>,
          ),
        )}
      </StyledCell>
      <StyledCell>
        <Typography>{pickup.address || 'Not available'}</Typography>
      </StyledCell>
      <StyledCell>
        <Typography>{delivery.address || 'Not available'}</Typography>
      </StyledCell>
      <StyledCell>
        {!pickup.date && pickup.scheduled_date && (
          <Stack space="none">
            <TextBox variant="caption" color="secondary">
              Scheduled for{' '}
            </TextBox>
            <Typography>
              <FormattedDate variant="Date" date={pickup.scheduled_date} />
            </Typography>
          </Stack>
        )}
        {pickup.date && <FormattedDate variant="Date" date={pickup.date} />}
      </StyledCell>
      <StyledCell>
        {!delivery.date && delivery.scheduled_date && (
          <Stack space="none">
            <TextBox variant="caption" color="secondary">
              Scheduled for{' '}
            </TextBox>
            <Typography>
              <FormattedDate variant="Date" date={delivery.scheduled_date} />
            </Typography>
          </Stack>
        )}
        {delivery.date && <FormattedDate variant="Date" date={delivery.date} />}
      </StyledCell>
      <StyledCell align="right">
        <Stack space="xxsmall">
          {customerName}
          <Typography>
            {payment.amount} ({payment.term})
          </Typography>
          {broker_fee && (
            <Typography>Broker Fee: {formatCurrency(broker_fee)}</Typography>
          )}
        </Stack>
      </StyledCell>
      {!isTripArchived && (
        <StyledCell>
          <ActionButton
            onClick={handleClick}
            aria-label={`action-menu-${guid}`}
            aria-controls={`action-menu-${guid}`}
          >
            <MoreHoriz htmlColor={Color.Dark100} />
          </ActionButton>

          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted={true}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                navigate(`/loads/${guid}`);
              }}
            >
              Edit
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate(`${pathname}/loads/delete/${guid}`);
              }}
            >
              Remove
            </MenuItem>
          </Menu>
        </StyledCell>
      )}
    </StyledRow>
  );
});

TripLoadsTableRow.displayName = 'TripLoadsTableRow';
