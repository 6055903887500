import { useSignMedia, useUploadMedia } from 'shared/data/MediaServiceAPI';
import { FileField, FileFieldProps } from 'shared/form/FileField';

export interface MediaFieldProps
  extends Pick<
    FileFieldProps,
    | 'disabled'
    | 'error'
    | 'fullWidth'
    | 'helperText'
    | 'label'
    | 'name'
    | 'onBlur'
    | 'accept'
  > {
  value?: string | null;
  onChange?: (url: string | null) => void;
  /** Controls if the files will be uploaded as restricted files.
   * These files can be opened only if signed. MediaField will automatically sign the URL.
   */
  isRestricted?: boolean;
}

export function MediaField({
  onChange,
  disabled,
  helperText,
  error: errorProp,
  isRestricted,
  ...props
}: MediaFieldProps) {
  const { error, mutate, isLoading } = useUploadMedia({
    onSuccess: (url) => onChange?.(url),
    isRestricted,
  });

  const { data } = useSignMedia(isRestricted ? props.value : undefined);

  const errorText = error?.message;

  return (
    <FileField
      {...props}
      pending={isLoading}
      disabled={disabled || isLoading}
      error={!!errorText || errorProp}
      helperText={errorText || helperText}
      value={data?.signed_url || props.value}
      onChange={(file) => {
        if (!file) {
          onChange?.(null);
        } else {
          mutate(file);
        }
      }}
    />
  );
}
