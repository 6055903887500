import { IconButton, Link, Tooltip, Typography } from '@material-ui/core';
import { Add, Comment, Edit, LocationOn, Person } from '@material-ui/icons';
import { FormattedDate } from '@superdispatch/dates';
import {
  CardButton,
  ColorDynamic,
  Column,
  Columns,
  Inline,
  OverflowText,
  Stack,
} from '@superdispatch/ui';
import { Box, DescriptionItem, TextBox } from '@superdispatch/ui-lab';
import { useMemo, useState } from 'react';
import { CalendarMonthIcon, PoundIcon } from 'shared/icons';
import { PickupAltIcon } from 'shared/icons/PickupAltIcon';
import { useFlag } from 'shared/settings/FeatureToggles';
import { BusinessTypeTag } from 'shared/ui/BusinessTypeTag';
import { HiddenForPrint } from 'shared/ui/Print';
import { joinStrings } from 'shared/utils/StringUtils';
import styled from 'styled-components';
import { EditLoadPickupDrawer } from '../core/EditLoadPickupDrawer';
import { isEmptyLoadLeg, LoadDTO } from '../data/LoadDTO';
import { ViewLoadLegNotes } from './ViewLoadLegNotes';

interface ViewLoadPickupProps {
  load: LoadDTO;
  layout?: 'print';
}

export const TooltipDescription = styled(TextBox)`
  color: ${ColorDynamic.Silver500};
`;

export function ViewLoadPickup({ load, layout }: ViewLoadPickupProps) {
  const { guid, pickup, pickup_driver_address } = load;
  const isGeoTagEnabled = useFlag('geo_tag');
  const shouldDisableEditing = useFlag('ctms_disabled_fields');
  const isEmpty = useMemo(() => isEmptyLoadLeg(pickup), [pickup]);
  const { venue, notes, actual_date, scheduled_date } = pickup;
  const [modalState, setModalState] = useState<'edit'>();

  const openDrawer = () => {
    setModalState('edit');
  };

  let canBeEdited = layout !== 'print' && load.can_be_edited;

  if (shouldDisableEditing) {
    canBeEdited = canBeEdited && !load.is_created_by_broker;
  }

  return (
    <>
      <EditLoadPickupDrawer
        loadGUID={guid}
        open={modalState === 'edit'}
        onClose={() => {
          setModalState(undefined);
        }}
      />

      {isEmpty ? (
        layout !== 'print' && (
          <HiddenForPrint>
            <CardButton
              startIcon={<Add />}
              onClick={openDrawer}
              disabled={!load.can_be_edited}
            >
              Add Pickup
            </CardButton>
          </HiddenForPrint>
        )
      ) : (
        <Stack>
          <Columns align="center" space="xsmall">
            <Column width="content">
              <PickupAltIcon />
            </Column>

            <Column width="adaptive">
              {!venue.name ? (
                <Typography variant="h3" color="textSecondary">
                  No pickup name
                </Typography>
              ) : layout === 'print' ? (
                <Typography variant="h3">{venue.name}</Typography>
              ) : (
                <OverflowText variant="h3">{venue.name}</OverflowText>
              )}
            </Column>

            {canBeEdited && (
              <Column width="content">
                <IconButton
                  aria-label="Edit Pickup Button"
                  size="small"
                  onClick={openDrawer}
                >
                  <Edit />
                </IconButton>
              </Column>
            )}
          </Columns>

          <Box paddingLeft="xsmall">
            <Stack>
              <Inline space="xxsmall">
                {!!load.pickup.venue.business_type && (
                  <BusinessTypeTag
                    businessType={load.pickup.venue.business_type}
                  />
                )}
                <DescriptionItem
                  icon={load.pickup.venue.business_type ? null : <LocationOn />}
                  fallback="No address"
                  wrap={true}
                >
                  {joinStrings(
                    ', ',
                    venue.address,
                    venue.city,
                    `${venue.state} ${venue.zip}`,
                  )}
                </DescriptionItem>
              </Inline>

              <DescriptionItem
                icon={<CalendarMonthIcon />}
                label={scheduled_date ? 'Scheduled for' : undefined}
                fallback="No scheduled pickup date"
              >
                {!!scheduled_date && (
                  <FormattedDate date={scheduled_date} variant="Date" />
                )}
              </DescriptionItem>

              {!!actual_date && (
                <Inline verticalAlign="center" space="none">
                  <DescriptionItem inset={true} label="Picked Up on">
                    <FormattedDate date={actual_date} variant="DateTime" />
                  </DescriptionItem>

                  {isGeoTagEnabled && pickup_driver_address && (
                    <Tooltip
                      placement="top"
                      interactive={true}
                      title={
                        <>
                          <TextBox align="left" color="white" variant="body">
                            Picked Up
                          </TextBox>
                          <br />
                          <TooltipDescription align="left" variant="body">
                            {pickup_driver_address}
                          </TooltipDescription>
                        </>
                      }
                    >
                      <LocationOn color="action" fontSize="small" />
                    </Tooltip>
                  )}
                </Inline>
              )}

              {!!venue.buyer_number && (
                <DescriptionItem icon={<PoundIcon />} label="Buyer Number">
                  {venue.buyer_number}
                </DescriptionItem>
              )}

              {venue.contacts.map((contact, idx) => (
                <Stack space="xxsmall" key={idx}>
                  <DescriptionItem icon={<Person />} fallback="No contact name">
                    {contact.name}
                  </DescriptionItem>

                  {!!contact.phone && (
                    <DescriptionItem inset={true} wrap={true}>
                      {contact.phone}
                    </DescriptionItem>
                  )}
                  {!!contact.phone2 && (
                    <DescriptionItem inset={true} wrap={true}>
                      {contact.phone2}
                    </DescriptionItem>
                  )}

                  {!!contact.email && (
                    <DescriptionItem inset={true}>
                      <Link href={`mailto:${contact.email}`}>
                        {contact.email}
                      </Link>
                    </DescriptionItem>
                  )}
                </Stack>
              ))}

              {!notes ? (
                <DescriptionItem
                  icon={<Comment />}
                  fallback="No pickup notes"
                />
              ) : (
                <>
                  <DescriptionItem icon={<Comment />} label="Pickup notes" />
                  <ViewLoadLegNotes
                    content={notes}
                    disableTruncate={layout === 'print'}
                  />
                </>
              )}
            </Stack>
          </Box>
        </Stack>
      )}
    </>
  );
}
