import { useDeepEqualMemo } from '@superdispatch/hooks';
import { kebabCase } from 'lodash-es';
import { useMemo } from 'react';
import { useQueryClient } from 'react-query';
import {
  APIPageQueryOptions,
  APIPageQueryResult,
  useAPIPageQuery,
} from 'shared/api/APIPageQuery';
import {
  APIQueryOptions,
  APIQueryResult,
  setAPIQueryData,
  useAPIQuery,
} from 'shared/api/APIQuery';
import {
  APIPageResponse,
  APIResponse,
  request,
  requestCarrierAPI,
} from 'shared/api/CarrierAPIClient';
import { useFlag } from 'shared/settings/FeatureToggles';
import { shallowDiff } from 'shared/utils/DataUtils';
import { SuggestedLoadDTO } from '../loads-page/suggested-loads-components/data/SuggestedLoadDTO';
import {
  LoadBOLSendDTO,
  LoadBOLTemplate,
  LoadCustomerEditDTO,
  LoadDeliveryEditDTO,
  LoadDetailsEditDTO,
  LoadPickupEditDTO,
  SendInvoiceEmailDTO,
  SendQuickBooksDTO,
} from './LoadActionsDTO';
import {
  AccountInfoDTO,
  AddLoadDTO,
  LoadCountsDTO,
  LoadDTO,
  LoadMarkAsPaidDTO,
  LoadPaymentDTO,
  loadSchema,
  LoadStage,
  LoadStagesCounts,
  LoadUpdateTerminalDTO,
  loadUpdateTerminalSchema,
  LOAD_STAGES,
  OrdersDTO,
} from './LoadDTO';
import {
  LoadGroupByOptionsDTO,
  LoadGroupByParams,
  loadGroupByParamsSchema,
} from './LoadGroupByOptionsDTO';
import { LoadsPageParams } from './LoadsPageParams';
import {
  QuickBooksSettingsDTO,
  quickBooksSettingsSchema,
} from './QuickBooksSettingsDTO';

export type LoadsImportResult = Array<{ guid: string }>;

export class LoadsAPI {
  addLoad(load: AddLoadDTO): Promise<{ guid: string }> {
    return requestCarrierAPI<APIResponse<{ guid: string }>>(
      'POST /internal/web/loads/',
      { json: load },
    ).then((response) => response.data);
  }

  uploadLoads(file: File): Promise<LoadsImportResult> {
    const body = new FormData();

    body.append('file', file);

    return requestCarrierAPI<APIResponse<LoadsImportResult>>(
      'POST /internal/web/loads/import/',
      { body },
    ).then((response) => response.data);
  }

  duplicateLoad(guid: string): Promise<LoadDTO> {
    return requestCarrierAPI<APIResponse<LoadDTO>>(
      `POST /internal/web/loads/${guid}/duplicate/`,
    ).then((response) => response.data);
  }

  archiveLoad(guid: string): Promise<APIResponse> {
    return requestCarrierAPI([
      'PATCH /internal/web/loads/{guid}/archive/',
      { guid },
    ]);
  }

  unarchiveLoad(guid: string): Promise<APIResponse> {
    return requestCarrierAPI([
      'PATCH /internal/web/loads/{guid}/unarchive/',
      { guid },
    ]);
  }

  deleteLoad(guid: string): Promise<APIResponse> {
    return requestCarrierAPI([
      'DELETE /internal/web/loads/{guid}/delete/',
      { guid },
    ]);
  }

  restoreLoad(guid: string): Promise<APIResponse> {
    return requestCarrierAPI([
      'PATCH /internal/web/loads/{guid}/restore/',
      { guid },
    ]);
  }

  assignDriver(guid: string, driverGUID: string): Promise<APIResponse> {
    return requestCarrierAPI(
      ['POST /internal/web/loads/{guid}/assign/', { guid }],
      { json: { driver_guid: driverGUID } },
    );
  }

  reassignDriver(guid: string, driverGUID: string): Promise<APIResponse> {
    return this.unassignDriver(guid).then(() =>
      this.assignDriver(guid, driverGUID),
    );
  }

  unassignDriver(guid: string): Promise<APIResponse> {
    return requestCarrierAPI([
      'POST /internal/web/loads/{guid}/unassign/',
      { guid },
    ]);
  }

  markAsNew(guid: string): Promise<APIResponse> {
    return requestCarrierAPI([
      'POST /internal/web/loads/{guid}/mark-as-new/',
      { guid },
    ]);
  }

  markAsPickedUp(guid: string): Promise<APIResponse> {
    return requestCarrierAPI([
      'POST /internal/web/loads/{guid}/mark-as-picked-up/',
      { guid },
    ]);
  }

  markAsDelivered(guid: string): Promise<APIResponse> {
    return requestCarrierAPI([
      'POST /internal/web/loads/{guid}/mark-as-delivered/',
      { guid },
    ]);
  }

  markAsPaid(guid: string, values: LoadMarkAsPaidDTO): Promise<APIResponse> {
    return requestCarrierAPI(
      ['POST /internal/web/loads/{guid}/mark-as-paid/', { guid }],
      { json: values },
    );
  }

  markAsUnpaid(guid: string): Promise<APIResponse> {
    return requestCarrierAPI([
      'POST /internal/web/loads/{guid}/mark-as-unpaid/',
      { guid },
    ]);
  }

  addTerminal(
    guid: string,
    terminalGUID: string,
  ): Promise<APIResponse<LoadUpdateTerminalDTO>> {
    return requestCarrierAPI<APIResponse>(
      ['PATCH /internal/web/loads/{guid}/add-terminal/', { guid }],
      { json: { terminal_guid: terminalGUID } },
    ).then((response) => ({
      ...response,
      data: loadUpdateTerminalSchema.cast(response.data),
    }));
  }

  removeLastTerminal(
    guid: string,
  ): Promise<APIResponse<LoadUpdateTerminalDTO>> {
    return requestCarrierAPI<APIResponse>([
      'PATCH /internal/web/loads/{guid}/remove-terminal/',
      { guid },
    ]).then((response) => ({
      ...response,
      data: loadUpdateTerminalSchema.cast(response.data),
    }));
  }

  markAsInTerminal(guid: string, terminalGUID: string): Promise<APIResponse> {
    return requestCarrierAPI(
      ['PATCH /internal/web/loads/{guid}/mark-as-in-terminal/', { guid }],
      { json: { terminal_guid: terminalGUID } },
    );
  }

  dispatchToCarrier(guid: string): Promise<APIResponse> {
    return requestCarrierAPI([
      'POST /internal/web/loads/{guid}/dispatch-to-carrier/',
      { guid },
    ]);
  }

  removeDispatchedToCarrierLabel(guid: string): Promise<APIResponse> {
    return requestCarrierAPI([
      'POST /internal/web/loads/{guid}/remove-dispatched-to-carrier-label/',
      { guid },
    ]);
  }

  getQuickBooksSettings(): Promise<QuickBooksSettingsDTO> {
    return requestCarrierAPI(
      'GET /quickbooks/desktop/carrier-connection-info/',
    ).then(({ data }) => quickBooksSettingsSchema.cast(data));
  }

  getLoadInvoiceInfo(guid: string) {
    return requestCarrierAPI<
      APIResponse<{ qb_desktop: { connected: boolean } }>
    >(['GET /accounting/invoice-info/{?order_guid}', { order_guid: guid }]);
  }

  getBolPDF(guid: string) {
    return request(['GET /internal/web/loads/{guid}/bol-pdf/', { guid }]);
  }

  getInvoicePDF(guid: string) {
    return request(['GET /internal/web/loads/{guid}/invoice-pdf/', { guid }]);
  }
}

export function useLoadsAPI() {
  return useMemo(() => {
    function patchLoadDetails(
      guid: string,
      updated: LoadDetailsEditDTO,
      original?: LoadDetailsEditDTO,
    ) {
      return requestCarrierAPI(
        ['PATCH /internal/web/loads/{guid}/edit/', { guid }],
        { json: shallowDiff(updated, original) },
      );
    }

    function editLoadPickup(guid: string, json: LoadPickupEditDTO) {
      return requestCarrierAPI(
        ['POST /internal/web/loads/{guid}/edit-pickup-info/', { guid }],
        { json },
      );
    }

    function editLoadDelivery(guid: string, json: LoadDeliveryEditDTO) {
      return requestCarrierAPI(
        ['POST /internal/web/loads/{guid}/edit-delivery-info/', { guid }],
        { json },
      );
    }

    function editLoadCustomer(guid: string, json: LoadCustomerEditDTO) {
      return requestCarrierAPI(
        ['POST /internal/web/loads/{guid}/edit-customer-info/', { guid }],
        { json },
      );
    }

    function editLoadPayment(guid: string, json: LoadPaymentDTO) {
      return requestCarrierAPI(
        ['POST /internal/web/loads/{guid}/edit-payment-info/', { guid }],
        { json },
      );
    }

    function addLoadNote(guid: string, text: string) {
      return requestCarrierAPI(
        ['POST /internal/web/loads/{guid}/add-note/', { guid }],
        { json: { text } },
      );
    }

    function deleteLoadNote(guid: string, noteId: number) {
      return requestCarrierAPI([
        'DELETE /internal/web/loads/{guid}/notes/{note_id}/',
        { guid, note_id: noteId },
      ]);
    }

    function sendBOL(guid: string, values: LoadBOLSendDTO) {
      return requestCarrierAPI(
        ['POST /internal/web/bol/{guid}/send/', { guid }],
        {
          json: values,
        },
      );
    }

    function cancelLoad(
      guid: string,
      values: { cancel_reason_type: string; cancel_reason: string },
    ) {
      return requestCarrierAPI(
        ['POST /internal/web/loads/{guid}/cancel/', { guid }],
        {
          json: values,
        },
      );
    }

    return {
      patchLoadDetails,
      editLoadPickup,
      editLoadDelivery,
      editLoadCustomer,
      editLoadPayment,
      addLoadNote,
      deleteLoadNote,
      sendBOL,
      cancelLoad,
    };
  }, []);
}

export function useGetCombinedInvoicePDF(
  params: {
    load_guids: string[];
    invoice_number: string;
    invoice_date: string;
    bol_template: string;
    factor_this_invoice: boolean;
    is_carrier_requested_superpay: boolean | undefined;
  },
  options?: APIQueryOptions<{ html: string }>,
): APIQueryResult<{ html: string }> {
  return useAPIQuery(
    ['get_combined_invoice_pdf', 'invoice', { params }],
    () =>
      requestCarrierAPI([
        'GET /internal/web/loads/preview-invoice/{?params*}',
        { params },
      ]),
    options,
  );
}

export function useSendInvoiceEmail(
  invoiceEmail: SendInvoiceEmailDTO,
  options?: APIQueryOptions<SendInvoiceEmailDTO>,
) {
  const { invalidateLoads } = useLoadsCache();

  return useAPIQuery(
    ['send_invoice_email', 'invoice'],
    () =>
      requestCarrierAPI('POST /internal/web/loads/send-invoice-email/', {
        json: invoiceEmail,
      }),
    {
      ...options,
      //there do need to keep data on cache, because while open/close drawer multiple times it should refetch data and isLoading status need to work
      cacheTime: 0,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        invalidateLoads();
        options?.onSuccess?.(data);
      },
    },
  );
}

export function useQbInvoice(
  type: 'online' | 'desktop',
  quickBook: SendQuickBooksDTO,
  options?: APIQueryOptions<SendQuickBooksDTO>,
) {
  const { invalidateLoads } = useLoadsCache();

  return useAPIQuery(
    [`send_invoice_quickbook_${type}`, 'quickbook'],
    () =>
      requestCarrierAPI(`POST /quickbooks/${type}/send-combined-invoice/`, {
        json: quickBook,
      }),
    {
      ...options,
      //there do need to keep data on cache, because while open/close drawer multiple times it should refetch data and isLoading status need to work
      cacheTime: 0,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        invalidateLoads();
        options?.onSuccess?.(data);
      },
    },
  );
}

export function useLoadsPage(
  params: LoadsPageParams,
  options?: APIPageQueryOptions<LoadDTO>,
): APIPageQueryResult<LoadDTO> {
  return useAPIPageQuery(
    ['loads', 'page', params],
    () => {
      const { stage, ...query } = normalizeParams(params);

      if (stage === 'receivable/past-due') {
        return requestCarrierAPI([
          'GET /internal/web/loads/receivable/past-due/{?query*}',
          { query },
        ]);
      }

      return requestCarrierAPI([
        'GET /internal/web/loads/{stage}/{?query*}',
        { query, stage: kebabCase(stage) },
      ]);
    },
    {
      keepPreviousData: params.stage !== 'active',
      ...options,
    },
  );
}

function normalizeParams(params: LoadsPageParams) {
  const {
    number,
    vehicle,
    vin,
    order_id,
    customer,
    pickup_name,
    delivery_name,
    internal_load_id,
    invoice_id,
    all,
    stage,
  } = params;

  // if stage is active, there have to be send with one of the criteria, otherwise it will return all loads
  if (
    !(
      number ||
      vehicle ||
      vin ||
      order_id ||
      customer ||
      pickup_name ||
      delivery_name ||
      internal_load_id ||
      invoice_id ||
      all
    ) &&
    stage === 'active'
  ) {
    return { ...params, stage: 'new' };
  }

  return params;
}

export function useSuggestedLoads(): APIQueryResult<SuggestedLoadDTO[]> {
  const shouldShowSuggestedLoads = useFlag('ctms_suggested_loads');
  const isSuggestedLoadsSecondIterationEnabled = useFlag(
    'ctms_suggested_loads_second_iteration',
  );

  return useAPIQuery(
    ['loads', 'suggested'],
    () => {
      return requestCarrierAPI('GET /internal/web/loads/suggested/');
    },
    {
      enabled:
        shouldShowSuggestedLoads && !isSuggestedLoadsSecondIterationEnabled,
    },
  );
}

export function useLoadCounts(
  params: LoadsPageParams,
): APIQueryResult<Map<LoadStage, LoadCountsDTO>> {
  return useAPIQuery(
    ['loads', 'count', params],
    () => {
      const { page, stage, order_by, ...query } = params;

      return requestCarrierAPI([
        'GET /internal/web/loads/count/{?query*}',
        { query },
      ]);
    },
    {
      keepPreviousData: true,
      enabled: params.stage !== 'active',
      normalize(response: LoadStagesCounts) {
        const counts = new Map<LoadStage, LoadCountsDTO>();

        for (const stage of LOAD_STAGES) {
          const value = response[stage];

          if (value != null) {
            counts.set(stage, value);
          }
        }

        return counts;
      },
    },
  );
}

export function useLoadsCache() {
  const queryClient = useQueryClient();

  return useMemo(() => {
    function invalidateLoads() {
      void queryClient.invalidateQueries('loads');
    }

    function setLoadsPageItems(
      params: LoadsPageParams,
      fn: (items: LoadDTO[]) => LoadDTO[],
    ) {
      setAPIQueryData<APIPageResponse<LoadDTO>>(
        queryClient,
        ['loads', 'page', params],
        (response) => ({ ...response, data: fn(response.data) }),
      );
      invalidateLoads();
    }

    return { invalidateLoads, setLoadsPageItems };
  }, [queryClient]);
}

export function useLoadsGroup(
  params: LoadGroupByParams,
): APIQueryResult<LoadGroupByOptionsDTO> {
  const query = useDeepEqualMemo(
    () => loadGroupByParamsSchema.cast(params),
    [params],
  );

  return useAPIQuery(['loads', 'groupBy', query], () =>
    requestCarrierAPI(['GET /internal/web/loads/groupby/{?query*}', { query }]),
  );
}

export function useLoadDetails(
  guid: null | string | undefined,
): APIQueryResult<LoadDTO> {
  return useAPIQuery(
    ['loads', 'item', { guid }],
    () => requestCarrierAPI(['GET /internal/web/loads/{guid}/', { guid }]),
    { enabled: !!guid, schema: loadSchema },
  );
}

export interface LoadBOLPreview {
  html: string;
}

export interface LoadBOLPReviewParams {
  template?: null | LoadBOLTemplate;
  use_broker_info?: null | boolean;
}

export function useLoadBOLPreview(
  guid: null | string | undefined,
  params?: LoadBOLPReviewParams,
): APIQueryResult<LoadBOLPreview> {
  return useAPIQuery(
    ['loads', 'bolPreview', { guid, params }],
    () =>
      requestCarrierAPI([
        'GET /internal/web/bol/{guid}/preview/{?params*}',
        { guid, params },
      ]),
    { enabled: !!guid },
  );
}

export function useRelatedLoads(guid: string): APIQueryResult<
  Array<{
    guid: string;
    number: string;
    status: string;
    is_in_terminal: boolean;
  }>
> {
  return useAPIQuery(
    ['loads-related', guid],
    () => {
      return requestCarrierAPI([
        'GET /internal/web/loads/{guid}/loads-to-cancel/',
        { guid },
      ]);
    },
    { enabled: !!guid, refetchOnWindowFocus: false },
  );
}

export interface OrdersPageParams {
  q?: string;
  page_size?: number;
  page?: number;
  archived: boolean;
  broker_name: string;
}

export function useOrders(
  guid?: string,
  params?: OrdersPageParams,
  options?: APIQueryOptions<OrdersDTO[]>,
): APIQueryResult<OrdersDTO[]> {
  return useAPIQuery(
    ['order_list', 'list', { params, guid }],
    () =>
      requestCarrierAPI([
        'GET /internal/web/accounting/orders/{?page,params*}',
        { params },
      ]),
    {
      ...options,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
    },
  );
}

interface AccountInfoParams {
  order_guid?: string;
  skip_customer_name?: boolean;
}

export function useAccountingInfo(
  params?: AccountInfoParams,
  options?: APIQueryOptions<AccountInfoDTO>,
): APIQueryResult<AccountInfoDTO> {
  return useAPIQuery(
    ['account_info', 'item', { params }],
    () =>
      requestCarrierAPI([
        'GET /accounting/invoice-info/{?page,params*}',
        { params },
      ]),
    {
      ...options,
      enabled: !!params?.order_guid,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
    },
  );
}
