import { Fade, Typography } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import {
  ColorDynamic,
  Column,
  Columns,
  Inline,
  Stack,
} from '@superdispatch/ui';
import {
  Alert,
  Box,
  Button,
  DescriptionLineItem,
  TextBox,
} from '@superdispatch/ui-lab';
import {
  CountryFieldOption,
  countryFieldOptions,
} from 'shared/form/CountryFieldOptions';
import { trackEvent } from 'shared/helpers/Analytics';
import { formatCurrency } from 'shared/helpers/IntlHelpers';
import { useSearchParam } from 'shared/helpers/SearchParamsHelpers';
import { openExternalURL } from 'shared/helpers/URLHelpers';
import { useShowCelebrationNotification } from 'shared/modules/subscription/core/useShowCelebrationNotification';
import { trackSubscriptionEvent } from 'shared/modules/subscription/data/SubscriptionAnalytics';
import {
  useBillingAddress,
  useEstimateTaxPrice,
  useReactivateSubscriptionMutation,
  useSubscriptionSettings,
  useUpdateSubscriptionSettingsMutation,
} from 'shared/modules/subscription/SubscriptionAPI';
import {
  LocationState,
  useNextSubscriptionPlan,
} from 'shared/modules/subscription/SubscriptionSalesTaxDrawer';
import { BackButton } from 'shared/ui/BackButton';
import { joinStrings } from 'shared/utils/StringUtils';
import { useShowTrialExpiredDialog } from '../data/useShowTrialExpiredDialog';

interface PaywallPaymentTaxFormProps {
  billingInfo: LocationState;
  onBack: () => void;
}

export function PaywallPaymentTaxForm({
  billingInfo,
  onBack,
}: PaywallPaymentTaxFormProps) {
  const { cardDetails, token, vaultToken, seatsCount } = billingInfo;
  const { data: taxPrice } = useEstimateTaxPrice({}, seatsCount);
  const { data: subscriptionDetails } = useSubscriptionSettings();
  const { data: billingAddress } = useBillingAddress();
  const [pagePath] = useSearchParam('path');
  const { showCelebrationNotification } = useShowCelebrationNotification();
  const { closeTrialExpiredDialog } = useShowTrialExpiredDialog();

  const {
    mutate: updateSubscription,
    isLoading,
    error,
  } = useUpdateSubscriptionSettingsMutation({
    onSuccess: () => {
      trackEvent('Carrier Upgraded To Pro Plan', {
        utm_medium: pagePath,
        utm_source: 'paywall',
      });
      closeTrialExpiredDialog();
      openExternalURL(pagePath || '/loadboard');
      showCelebrationNotification();
    },
  });

  const {
    mutate: reactivate,
    isLoading: isReactivateLoading,
    error: reactivateError,
  } = useReactivateSubscriptionMutation({
    onSuccess: () => {
      trackSubscriptionEvent({
        name: 'Carrier Reactivated Subscription',
      });
      closeTrialExpiredDialog();
      showCelebrationNotification();
      openExternalURL('/loadboard');
    },
  });

  const country = getCountryName(
    billingAddress?.billing_country as CountryFieldOption,
  );
  const hasCreditCard = !!cardDetails?.creditCard;
  const isSubscriptionCanceled = subscriptionDetails?.status === 'Canceled';

  const nextPlan = useNextSubscriptionPlan(seatsCount);

  return (
    <Fade in={!!billingInfo}>
      <Stack space="large">
        <Inline noWrap={true} verticalAlign="center" space="small">
          <BackButton onClick={onBack} />
          <Typography variant="h3">Upgrading Plan</Typography>
        </Inline>
        <Stack space="medium">
          <Box paddingBottom="small">
            <Stack space="large">
              {hasCreditCard && (
                <Stack space="xsmall">
                  <Typography color="textSecondary" variant="h6">
                    CARD DETAILS
                  </Typography>
                  <Typography color="textSecondary">
                    Card Number: •••• {cardDetails.creditCard.details.lastFour}
                  </Typography>
                  <Typography color="textSecondary">
                    Expiration Date:{' '}
                    {cardDetails.creditCard.details.expirationMonth} /{' '}
                    {cardDetails.creditCard.details.expirationYear}
                  </Typography>
                </Stack>
              )}

              <Stack space="xsmall">
                <Typography color="textSecondary" variant="h6">
                  Billing Address
                </Typography>
                <Typography color="textSecondary">
                  {joinStrings(
                    ', ',
                    billingAddress?.billing_state,
                    billingAddress?.billing_zip,
                    country,
                  )}
                </Typography>
              </Stack>
            </Stack>
          </Box>

          <Stack space="small">
            {nextPlan && (
              <Stack space="small">
                <Stack space="xxsmall">
                  <DescriptionLineItem
                    title={
                      <TextBox variant="body" color="primary">
                        {nextPlan.type}
                      </TextBox>
                    }
                  >
                    <TextBox align="right" color="primary">
                      {formatCurrency(nextPlan.price)}
                    </TextBox>
                  </DescriptionLineItem>

                  <TextBox color="secondary">
                    {formatCurrency(
                      Number(nextPlan.price) / Number(nextPlan.drivers_count),
                    )}{' '}
                    / driver / month x {nextPlan.drivers_count} drivers
                  </TextBox>
                  <TextBox color="secondary">Billed Monthly</TextBox>
                </Stack>
              </Stack>
            )}

            <Stack space="xsmall">
              <DescriptionLineItem
                title={
                  <TextBox variant="body" color="primary">
                    Subtotal
                  </TextBox>
                }
              >
                <TextBox align="right" color="primary">
                  {formatCurrency(taxPrice?.sub_total || nextPlan?.price)}
                </TextBox>
              </DescriptionLineItem>
              {taxPrice?.taxes && taxPrice.taxes.length > 0 && (
                <Columns>
                  <Column>
                    <Box />
                  </Column>
                  <Column width="2/3">
                    <Stack align="right" space="xxsmall">
                      {taxPrice.taxes.map((tax) => {
                        return (
                          <DescriptionLineItem
                            key={tax.name}
                            title={
                              <TextBox color="secondary">
                                {tax.description}
                              </TextBox>
                            }
                          >
                            <TextBox align="right" color="secondary">
                              {formatCurrency(tax.amount)}
                            </TextBox>
                          </DescriptionLineItem>
                        );
                      })}
                    </Stack>
                  </Column>
                </Columns>
              )}
            </Stack>

            <DescriptionLineItem
              title={<TextBox variant="heading-4">Total</TextBox>}
            >
              <TextBox variant="heading-4" align="right">
                {formatCurrency(taxPrice?.total || nextPlan?.price)}
              </TextBox>
            </DescriptionLineItem>
          </Stack>
          <Box paddingTop="xsmall">
            <Button
              pending={isLoading || isReactivateLoading}
              onClick={() => {
                if (isSubscriptionCanceled) {
                  reactivate();
                } else {
                  updateSubscription({
                    payment_method_nonce: token,
                    id: token,
                    id_at_vault: vaultToken,
                    seats_count: seatsCount,
                  });
                }
              }}
              size="large"
              fullWidth={true}
            >
              Upgrade Now
            </Button>
          </Box>
        </Stack>
        {(error || reactivateError) && !isLoading && !isReactivateLoading && (
          <Box maxWidth="430px" paddingRight="large" paddingLeft="large">
            <Alert
              icon={<Info fontSize="small" htmlColor={ColorDynamic.Red500} />}
              severity="critical"
            >
              {error?.message || reactivateError?.message}
            </Alert>
          </Box>
        )}
      </Stack>
    </Fade>
  );
}

function getCountryName(countryCode: CountryFieldOption) {
  return countryFieldOptions.get(countryCode);
}
