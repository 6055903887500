import { RouteObject } from 'react-router-dom';
import { SuperPayVerificationStatus } from 'shared/settings/CarrierSettingsAPI';
import { AccountDetailsPage } from './superpay/account-details/AccountDetailsPage';
import { BankAccountPage } from './superpay/bank-account/BankAccountPage';
import { BusinessDetailsPage } from './superpay/business-details/BusinessDetailsPage';
import { PromoPage } from './superpay/promo/PromoPage';
import {
  SuperPayRoute,
  SUPERPAY_PROFILE_ROUTES,
} from './superpay/SuperPayRoute';

const getPath = (status: SuperPayVerificationStatus | null | undefined) => {
  switch (status) {
    case 'restricted':
      return [SUPERPAY_PROFILE_ROUTES.promo];
    case 'bank_account':
      return [SUPERPAY_PROFILE_ROUTES.bankDetails];
    case 'bank_account_verification':
    case 'verified':
    case 'bank_account_failed':
    case 'bank_account_errored':
      return [SUPERPAY_PROFILE_ROUTES.accountDetails];
    default:
      return [
        SUPERPAY_PROFILE_ROUTES.promo,
        SUPERPAY_PROFILE_ROUTES.businessDetails,
      ];
  }
};

export const superPayRoutes: RouteObject[] = [
  {
    path: 'superpay',
    children: [
      {
        path: 'onboarding',
        children: [
          {
            path: 'create-account',
            element: (
              <SuperPayRoute
                path={SUPERPAY_PROFILE_ROUTES.businessDetails}
                getPathByStatus={getPath}
              >
                <BusinessDetailsPage />
              </SuperPayRoute>
            ),
          },
          {
            path: 'bank-account',
            element: (
              <SuperPayRoute
                path={SUPERPAY_PROFILE_ROUTES.bankDetails}
                getPathByStatus={getPath}
              >
                <BankAccountPage />
              </SuperPayRoute>
            ),
          },
          {
            path: 'account-details/:page?',
            element: (
              <SuperPayRoute
                path={SUPERPAY_PROFILE_ROUTES.accountDetails}
                getPathByStatus={getPath}
              >
                <AccountDetailsPage />
              </SuperPayRoute>
            ),
          },
        ],
      },
      {
        index: true,
        element: (
          <SuperPayRoute
            path={SUPERPAY_PROFILE_ROUTES.promo}
            getPathByStatus={getPath}
          >
            <PromoPage />
          </SuperPayRoute>
        ),
      },
    ],
  },
];
