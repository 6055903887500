import {
  Dialog,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
  IconButton as MuiIconButton,
  Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useFormikEnhanced } from '@superdispatch/forms';
import { ColorDynamic } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { FormikProvider } from 'formik';
import styled from 'styled-components';
import { TripActionsDTO, tripFormSchema } from '../data/TripsDTO';
import { getCenter, getLoads } from '../data/TripsUtils';
import { TripFormMap } from '../trip-form-page/TripFormMap';
import { TripEditRouteForm } from './TripEditRouteForm';

const DialogTitle = styled(MuiDialogTitle)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${ColorDynamic.Silver400};
`;

const DialogContent = styled(MuiDialogContent)`
  padding: 0;
`;

const IconButton = styled(MuiIconButton)`
  padding: 2px;
`;

const Wrapper = styled.div`
  display: flex;
  height: 700px;
  max-height: 750px;
`;
const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    overflow-x: hidden;
  }
`;

interface TripLoadDialogProps {
  open: boolean;
  onClose: () => void;
  trip: TripActionsDTO;
}

export function TripLoadDialog({ open, onClose, trip }: TripLoadDialogProps) {
  return (
    <StyledDialog maxWidth="xl" fullWidth={true} open={open} onClose={onClose}>
      <Content trip={trip} onClose={onClose} />
    </StyledDialog>
  );
}

interface ContentProps {
  trip: TripActionsDTO;
  onClose: () => void;
}

function Content({ trip, onClose }: ContentProps) {
  const { route } = trip;

  const end_address = route?.destination;
  const start_address = route?.origin;
  const loads = getLoads(trip.loads);
  const center = getCenter(route?.origin);

  const formik = useFormikEnhanced({
    validationSchema: tripFormSchema,
    initialValues: {
      loads,
      end_address,
      start_address,
      start_address_guid: route?.origin?.guid,
      end_address_guid: route?.destination?.guid,
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function -- that saves data while form changes
    onSubmit: () => {},
  });

  return (
    <FormikProvider value={formik}>
      <DialogTitle>
        <Typography variant="h3">Edit Route</Typography>
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Wrapper>
          <TripEditRouteForm
            tripGuid={trip.guid}
            driverGuid={trip.driver?.guid}
          />

          <Box width="100%" height="700px">
            <TripFormMap center={center} />
          </Box>
        </Wrapper>
      </DialogContent>
    </FormikProvider>
  );
}
