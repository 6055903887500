import { Collapse } from '@material-ui/core';
import { ColorDynamic, Inline, VisibilityObserver } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { once } from 'lodash-es';
import { useEffect, useMemo, useRef } from 'react';
import { TransitionGroup } from 'react-transition-group';
import {
  MutationDrawer,
  MutationDrawerContent,
} from 'shared/form/MutationDrawer';
import { trackEvent } from 'shared/helpers/Analytics';
import { launchIntercomTour } from 'shared/helpers/Intercom';
import { LoadSuggestionsSidebarIcon } from 'shared/icons/LoadSuggestionsSidebarIcon';
import styled from 'styled-components';
import { LoadSuggestionsLoadingSkeleton } from './core/LoadSuggestionsLoadingSkeleton';
import { LoadSuggestionsNotFound } from './core/LoadSuggestionsNotFound';
import { LoadSuggestionDTO } from './data/LoadSuggestionDTO';
import {
  useLoadSuggestions,
  useMarkAsSeenLoadSuggestion,
} from './data/LoadSuggestionsAPI';
import { useSelectedDrivers } from './data/LoadSuggestionsSelectedDriver';
import { LoadSuggestionDriverField } from './LoadSuggestionDriverField';
import { LoadSuggestionsDrawerItem } from './LoadSuggestionsDrawerItem';

export interface LoadSuggestionsDrawerContentProps {
  onClose: () => void;
  onView: (load: LoadSuggestionDTO) => void;
}

export function LoadSuggestionsDrawerContent({
  onClose,
  onView,
}: LoadSuggestionsDrawerContentProps) {
  const trackEventOnce = useMemo(() => once(trackEvent), []);
  const [drivers, setDrivers] = useSelectedDrivers();
  const { data: loads, isLoading } = useLoadSuggestions(
    drivers.map((d) => d.guid),
  );

  useEffect(() => {
    if (!loads) return;

    trackEventOnce('Carrier Opened Suggested Loads Drawer', {
      current_page_path: window.location.pathname,
      count_of_drivers_selected: drivers.length,
      load_suggestion: JSON.stringify(
        loads.map((load) => ({
          guid: load.guid,
          posting_guid: load.posting_guid,
          load_suggestion_guid: load.suggestion_guid,
        })),
      ),
    });
  }, [loads, trackEventOnce, drivers]);

  useEffect(() => {
    if (loads?.length) {
      launchIntercomTour('load_suggestions_drawer');
    }
  }, [loads?.length]);

  return (
    <MutationDrawerContent
      data-intercom-target="load-suggestions-drawer"
      onClose={onClose}
      enableAutoClose={true}
      disableDrawerContent={true}
      title={
        <Box paddingTop="small" paddingBottom="small">
          <Inline verticalAlign="center">
            <LoadSuggestionsSidebarIcon htmlColor={ColorDynamic.Dark100} />
            Suggested Loads
          </Inline>
        </Box>
      }
      subtitle={
        <LoadSuggestionDriverField
          value={drivers}
          onChange={(value) => {
            setDrivers(value);
            trackEvent('Carrier Selected Driver on Load Suggestion Drawer', {
              count_of_drivers_selected: value.length,
            });
          }}
        />
      }
    >
      <Box width="560px" data-intercom-target="load-suggestions-list">
        {loads?.length === 0 && (
          <Box marginTop="xxlarge" paddingTop="xxlarge">
            <LoadSuggestionsNotFound
              hasSelection={!!drivers.length}
              onClear={() => {
                setDrivers([]);
              }}
            />
          </Box>
        )}

        {isLoading && (
          <>
            <LoadSuggestionsLoadingSkeleton />
            <LoadSuggestionsLoadingSkeleton />
          </>
        )}
        <TransitionGroup enter={false}>
          {loads?.map((load) => (
            <Collapse key={load.guid} timeout={450}>
              <VisibilityObserver
                onChange={(visibility) => {
                  if (visibility === 'visible') {
                    onView(load);
                  }
                }}
                render={({ ref, visibility }) => (
                  <LoadSuggestionsDrawerItem
                    ref={ref}
                    load={load}
                    visibilityState={visibility}
                  />
                )}
              />
            </Collapse>
          ))}
        </TransitionGroup>
      </Box>
    </MutationDrawerContent>
  );
}

const StyledDrawer = styled(MutationDrawer)`
  @media (min-width: 600px) {
    & .MuiDrawer-paper {
      max-width: 560px;
    }
  }
`;

export interface LoadSuggestionsDrawerProps {
  open: boolean;
  onClose: () => void;
}

export function LoadSuggestionsDrawer({
  open,
  onClose,
}: LoadSuggestionsDrawerProps) {
  const visibleLoadSuggestionIds = useRef(new Set<number>([]));
  const { mutate: markAsSeen } = useMarkAsSeenLoadSuggestion();

  const handleClose = () => {
    if (visibleLoadSuggestionIds.current.size > 0) {
      markAsSeen(visibleLoadSuggestionIds.current);
      visibleLoadSuggestionIds.current = new Set([]);
    }
    onClose();
  };

  return (
    <StyledDrawer open={open} onClose={handleClose}>
      <Box data-intercom-target="load-suggestions-drawer" height="100%">
        <LoadSuggestionsDrawerContent
          onClose={handleClose}
          onView={(load) => {
            if (load.is_seen === false) {
              visibleLoadSuggestionIds.current.add(load.suggestion_id);
            }
          }}
        />
      </Box>
    </StyledDrawer>
  );
}
