import { Tooltip, Typography } from '@material-ui/core';
import { formatVehicleType } from '@superdispatch/sdk';
import { Inline, Stack } from '@superdispatch/ui';
import { TextBox } from '@superdispatch/ui-lab';
import { useMemo } from 'react';
import { formatCurrency } from 'shared/helpers/IntlHelpers';
import { VehicleConditionIcon } from 'shared/ui/VehicleConditionIcon';
import { LoadVehicleDTO } from '../../data/LoadDTO';

export function LoadsPageLoadMobileVehicles({
  vehicles,
}: {
  vehicles: LoadVehicleDTO[];
}) {
  const [vehiclesToRender, vehiclesToShowOnTooltip] = useMemo(() => {
    if (vehicles.length <= 3) {
      return [vehicles, []];
    }
    return [vehicles.slice(0, 2), vehicles.slice(2)];
  }, [vehicles]);

  const additionalVehicles = vehicles.length - 3;

  return (
    <Stack space="xxsmall">
      {vehiclesToRender.map((vehicle) => (
        <Inline key={vehicle.guid} space="xxsmall" verticalAlign="center">
          <TextBox variant="body-semibold">
            {vehicle.year} {vehicle.make} {vehicle.model}
          </TextBox>
          <TextBox color="secondary">{formatVehicleType(vehicle.type)}</TextBox>
          <VehicleConditionIcon
            transportType={
              vehicle.requires_enclosed_trailer ? 'ENCLOSED' : 'OPEN'
            }
            isInoperable={!!vehicle.is_inoperable}
          />
          <Typography component="strong">
            {formatCurrency(vehicle.price)}
          </Typography>
        </Inline>
      ))}

      {additionalVehicles > 0 && (
        <Tooltip
          title={
            <Stack>
              {vehiclesToShowOnTooltip.map((v) => (
                <div key={v.guid}>
                  {v.year} {v.make} {v.model} {v.type}
                </div>
              ))}
            </Stack>
          }
        >
          <div>
            <TextBox>+{additionalVehicles} more</TextBox>
          </div>
        </Tooltip>
      )}
    </Stack>
  );
}
