import {
  APIQueryResult,
  useAPIQuery,
  UseAPIQueryOptions,
} from 'shared/api/APIQuery';
import { APIResponse } from 'shared/api/CarrierAPIClient';
import { requestCarrierAPIPublic } from 'shared/api/PublicCarrierAPIClient';
import { DriverAccountDeletionDTO } from './DriverAccountDeletionDTO';

export function useValidateDriverAccountDeletionToken(
  token: string,
  options?: UseAPIQueryOptions<DriverAccountDeletionDTO>,
): APIQueryResult<DriverAccountDeletionDTO> {
  return useAPIQuery(
    ['driver-deletion', 'validate', { token }],
    () => {
      return requestCarrierAPIPublic(
        'POST /internal/web/driver-account-deletion/validate/',
        { json: { token } },
      );
    },
    { ...options, enabled: !!token },
  );
}

export function deleteDriverAccount({
  token,
  ...json
}: {
  reason: string;
  email: string;
  token: string;
}) {
  return requestCarrierAPIPublic<APIResponse<DriverAccountDeletionDTO>>(
    'POST /internal/web/driver-account-deletion/delete/',
    { json, headers: { authorization: `Token ${token}` } },
  );
}
