import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import { Clear, Search as SearchIcon } from '@material-ui/icons';
import { useEventHandler } from '@superdispatch/hooks';
import { ColorDynamic } from '@superdispatch/ui';
import { useEffect, useState } from 'react';
import { ONE_SECOND } from 'shared/constants/NumberConstants';
import { useTimedOutEffect } from 'shared/helpers/ReactHelpers';
import { useFlag } from 'shared/settings/FeatureToggles';

interface Props {
  value: string;
  placeholder?: string;
  onChange: (value: string) => void;
}

export function TripsSearchField({
  value,
  onChange,
  placeholder = 'Search for trips...',
}: Props) {
  const [text, setText] = useState(value);
  const onChangeHandler = useEventHandler(onChange);
  const shouldShowTripChanges = useFlag('trip_improvements_v2');

  useEffect(() => {
    setText(value);
  }, [value]);

  useTimedOutEffect(
    () => {
      if (text !== value) {
        onChangeHandler(text);
      }
    },
    [text, value, onChangeHandler],
    ONE_SECOND,
  );

  return (
    <TextField
      fullWidth={true}
      variant="outlined"
      placeholder={placeholder}
      aria-label="search trips"
      value={text}
      onChange={({ target }) => {
        setText(target.value);
      }}
      onKeyDown={({ key }) => {
        if (key === 'Enter') {
          onChange(text);
        }
      }}
      InputProps={{
        startAdornment: !shouldShowTripChanges && (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: shouldShowTripChanges ? (
          <InputAdornment
            position="end"
            onClick={() => {
              onChangeHandler('');
            }}
          >
            {text ? (
              <IconButton>
                <Clear />
              </IconButton>
            ) : (
              <IconButton>
                <SearchIcon htmlColor={ColorDynamic.Blue500} />
              </IconButton>
            )}
          </InputAdornment>
        ) : (
          <InputAdornment
            position="end"
            onClick={() => {
              onChangeHandler('');
            }}
          >
            <IconButton>
              <Clear />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}
