import { InputAdornment, Link, Typography } from '@material-ui/core';
import { Warning } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { FormikEnhancedStatus, FormikTextField } from '@superdispatch/forms';
import { Color, Column, Columns, Stack } from '@superdispatch/ui';
import { useField, useFormikContext } from 'formik';
import { ChangeEvent, useEffect } from 'react';
import { SuperPayBankAccountDTO } from 'shared/modules/superpay/SuperPayDTO';
import styled from 'styled-components';
import microDepositExample from '../../assets/micro_deposit_example.png';
import { trackSuperPayEvent } from '../../data/SuperPayAnalytics';
import { VerificationErrorBanner } from './BankErrorBanners';
import { getStatusErrors } from './useBankVerificationFormDrawer';

const StyledInputAdornment = styled(InputAdornment)`
  margin-right: 0;
`;

const StyledImg = styled.img`
  display: block;
  margin: auto;
  max-width: 400px;
`;

const currencyFieldAdornment = (
  <StyledInputAdornment disableTypography={true} position="start">
    <Typography variant="body1" color="textSecondary">
      $ 0.
    </Typography>
  </StyledInputAdornment>
);

function VerifyBankAccountField({
  label,
  name,
  isDisabled,
}: {
  label: string;
  name: string;
  isDisabled: boolean;
}) {
  const [{ value: currentValue }, { error, touched }, { setValue }] =
    useField<string>(name);
  const hasError = touched && !!error;

  function parseValue(
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) {
    const value = event.target.value.trim();
    const numberValue = Number(value);
    if (Number.isNaN(numberValue)) {
      return currentValue;
    }
    return value.length < 2 ? value : value.slice(-2);
  }

  function onFocus(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    const value = event.target.value.trim();
    if (!value) {
      void setValue('00');
    }
  }

  function onBlur(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    const value = event.target.value.trim();
    if (value.length === 1) {
      // Moov is waiting format - 0.05 = 5, 0.5 = 50
      // if user set '5' - then we should send '50'
      void setValue(`${value}0`);
    }
  }

  return (
    <FormikTextField
      label={label}
      name={name}
      error={hasError}
      fullWidth={true}
      placeholder="00"
      disabled={isDisabled}
      InputProps={{
        startAdornment: currencyFieldAdornment,
      }}
      parse={parseValue}
      onFocus={onFocus}
      onBlur={onBlur}
    />
  );
}

interface Props {
  bankAccountDetails?: SuperPayBankAccountDTO;
  onContactSupport?: () => void;
}

export function BankVerificationDrawerFormContent({
  bankAccountDetails,
  onContactSupport,
}: Props) {
  const formik = useFormikContext();
  const { isIncorrectTotalSum, isIncorrectLastValues } = getStatusErrors(
    formik.status as FormikEnhancedStatus<unknown>,
  );

  useEffect(() => {
    trackSuperPayEvent({ name: 'Carrier Started Bank Account Verification' });
  }, []);

  if (!bankAccountDetails) {
    return (
      <>
        <Skeleton />
        <Skeleton />
      </>
    );
  }

  const isErrorMoov = bankAccountDetails.verification_error_count === 1;

  const isVerificationAttemptsExceeded =
    bankAccountDetails.verification_error_count != null &&
    bankAccountDetails.verification_error_count >= 2;

  const isFormDisabled =
    bankAccountDetails.verification_status === 'errored' ||
    bankAccountDetails.verification_status === 'failed' ||
    bankAccountDetails.verification_status === 'locked' ||
    isVerificationAttemptsExceeded;

  const isErrorTotalSum = isIncorrectTotalSum && !formik.isValid;
  const isErrorLastValues = isIncorrectLastValues && !formik.isValid;

  return (
    <Stack space={['small', 'large']}>
      <Stack space="small">
        <StyledImg src={microDepositExample} width="100%" />
        <Columns space="xsmall">
          <Column width="content">
            <Warning fontSize="small" htmlColor={Color.Yellow300} />
          </Column>
          <Column>
            <Stack space="xsmall">
              <Typography>
                Please enter 3 transactions that appeared in your bank account
                from <b>Super Dispatch mentioning AcctVerify</b>.
              </Typography>
              <Link
                target="_blank"
                rel="noreferrer"
                variant="caption"
                color="primary"
                href="https://support.superdispatch.com/en/articles/6985826-what-is-a-micro-deposit#h_0b602df1a8"
              >
                Learn how to verify micro-deposits.
              </Link>
            </Stack>
          </Column>
        </Columns>
      </Stack>

      <Stack space="small">
        <VerificationErrorBanner
          isErrorMoov={isErrorMoov}
          isErrorTotalSum={isErrorTotalSum}
          isErrorLastValues={isErrorLastValues}
          isDisabled={isFormDisabled}
          onContactSupport={onContactSupport}
        />

        <Typography variant="body1">Submit in any order</Typography>

        <VerifyBankAccountField
          label="First Value"
          name="firstDeposit"
          isDisabled={isFormDisabled}
        />

        <VerifyBankAccountField
          label="Second Value"
          name="secondDeposit"
          isDisabled={isFormDisabled}
        />

        <VerifyBankAccountField
          label="Third Value"
          name="thirdDeposit"
          isDisabled={isFormDisabled}
        />
      </Stack>
    </Stack>
  );
}
