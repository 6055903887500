import { useState } from 'react';
import { hasSuperDispatchExtensionInstalled } from 'shared/helpers/ExtensionHelper';
import { BecomeVerifiedDialog } from 'shared/modules/onboarding/core/BecomeVerifiedDialog';
import { useFlag } from 'shared/settings/FeatureToggles';
import { OnboardingDialog } from './core/OnboardingDialog';
import { useOnboardingDialogState } from './core/OnboardingWrapper';
import { useOnboardingDetails } from './data/OnboardingAPI';
import { useSaveOnboardingWithErrorHandler } from './data/useSaveOnboardingWithErrorHandler';
import { OnboardingSDConnectionDialog } from './OnboardingSDConnectionDialog';

export function OnboardingDialogs() {
  const {
    isOnboardingDialogOpen,
    closeOnboardingDialog,
    isBecomeVerifiedDialogOpen,
    closeBecomeVerifiedDialog,
    openOnboardingDialog,
  } = useOnboardingDialogState();
  const { mutateAsync: saveStep } = useSaveOnboardingWithErrorHandler();
  const [isSDConnectionDialogOpen, setIsSDConnectionDialogOpen] =
    useState(false);
  const isCarrierOnboardingEnabled = useFlag('ctms_onboarding');
  const { data } = useOnboardingDetails({
    enabled: isCarrierOnboardingEnabled,
  });

  const isInstalled = hasSuperDispatchExtensionInstalled();

  const handleVerificationComplete = () => {
    if (!isInstalled) {
      setIsSDConnectionDialogOpen(true);
    } else if (data?.steps?.install_chrome_extension?.status === 'pending') {
      void saveStep({
        stepName: 'install_chrome_extension',
        status: 'completed',
      });

      openOnboardingDialog();
    } else {
      openOnboardingDialog();
    }
  };

  return (
    <>
      <OnboardingDialog
        isOpen={isOnboardingDialogOpen}
        onClose={() => {
          closeOnboardingDialog();
        }}
      />

      <BecomeVerifiedDialog
        open={isBecomeVerifiedDialogOpen}
        onClose={closeBecomeVerifiedDialog}
        onCompleted={handleVerificationComplete}
      />

      <OnboardingSDConnectionDialog
        open={isSDConnectionDialogOpen}
        onClose={() => {
          void saveStep({
            stepName: 'install_chrome_extension',
            status: 'skipped',
          });

          openOnboardingDialog();
          setIsSDConnectionDialogOpen(false);
        }}
        onComplete={() => {
          setIsSDConnectionDialogOpen(false);
          void saveStep({
            stepName: 'install_chrome_extension',
            status: 'completed',
          });

          openOnboardingDialog();
        }}
      />
    </>
  );
}
