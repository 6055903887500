import {
  Fade,
  Link,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { FiberManualRecord, OpenInNew, ThumbUp } from '@material-ui/icons';
import {
  Column,
  Columns,
  ElementVisibility,
  Inline,
  Stack,
  Tag,
  useSnackbarStack,
} from '@superdispatch/ui';
import { Box, Button, TextBox } from '@superdispatch/ui-lab';
import { forwardRef, useMemo } from 'react';
import { generateGoogleMapDirectionLink } from 'shared/geo/GoogleMapUtils';
import { trackEvent } from 'shared/helpers/Analytics';
import { formatCurrency, formatNumber } from 'shared/helpers/IntlHelpers';
import { openExternalURL } from 'shared/helpers/URLHelpers';
import { BookNowIcon } from 'shared/icons/BookNowIcon';
import { PostingExclusiveTag } from 'shared/modules/loadboard/PostingExclusiveTag';
import { FormattedRelativeTimeToNow } from 'shared/ui/FormattedRelativeTimeToNow';
import { InlineBulletItems } from 'shared/ui/InlineBulletItems';
import { PaymentInfo } from 'shared/ui/PaymentInfo';
import styled from 'styled-components';
import { PostingPrivateTag } from '../loadboard/PostingPrivateTag';
import { LoadSuggestionsVehicles } from './core/LoadSuggestionsVehicles';
import { LoadSuggestionsVenues } from './core/LoadSuggestionsVenues';
import { useTrackViewedLoad } from './data/LoadSuggestionAnalytics';
import { LoadSuggestionDTO } from './data/LoadSuggestionDTO';
import {
  useDismissLoadSuggestion,
  useMarkAsSeenLoadSuggestion,
} from './data/LoadSuggestionsAPI';
import { LoadSuggestionsActivity } from './LoadSuggestionsActivity';

const PriceBox = styled(TextBox)`
  line-height: normal;
`;

export const LoadSuggestionsDrawerItem = forwardRef<
  HTMLDivElement,
  { visibilityState: ElementVisibility; load: LoadSuggestionDTO }
>(({ load, visibilityState }, ref) => {
  const { addSnackbar } = useSnackbarStack();
  const googleMapDirectionLink = useMemo(
    () =>
      generateGoogleMapDirectionLink(load.pickup.venue, load.delivery.venue),
    [load.pickup, load.delivery],
  );
  const { mutate: markAsSeen } = useMarkAsSeenLoadSuggestion();
  const { mutate: dismissLoadSuggestion, isLoading } = useDismissLoadSuggestion(
    {
      onSuccess: () => {
        addSnackbar('Load suggestion dismissed', {
          variant: 'success',
          key: 'dismiss-alert-key',
          anchorOrigin: {
            horizontal: 'right',
            vertical: 'bottom',
          },
        });
      },
    },
  );

  useTrackViewedLoad({
    loadVisibilityState: visibilityState,
    load,
  });

  return (
    <ListItem divider={true} component="div" ContainerComponent="div" ref={ref}>
      <ListItemText
        disableTypography={true}
        primary={
          <Box
            paddingTop="medium"
            paddingBottom="medium"
            paddingLeft="small"
            paddingRight="small"
          >
            <Stack space="small">
              {!!load.driver_names && (
                <LoadSuggestionsActivity driverNames={load.driver_names} />
              )}

              <Columns align="center" space="medium">
                <Column width="fluid">
                  <Stack space="xxsmall">
                    <Inline space="xxsmall">
                      {load.is_exclusive && <PostingExclusiveTag />}
                      {load.is_posted_to_private_loadboard && (
                        <PostingPrivateTag />
                      )}
                      {load.carrier_access.can_book && (
                        <Tag variant="bold" color="blue">
                          <Inline verticalAlign="center" space="xxsmall">
                            <BookNowIcon />
                            Book Now
                          </Inline>
                        </Tag>
                      )}
                    </Inline>

                    <Inline space="xxsmall" verticalAlign="bottom">
                      <PriceBox variant="heading-3">
                        {formatCurrency(load.price, {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 0,
                        })}
                      </PriceBox>

                      {!!load.price_per_mile && (
                        <TextBox variant="caption" color="secondary">
                          {formatCurrency(load.price_per_mile, {
                            maximumFractionDigits: 2,
                          })}
                          /mi
                        </TextBox>
                      )}
                    </Inline>

                    <PaymentInfo
                      method={load.payment.method}
                      terms={load.payment.terms}
                      source="Suggested Load"
                      superPayLabelTarget="_blank"
                    />
                  </Stack>
                </Column>
                <Column width="content">
                  <Stack space="xxsmall" align="right">
                    <Box data-intercom-target="load-suggestions-unseen-indicator">
                      <Inline verticalAlign="center" horizontalAlign="right">
                        <Fade
                          in={!load.is_seen}
                          timeout={{ enter: 0, appear: 0, exit: 500 }}
                        >
                          <FiberManualRecord fontSize="small" color="primary" />
                        </Fade>

                        <Typography color="textSecondary" align="right">
                          Posted{' '}
                          <FormattedRelativeTimeToNow
                            date={load.posted_to_loadboard_at}
                          />
                        </Typography>
                      </Inline>
                    </Box>
                    <TextBox color="secondary">
                      <Inline
                        space="xsmall"
                        verticalAlign="center"
                        horizontalAlign="right"
                      >
                        {load.shipper.name}
                        {!!load.shipper.rating_details && (
                          <Inline space="xxsmall" verticalAlign="center">
                            <ThumbUp fontSize="small" color="action" />
                            <TextBox color="inherit">
                              {load.shipper.rating_details.overall_rating}%
                            </TextBox>
                          </Inline>
                        )}
                      </Inline>
                    </TextBox>
                  </Stack>
                </Column>
              </Columns>

              <Stack space="medium">
                <Stack>
                  <LoadSuggestionsVenues
                    pickup={load.pickup}
                    delivery={load.delivery}
                  />
                  <InlineBulletItems>
                    <Link
                      href={googleMapDirectionLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      View Route
                    </Link>
                    {!!load.distance_miles && (
                      <TextBox color="secondary">
                        {formatNumber(load.distance_miles, {
                          maximumFractionDigits: 2,
                        })}{' '}
                        mi
                      </TextBox>
                    )}
                  </InlineBulletItems>
                </Stack>

                <LoadSuggestionsVehicles
                  vehicles={load.vehicles}
                  transportType={load.transport_type}
                />
              </Stack>
              <Columns space="medium">
                <Column width="1/3">
                  <Button
                    fullWidth={true}
                    pending={isLoading}
                    variant="neutral"
                    onClick={() => {
                      dismissLoadSuggestion(load.suggestion_id);
                    }}
                    data-intercom-target="load-suggestions-dismiss-button"
                  >
                    Dismiss
                  </Button>
                </Column>
                <Column width="2/3">
                  <Button
                    fullWidth={true}
                    startIcon={<OpenInNew color="action" />}
                    variant="neutral"
                    data-intercom-target="load-suggestions-open-details-button"
                    onClick={() => {
                      trackEvent('Carrier Clicked Suggested Load', {
                        load_guid: load.guid,
                        posting_guid: load.posting_guid,
                        load_suggestion_guid: load.suggestion_guid,
                        suggestion_based_on: !load.driver_names
                          ? undefined
                          : load.driver_names.length > 0
                          ? 'drivers'
                          : 'dispatcher_activity',
                      });
                      if (!load.is_seen) {
                        markAsSeen(new Set([load.suggestion_id]));
                      }

                      openExternalURL(
                        '/loadboard/loads/{?back_url,current_load_guid,utm_medium}',
                        {
                          back_url:
                            window.location.pathname + window.location.search,
                          current_load_guid: load.guid,
                          utm_medium: 'Suggested Load',
                          target: '_blank',
                        },
                      );
                    }}
                  >
                    Open Details
                  </Button>
                </Column>
              </Columns>
            </Stack>
          </Box>
        }
      />
    </ListItem>
  );
});

LoadSuggestionsDrawerItem.displayName = 'LoadSuggestionsDrawerItem';
