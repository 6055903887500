import { CircularProgress, Typography } from '@material-ui/core';
import { FormikPhoneField, useFormikEnhanced } from '@superdispatch/forms';
import {
  Inline,
  Stack,
  useResponsiveValue,
  useSnackbarStack,
} from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { Form, FormikProvider } from 'formik';
import { useInitialValues } from 'shared/form/AppFormik';
import operatorDark from 'shared/modules/carrier-profile/assets/operator-dark.png';
import operator from 'shared/modules/carrier-profile/assets/operator.png';
import { Image } from 'shared/ui/Image';
import styled from 'styled-components';
import {
  useCarrierFmcsaPhoneNumber,
  useCarrierProfileApi,
} from './data/PhoneVerificationAPI';
import { carrierEditFmcsaPhoneSchema } from './data/PhoneVerificationDTO';
const MainImage = styled(Image)`
  max-width: 120px;
`;

interface PhoneVerificationInputFormProps {
  onSubmitSuccess: () => void;
}

export function PhoneVerificationInputForm({
  onSubmitSuccess,
}: PhoneVerificationInputFormProps) {
  const { addSnackbar } = useSnackbarStack();
  const isMobile = useResponsiveValue(true, false);
  const { data: fmcsaPhoneInfo, isFetched } = useCarrierFmcsaPhoneNumber();
  const { editFMCSAPhone } = useCarrierProfileApi();

  const initialValues = useInitialValues(carrierEditFmcsaPhoneSchema, {
    phone_number: fmcsaPhoneInfo?.number,
  });

  const formik = useFormikEnhanced({
    initialValues,
    validationSchema: carrierEditFmcsaPhoneSchema,
    onSubmit: editFMCSAPhone,
    onSubmitSuccess() {
      onSubmitSuccess();
    },
    onSubmitFailure: (error) => {
      addSnackbar(error.message, { variant: 'error' });
    },
  });

  if (!isFetched) {
    return (
      <Inline verticalAlign="center" horizontalAlign="center">
        <CircularProgress />
      </Inline>
    );
  }

  return (
    <FormikProvider value={formik}>
      <Form>
        <Stack space="xxlarge">
          <Stack space="small">
            <MainImage src={operator} srcDark={operatorDark} />
            <Stack space="xxsmall">
              <Typography variant="h4">
                Let&apos;s verify your phone number
              </Typography>
              <Typography color="textSecondary">
                Verifying your phone number helps us keep our platform secure
                and your identity safe.
              </Typography>
            </Stack>
            <FormikPhoneField name="phone_number" label="Phone Number" />
          </Stack>
          <Button
            disabled={!formik.isValid}
            pending={formik.isSubmitting}
            type="submit"
            fullWidth={isMobile}
          >
            Submit
          </Button>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
