import { ColorDynamic } from '@superdispatch/ui';
import { createSvgIcon } from 'shared/icons/IconUtils';

export const TickDoneIcon = createSvgIcon(
  'TickDone',
  <path
    d="M2.92035 3.89469L6.48803 0.388184L7.35904 1.24507L2.92025 5.61187L0.640228 3.36882L1.5161 2.51322L2.92035 3.89469Z"
    fill={ColorDynamic.Blue300}
  />,
  {
    viewBox: '0 0 8 6',
    style: { height: '10px', width: '10px' },
  },
);
