import { parseDate } from '@superdispatch/dates';
import { useCallback, useEffect, useMemo } from 'react';
import { LocalStore, useLocalStore } from 'shared/helpers/Store';

const RESET_PASSWORD_LINK_EXPIRE_TIME = 10; //minutes
const RESET_PASSWORD_LINK_SENT_TIME_STORAGE_KEY =
  'reset_password_link_sent_time';

export function useResetPasswordLinkStorageService(
  currentDriverGUID: string | undefined,
) {
  const resetPasswordLinkSentTime = useLocalStore(
    RESET_PASSWORD_LINK_SENT_TIME_STORAGE_KEY,
  );

  const parsedResetPasswordLinkSentTime = useMemo(() => {
    if (resetPasswordLinkSentTime) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      return new Map<string, string>(JSON.parse(resetPasswordLinkSentTime));
    }
    return new Map<string, string>();
  }, [resetPasswordLinkSentTime]);

  const setSentTime = useCallback(() => {
    if (!currentDriverGUID) {
      return;
    }

    parsedResetPasswordLinkSentTime.set(
      currentDriverGUID,
      new Date().toISOString(),
    );

    LocalStore.set(
      RESET_PASSWORD_LINK_SENT_TIME_STORAGE_KEY,
      JSON.stringify(Array.from(parsedResetPasswordLinkSentTime.entries())),
    );
  }, [currentDriverGUID, parsedResetPasswordLinkSentTime]);

  const sentTime = useMemo(() => {
    if (currentDriverGUID) {
      return parseDate(parsedResetPasswordLinkSentTime.get(currentDriverGUID), {
        format: 'DateTimeISO',
      });
    }
    return undefined;
  }, [currentDriverGUID, parsedResetPasswordLinkSentTime]);

  const hasActiveLink = useMemo(() => {
    if (!sentTime) {
      return false;
    }
    const diffTime = Math.abs(sentTime.diffNow('minutes').minutes);
    return diffTime < RESET_PASSWORD_LINK_EXPIRE_TIME;
  }, [sentTime]);

  useEffect(() => {
    if (!hasActiveLink && currentDriverGUID) {
      parsedResetPasswordLinkSentTime.delete(currentDriverGUID);
      LocalStore.set(
        RESET_PASSWORD_LINK_SENT_TIME_STORAGE_KEY,
        JSON.stringify(Array.from(parsedResetPasswordLinkSentTime.entries())),
      );
    }
  }, [hasActiveLink, currentDriverGUID, parsedResetPasswordLinkSentTime]);

  return useMemo(
    () => ({
      setSentTime,
      sentTime,
      hasActiveLink,
    }),
    [setSentTime, sentTime, hasActiveLink],
  );
}

export const JOIN_REQUEST_ALREADY_SENT_ERROR = 'JoinRequestAlreadySent';
export const INVITE_ALREADY_SENT_ERROR = 'InviteAlreadySent';
export const CONNECTION_ALREADY_EXISTS_ERROR = 'ConnectionAlreadyExists';
interface GetDriverLabelProps {
  name?: string;
  email?: string;
}

export function getDriverLabel({ name, email }: GetDriverLabelProps) {
  return name ? name : email ? email : '';
}
