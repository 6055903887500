import {
  Card,
  CardContent,
  Divider,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';
import { Inline, Stack } from '@superdispatch/ui';
import { useState } from 'react';
import styled from 'styled-components';
import { LoadDTO } from '../data/LoadDTO';
import { ViewLoadChat } from './ViewLoadChat';
import { ViewLoadInternalNotesUpdated } from './ViewLoadInternalNotesUpdated';

interface ViewLoadMessagesProps {
  load: LoadDTO;
}

const StyledTabs = styled(Tabs)`
  margin: 0 -16px;
`;

const StyledDivider = styled(Divider)`
  margin: -8px -16px 0;
`;

export function ViewLoadMessages({ load }: ViewLoadMessagesProps) {
  const [tabValue, setTabValue] = useState<'notes' | 'chat'>('notes');

  return (
    <Card>
      <CardContent>
        <Stack>
          <Inline verticalAlign="center">
            <Typography variant="h3" color="textPrimary" id="messaging">
              Messages
            </Typography>
          </Inline>
          <StyledTabs
            value={tabValue}
            onChange={(_, next: 'notes' | 'chat') => {
              setTabValue(next);
            }}
          >
            <Tab value="notes" label="Internal Notes" />
            <Tab value="chat" label="Chat" />
          </StyledTabs>
          <StyledDivider />
          {tabValue === 'notes' && <ViewLoadInternalNotesUpdated load={load} />}
          {tabValue === 'chat' && <ViewLoadChat load={load} />}
        </Stack>
      </CardContent>
    </Card>
  );
}
