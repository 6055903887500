import { ColorDynamic, Stack } from '@superdispatch/ui';
import { NotesIcon } from 'shared/icons/NotesIcon';
import styled from 'styled-components';

export function splitTextByLine(text: string) {
  return (
    <Stack space="xxsmall">
      {text.split('\n').map((line, index) => (
        <span key={index}>{line}</span>
      ))}
    </Stack>
  );
}

export const StyledNotesIcon = styled(NotesIcon)`
  &:hover {
    cursor: pointer;
    & path {
      fill: ${ColorDynamic.Blue500};
    }
  }
`;
