import { ColorDynamic } from '@superdispatch/ui';
import styled from 'styled-components';

export const Timeline = styled.ul`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  margin: 0;
  padding: 0;
`;

export const TimelineSeparator = styled.div`
  flex: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

type TimelineDotColor = 'inherit' | 'info' | 'critical' | 'warning';

export const TimelineDot = styled.div<{ color?: TimelineDotColor }>`
  display: flex;
  padding: 2px;
  align-self: baseline;
  margin-top: 14px;
  margin-bottom: 8px;
  border-radius: 50%;
  border: 2px solid transparent;

  background-color: ${({ color }) => {
    switch (color) {
      case 'inherit':
        return 'inherit';
      case 'critical':
        return ColorDynamic.Red300;
      case 'warning':
        return ColorDynamic.Yellow300;
      default:
        return ColorDynamic.Silver400;
    }
  }};
`;

export const TimelineConnector = styled.div`
  width: 1px;
  flex-grow: 1;
  background-color: ${ColorDynamic.Silver400};
`;

export const TimelineContent = styled.div<{ isUnRead?: boolean }>`
  width: 100%;
  flex-shrink: 1;
  flex-grow: 0;
  padding: 8px;
  border-radius: 8px;
  margin-bottom: 8px;
  margin-left: 8px;
  transition: 2s all linear;
  background-color: ${({ isUnRead }) =>
    isUnRead ? ColorDynamic.Yellow50 : ColorDynamic.White};
`;

export const TimelineItem = styled.li`
  display: flex;
  position: relative;
  list-style: none;
  min-height: 36px;
`;
