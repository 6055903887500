import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import { Stack } from '@superdispatch/ui';
import {
  AppThemeModeSetting,
  setThemeModeSetting,
  useThemeModeSetting,
} from 'shared/theme/AppThemeProvider';

export function SettingsAppearance() {
  const themeSetting = useThemeModeSetting();

  return (
    <Stack space="xsmall">
      <Typography>Appearance</Typography>
      <Stack space="xxsmall">
        <Typography variant="body2">
          Customize how Carrier TMS looks on your device.
        </Typography>
      </Stack>
      <FormControl component="fieldset">
        <RadioGroup
          value={themeSetting}
          onChange={(event) => {
            setThemeModeSetting(event.target.value as AppThemeModeSetting);
          }}
        >
          <FormControlLabel
            value="system"
            control={<Radio />}
            label="Use system settings"
          />
          <FormControlLabel value="light" control={<Radio />} label="Light" />
          <FormControlLabel value="dark" control={<Radio />} label="Dark" />
        </RadioGroup>
      </FormControl>
    </Stack>
  );
}
