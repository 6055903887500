import { Typography } from '@material-ui/core';
import { Column, Columns } from '@superdispatch/ui';
import { DottedLine } from 'shared/ui/DottedLine';

interface ViewLoadPaymentCardRowProps {
  title: string;
  e2eValue: string;
  value: string | number | JSX.Element;
  boldValue?: boolean;
}

export const ViewLoadPaymentCardRow = ({
  title,
  e2eValue,
  value,
  boldValue,
}: ViewLoadPaymentCardRowProps) => (
  <Columns align="center">
    <Column width="content">
      <Typography color="textSecondary" gutterBottom={true}>
        {title}
      </Typography>
    </Column>
    <Column width="fluid">
      <DottedLine />
    </Column>
    <Column width="content">
      <Typography
        align="right"
        color="textPrimary"
        variant={boldValue ? 'h4' : 'body2'}
        data-e2e={e2eValue}
      >
        {value}
      </Typography>
    </Column>
  </Columns>
);
